import { Check } from "lucide-react";
import { Button } from "sui";

import { i18n } from "@lib/i18n";
import { queries } from "@lib/queries";
import { useQueryClient } from "@tanstack/react-query";

import ActionBar from "../../../../components/ActionBar";

const SaveBar = ({ resetAction, saveAction, canSave, loading }) => {
    const queryClient = useQueryClient();

    return (
        <ActionBar maxWidth='800px'>
            <div className='settings__action'>
                <div className='settings__action__content'>{i18n.settings_actions_content()}</div>
                <div className='settings__action__buttons'>
                    {resetAction && (
                        <button
                            type='button'
                            className='settings__action__discard'
                            onClick={resetAction}
                        >
                            {i18n.settings_actions_discard()}
                        </button>
                    )}
                    <Button
                        intention='secondary'
                        icon={
                            <div className='flex-shrink-0'>
                                <Check className='h-5 w-5' />
                            </div>
                        }
                        onClick={() => {
                            queryClient.invalidateQueries(queries.company.completionStatus());
                            saveAction();
                        }}
                        disabled={!canSave}
                        loading={loading}
                    >
                        {i18n.settings_actions_save()}
                    </Button>
                </div>
            </div>
        </ActionBar>
    );
};

export default SaveBar;
