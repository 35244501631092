import { forwardRef } from "react";
import { Button } from "saphir";
import { Modal, ModalContent, ModalFooter, ModalHeader } from "sui";

import { i18n } from "@lib/i18n";

type Props = {
    deleteJobDescription: () => void;
    isDeletePending: boolean;
};
export const DeleteJobDescriptionModal = forwardRef<HTMLDialogElement, Props>(
    ({ deleteJobDescription, isDeletePending }, ref) => (
        <Modal ref={ref}>
            <ModalHeader title={i18n.modal_delete_job_description_title()} />
            <ModalContent>
                <p>{i18n.modal_delete_job_description_content()}</p>
            </ModalContent>
            <ModalFooter
                cancelButtonLabel={i18n.cancel()}
                mainButton={
                    <Button
                        type='submit'
                        disabled={isDeletePending}
                        loading={isDeletePending}
                        onClick={deleteJobDescription}
                    >
                        {i18n.label_delete()}
                    </Button>
                }
            ></ModalFooter>
        </Modal>
    ),
);
