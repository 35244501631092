import { useContext, useEffect, useState } from "react";
import clsx from "clsx";
import { Pencil, Plus, X } from "lucide-react";
import { Button } from "saphir";
import { Chip, ModalSimple, Overlay, SectionHeader, Select, SlidingWrapper } from "side-ui";

import { i18n, i18nDK } from "@lib/i18n";
import { queries } from "@lib/queries";
import { normalizeLanguages, sortLanguagesByLevel } from "@lib/queries/normalizeJobDescriptions";
import { useQuery } from "@tanstack/react-query";

import { keyPressHandler } from "../../../utils";
import { JobDescriptionContext } from "../../JobDescriptionContext";
import { normalizeLanguage } from "../SkillsStep";

import { EditLanguages } from "./EditLanguages";

import styles from "../../FormWithSlidingWrapper.module.css";

const commonLanguages = ["fre", "eng", "ger", "spa", "ara", "ita", "por"];

type Props = {
    selectedLanguages: ReturnType<typeof normalizeLanguage>[];
    setSelectedLanguages: (selectedLanguages: ReturnType<typeof normalizeLanguage>[]) => void;
};

export function LanguagesForm({ selectedLanguages = [], setSelectedLanguages }: Props) {
    const [displayPanel, setDisplayPanel] = useState(false);
    const [displayModal, setDisplayModal] = useState(false);
    const [displayRecapModal, setDisplayRecapModal] = useState(false);
    const [currentLanguages, setCurrentLanguages] = useState(selectedLanguages);
    const [currentLanguage, setCurrentLanguage] = useState<
        | (ReturnType<typeof normalizeLanguages>[keyof ReturnType<typeof normalizeLanguages>] & {
              level?: string;
          })
        | null
    >(null);

    const { data: languages = {} } = useQuery({
        ...queries.jobDescriptionOptions.list(),
        select: (data) => normalizeLanguages(data.languages),
    });

    useEffect(() => {
        // re-render when currentTools ref has changed to display changes
        // meaning when tools have been submitted to SkillsStep
        if (selectedLanguages) {
            setCurrentLanguages(selectedLanguages);
        }
    }, [selectedLanguages]);

    function selectLanguage({ id }) {
        // it exists, remove it from the state
        if (currentLanguages.find((l) => l.id === id)) {
            const selectedFilteredLanguages = currentLanguages.filter((lang) => lang.id !== id);
            setCurrentLanguages(selectedFilteredLanguages);
        } else {
            setCurrentLanguages(currentLanguages.concat({ id }));
        }
    }

    function handleLanguageLevelChange(_, __, selectedItem) {
        setCurrentLanguage((prevState) => ({
            ...prevState!,
            level: selectedItem,
        }));
    }

    const { setDataToUpdate } = useContext(JobDescriptionContext);

    useEffect(() => {
        setDataToUpdate((prevState) => ({
            ...prevState,
            languageIds: currentLanguages?.map((language) => language.id),
            languages: currentLanguages?.map((language) => ({
                ISOCode: language.name,
                id: language.id,
                level: language.value,
            })),
        }));
    }, [currentLanguages]);

    const languagesList = [
        ...commonLanguages
            // remove already selected langagues
            .filter((lang) => !currentLanguages.find(({ name }) => name === lang)),
        ...Object.keys(languages)
            .sort()
            // remove common languages from the list
            // as well as already selected languages
            .filter(
                (lang) =>
                    !commonLanguages.includes(lang) &&
                    !currentLanguages.find(({ name }) => name === lang),
            ),
    ];

    return (
        <div className={clsx(styles.section, "languages")}>
            <div className={styles.actions}>
                {!currentLanguages?.length ? (
                    <p className={styles.title}>
                        {i18n.job_descriptions_creation_skills_languages_title()}
                    </p>
                ) : (
                    <div
                        className={styles.edit}
                        role='button'
                        tabIndex={0}
                        onClick={() => setDisplayRecapModal(true)}
                        onKeyDown={(event) =>
                            keyPressHandler(event, "Enter", () => setDisplayRecapModal(true))
                        }
                    >
                        <p className={styles.title}>
                            {i18n.job_descriptions_creation_skills_languages_title()}

                            <Pencil className='h-4 w-4 text-gray-300' />
                        </p>

                        {currentLanguages?.length
                            ? currentLanguages.map((selectedLanguage) => (
                                  <div
                                      key={selectedLanguage.id}
                                      className={styles.list}
                                      role='list'
                                  >
                                      <div className={styles.item} role='listitem'>
                                          <span className={styles.label}>
                                              {i18nDK(selectedLanguage.name)}
                                          </span>

                                          <span className={styles.level}>
                                              {i18nDK(selectedLanguage.value)}
                                          </span>
                                      </div>
                                  </div>
                              ))
                            : null}
                    </div>
                )}

                <Button variant='primary-outlined' onClick={() => setDisplayPanel(true)}>
                    {i18n.job_descriptions_creation_skills_languages_add_button()}
                    <Plus />
                </Button>
            </div>

            <Overlay isVisible={displayPanel} toggleVisibility={() => setDisplayPanel(false)} />
            <SlidingWrapper isVisible={displayPanel} position='bottom'>
                <SectionHeader
                    title={i18n.job_descriptions_creation_skills_languages_title()}
                    subtitle={i18n.job_descriptions_creation_skills_languages_subtitles()}
                    icon='Comment'
                    closeAction={() => {
                        setDisplayPanel(false);
                    }}
                />
                {displayPanel ? (
                    <div className={clsx(styles.selected, styles.content)}>
                        {currentLanguages?.length ? (
                            <div className={styles.wrapper}>
                                <ul className={clsx(styles.list, styles.common)}>
                                    {currentLanguages.map(({ id, name }) => (
                                        <li key={id} className={clsx(styles.item, styles.selected)}>
                                            <Button
                                                variant='primary-outlined'
                                                onClick={() => {
                                                    selectLanguage({ id });
                                                }}
                                            >
                                                {i18nDK(name)}
                                                <X />
                                            </Button>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        ) : null}
                        {/* display first the list of 7 most commonly used languages */}
                        {languages ? (
                            <ul className={clsx(styles.list, styles.common)}>
                                {languagesList.map((languageItem) => (
                                    <li key={languages[languageItem]?.id} className={styles.item}>
                                        <Chip
                                            id={languages[languageItem]?.id}
                                            label={i18nDK(languageItem)}
                                            name={languages[languageItem]?.id}
                                            value={languages[languageItem]?.id}
                                            checked={
                                                !!currentLanguages.find(
                                                    (lang) => lang.name === languageItem,
                                                )
                                            }
                                            onChange={(event) => {
                                                // important to prevent re-render
                                                event.preventDefault();

                                                setCurrentLanguage(() => ({
                                                    ...languages[languageItem],
                                                    levels: languages[languageItem]?.levels,
                                                    level: "", // level is not select yet
                                                    // used to display Selects's placeholder
                                                }));
                                                setDisplayModal(true);
                                            }}
                                        />
                                    </li>
                                ))}
                            </ul>
                        ) : null}
                    </div>
                ) : null}
                <footer className={styles.footer}>
                    <Button
                        onClick={() => {
                            // cancel all
                            setDisplayPanel(false);
                            setCurrentLanguages([]);
                            setSelectedLanguages([]);
                        }}
                        variant='primary-ghost'
                    >
                        {i18n.cancel()}
                    </Button>

                    <Button
                        onClick={() => {
                            setSelectedLanguages(currentLanguages);
                            setDisplayPanel(false);
                        }}
                        disabled={!currentLanguages?.length}
                    >
                        {i18n.save()}
                    </Button>
                </footer>
            </SlidingWrapper>

            {displayModal ? (
                <ModalSimple
                    title={i18nDK(currentLanguage!.label)}
                    subtitle={i18n.job_descriptions_creation_skills_languages_modal_subtitle()}
                    hideModal={() => {
                        setDisplayModal(false);
                        setCurrentLanguage(null);
                    }}
                    action={() => {
                        setDisplayModal(false);
                        setCurrentLanguages((prevCurrentLanguages) => {
                            return [
                                ...prevCurrentLanguages,
                                currentLanguage?.levels.find(
                                    ({ label }) => label === currentLanguage!.level,
                                ) as any,
                            ];
                        });
                        // reset currentLanguage
                        setCurrentLanguage(null);
                    }}
                    cancelLabel={i18n.cancel()}
                    validateLabel={i18n.save()}
                    disableButton={!currentLanguage?.level}
                    cancelFunction={() => {
                        // if it exists in currentLanguages remove it
                        if (currentLanguages.find(({ id }) => id === currentLanguage?.id)) {
                            selectLanguage(currentLanguage!);
                        }

                        // otherwise do nothing
                    }}
                >
                    <Select
                        id='language-level'
                        name='language-level'
                        label={i18n.job_descriptions_creation_skills_languages_modal_label()}
                        placeholder={i18n.job_descriptions_creation_skills_languages_modal_placeholder()}
                        options={sortLanguagesByLevel(currentLanguage?.levels).map((level) => ({
                            id: level.id,
                            label: i18nDK(level.label),
                            value: level.value,
                            name: level.name,
                        }))}
                        chevronIcon={true}
                        onChange={handleLanguageLevelChange}
                        selectedItem={currentLanguage?.level && i18nDK(currentLanguage.level)}
                    />
                </ModalSimple>
            ) : null}

            {displayRecapModal && currentLanguages?.length ? (
                <EditLanguages
                    languages={languages}
                    selectedLanguages={currentLanguages}
                    hideModal={() => setDisplayRecapModal(false)}
                    submitLanguages={setCurrentLanguages}
                />
            ) : null}
        </div>
    );
}
