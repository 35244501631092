import { useCompanyManagers } from "./useCompanyManagers";

export function useCheckIfManagerExists() {
    const companyManagers = useCompanyManagers();
    function checkIfManagerExist(managerId: string) {
        let exists = true;
        if (managerId && companyManagers.length) {
            exists = companyManagers.some(({ id }) => id === managerId);
        }
        return exists;
    }
    return checkIfManagerExist;
}
