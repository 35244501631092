import { useState } from "react";
import clsx from "clsx";
import { X } from "lucide-react";
import { ModalSimple } from "side-ui";

import { i18n, i18nDK } from "@lib/i18n";

import styles from "../../WorkConditionsAndSkillsModals.module.css";

export function EditLicences({ hideModal, selectedLicences, submitLicences }) {
    const [touched, setTouched] = useState(false);
    // caching data to preserve them if user hits the cancel button
    const [licences, setLicences] = useState(selectedLicences);

    return (
        <ModalSimple
            hideModal={hideModal}
            title={i18n.job_descriptions_creation_skills_licence_title()}
            cancelLabel={i18n.cancel()}
            validateLabel={i18n.save()}
            disableButton={!touched}
            action={() => {
                submitLicences(licences);
                hideModal();
            }}
            customClass={styles.modal}
        >
            {licences.map((selectedLicence) => (
                <div key={selectedLicence.id} className={clsx(styles.edit, styles.licences)}>
                    <div className={styles.title}>
                        {i18nDK(selectedLicence.name)}

                        <button
                            type='button'
                            className={styles.delete}
                            title='Supprimer cet outil'
                            onClick={() => {
                                setLicences((prevState) =>
                                    prevState.filter(({ id }) => id !== selectedLicence.id),
                                );
                                setTouched(true);
                            }}
                        >
                            <X className='h-4 w-4' />
                        </button>
                    </div>
                </div>
            ))}
        </ModalSimple>
    );
}
