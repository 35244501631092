import { Plus } from "lucide-react";
import { Button } from "saphir";

import { queryClient } from "@App";
import { updateTask } from "@lib/api";
import { i18n } from "@lib/i18n";
import { queries } from "@lib/queries";
import trackEvent from "@lib/trackers";
import { useMutation } from "@tanstack/react-query";
import { Link, useNavigate } from "@tanstack/react-router";

import { taskFormRoute } from "../route";

import accurateImg from "./assets/accurate.png";
import flexibleImg from "./assets/flexible.png";

export function PlanningTypeStep() {
    const { taskId } = taskFormRoute.useParams();
    const navigate = useNavigate();
    const { mutate: patchTask } = useMutation({
        mutationFn: updateTask,
        onSuccess: (data) => {
            if (data.flexiblePlanning) {
                navigate({ to: "/taskPosting/$taskId/flexible-planning", params: { taskId } });
            } else {
                navigate({
                    to: "/taskPosting/$taskId/shifts",
                    params: { taskId },
                    search: { create: true },
                });
            }
            queryClient.invalidateQueries(queries.task.detail(taskId));
        },
    });
    return (
        <div className='grid h-full w-full place-items-center overflow-auto bg-gray-20 p-6'>
            <div className='flex flex-col gap-8 text-center'>
                <div>
                    <h1 className='mb-1 text-gray-900 typography-heading-l-semibold'>
                        {i18n.planning_type_title()}
                    </h1>
                    <h2 className='typography-body-m-regular'>{i18n.planning_type_desc()}</h2>
                </div>
                <div className='flex gap-8'>
                    <Link
                        to='/taskPosting/$taskId/shifts'
                        params={{ taskId }}
                        search={{ create: true }}
                        onClick={() => {
                            patchTask({ flexiblePlanning: false, id: taskId });
                            trackEvent({
                                name: "jobposting - planning type selected",
                                params: { taskId, type: "precise" },
                            });
                        }}
                    >
                        <div className='flex w-[272px] flex-col items-center justify-center rounded border border-gray-100 bg-white p-8 pb-6 text-center hover:border-blue-500 hover:shadow-[0_0_3px] hover:shadow-blue-500'>
                            <img
                                className='mb-4 w-[140px]'
                                src={accurateImg}
                                alt={i18n.planning_type_accurate_title()}
                            />
                            <h3 className='text-blue-500 typography-heading-s-semibold'>
                                {i18n.planning_type_accurate_title()}
                            </h3>
                            <p className='mt-2 text-gray-600 typography-body-m-semibold'>
                                {i18n.planning_type_accurate_subtitle()}
                            </p>
                            <p className='mt-3 text-gray-400 typography-body-m-regular'>
                                {i18n.planning_type_accurate_desc()}
                            </p>

                            <Button className='mt-6 w-full'>
                                <Plus />
                                {i18n.planning_type_flexible_button_create()}
                            </Button>
                        </div>
                    </Link>
                    <Link
                        to='/taskPosting/$taskId/flexible-planning'
                        params={{ taskId }}
                        onClick={() => {
                            patchTask({ flexiblePlanning: true, id: taskId });
                            trackEvent({
                                name: "jobposting - planning type selected",
                                params: { taskId, type: "flexible" },
                            });
                        }}
                    >
                        <div className='flex w-[272px] flex-col items-center justify-center rounded border border-gray-100 bg-white p-8 pb-6 text-center hover:border-blue-500 hover:shadow-[0_0_3px] hover:shadow-blue-500'>
                            <img
                                className='mb-4 w-[140px]'
                                src={flexibleImg}
                                alt={i18n.planning_type_flexible_title()}
                            />
                            <h3 className='text-blue-500 typography-heading-s-semibold'>
                                {i18n.planning_type_flexible_title()}
                            </h3>
                            <p className='mt-2 text-gray-600 typography-body-m-semibold'>
                                {i18n.planning_type_flexible_subtitle()}
                            </p>
                            <p className='mt-3 text-gray-400 typography-body-m-regular'>
                                {i18n.planning_type_flexible_desc()}
                            </p>

                            <Button className='mt-6 w-full'>
                                <Plus />
                                {i18n.planning_type_flexible_button_create()}
                            </Button>
                        </div>
                    </Link>
                </div>
            </div>
        </div>
    );
}
