import { useEffect, useRef, useState } from "react";
import { ArrowDown } from "lucide-react";
import { toast } from "saphir";
import { ContextBar, ModalsService, PdfVisualizer, RecapConfirmation } from "side-ui";

import { i18n } from "@lib/i18n";
import { queries } from "@lib/queries";
import { useQuery } from "@tanstack/react-query";
import { useNavigate } from "@tanstack/react-router";

import trackEvent from "../../../lib/trackers";
import ContestationModal from "../containers/ContestationModal";
import { contractsSignatureRoute } from "../route";

import "./ContractsSignature.scss";

const ContractsSignature = ({ contractsBatch, signError, signContracts, regenerateDocument }) => {
    const { id } = contractsSignatureRoute.useParams();
    const [state, setState] = useState({
        loadingError: false,
        userHasReachedLastPaginationPage: false,
    });
    const recapRef = useRef(null);
    const navigate = useNavigate();

    const { data: user } = useQuery(queries.user.detail());
    const isInsider = user?.isInsider;

    useEffect(() => {
        if (contractsBatch) {
            trackEvent({
                name: `teamcontract - company saw contracts`,
                params: {
                    organisationId: localStorage.getItem("side_team_activeOrganisationId"),
                    contractId: contractsBatch.documentId,
                    taskId: contractsBatch.taskId,
                    status: contractsBatch.status,
                },
            });
        }
    }, []);

    const handleScrollToRecap = () => {
        const { userHasReachedLastPaginationPage } = state;
        const { contractsIds = [] } = contractsBatch;

        // Meaning pagination is not needed OR signature block is rendered in the DOM
        if (contractsIds.length < 10 || userHasReachedLastPaginationPage) {
            return (
                recapRef.current &&
                recapRef.current.scrollIntoView({
                    block: "start",
                    behavior: "smooth",
                })
            );
        }

        // We add a toast to inform the user he needs to go to the last contract
        toast.info(i18n.contract_signature_pagination_last_page());

        const pagination = document.querySelector(".sui-visualizer--pagination");

        return window.scrollTo({
            top: pagination && pagination.offsetTop,
            behavior: "smooth",
        });
    };

    const PdfErrorHandler = () => {
        setTimeout(() => {
            setState({ loadingError: true });
        }, 0);
    };

    const signAndRedirectToContractsList = (documentId) => {
        trackEvent({
            name: `teamcontract - company signed contracts`,
            params: {
                organisationId: localStorage.getItem("side_team_activeOrganisationId"),
                contractId: contractsBatch.documentId,
                taskId: contractsBatch.taskId,
                status: contractsBatch.status,
            },
        });

        signContracts(documentId);
        setTimeout(() => {
            navigate({ to: "/contracts" });
        }, 300);
    };

    const handleScrollToTop = (event) => {
        event.preventDefault();

        if (window) {
            window.scrollTo(0, 0);
        }
    };

    const handleClickOnLastPaginationItem = () => {
        setTimeout(() => {
            setState({
                userHasReachedLastPaginationPage: true,
            });
        }, 0);
    };

    const { loadingError, userHasReachedLastPaginationPage } = state;
    const phoneNumberMissing = !user?.phoneNumber || user?.phoneNumber.length === 0;
    let signatureError = "";

    if (signError) {
        signatureError = i18n.contract_signature_confirmation_block_error();
    } else if (phoneNumberMissing) {
        signatureError = i18n.contract_signature_phone_number_required();
    }

    if (!contractsBatch) {
        navigate({ to: "/contracts" });
        return <div />;
    }

    const { contractsIds = [] } = contractsBatch;
    const needPagination = contractsIds && contractsIds.length > 10;

    return (
        <div className='contracts-signature'>
            <div className='contracts-signature__bar'>
                <ContextBar
                    title={contractsBatch && contractsBatch.taskName}
                    leftPartIcon='ArrowLeft'
                    leftPartAction={
                        contractsBatch.status !== "signed"
                            ? () => navigate({ to: "/contracts" })
                            : () => navigate({ to: "/contracts/archives" })
                    }
                    validationButtonLabel={
                        !isInsider // eslint-disable-line no-nested-ternary
                            ? contractsBatch.status === "contested"
                                ? i18n.contract_signature_see_contestation_button_label()
                                : i18n.contract_signature_sign_button_label()
                            : i18n.contract_signature_regenerate_document_label()
                    }
                    validationButtonAction={
                        !isInsider // eslint-disable-line no-nested-ternary
                            ? contractsBatch.status === "contested"
                                ? () =>
                                      ModalsService.openModal({
                                          id: `CONTRACTS_CONTESTATION`,
                                          content: (
                                              <ContestationModal
                                                  documentId={id}
                                                  contractsBatchStatus={contractsBatch.status}
                                                  contestation={contractsBatch.contestation}
                                              />
                                          ),
                                          hideModal:
                                              ModalsService.closeModal(`CONTRACTS_CONTESTATION`),
                                      })
                                : handleScrollToRecap
                            : () => {
                                  regenerateDocument(contractsBatch.documentId);
                                  trackEvent({
                                      name: `teamcontract - insider regenerated contract`,
                                      params: {
                                          organisationId: localStorage.getItem(
                                              "side_team_activeOrganisationId",
                                          ),
                                          contractId: contractsBatch.documentId,
                                          taskId: contractsBatch.taskId,
                                          startDate: contractsBatch.startDate,
                                      },
                                  });
                                  navigate({ to: "/contracts" });
                              }
                    }
                    validationButtonIconAfter={
                        !isInsider ? contractsBatch.status === "unsigned" && "ArrowDown" : "Refresh"
                    }
                    validationButtonDisabled={!isInsider ? loadingError : false}
                    reportButtonLabel={
                        contractsBatch.status !== "contested" &&
                        !loadingError &&
                        i18n.contract_signature_report_button_label()
                    }
                    reportButtonAction={() => {
                        trackEvent({
                            name: `teamcontract - company contested contracts`,
                            params: {
                                organisationId: localStorage.getItem(
                                    "side_team_activeOrganisationId",
                                ),
                                contractId: contractsBatch.documentId,
                                taskId: contractsBatch.taskId,
                                status: contractsBatch.status,
                            },
                        });
                        ModalsService.openModal({
                            id: `CONTRACTS_CONTESTATION`,
                            content: (
                                <ContestationModal
                                    contestation={undefined}
                                    contractsBatch={contractsBatch}
                                    contractsBatchStatus={undefined}
                                    documentId={id}
                                />
                            ),
                        });
                    }}
                />
            </div>
            <div
                className={` contracts-signature__visualizer${
                    userHasReachedLastPaginationPage ? "--with-signature-block" : ""
                } `}
            >
                <PdfVisualizer
                    documentUrl={
                        contractsBatch.generation.url === ""
                            ? "invalid link"
                            : contractsBatch.generation.url
                    }
                    errorTitle={i18n.contract_signature_document_error_title()}
                    errorSubtitle={i18n.contract_signature_document_error_subtitle()}
                    errorIcon='UnavailableDocument'
                    errorIconColor='red'
                    errorButtonLabel={i18n.contract_signature_document_error_button_label()}
                    errorButtonAction={() => navigate({ to: "/contracts" })}
                    errorButtonColor='blue'
                    errorButtonIcon='ArrowLeft'
                    onLoadError={PdfErrorHandler}
                    enableAutoPagination={needPagination}
                    handleClickOnPaginationItem={handleScrollToTop}
                    handleClickOnLastPaginationItem={handleClickOnLastPaginationItem}
                />
            </div>
            {contractsBatch.status === "unsigned" && !loadingError && (
                <div
                    className='contracts-signature__button visible-sm'
                    onClick={handleScrollToRecap}
                    onKeyDown={null}
                >
                    <ArrowDown className='h-5 w-5' />
                    {i18n.contract_signature_sign_button_label()}
                    <ArrowDown className='h-5 w-5' />
                </div>
            )}
            {contractsBatch.status === "unsigned" &&
            !loadingError &&
            !isInsider &&
            (!needPagination || userHasReachedLastPaginationPage) ? (
                <div className='contracts-signature__recap' ref={recapRef}>
                    <RecapConfirmation
                        title={i18n.contract_signature_confirmation_block_title()}
                        confirmationButtonLabel={i18n.contract_signature_sign_button_label()}
                        confirmationButtonAction={() =>
                            signAndRedirectToContractsList(contractsBatch.documentId)
                        }
                        recapNote={i18n.contract_signature_confirmation_block_notice()}
                        footNote={i18n.contract_signature_confirmation_block_agreement()}
                        checkboxLabel={i18n.contract_signature_confirmation_block_checkbox()}
                        recapError={signatureError}
                        disabled={phoneNumberMissing}
                    >
                        <div className='contracts-signature__recap--numbers'>
                            <span className='contracts-signature__recap--numbers--title'>
                                {i18n.contract_signature_confirmation_block_number()}
                            </span>
                            <span className='contracts-signature__recap--numbers--data'>
                                {contractsBatch.contractsIds.length}
                            </span>
                        </div>
                    </RecapConfirmation>
                </div>
            ) : null}
        </div>
    );
};

export default ContractsSignature;
