import clsx from "clsx";
import { CircleAlert } from "lucide-react";
import { toast } from "saphir";
import { Tag } from "sui";

import { useCheckIfManagerExists } from "@lib/hooks/useCheckIfManagerExists";
import { useCompanyManagers } from "@lib/hooks/useCompanyManagers";
import { i18n } from "@lib/i18n";
import { useJobDescriptions } from "@routes/JobDescriptions/hooks/useJobDescriptions";

import styles from "./JobDescriptionCard.module.css";

export const JobDescriptionCard = ({ jobDescription, isSelected, displayId }) => {
    const { setJobDescription } = useJobDescriptions();
    const companyManagers = useCompanyManagers();
    const checkIfManagerExists = useCheckIfManagerExists();
    const managerExists = checkIfManagerExists(jobDescription?.managerId);
    const managerInitials =
        managerExists &&
        companyManagers.find(({ value }) => value === jobDescription.managerId)?.label[0];
    const jobDescriptionName = jobDescription?.job?.jobTitle?.name
        ? `${jobDescription?.job?.jobTitle?.name}${
              jobDescription?.suffix ? ` - ${jobDescription?.suffix}` : ""
          }`
        : "";
    const jobDescriptionSubtasks = jobDescription?.job?.subtasks
        ?.map((item) => item.name)
        .join(", ");
    const jobDescriptionAddress = jobDescription?.location?.address;

    return (
        <div
            role='button'
            key={jobDescription?.id}
            onClick={() => setJobDescription(jobDescription?.id)}
            className={clsx(styles.card, isSelected && styles.selected)}
        >
            <div className={styles.header}>
                {jobDescriptionName && (
                    <p
                        className={clsx(styles.title, "typography-body-m-semibold")}
                        title={jobDescriptionName}
                    >
                        {jobDescriptionName}
                    </p>
                )}
                {jobDescriptionSubtasks && (
                    <p className={styles.subtitle} title={jobDescriptionSubtasks}>
                        {jobDescriptionSubtasks}
                    </p>
                )}
                {jobDescriptionAddress && (
                    <p className={styles.description} title={jobDescriptionAddress}>
                        {jobDescriptionAddress}
                    </p>
                )}
            </div>
            {jobDescription?.hourlyRate && jobDescription?.executiveStatus ? (
                <div className={styles.footer}>
                    <div className={styles.salary}>
                        <p className={styles.salaryLabel}>
                            {i18n.job_descriptions_creation_salary_raw()}
                        </p>
                        <p className={styles.salaryValue}>
                            {(+jobDescription?.hourlyRate || 0).toFixed(2)}€
                        </p>
                    </div>
                    {jobDescription?.managerId ? (
                        managerExists ? (
                            <div className={styles.manager}>
                                <span>{managerInitials}</span>
                            </div>
                        ) : (
                            <CircleAlert className='text-orange-500' />
                        )
                    ) : null}
                    {displayId && (
                        <div
                            className={styles.id}
                            onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                navigator.clipboard.writeText(jobDescription.id).then(() => {
                                    toast.success(i18n.job_descriptions_copy_id());
                                });
                            }}
                        >
                            #
                        </div>
                    )}
                </div>
            ) : (
                <div className={styles.draft}>
                    <Tag color='orange'>{i18n.job_descriptions_draft()}</Tag>
                </div>
            )}
        </div>
    );
};
