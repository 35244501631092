export function objectToQueryParams(obj: Record<string, any>, parentKey: string = "") {
    const queryParams: string[] = [];

    for (const [key, value] of Object.entries(obj)) {
        const fullKey = parentKey ? `${parentKey}.${key}` : key;

        if (typeof value === "object" && value !== null) {
            // Recursively handle nested objects
            queryParams.push(objectToQueryParams(value, fullKey));
        } else {
            // Encode the key and value for query string
            queryParams.push(`${encodeURIComponent(fullKey)}=${encodeURIComponent(value)}`);
        }
    }

    return queryParams.join("&");
}
