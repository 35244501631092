import { FileText, Plus } from "lucide-react";
import { Button } from "saphir";

import { i18n } from "@lib/i18n";

import styles from "./Blank.module.css";

export function Blank({ openModal }: { openModal: () => void }) {
    return (
        <div className={styles.blank}>
            <div className={styles.block}>
                <div className={styles.icon}>
                    <FileText className='!rounded-none' />
                </div>
                <h1 className='mb-2 text-gray-900 typography-heading-l-semibold'>
                    {i18n.task_shifts_no_shifts_title()}
                </h1>
                <h3 className='mb-6 typography-body-m-regular'>
                    {i18n.task_shifts_no_shifts_description()}
                </h3>
                <Button onClick={openModal}>
                    <Plus />
                    {i18n.task_shifts_add()}
                </Button>
            </div>
        </div>
    );
}
