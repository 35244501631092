import { memo } from "react";
import { Timer, TriangleAlert } from "lucide-react";

import { i18nDK } from "@lib/i18n";

import { ATT_CONFLICT, ATT_DISPUTED } from "../../../../utils";

type ConflictCellProps = {
    status: string;
    siderName: string;
    currentVersion: any;
};

const ConflictCell = memo(function ConflictCell({
    // eslint-disable-line
    status,
    siderName,
    currentVersion,
}: ConflictCellProps) {
    function conflictDisclaimer(origin) {
        return (
            <>
                {origin === "company" ? (
                    <Timer className='h-4 w-4 !fill-none text-red-500' />
                ) : (
                    <TriangleAlert className='h-4 w-4 !fill-none text-red-500' />
                )}

                <div className='timesheets-table__cell__hoverable__tooltip'>
                    <span className='timesheets-table__cell__hoverable__tooltip__title timesheets-table__cell__hoverable__tooltip__title--red'>
                        {i18nDK(`ts_table_conflict_${origin}_title`)}
                    </span>

                    <div className='timesheets-table__cell__hoverable__tooltip__content timesheets-table__cell__hoverable__tooltip__content--smaller'>
                        {i18nDK(`ts_table_conflict_${origin}_description`, { siderName })}
                    </div>
                </div>
            </>
        );
    }

    function renderConflictDisclaimer() {
        // if no actions just render based off of the origin
        if (!currentVersion?.actions) {
            return conflictDisclaimer(currentVersion.origin);
        }
        // look for type and origin in latest history.actions array
        const latestAction = currentVersion?.actions[currentVersion?.actions?.length - 1];
        const { type, origin } = latestAction;

        if (origin === "sider" && type === "refusal") {
            return conflictDisclaimer("sider");
        }

        return conflictDisclaimer(currentVersion.origin);
    }

    return status === ATT_DISPUTED || status === ATT_CONFLICT ? (
        <span className='timesheets-table__cell timesheets-table__cell--conflict timesheets-table__cell--disputed conflict timesheets-table__cell__hoverable__wrapper'>
            {renderConflictDisclaimer()}
        </span>
    ) : (
        <span className='timesheets-table__cell timesheets-table__cell--empty' />
    );
});

export default ConflictCell;
