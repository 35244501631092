import { differenceInDays, differenceInMinutes, format, getDate } from "date-fns";
import { enGB } from "date-fns/locale";
import { toast } from "saphir";
import { z } from "zod";

import {
    addShift,
    AddShiftParams,
    deleteShift,
    DeleteShiftParams,
    updateShift as patchShift,
    UpdateShiftParams,
} from "@lib/api";
import { deleteShifts } from "@lib/api/deleteShifts";
import { i18n, i18nDK } from "@lib/i18n";
import { queries } from "@lib/queries";
import trackEvent from "@lib/trackers";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

export const recurrenceOptions = z.enum(["recurrence-all", "recurrence-following"]).optional();
export type RecurrenceOptions = z.infer<typeof recurrenceOptions>;

export function useShiftAPI(mode?: "creation" | "edition" | "deletion") {
    const queryClient = useQueryClient();
    const { data: company } = useQuery(queries.company.detail());

    const { mutate: createShift, isPending: isCreateShiftPending } = useMutation({
        mutationFn: addShift,
        onSuccess: (_, variables) => {
            queryClient.invalidateQueries({ queryKey: ["task"] });
            showToast();

            trackEvent({
                name: `bulkshift - created shift`,
                params: {
                    ...formatPayloadForTracker(variables.shift),
                    industry: company?.organisation?.industry ?? "",
                    taskId: variables.taskId,
                },
            });
        },
    });

    const { mutate: updateShift, isPending: isUpdateShiftPending } = useMutation({
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        mutationFn: ({ taskId, params }: { taskId: string; params: UpdateShiftParams }) =>
            patchShift(params),
        onSuccess: (_, variables) => {
            queryClient.invalidateQueries({ queryKey: ["task"] });
            showToast();

            trackEvent({
                name: `bulkshift - edited shift`,
                params: {
                    ...formatPayloadForTracker(variables.params.shift),
                    industry: company?.organisation?.industry ?? "",
                    taskId: variables.taskId,
                },
            });
        },
    });

    const { mutate: removeShift, isPending: isRemoveShiftPending } = useMutation({
        mutationFn: ({
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            taskId,
            shiftId,
            scope,
        }: { taskId: string } & DeleteShiftParams & Pick<AddShiftParams, "shift">) =>
            deleteShift({ shiftId, scope }),
        onSuccess: (_, variables) => {
            queryClient.invalidateQueries({ queryKey: ["task"] });
            showToast();
            trackEvent({
                name: `bulkshift - deleted shift`,
                params: {
                    ...formatPayloadForTracker(variables.shift),
                    industry: company?.organisation?.industry ?? "",
                    taskId: variables.taskId,
                },
            });
        },
    });

    const { mutate: removeShifts, isPending: isRemoveShiftsPending } = useMutation({
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        mutationFn: ({ taskId, ids }: { taskId: string; ids: string[] }) => {
            return deleteShifts({ ids });
        },
        onSuccess: (_, variables) => {
            queryClient.invalidateQueries({ queryKey: ["task"] });

            trackEvent({
                name: `bulkshift - deleted shifts`,
                params: {
                    taskId: variables.taskId,
                    shiftsDeleted: variables.ids.length,
                    industry: company?.organisation?.industry ?? "",
                },
            });

            toast.success(i18n.task_shifts_success_toast_deletion());
        },
    });

    function showToast() {
        toast.success(i18nDK(`task_shifts_success_toast_${mode}`));
    }

    return {
        createShift,
        updateShift,
        removeShift,
        removeShifts,
        isPending:
            isCreateShiftPending ||
            isUpdateShiftPending ||
            isRemoveShiftPending ||
            isRemoveShiftsPending,
    };
}

const occurencies = {
    "1": "first",
    "2": "second",
    "3": "third",
    "4": "fourth",
    "-1": "last",
} as const;

function formatPayloadForTracker(shift: Partial<UpdateShiftParams["shift"]>) {
    const { startDate, endDate, slots, breakDuration, recurrence } = shift;
    const formatedShift: Record<string, any> = {
        slots,
        startDate,
        endDate,
        breakDuration,
        shiftDuration: Math.abs(differenceInMinutes(new Date(startDate!), new Date(endDate!))),
        recurrence: !!shift.recurrence,
    };

    if (recurrence) {
        formatedShift.recurrenceWithHolidays = !!recurrence.withHolidays;
        formatedShift.recurrenceUntil = recurrence.until;
        formatedShift.recurrenceType = recurrence.type;
        formatedShift.recurrenceInterval = recurrence.interval;
        formatedShift.recurrenceDurationInDays = Math.abs(
            differenceInDays(new Date(startDate!), new Date(recurrence.until)),
        );

        if (recurrence.days?.length) {
            recurrence.days.forEach((dayRRulesIndex) => {
                formatedShift[
                    enGB.localize?.day(dayRRulesIndex === 6 ? 0 : dayRRulesIndex + 1).toLowerCase()
                ] = true;
            });
        }
        if (recurrence.setPos?.length && recurrence.type === "monthly") {
            formatedShift.recurrenceMonthlyWeekdayPosition = occurencies[recurrence.setPos[0]];
            formatedShift.recurrenceMonthlyWeekday = format(new Date(startDate!), "EEEE", {
                locale: enGB,
            }).toLowerCase();
        }
        if (!recurrence.setPos?.length && recurrence.type === "monthly") {
            formatedShift.recurrenceMonthlySameDate = getDate(new Date(startDate!));
        }
    }

    return formatedShift;
}
