import { useEffect, useMemo, useRef, useState } from "react";
import clsx from "clsx";
import { format, isPast } from "date-fns";
import { debounce } from "lodash";
import { Check, ChevronRight, Download, Eye, EyeClosed, Hash, Upload, X } from "lucide-react";
import { toast } from "saphir";
import {
    Button,
    fonts,
    Loader,
    Modal,
    ModalContent,
    ModalFooter,
    ModalHeader,
    Tag,
    TextArea,
    Tooltip,
    TooltipContent,
    TooltipDelayGroup,
    TooltipTrigger,
} from "sui";

import { download } from "@lib/api/download";
import { PreSelectionSider } from "@lib/api/getTaskPreselection";
import { upload } from "@lib/api/upload";
import { i18n } from "@lib/i18n";
import { usePatchTask } from "@lib/mutations/usePatchTask";
import { queries } from "@lib/queries";
import { useQueryClient, useSuspenseQuery } from "@tanstack/react-query";
import { getRouteApi } from "@tanstack/react-router";
import {
    createColumnHelper,
    flexRender,
    getCoreRowModel,
    useReactTable,
    VisibilityState,
} from "@tanstack/react-table";

import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "./Table";

import styles from "./PreselectionTable.module.css";

export function PreselectionTable() {
    const route = getRouteApi("/globalLayout/preselection/$taskId");
    const { taskId } = route.useParams();
    const { data: preSelection } = useSuspenseQuery(queries.taskPreSelection.list(taskId));
    const isLogas = Boolean(localStorage.getItem(`side_team_logas_email`));
    const queryClient = useQueryClient();

    const { patchTask } = usePatchTask({
        onSettled: () => queryClient.invalidateQueries({ queryKey: ["preSelection"] }),
    });

    const [columnVisibility, setColumnVisibility] = useState<VisibilityState>({
        // this is the visibility of column with id "visible", see below
        visible: false,
        clipboard: false,
    });

    function handleFileUpload(siderId: string, file: File) {
        const formData = new FormData();
        formData.append(siderId, file);
        upload({ type: "pre-selection", id: siderId, file: formData }).then(({ url }) => {
            patchTask({
                id: taskId,
                applicant: {
                    id: siderId,
                    update: { cvLink: url },
                },
            });
            toast.success(i18n.preselect_cv_uploaded());
        });
    }

    const columnHelper = createColumnHelper<PreSelectionSider>();

    const columns = useMemo(
        () => [
            columnHelper.accessor("visible", {
                header: i18n.preselection_table_header_visibility(),
                cell: ({ row }) => {
                    return row.original.visible ? (
                        <button
                            className={styles.show}
                            onClick={() => {
                                patchTask({
                                    id: taskId,
                                    applicant: {
                                        id: row.original.id,
                                        update: { visible: false },
                                    },
                                });
                            }}
                        >
                            <Eye className='h-4 w-4' />
                        </button>
                    ) : (
                        <button
                            className={styles.showDisable}
                            onClick={() => {
                                patchTask({
                                    id: taskId,
                                    applicant: {
                                        id: row.original.id,
                                        update: { visible: true },
                                    },
                                });
                            }}
                        >
                            <EyeClosed className='h-4 w-4' />
                        </button>
                    );
                },
                enableHiding: true,
            }),
            columnHelper.display({
                id: "clipboard",
                cell: ({ row }) => {
                    return (
                        <TooltipDelayGroup>
                            <Tooltip>
                                <TooltipTrigger className={styles.clipboard}>
                                    <Button
                                        size='small'
                                        shape='outlined'
                                        intention='secondary'
                                        icon={<Hash className='h-5 w-5' />}
                                        onClick={() => {
                                            navigator.clipboard.writeText(row.original.id);
                                            toast.success(i18n.job_descriptions_copy_id());
                                        }}
                                    />
                                </TooltipTrigger>
                                <TooltipContent type='action'>Copier le Sider Id</TooltipContent>
                            </Tooltip>
                        </TooltipDelayGroup>
                    );
                },
                enableHiding: true,
            }),
            columnHelper.display({
                id: "applicant",
                header: i18n.preselection_table_header_applicant(),
                cell: ({ row }) => {
                    const formattedName = isLogas
                        ? `${row.original.firstName} ${row.original.lastName}`
                        : `${row.original.firstName}.${row.original.lastName}`;
                    return (
                        <div className={clsx(fonts.sans18Medium, styles.name)}>{formattedName}</div>
                    );
                },
            }),
            columnHelper.display({
                id: "availabilities",
                header: i18n.preselection_table_header_availabilities(),
                cell: ({ row }) => {
                    const formattedStartDate =
                        row.original.availabilities?.startDate &&
                        format(new Date(row.original.availabilities?.startDate), "dd/MM/yyyy");
                    const formattedEndDate =
                        row.original.availabilities?.endDate &&
                        format(new Date(row.original.availabilities?.endDate), "dd/MM/yyyy");

                    const startDateIsPast =
                        row.original.availabilities?.startDate &&
                        isPast(new Date(row.original.availabilities?.startDate));

                    if (!formattedStartDate && !formattedEndDate) {
                        return <div className={styles.availabilities}>-</div>;
                    }

                    if ((!formattedStartDate || startDateIsPast) && formattedEndDate) {
                        return (
                            <div className={styles.availabilities}>
                                {i18n.preselect_availabilities_until()} {formattedEndDate}
                            </div>
                        );
                    }

                    if (formattedStartDate && !formattedEndDate) {
                        return (
                            <div className={styles.availabilities}>
                                {i18n.preselect_availabilities_from()} {formattedStartDate}
                            </div>
                        );
                    }

                    return (
                        <div className={styles.availabilities}>
                            <div>{formattedStartDate}</div>
                            <div className={styles.chevron}>
                                <ChevronRight className='h-4 w-4 text-gray-300' />
                            </div>
                            <div>{formattedEndDate}</div>
                        </div>
                    );
                },
            }),
            columnHelper.accessor("comment", {
                header: i18n.preselection_table_header_notes(),
                cell: ({ row }) => {
                    const [value, setvalue] = useState(row.original.comment ?? "");

                    const debouncedPatch = useMemo(
                        () =>
                            debounce((value: string) => {
                                patchTask({
                                    id: taskId,
                                    applicant: {
                                        id: row.original.id,
                                        update: { comment: value },
                                    },
                                });
                            }, 1000),
                        [patchTask, taskId, row.original.id],
                    );

                    function handleChange(value: string) {
                        setvalue(value);
                        debouncedPatch(value);
                    }

                    const isServerValue = row.original.comment === value;

                    return isLogas ? (
                        <div className={styles.textArea}>
                            <TextArea
                                aria-label='note'
                                placeholder={i18n.preselect_textarea_placeholder()}
                                value={value}
                                hint={i18n.preselect_textarea_hint()}
                                onChange={(e) => handleChange(e.target.value)}
                                maxLength={500}
                            />
                            {value ? (
                                isServerValue ? (
                                    <div className={styles.textSaved}>
                                        <Check className='h-4 w-4' />
                                    </div>
                                ) : (
                                    <div className={styles.textLoading}>
                                        <Loader />
                                    </div>
                                )
                            ) : null}
                        </div>
                    ) : (
                        <pre className={clsx(fonts.sans18Regular, styles.comment)}>
                            {row.original.comment}
                        </pre>
                    );
                },
            }),
            columnHelper.accessor("preSelectionStatus", {
                header: i18n.preselection_table_header_status(),
                cell: ({ row }) => {
                    switch (row.original.preSelectionStatus) {
                        case "sent":
                            return <Tag color='grey'>{i18n.preselection_status_sent()}</Tag>;
                        case "approved":
                            return <Tag color='green'>{i18n.preselection_status_approved()}</Tag>;
                        case "refused":
                            return (
                                <div>
                                    <Tag color='red'>{i18n.preselection_status_refused()}</Tag>
                                    {row.original.refusalReason ? (
                                        <p
                                            className={clsx(
                                                fonts.sans18Regular,
                                                styles.refusalReason,
                                            )}
                                        >
                                            {row.original.refusalReason}
                                        </p>
                                    ) : null}
                                </div>
                            );
                    }
                },
            }),
            columnHelper.display({
                id: "actions",
                cell: ({ row }) => {
                    const inputRef = useRef<HTMLInputElement>(null);
                    const modalRef = useRef<HTMLDialogElement>(null);
                    const [refusalReason, setRefusalReason] = useState(
                        row.original.refusalReason ?? "",
                    );

                    return (
                        <TooltipDelayGroup>
                            <div className={styles.actions}>
                                {isLogas ? (
                                    <>
                                        <Tooltip>
                                            <TooltipTrigger>
                                                <Button
                                                    icon={<Upload className='h-5 w-5' />}
                                                    shape='outlined'
                                                    intention='secondary'
                                                    size='small'
                                                    onClick={() => inputRef.current?.click()}
                                                />
                                                <input
                                                    ref={inputRef}
                                                    type='file'
                                                    className={styles.hidden}
                                                    accept='.pdf'
                                                    onChange={(e) => {
                                                        if (
                                                            e.target.files &&
                                                            e.target.files.length
                                                        ) {
                                                            handleFileUpload(
                                                                row.original.id,
                                                                e.target.files[0],
                                                            );
                                                        }
                                                    }}
                                                />
                                            </TooltipTrigger>
                                            <TooltipContent type='action'>
                                                {i18n.preselect_actions_tooltip_upload()}
                                            </TooltipContent>
                                        </Tooltip>
                                    </>
                                ) : null}
                                {row.original.cvLink ? (
                                    <Tooltip>
                                        <TooltipTrigger>
                                            <Button
                                                icon={<Download className='h-5 w-5' />}
                                                shape='outlined'
                                                intention='secondary'
                                                size='small'
                                                onClick={() => {
                                                    if (!row.original.cvLink) return;
                                                    download({
                                                        url: `pre-selection/${row.original.id}`,
                                                        fileName: `${row.original.firstName}_${row.original.lastName}_CV.pdf`,
                                                    });
                                                }}
                                            />
                                        </TooltipTrigger>
                                        <TooltipContent type='action'>
                                            {i18n.preselect_actions_tooltip_download()}
                                        </TooltipContent>
                                    </Tooltip>
                                ) : null}
                                <Tooltip>
                                    <TooltipTrigger>
                                        <Button
                                            className={styles.approveButton}
                                            icon={<Check className='h-5 w-5' />}
                                            shape='outlined'
                                            intention='secondary'
                                            size='small'
                                            onClick={() => {
                                                patchTask({
                                                    id: taskId,
                                                    applicant: {
                                                        id: row.original.id,
                                                        update: { preSelectionStatus: "approved" },
                                                    },
                                                });
                                            }}
                                        />
                                    </TooltipTrigger>
                                    <TooltipContent type='action'>
                                        {i18n.preselect_actions_tooltip_approve()}
                                    </TooltipContent>
                                </Tooltip>

                                <Tooltip>
                                    <TooltipTrigger>
                                        <Button
                                            className={styles.refuseButton}
                                            icon={<X className='h-5 w-5' />}
                                            shape='outlined'
                                            intention='secondary'
                                            size='small'
                                            onClick={() => {
                                                modalRef.current?.showModal();
                                            }}
                                        />
                                    </TooltipTrigger>
                                    <TooltipContent type='action'>
                                        {i18n.preselect_actions_tooltip_refuse()}
                                    </TooltipContent>
                                </Tooltip>

                                <Modal
                                    ref={modalRef}
                                    onSubmit={() => {
                                        patchTask({
                                            id: taskId,
                                            applicant: {
                                                id: row.original.id,
                                                update: {
                                                    preSelectionStatus: "refused",
                                                    refusalReason: refusalReason,
                                                },
                                            },
                                        });
                                        modalRef.current?.close();
                                    }}
                                >
                                    <ModalHeader
                                        title={i18n.preselect_refusal_modal_title()}
                                        description={i18n.preselect_refusal_modal_description()}
                                    />
                                    <ModalContent>
                                        {
                                            <TextArea
                                                aria-label='refusalReason'
                                                placeholder={i18n.preselect_refusal_modal_placeholder()}
                                                value={refusalReason}
                                                onChange={(e) => setRefusalReason(e.target.value)}
                                                hint={i18n.preselect_textarea_hint()}
                                                maxLength={500}
                                            />
                                        }
                                    </ModalContent>
                                    <ModalFooter
                                        mainButton={
                                            <Button disabled={!refusalReason}>
                                                {i18n.preselect_refusal_modal_button()}
                                            </Button>
                                        }
                                        cancelButtonLabel={i18n.action_cancel()}
                                    />
                                </Modal>
                            </div>
                        </TooltipDelayGroup>
                    );
                },
            }),
        ],
        [],
    );

    const table = useReactTable({
        data: preSelection,
        columns,
        getCoreRowModel: getCoreRowModel(),
        onColumnVisibilityChange: setColumnVisibility,
        state: {
            columnVisibility,
        },
    });

    // if the user is logas, we want to show the visibility column and the clipboard
    useEffect(() => {
        if (isLogas) {
            table.getColumn("visible")?.toggleVisibility(true);
            table.getColumn("clipboard")?.toggleVisibility(true);
        }
    }, [table, isLogas]);

    return (
        <div>
            <Table>
                <TableHeader>
                    {table.getHeaderGroups().map((headerGroup) => (
                        <TableRow key={headerGroup.id}>
                            {headerGroup.headers.map((header) => {
                                return (
                                    <TableHead key={header.id}>
                                        {header.isPlaceholder
                                            ? null
                                            : flexRender(
                                                  header.column.columnDef.header,
                                                  header.getContext(),
                                              )}
                                    </TableHead>
                                );
                            })}
                        </TableRow>
                    ))}
                </TableHeader>
                <TableBody>
                    {table.getRowModel().rows?.length ? (
                        table.getRowModel().rows.map((row) => (
                            <TableRow
                                className={styles.row}
                                key={row.id}
                                data-state={row.getIsSelected() && "selected"}
                            >
                                {row.getVisibleCells().map((cell) => (
                                    <TableCell key={cell.id}>
                                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                    </TableCell>
                                ))}
                            </TableRow>
                        ))
                    ) : (
                        <TableRow>
                            <TableCell colSpan={columns.length}>
                                {isLogas
                                    ? i18n.preselect_no_result_logas()
                                    : i18n.preselect_no_result_client()}
                            </TableCell>
                        </TableRow>
                    )}
                </TableBody>
            </Table>
        </div>
    );
}
