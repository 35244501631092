import clsx from "clsx";
import { useSetAtom } from "jotai";
import { Info, X } from "lucide-react";
import { ModalsService } from "side-ui";
import { Breadcrumb, Button, fonts, Tag } from "sui";

import { i18n } from "@lib/i18n";
import { queries } from "@lib/queries";
import { useQuery } from "@tanstack/react-query";
import { Link, useMatchRoute, useNavigate } from "@tanstack/react-router";
import { useFlag } from "@unleash/proxy-client-react";

import { taskFormRoute } from "./route";
import OrderDetailsModal from "./TaskDetailsModal";
import { orderDataToSendAtom } from "./TaskFormFooter";

import styles from "./TaskFormHeader.module.css";

export function TaskFormHeader() {
    const matchRoute = useMatchRoute();

    const { taskId } = taskFormRoute.useParams();
    const setOrderDataToSend = useSetAtom(orderDataToSendAtom);

    const isMotiveStep = matchRoute({ to: "/taskPosting/$taskId/motive" });
    const isPlanningSelectionStep = matchRoute({ to: "/taskPosting/$taskId/planning-type" });
    const isFlexiblePlanningStep = matchRoute({ to: "/taskPosting/$taskId/flexible-planning" });
    const isShiftsStep = matchRoute({ to: "/taskPosting/$taskId/shifts" });

    const { data: task } = useQuery(queries.task.detail(taskId));
    const orderType = task?.type;
    const isFlexiblePlanningEnabled = useFlag("flexible-planning");

    const navigate = useNavigate();

    function displayOrderDetails() {
        ModalsService.openModal({
            id: `ORDER_DETAILS`,
            content: (
                <OrderDetailsModal
                    order={task}
                    hideModal={() => ModalsService.closeModal(`ORDER_DETAILS`)}
                />
            ),
        });
    }

    const breadcrumb = [
        <Link
            key={`/taskPosting/$taskId/motive`}
            to={`/taskPosting/$taskId/motive`}
            params={{ taskId }}
            className={styles.breadcrumbItem}
            onClick={() => setOrderDataToSend({})}
        >
            {i18n.order_stepper_motive()}
        </Link>,
        // PLANNING TYPE SELECTION
        ...(isPlanningSelectionStep ||
        (isFlexiblePlanningEnabled &&
            !task?.flexiblePlanningId &&
            !task?.shifts.length &&
            isMotiveStep)
            ? [
                  <Link
                      key={`/taskPosting/$taskId/planning-type`}
                      to={`/taskPosting/$taskId/planning-type`}
                      params={{ taskId }}
                      className={styles.breadcrumbItem}
                      disabled={!task?.motive}
                      onClick={() => setOrderDataToSend({})}
                  >
                      {i18n.order_stepper_planning()}
                  </Link>,
              ]
            : []),
        // FLEXIBLE PLANNING
        ...(isFlexiblePlanningStep ||
        task?.flexiblePlanningId ||
        (isFlexiblePlanningEnabled && task?.flexiblePlanning === true && task?.flexiblePlanningId)
            ? [
                  <Link
                      key={`/taskPosting/$taskId/flexible-planning`}
                      to={`/taskPosting/$taskId/flexible-planning`}
                      params={{ taskId }}
                      className={styles.breadcrumbItem}
                      onClick={() => setOrderDataToSend({})}
                  >
                      {i18n.order_stepper_planning()}
                  </Link>,
              ]
            : []),
        // ACCURATE PLANNING
        ...(isShiftsStep ||
        !isFlexiblePlanningEnabled ||
        (isFlexiblePlanningEnabled && task?.flexiblePlanning === false && task?.shifts.length)
            ? [
                  <Link
                      key={`/taskPosting/$taskId/shifts`}
                      to={`/taskPosting/$taskId/shifts`}
                      params={{ taskId }}
                      disabled={!(Boolean(task?.motive) || Boolean(task?.shifts?.length))}
                      className={styles.breadcrumbItem}
                      onClick={() => setOrderDataToSend({})}
                  >
                      {isFlexiblePlanningEnabled
                          ? i18n.order_stepper_planning()
                          : i18n.order_stepper_shifts()}
                  </Link>,
              ]
            : []),
        <Link
            key={`/taskPosting/$taskId/details`}
            to={`/taskPosting/$taskId/details`}
            params={{ taskId }}
            disabled={!(Boolean(task?.motive) && Boolean(task?.shifts?.length))}
            className={styles.breadcrumbItem}
            onClick={() => setOrderDataToSend({})}
        >
            {i18n.order_stepper_details()}
        </Link>,
        <Link
            key={`/taskPosting/$taskId/workers`}
            to={`/taskPosting/$taskId/workers`}
            params={{ taskId }}
            disabled={
                !(
                    Boolean(task?.motive) &&
                    Boolean(task?.shifts?.length) &&
                    Boolean(task?.location?.address)
                )
            }
            className={styles.breadcrumbItem}
            onClick={() => setOrderDataToSend({})}
        >
            {i18n.order_stepper_workers()}
        </Link>,
        <Link
            key={`/taskPosting/$taskId/summary`}
            to={`/taskPosting/$taskId/summary`}
            params={{ taskId }}
            disabled={
                !(
                    Boolean(task?.motive) &&
                    Boolean(task?.shifts?.length) &&
                    Boolean(task?.location?.address)
                )
            }
            className={styles.breadcrumbItem}
            onClick={() => setOrderDataToSend({})}
        >
            {i18n.order_stepper_summary()}
        </Link>,
    ];

    return (
        <div className={styles.header}>
            <div className={styles.details}>
                <p className={clsx(styles.name, fonts.sans22Semibold)}>{task?.name}</p>
                <div className='flex gap-2'>
                    <Tag color={orderType === "delegation" ? "blue" : "purple"}>{orderType}</Tag>
                    {task?.flexiblePlanning && (
                        <Tag color='pink'>{i18n.planning_type_flexible_title()}</Tag>
                    )}
                </div>
                <Button
                    icon={<Info className='h-5 w-5' />}
                    onClick={() => displayOrderDetails()}
                    shape='outlined'
                    intention='secondary'
                />
            </div>
            <div className={styles.breadcrumbWrapper}>
                <Breadcrumb>{...breadcrumb}</Breadcrumb>
                <Button
                    icon={<X className='h-5 w-5' />}
                    onClick={() => navigate({ to: `/job-descriptions` })}
                    shape='outlined'
                    intention='secondary'
                />
            </div>
        </div>
    );
}
