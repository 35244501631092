import { useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { Dialog, TextArea } from "saphir";
import { toast } from "saphir";
import { Button } from "sui";
import { z } from "zod";

import { zodResolver } from "@hookform/resolvers/zod";
import { updateLocation } from "@lib/api";
import { i18n } from "@lib/i18n";
import { usePatchTask } from "@lib/mutations/usePatchTask";
import { queries } from "@lib/queries";
import { useMutation, useQueryClient, useSuspenseQuery } from "@tanstack/react-query";

interface Props {
    taskId: string;
    trigger: React.ReactNode;
    tracker: () => void;
}

export function EditTaskAddressDetailsDialog(props: Props) {
    const [open, setOpen] = useState(false);

    return (
        <Dialog.Root open={open} onOpenChange={setOpen}>
            <Dialog.Trigger asChild>{props.trigger}</Dialog.Trigger>
            <Dialog.Content>
                <EditTaskAddressDetailsDialogContent {...props} setOpen={setOpen} />
            </Dialog.Content>
        </Dialog.Root>
    );
}

function EditTaskAddressDetailsDialogContent(props: Props & { setOpen: (open: boolean) => void }) {
    const { data: task } = useSuspenseQuery(queries.task.detail(props.taskId));
    const schema = z.object({
        locationDescription: z.string().optional(),
    });

    type Inputs = z.infer<typeof schema>;

    const {
        register,
        handleSubmit,
        formState: { isDirty },
        reset,
    } = useForm<Inputs>({
        mode: "onBlur",
        resolver: zodResolver(schema),
        defaultValues: {
            locationDescription: task.location?.description ?? "",
        },
    });

    const queryClient = useQueryClient();

    const { patchTask } = usePatchTask();
    const { mutate: patchLocation, isPending } = useMutation({
        mutationFn: updateLocation,
        onSuccess: (data: any) => {
            patchTask({ id: props.taskId, addressDetails: data?.description });
            props.tracker();
            reset({
                locationDescription: data?.description,
            });
            props.setOpen(false);
            toast.success(i18n.task_edit_success_toast());
        },
        onSettled: () => {
            queryClient.invalidateQueries({ queryKey: ["task"] });
        },
    });

    const onSubmit: SubmitHandler<Inputs> = async (data) => {
        patchLocation({
            organisationId: props.taskId,
            location: {
                ...task.location!,
                description: data.locationDescription?.trim() ?? "",
            },
        });
    };
    return (
        <form onSubmit={handleSubmit(onSubmit)} className='contents'>
            <Dialog.ScrollArea>
                <Dialog.Header>
                    <Dialog.Title>{i18n.order_summary_address_additional_infos()}</Dialog.Title>
                    <Dialog.Description>{i18n.task_edit_location_desc()}</Dialog.Description>
                </Dialog.Header>
                <Dialog.Main>
                    <TextArea.Root
                        aria-label={i18n.order_summary_info_title()}
                        placeholder={i18n.task_edit_location_desc_placeholder()}
                        {...register("locationDescription")}
                    />
                </Dialog.Main>
            </Dialog.ScrollArea>
            <Dialog.Footer className='justify-end'>
                <div className='flex gap-2'>
                    <Dialog.Close asChild>
                        <Button type='button' shape='invisible' intention='secondary'>
                            {i18n.action_cancel()}
                        </Button>
                    </Dialog.Close>
                    <Button loading={isPending} disabled={!isDirty}>
                        {i18n.save()}
                    </Button>
                </div>
            </Dialog.Footer>
        </form>
    );
}
