import { useState } from "react";
import { Pencil, Plus } from "lucide-react";
import { Button } from "saphir";
import { ModalSimple } from "side-ui";

import { i18n } from "@lib/i18n";

import FavoriteLocationForm from "./FavoriteLocationForm";

import "./FavoriteLocationsList.scss";

const modes = {
    SUMMARY: "summary",
    EDIT: "edit",
    CREATE: "create",
};

const FavoriteLocationsList = ({
    placesRef,
    favoriteLocations,
    hideModal,
    submitAddress,
    createAddress,
}) => {
    const [mode, setMode] = useState(modes.SUMMARY);
    const [location, setLocation] = useState(null);

    return (
        <div className='update-favorite__wrapper'>
            {mode === modes.SUMMARY && (
                <ModalSimple
                    title={i18n.location_form_workplace_favorite_label()}
                    subtitle={i18n.location_form_workplace_favorite_subtitle()}
                    hideModal={hideModal}
                    action={() => setMode(modes.EDIT)}
                    withCloseButton={true}
                >
                    <div className='update-favorite'>
                        <div className='update-favorite__location__list' role='list'>
                            {favoriteLocations?.length
                                ? favoriteLocations.map((favoriteLocation) => (
                                      <div
                                          key={favoriteLocation.id}
                                          className='update-favorite__location__list__item'
                                          role='listitem'
                                      >
                                          <div
                                              className='update-favorite__location__list__item__wrapper'
                                              role='button'
                                              onClick={() => {
                                                  setLocation(favoriteLocation);
                                                  setMode(modes.EDIT);
                                              }}
                                              onKeyDown={() => null}
                                              tabIndex='0'
                                          >
                                              <div className='update-favorite__location__list__item__infos'>
                                                  <span className='update-favorite__location__title'>
                                                      {/* &nbsp; not to break flex when no title */}
                                                      {favoriteLocation.title || (
                                                          <>Sans titre&nbsp;</>
                                                      )}

                                                      <Pencil className='h-4 w-4 text-gray-300' />
                                                  </span>
                                                  <span className='update-favorite__location__address'>
                                                      {favoriteLocation.address}
                                                  </span>
                                              </div>
                                          </div>
                                      </div>
                                  ))
                                : null}
                        </div>

                        <div className='update-favorite__footer'>
                            <Button onClick={() => setMode(modes.CREATE)}>
                                {i18n.location_form_workplace_favorite_title()}
                                <Plus />
                            </Button>
                        </div>
                    </div>
                </ModalSimple>
            )}

            {mode === modes.EDIT && (
                <FavoriteLocationForm
                    modalTitle={i18n.location_form_workplace_favorite_title()}
                    validateLabel={i18n.save()}
                    placesRef={placesRef}
                    hideModal={hideModal}
                    submitAddress={submitAddress}
                    cancelAction={() => setMode(modes.SUMMARY)}
                    location={location}
                    deleteAction={submitAddress}
                    deleteLabel={i18n.label_delete()}
                />
            )}

            {mode === modes.CREATE && (
                <FavoriteLocationForm
                    modalTitle={i18n.location_form_workplace_favorite_title()}
                    validateLabel={i18n.save()}
                    placesRef={placesRef}
                    hideModal={hideModal}
                    submitAddress={createAddress}
                    cancelAction={() => setMode(modes.SUMMARY)}
                />
            )}
        </div>
    );
};

export default FavoriteLocationsList;
