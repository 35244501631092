import { useState } from "react";
import clsx from "clsx";
import { X } from "lucide-react";
import { ModalSimple, Select } from "side-ui";

import { i18n, i18nDK } from "@lib/i18n";

import styles from "../../WorkConditionsAndSkillsModals.module.css";

export function EditSafetyEquipment({ selectedEquipments, hideModal, setEquipment }) {
    const [state, setState] = useState({
        equipments: selectedEquipments,
        touched: false,
    });

    function removeEquipment(equipment) {
        setState((prevState) => {
            // handles the deletion of a custom equipment which is not yet persisted
            // -> id is set by the backend so id doesn't exist yet if equipment is issuedByTheFrontEnd
            if (equipment.custom && equipment?.issuedByFrontEnd) {
                return {
                    ...prevState,
                    equipments: prevState.equipments.filter(
                        (e) => e?.customId !== equipment.customId,
                    ),
                    touched: true,
                };
            }

            return {
                ...prevState,
                equipments: prevState.equipments.filter((e) => e.id !== equipment.id),
                touched: true,
            };
        });
    }

    function handleProvidedByChange(id, selectedItem) {
        const updatedEquipments = state.equipments.map((eq) => {
            if (eq.id === id) {
                return {
                    ...eq,
                    providedBy: selectedItem,
                };
            }
            return eq;
        });

        setState((prevState) => ({
            ...prevState,
            equipments: updatedEquipments,
            touched: true,
        }));
    }
    return (
        <ModalSimple
            hideModal={() => hideModal()}
            title={i18n.job_descriptions_creation_conditions_equipments_security_title()}
            cancelLabel={i18n.cancel()}
            validateLabel={i18n.save()}
            disableButton={!state.touched}
            action={() => {
                setEquipment(state.equipments);
                hideModal();
            }}
            customClass={styles.modal}
        >
            {state.equipments.map((selectedEquipment) => (
                <div key={selectedEquipment.id} className={clsx(styles.edit, styles.equipments)}>
                    <div className={styles.title}>
                        {selectedEquipment?.custom
                            ? selectedEquipment.name
                            : i18nDK(selectedEquipment.name)}

                        {selectedEquipment.name?.length ? (
                            <button
                                type='button'
                                title={i18n.job_descriptions_creation_conditions_equipments_security_delete_button()}
                                onClick={() => removeEquipment(selectedEquipment)}
                            >
                                <X className='h-4 w-4' />
                            </button>
                        ) : null}
                    </div>

                    <div className={styles.inputWrapper}>
                        <Select
                            id={`equipment-edit-${selectedEquipment.id}`}
                            name={selectedEquipment.name}
                            chevronIcon={true}
                            options={[
                                {
                                    key: "sider",
                                    value: "sider",
                                    label: i18n.job_descriptions_creation_conditions_equipment_provider_sider(),
                                },
                                {
                                    key: "company",
                                    value: "company",
                                    label: i18n.job_descriptions_creation_conditions_equipment_provider_company(),
                                },
                            ]}
                            selectedItem={
                                [
                                    {
                                        value: "sider",
                                        label: i18n.job_descriptions_creation_conditions_equipment_provider_sider(),
                                    },
                                    {
                                        value: "company",
                                        label: i18n.job_descriptions_creation_conditions_equipment_provider_company(),
                                    },
                                ].find((opt) => opt.value === selectedEquipment.providedBy)
                                    ?.label || ""
                            }
                            onChange={(_, __, selectedItem) =>
                                handleProvidedByChange(selectedEquipment.id, selectedItem)
                            }
                        />
                    </div>
                </div>
            ))}
        </ModalSimple>
    );
}
