import { memo } from "react";
import { format } from "date-fns";
import { ChevronRight } from "lucide-react";

import { i18n } from "@lib/i18n";
import { getLocale } from "@lib/utils/getLocale";

import { convertMinutesToHours } from "../../../../utils/dates";

type SlotCellProps = {
    startDate: string;
    endDate: string;
    conflict: any;
    siderDidntWork: boolean;
};

const SlotCell = memo(function SlotCell({
    startDate,
    endDate,
    conflict,
    siderDidntWork,
}: SlotCellProps) {
    const locale = getLocale();
    return (
        <span
            className={`timesheets-table__cell timesheets-table__cell__slot timesheets-table__cell--slot ${siderDidntWork ? "--sider-didnt-work" : ""} `}
        >
            <span
                className={` ${
                    conflict.startDate && !siderDidntWork
                        ? "timesheets-table__cell__hoverable__wrapper conflicting"
                        : ""
                } `}
            >
                {format(new Date(startDate), "HH:mm", { locale })}

                {conflict.startDate && !siderDidntWork ? (
                    <div className='timesheets-table__cell__hoverable__tooltip'>
                        <span className='timesheets-table__cell__hoverable__tooltip__title timesheets-table__cell__hoverable__tooltip__title--red'>
                            {i18n.ts_table_diff_hours_label({
                                delta: convertMinutesToHours(conflict.startDate),
                            })}
                        </span>

                        <div className='timesheets-table__cell__hoverable__tooltip__content timesheets-table__cell__hoverable__tooltip__content--smaller'>
                            {i18n.ts_table_diff_hours_description({
                                delta: convertMinutesToHours(conflict.startDate),
                            })}
                        </div>
                    </div>
                ) : null}
            </span>

            <ChevronRight className='h-4 w-4 text-gray-300' />

            <span
                className={` ${
                    conflict.endDate && !siderDidntWork
                        ? "timesheets-table__cell__hoverable__wrapper conflicting"
                        : ""
                } `}
            >
                {format(new Date(endDate), "HH:mm", { locale })}

                {conflict.endDate && !siderDidntWork ? (
                    <div className='timesheets-table__cell__hoverable__tooltip'>
                        <span className='timesheets-table__cell__hoverable__tooltip__title timesheets-table__cell__hoverable__tooltip__title--red'>
                            {i18n.ts_table_diff_hours_label({
                                delta: convertMinutesToHours(conflict.endDate),
                            })}
                        </span>

                        <div className='timesheets-table__cell__hoverable__tooltip__content timesheets-table__cell__hoverable__tooltip__content--smaller'>
                            {i18n.ts_table_diff_hours_description({
                                delta: convertMinutesToHours(conflict.endDate),
                            })}
                        </div>
                    </div>
                ) : null}
            </span>
        </span>
    );
});

export default SlotCell;
