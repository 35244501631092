/* eslint-disable */
/** 
 * This file contains language specific message functions for tree-shaking. 
 * 
 *! WARNING: Only import messages from this file if you want to manually
 *! optimize your bundle. Else, import from the `messages.js` file. 
 * 
 * Your bundler will (in the future) automatically replace the index function 
 * with a language specific message function in the build step. 
 */


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const EQ_AUDITIVE_PROTECTION = () => `Protections auditives`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const EQ_BIKE = () => `Vélo`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const EQ_FACIAL_SCREEN = () => `Écran facial`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const EQ_GLOVES = () => `Gants de travail`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const EQ_HARD_HAT = () => `Casque de protection`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const EQ_HEADPHONES_AND_MIC = () => `Casque avec micro intégré`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const EQ_LAPTOP = () => `Ordinateur portable`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const EQ_PROTECTIVE_CLOTHING = () => `Vêtements protecteurs`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const EQ_PROTECTIVE_GLASSES = () => `Lunettes de protection`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const EQ_RESPIRATORY_PROTECTION = () => `Protection respiratoire (masque)`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const EQ_SAFETY_SHOES = () => `Chaussures de sécurité`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const EQ_SMARTPHONE = () => `Smartphone`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const EQ_YELLOW_JACKET = () => `Gilet jaune`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const LI_CACES_1 = () => `CACES 1`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const LI_CACES_3 = () => `CACES 3`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const LI_CACES_5 = () => `CACES 5`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const LI_DRIVING_LICENSE_B = () => `Permis B`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const RIO_LOAD_HEAVY = () => `Charges lourdes (+15kg)`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const RIO_LOAD_LIGHT = () => `Charges légères (moins de 5kg)`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const RIO_LOAD_MEDIUM = () => `Charges intermédiaires (5 à 15kg)`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const RIO_TEMP_CHILL = () => `Frais (3 à 5°C)`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const RIO_TEMP_COLD = () => `Froid (0 à 3°C)`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const RIO_TEMP_HOT = () => `Chaud (>=30°C)`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const RIO_TEMP_NEGATIVE_COLD = () => `Froid négatif (<0°C)`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const RI_ALTERNATING_TEAMS = () => `Travail en équipes successives alternantes`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const RI_CHEMICALS_DUST_FUMES = () => `Agents chimiques dangereux, y compris poussières et fumées`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const RI_EXTREME_TEMPERATURES = () => `Températures extrêmes`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const RI_HARD_POSTURES = () => `Postures pénibles`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const RI_HYPERBARIC_ENV = () => `Activités exercées en milieu hyperbare`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const RI_MANUAL_HANDLING = () => `Manutentions manuelles de charges`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const RI_NIGHT_WORK = () => `Travail de nuit`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const RI_NOISE = () => `Bruit`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const RI_REPETITIVE_WORK = () => `Travail répétitif`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const RI_VIBRATIONS = () => `Vibrations mécaniques`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const TO_EXCEL = () => `Excel`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const TO_GOOGLE_DRIVE = () => `Google Drive`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const TO_INTERCOM = () => `Intercom`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const TO_NOTION = () => `Notion`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const TO_POWER_POINT = () => `Powerpoint`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const TO_SLACK = () => `Slack`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const TO_ZENDESK = () => `Zendesk`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const action_cancel = () => `Annuler`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const action_confirm = () => `Confirmer`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const action_unauthorized = () => `Non autorisé`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const action_unauthorized_extended = () => `Vous n’êtes pas autorisé à effectuer cette action.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const action_unauthorized_extended_logas = () => `Vous ne pouvez pas effectuer cette action en log as.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const activate_account_form_title = () => `Finalisation d'inscription`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const activate_account_link_broken = () => `Votre lien d'activation de compte semble caduc.\\n\\n

Demandez à un membre de votre équipe de vous renvoyer un lien d'invitation.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const activate_account_log_in = () => `Activer mon compte`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const activate_account_logo_company = () => `Entreprise`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const activate_account_or = () => `ou`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const activate_account_password_placeholder = () => `Mot de passe`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const activate_account_title = () => `Connectez-vous à Side`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const activate_account_with_google = () => `Activer mon compte avec Google`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const add = () => `Ajouter`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const advanced = () => `Avancé`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const alb = () => `Albanais`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ara = () => `Arabe`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const arm = () => `Arménien`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const at = () => `à`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const aym = () => `Aymara`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const aze = () => `Azéri`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const back = () => `Retour`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const beginner = () => `Débutant`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const bel = () => `Biélorusse`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ben = () => `Bengali`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const bul = () => `Bulgare`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const bur = () => `Birman`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const cancel = () => `Annuler`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const cat = () => `Catalan`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const chi = () => `Chinois (mandarin)`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const cnr = () => `Monténégrin`


/**
 * @param {{ siderNumber: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const completed_section_row_status_cell_subtitle_contact_plural = (params) => `Retrouver les ${params.siderNumber} Siders`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const completed_section_row_status_cell_subtitle_contact_singular = () => `Retrouver le Sider`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const completed_section_row_status_cell_title_selection_done = () => `Mission terminée`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const completed_section_row_task_cell_managed_by = () => `Gérée par `


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const completed_section_title = () => `Missions terminées`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const confirm = () => `Confirmer`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const contract_contestation_confirmation_toast = () => ``


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const contract_signature_confirmation_block_agreement = () => `En signant ce document vous acceptez nos <a href='https://s3.eu-central-1.amazonaws.com/weslash-static/pdfs/cgu-fr.pdf' target='_blank'>conditions d'utilisation</a>.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const contract_signature_confirmation_block_checkbox = () => `J’ai lu et vérifié les informations de l'ensemble des contrats présents dans ce document`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const contract_signature_confirmation_block_error = () => `Un problème est survenu lors de la signature du document !`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const contract_signature_confirmation_block_notice = () => `Une fois le document signé, vous ne pouvez pas le contester.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const contract_signature_confirmation_block_number = () => `Nombre de contrats`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const contract_signature_confirmation_block_title = () => `Signature`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const contract_signature_confirmation_toast = () => `Le document a bien été signé. Retrouvez vos contracts dans la section \\"Signés\\".`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const contract_signature_document_error_button_label = () => `Revenir à la liste de contrats`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const contract_signature_document_error_subtitle = () => `L’affichage des contrats n’est pas disponible pour le moment.
Si le problème persiste veuillez contacter le support.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const contract_signature_document_error_title = () => `Une erreur est survenue !`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const contract_signature_pagination_last_page = () => `Rendez-vous sur la dernière page pour pouvoir signer vos contrats`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const contract_signature_phone_number_required = () => `Vous devez d'abord renseigner votre numéro de téléphone dans les paramètres afin de pouvoir signer vos contrats.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const contract_signature_regenerate_document_label = () => `Re-générer le document`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const contract_signature_regeneration_success = () => `Le document est en cours de re-génération. Cela peut prendre quelques secondes.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const contract_signature_report_button_label = () => `Une erreur avec un contrat ?`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const contract_signature_see_contestation_button_label = () => `Voir le motif de contestation`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const contract_signature_sign_button_label = () => `Signer`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const contracts_batch_row_mobile_date = () => `Date de début : `


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const contracts_batch_row_mobile_manager = () => `Géré par`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const contracts_batch_row_mobile_number = () => `contrat(s)`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const contracts_batch_row_status_contested = () => `contesté`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const contracts_batch_row_status_contested_button_label = () => `Voir`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const contracts_batch_row_status_disputed_tooltip_content = () => `Vous avez contesté ce document. Une nouvelle version corrigée est en chemin. `


/**
 * @param {{ contestationDate: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const contracts_batch_row_status_disputed_tooltip_title = (params) => `Depuis le ${params.contestationDate}`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const contracts_batch_row_status_signed = () => `signé`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const contracts_batch_row_status_signed_button_label = () => `Télécharger`


/**
 * @param {{ signatureAuthorName: NonNullable<unknown>, signatureDate: NonNullable<unknown>, signatureTime: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const contracts_batch_row_status_signed_tooltip_content = (params) => `Signé par ${params.signatureAuthorName} le ${params.signatureDate} à ${params.signatureTime}.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const contracts_batch_row_status_tosign = () => `à signer`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const contracts_batch_row_status_tosign_button_label = () => `Vérifier et signer`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const contracts_batch_row_status_tosign_late_tooltip_content = () => `Les contrats doivent être signés 48h avant le début de la mission`


/**
 * @param {{ numberOfDays: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const contracts_batch_row_status_tosign_late_tooltip_title = (params) => `${params.numberOfDays} jours de retard`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const contracts_batches_list_header_date = () => `Date de début`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const contracts_batches_list_header_manager = () => `Gestionnaire`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const contracts_batches_list_header_number = () => `Nombre de contrats`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const contracts_batches_list_header_status = () => `Statut`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const contracts_batches_list_header_task = () => `Mission`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const contracts_batches_signed_blank_state_content = () => `Vous n'avez pas encore de contrat signé pour le moment.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const contracts_batches_signed_blank_state_title = () => `Aucun contrat signé`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const contracts_batches_tosign_blank_state_content = () => `Vous n'avez aucun contrat à signer pour le moment.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const contracts_batches_tosign_blank_state_title = () => `C'est tout bon !`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const contracts_contestation_modal_cancel = () => `Annuler`


/**
 * @param {{ contestationDate: NonNullable<unknown>, contestationAuthorName: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const contracts_contestation_modal_details = (params) => `Contesté le ${params.contestationDate} par ${params.contestationAuthorName}.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const contracts_contestation_modal_input_label = () => `Motif de contestation`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const contracts_contestation_modal_input_placeholder = () => `Indiquez les éléments à corriger dans le document`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const contracts_contestation_modal_send = () => `Envoyer`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const contracts_contestation_modal_subtitle_contested = () => `Les contrats de cette mission font l’objet d’une contestation. Une nouvelle version corrigée est en chemin. `


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const contracts_contestation_modal_subtitle_tocontest = () => `Précisez à notre équipe le motif de la contestation. Une fois votre demande traitée, vous pourrez consulter et signer la version modifiée du document. `


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const contracts_contestation_modal_title = () => `Contester les informations du contrat`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const contracts_top_menu_filter_signed = () => `Signés`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const contracts_top_menu_filter_to_sign = () => `À signer`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const countries_belgium = () => `Belgique`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const countries_denmark = () => `Danemark`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const countries_estonia = () => `Estonie`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const countries_france = () => `France`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const countries_germany = () => `Allemagne`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const countries_great_britain = () => `Royaume Uni`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const countries_italy = () => `Italie`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const countries_netherlands = () => `Pays-bas`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const countries_portugal = () => `Portugal`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const countries_spain = () => `Espagne`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_albania = () => `Albanie`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_algeria = () => `Algérie`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_andorra = () => `Andorre`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_angola = () => `Angola`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_antigua_and_barbuda = () => `Antigua-et-Barbuda`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_argentina = () => `Argentine`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_armenia = () => `Arménie`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_australia = () => `Australie`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_austria = () => `Autriche`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_azerbaijan = () => `Azerbaïdjan`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_bahamas = () => `Bahamas`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_bahrain = () => `Bahreïn`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_bangladesh = () => `Bangladesh`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_barbados = () => `Barbade`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_belarus = () => `Biélorussie`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_belgium = () => `Belgique`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_belize = () => `Belize`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_benin = () => `Bénin`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_bhutan = () => `Bhoutan`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_bolivia = () => `Bolivie`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_bosnia_and_herzegovina = () => `Bosnie-Herzégovine`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_botswana = () => `Botswana`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_brazil = () => `Brésil`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_brunei = () => `Brunéi`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_bulgaria = () => `Bulgarie`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_burkina_faso = () => `Burkina Faso`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_burundi = () => `Burundi`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_cambodia = () => `Cambodge`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_cameroon = () => `Cameroun`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_canada = () => `Canada`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_cape_verde = () => `Cap-Vert`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_chad = () => `Tchad`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_chile = () => `Chili`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_china = () => `Chine`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_colombia = () => `Colombie`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_comoros = () => `Comores`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_congo_brazzaville = () => `Congo-Brazzaville`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_congo_kinshasa = () => `Congo-Kinshasa`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_cook_islands = () => `Îles Cook`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_costa_rica = () => `Costa Rica`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_croatia = () => `Croatie`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_cuba = () => `Cuba`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_cyprus = () => `Chypre`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_czech_republic = () => `Tchéquie`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_denmark = () => `Danemark`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_djibouti = () => `Djibouti`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_dominica = () => `Dominique`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_ecuador = () => `Équateur`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_egypt = () => `Égypte`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_el_salvador = () => `Salvador`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_equatorial_guinea = () => `Guinée Équatoriale`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_eritrea = () => `Érythrée`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_estonia = () => `Estonie`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_ethiopia = () => `Éthiopie`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_fiji = () => `Fidji`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_finland = () => `Finlande`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_france = () => `France`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_gabon = () => `Gabon`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_gambia = () => `Gambie`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_georgia = () => `Géorgie`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_germany = () => `Allemagne`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_ghana = () => `Ghana`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_greece = () => `Grèce`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_grenada = () => `Grenade`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_guatemala = () => `Guatemala`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_guinea = () => `Guinée`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_guinea_bissau = () => `Guinée-Bissau`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_guyana = () => `Guyana`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_haiti = () => `Haïti`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_honduras = () => `Honduras`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_hungary = () => `Hongrie`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_iceland = () => `Islande`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_india = () => `Inde`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_indonesia = () => `Indonésie`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_iran = () => `Iran`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_iraq = () => `Irak`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_ireland = () => `Irlande`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_israel = () => `Israël`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_italy = () => `Italie`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_jamaica = () => `Jamaïque`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_japan = () => `Japon`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_jordan = () => `Jordanie`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_kazakhstan = () => `Kazakhstan`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_kenya = () => `Kenya`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_kiribati = () => `Kiribati`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_kosovo = () => `Kosovo`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_kuwait = () => `Koweït`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_kyrgyzstan = () => `Kirghizistan`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_laos = () => `Laos`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_latvia = () => `Lettonie`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_lebanon = () => `Liban`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_lesotho = () => `Lesotho`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_liberia = () => `Libéria`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_libya = () => `Libye`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_liechtenstein = () => `Liechtenstein`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_lithuania = () => `Lituanie`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_luxembourg = () => `Luxembourg`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_macedonia = () => `Macédoine`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_madagascar = () => `Madagascar`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_malawi = () => `Malawi`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_malaysia = () => `Malaisie`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_maldives = () => `Maldives`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_mali = () => `Mali`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_malta = () => `Malte`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_marshall_islands = () => `Îles Marshall`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_mauritania = () => `Mauritanie`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_mauritius = () => `Maurice`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_mexico = () => `Mexique`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_micronesia = () => `Micronésie`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_moldova = () => `Moldavie`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_monaco = () => `Monaco`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_mongolia = () => `Mongolie`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_montenegro = () => `Monténégro`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_morocco = () => `Maroc`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_mozambique = () => `Mozambique`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_myanmar = () => `Birmanie`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_namibia = () => `Namibie`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_nauru = () => `Nauru`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_nepal = () => `Népal`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_netherlands = () => `Pays-Bas`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_new_zealand = () => `Nouvelle-Zélande`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_nicaragua = () => `Nicaragua`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_niger = () => `Niger`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_nigeria = () => `Nigeria`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_north_korea = () => `Corée du Nord`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_norway = () => `Norvège`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_oman = () => `Oman`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_pakistan = () => `Pakistan`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_palau = () => `Palaos`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_palestine = () => `Palestine`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_panama = () => `Panama`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_papua_new_guinea = () => `Papouasie-Nouvelle-Guinée`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_paraguay = () => `Paraguay`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_peru = () => `Pérou`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_philippines = () => `Philippines`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_poland = () => `Pologne`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_portugal = () => `Portugal`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_qatar = () => `Qatar`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_romania = () => `Roumanie`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_russia = () => `Russie`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_rwanda = () => `Rwanda`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_saint_kitts_and_nevis = () => `Saint-Christophe-et-Niévès`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_saint_lucia = () => `Sainte-Lucie`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_saint_vincent_and_the_grenadines = () => `Saint-Vincent-et-les Grenadines`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_samoa = () => `Samoa`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_san_marino = () => `Saint-Marin`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_sao_tome_and_principe = () => `Sao Tomé-et-Principe`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_saudi_arabia = () => `Arabie Saoudite`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_senegal = () => `Sénégal`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_serbia = () => `Serbie`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_seychelles = () => `Seychelles`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_sierra_leone = () => `Sierra Leone`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_singapore = () => `Singapour`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_slovakia = () => `Slovaquie`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_slovenia = () => `Slovénie`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_solomon_islands = () => `Îles Salomon`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_somalia = () => `Somalie`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_south_africa = () => `Afrique du Sud`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_south_korea = () => `Corée du Sud`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_south_sudan = () => `Soudan du Sud`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_spain = () => `Espagne`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_sri_lanka = () => `Sri Lanka`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_sudan = () => `Soudan`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_suriname = () => `Suriname`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_swaziland = () => `Swaziland`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_sweden = () => `Suède`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_switzerland = () => `Suisse`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_syria = () => `Syrie`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_tajikistan = () => `Tadjikistan`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_tanzania = () => `Tanzanie`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_thailand = () => `Thaïlande`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_togo = () => `Togo`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_tonga = () => `Tonga`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_trinidad_and_tobago = () => `Trinité-et-Tobago`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_tunisia = () => `Tunisie`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_turkey = () => `Turquie`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_turkmenistan = () => `Turkménistan`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_tuvalu = () => `Tuvalu`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_uganda = () => `Ouganda`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_ukraine = () => `Ukraine`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_united_arab_emirates = () => `Émirats Arabes Unis`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_united_kingdom = () => `Royaume-Uni`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_united_states = () => `États-Unis`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_uruguay = () => `Uruguay`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_uzbekistan = () => `Ouzbékistan`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_vanuatu = () => `Vanuatu`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_vatican = () => `Vatican`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_venezuela = () => `Venezuela`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_vietnam = () => `Vietnam`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_yemen = () => `Yémen`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_zambia = () => `Zambie`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_zimbabwe = () => `Zimbabwe`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const cze = () => `Tchèque`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const dan = () => `Danois`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const deadline = () => `Date limite`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const delete_draft = () => `Supprimer le brouillon`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const details_completion_hoursTypesInformations = () => `Politique de rémunération - Types d'heures`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const details_completion_indemnitiesInformations = () => `Politique de rémunération - Primes et indemnités`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const details_completion_legalInformations = () => `Informations légales`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const details_completion_paymentInformations = () => `Informations de paiement`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const details_completion_profile = () => `Profil de l'entreprise`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const details_completion_status_incomplete = () => `Incomplet`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const details_completion_validate_label = () => `Compléter mes informations`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const doc_title_contracts = () => `Contrats`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const doc_title_contracts_signature = () => `Signature de contracts`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const doc_title_home = () => `Accueil`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const doc_title_invoices = () => `Factures`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const doc_title_job_descriptions = () => `Fiches de poste`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const doc_title_logas = () => `Admin`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const doc_title_not_found = () => `Introuvable`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const doc_title_planning = () => `Planning`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const doc_title_posting = () => `Nouvelle demande`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const doc_title_preselection = () => `Présélection`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const doc_title_settings = () => `Paramètres`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const doc_title_tasks = () => `Missions`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const doc_title_timesheets = () => `Relevés d'heures`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const draft_deleted_success = () => `Brouillon supprimé !`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const draft_section_row_status_cell_subtitle = () => `Finaliser le brouillon`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const draft_section_row_status_cell_title = () => `Brouillon`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const draft_section_row_task_cell_created_by = () => `Créée par `


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const draft_section_title = () => `Brouillons`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const duplicate = () => `Dupliquer`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const duplicate_draft = () => `Renouveler la mission`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const duplicate_task = () => `Renouveler la prestation`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const empty_placeholder_all_subtitle = () => `Toutes vos missions avec Side s'afficheront ici.<br>Pour démarrer, il suffit de cliquer sur le bouton \\"+ Nouvelle demande\\" en bas de la barre de navigation de gauche.`


/**
 * @param {{ firstName: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const empty_placeholder_all_title = (params) => `Bonjour ${params.firstName} !`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const empty_placeholder_completed_subtitle = () => `Retrouvez ici toutes les missions finalisées.<br>
Un Sider vous a plu ? N'hésitez pas à \\"Renouveler la mission\\", s'il est partant et disponible, vous pourrez refaire appel à lui directement.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const empty_placeholder_completed_title = () => `Aucune mission finalisée`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const empty_placeholder_draft_subtitle = () => `Des demandes non finalisées ? Vous pourrez les retrouver ici et les compléter.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const empty_placeholder_draft_title = () => `Aucun brouillon en attente`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const empty_placeholder_ongoing_subtitle = () => `Retrouvez ici toutes les missions sur lesquelles des Siders sont en train de travailler.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const empty_placeholder_ongoing_title = () => `Aucune mission en cours`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const empty_placeholder_planning_cta = () => `Nouvelle demande`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const empty_placeholder_planning_subtitle = () => `Vous n'avez pas de créneau prévu cette semaine. Affichez une autre semaine ou postez une nouvelle mission.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const empty_placeholder_planning_title = () => `Aucun créneau cette semaine`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const empty_placeholder_upcoming_subtitle = () => `Retrouvez ici toutes les missions n'ayant pas encore demarré.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const empty_placeholder_upcoming_title = () => `Aucune mission à venir`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const eng = () => `Anglais`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const error_confirmation_not_equal_password = () => `Les deux mots de passe sont différents`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const error_field_is_required = () => `Ce champ est obligatoire.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const error_field_is_required_checkbox = () => `Cette réponse est obligatoire. Veuillez sélectionner au moins une option.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const error_field_required = () => `Ce champ est obligatoire`


/**
 * @param {{ maxSize: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const error_file_size = (params) => `La taille du fichier dépasse la limite de ${params.maxSize}Mo`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const error_invalid_format = () => `Format invalide`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const error_invalid_shift = () => `Le créneau que vous avez ajouté n'est pas valide. Sa durée doit être comprise entre 2h et 10h de travail, pause comprise.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const error_no_auth = () => `Une erreur est survenue : nous n'avons pas pu vous authentifier.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const error_password_length_8 = () => `Votre mot de passe doit faire au moins 8 caractères`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const error_password_special_char = () => `Votre mot de passe doit contenir au moins un caractère spécial`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const error_phone_number_invalid = () => `Numéro de téléphone invalide`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const errors_email_conflict = () => `Email déjà utilisé. Connectez-vous`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const errors_generic = () => `Une erreur est survenue`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const errors_organisation_forbidden_blocked = () => ``


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const errors_shift_conflict_duplicated = () => `Ce créneau a déjà été renseigné, vous ne pouvez pas le dupliquer.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const errors_shift_forbidden_post_in_the_past = () => `Cette mission contient des créneaux dans le passé, vous pouvez les supprimer ou vous tourner vers notre équipe support.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const errors_sider_conflict_already_exists = () => `Ce sider est déjà inscrit sur la plateforme Side. Contactez le support si vous souhaitez quand même l'ajouter à vos siders gestion.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const errors_slot_forbidden_requested_siders_count = () => `Mince, vous avez sélectionné trop de Siders par rapport au nombre de places disponibles pour la mission.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const errors_task_forbidden_not_enough_siders_selected = () => `Pas assez de Siders sélectionnés`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const errors_task_salary_forbidden_too_low = () => `La fiche de poste n’a pas pu être créée, la rémunération doit être égale ou supérieure au SMIC en vigueur.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const errors_token_forbidden = () => `Votre lien d'activation est expiré ou invalide.\\n\\n

Demandez à un membre de votre équipe de vous renvoyer un lien d'invitation.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const errors_user_unauthorized_login_failed = () => `Connexion impossible. Vérifiez vos identifiants puis réessayez.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const est = () => `Estonien`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const expert = () => `Expert`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const feedback_dialog_content = () => `Les modifications que vous avez apportées ne seront pas prises en compte.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const feedback_dialog_title = () => `Quitter sans sauvegarder ?`


/**
 * @param {{ name: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const feedback_form_comment_question = (params) => `Avez-vous autre chose à ajouter à propos de ${params.name} ?`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const feedback_form_comment_question_label = () => `Commentaire privé (facultatif)`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const feedback_form_comment_question_placeholder = () => `Exemple : J’ai apprécié son dynamisme mais il faut faire attention à la rigueur…`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const feedback_form_general_answer_1 = () => `Oui`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const feedback_form_general_answer_1_notice = () => `Je suis satisfait`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const feedback_form_general_answer_2 = () => `Non`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const feedback_form_general_answer_2_notice = () => `Je ne suis pas satisfait`


/**
 * @param {{ name: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const feedback_form_general_question = (params) => `Seriez-vous prêt à retravailler avec ${params.name} sur des missions similaires ?`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const feedback_form_issue_choice_1 = () => `Professionnalisme`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const feedback_form_issue_choice_1_notice = () => `Le comportement du Sider n’était pas assez professionnel.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const feedback_form_issue_choice_2 = () => `Performance`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const feedback_form_issue_choice_2_notice = () => `Le Sider n’a pas su atteindre ses objectifs.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const feedback_form_issue_description = () => `Nous sommes désolés d’apprendre que cette prestation ne correspondait pas à vos attentes. Précisez ce qui n'allait pas avec ce sider.`


/**
 * @param {{ name: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const feedback_form_issue_question = (params) => `Quel était le problème avec ${params.name} ?`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const feedback_form_send_no_comment = () => `Envoyer<span class=\\"send-hide-mobile\\"> sans commentaire</span>`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const feedback_modal_exit_description = () => `Les modifications que vous avez apportées ne seront pas prises en compte`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const feedback_modal_exit_title = () => `Quitter sans sauvegarder ?`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const feedback_modal_title = () => `Votre avis sur…`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const feedback_tip_description = () => `Dites-nous si vous avez été satisfaits ou non de la prestation de ce Sider. Ceci nous aidera à vous proposer en priorité les profils que vous avez aimés. Vous pouvez modifier votre avis à tout moment.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const feedback_tip_description_rated = () => `N’oubliez pas de nous indiquer si vous êtes satisfaits ou non de la prestation de vos Siders. Ceci nous aidera à vous proposer en priorité les profils que vous avez aimés. Vous pouvez modifier votre avis à tout moment.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const feedback_tip_title = () => `Seriez-vous prêt à retravailler avec ce Sider ?`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const feedback_tip_title_rated = () => `Avez-vous changé d'avis à propos de ce Sider?`


/**
 * @param {{ name: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const feedback_toast_not_satisfied = (params) => `Vous n’êtes pas satisfaits de la prestation de ${params.name}. Ce sider sera par défaut retiré de votre liste de siders favoris.`


/**
 * @param {{ name: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const feedback_toast_not_saved = (params) => `Votre avis sur la prestation de ${params.name} n’a pas été pris en compte.`


/**
 * @param {{ name: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const feedback_toast_satisfied = (params) => `Vous êtes satisfaits de la prestation de ${params.name} ! Ce Sider a été ajouté à votre liste de Siders favoris, vous pourrez refaire appel à ce Sider en priorité.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const feedback_tooltip_not_satisfied = () => `Je ne suis pas satisfait de ce Sider`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const feedback_tooltip_satisfied = () => `Je suis satisfait de ce Sider`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const fin = () => `Finnois`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const finish = () => `Terminer`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const finish_draft = () => `Finaliser le brouillon`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const fluent = () => `Courant`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const fre = () => `Français`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ger = () => `Allemand`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const go_to_contracts_signature_page = () => `Signer les contrats`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const gre = () => `Grec`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const grn = () => `Guarani`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const guard_modal_incomplete = () => `incomplet`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const guard_modal_legal = () => `Informations légales`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const guard_modal_payment = () => `Informations de paiement`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const guard_modal_policy_hours = () => `Politique de rémunération - Type d’heures`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const guard_modal_policy_indemnities = () => `Politique de rémunération - Indemnités`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const guard_modal_profile = () => `Profil de l'entreprise`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const guard_modal_submit = () => `Compléter mes informations`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const guard_modal_subtitle = () => `Finissez de compléter vos informations pour pouvoir poster des missions`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const guard_modal_title = () => `Complétez vos informations`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const header_backhome = () => `Accueil`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const header_contracts = () => `Contrats`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const header_conversations = () => `Messages`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const header_cta_task = () => `Nouvelle demande`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const header_cta_team = () => `Mon équipe`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const header_dashboard = () => `Tableau de bord`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const header_dropdown_help = () => `Centre d'aide`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const header_dropdown_language = () => `Switch to English`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const header_dropdown_logout = () => `Se déconnecter`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const header_dropdown_payment_settings = () => `Informations de paiement`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const header_dropdown_personal_settings = () => `Informations personnelles`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const header_dropdown_profile = () => `Profil entreprise`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const header_dropdown_settings = () => `Paramètres de l'établissement`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const header_dropdown_team = () => `Équipe`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const header_invoices = () => `Factures`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const header_job_descriptions = () => `Fiches de poste`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const header_payments = () => `Paiements`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const header_planning = () => `Planning`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const header_search_company = () => `Recherche`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const header_settings = () => `Paramètres`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const header_siders = () => `Siders`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const header_switch_search_placeholder = () => `Rechercher`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const header_tasks = () => `Prestations`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const header_team = () => `Équipe`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const header_timesheets = () => `Relevés d'heures`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const heb = () => `Hébreu`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const hin = () => `Hindi`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const home_company_logo_button_tooltip = () => `Mettre à jour le logo`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const home_connecttochild_button = () => `Accéder à l'espace`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const home_search_child_label = () => `Recherchez un établissement`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const home_search_child_placeholder = () => `Nom ou adresse de l'établissement`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const home_welcome_subtitle = () => `Postez et gérez vos missions en vous rendant sur l'espace de votre établissement`


/**
 * @param {{ groupName: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const home_welcome_title = (params) => `Bienvenue sur ${params.groupName}`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const horizon_acquisition_argument_0 = () => `Des premiers CV qualifiés en <b>1 semaine</b>`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const horizon_acquisition_argument_1 = () => `Des <b>profils qualifiés</b> en visio d’au moins 30 mins`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const horizon_acquisition_argument_2 = () => `Des retours <b>transparents</b> à chaque étape`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const horizon_acquisition_title = () => `Un expert recrutement dédié`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const horizon_dialog_message = () => `Un expert recrutement Side vous est maintenant dédié pour en discuter et élaborer avec vous votre offre sur-mesure.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const horizon_dialog_primary_action = () => `Réserver un appel`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const horizon_dialog_secondary_action = () => `Plus tard`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const horizon_dialog_title = () => `Merci ! Nous avons bien noté votre besoin en CDI !`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const horizon_differentiate_title = () => `Ce qui nous distingue`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const horizon_modal_address_label = () => `Localisation du poste`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const horizon_modal_commentary_label = () => `Informations complémentaires`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const horizon_modal_commentary_placeholder = () => `Lien d’une fiche de poste ou autre élément à partager`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const horizon_modal_confirmation = () => `Votre besoin en CDI a bien été soumis. Nous revenons vers vous au plus vite !`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const horizon_modal_jobdesc_document_label = () => `Document`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const horizon_modal_jobdesc_label = () => `Fiche de poste ou offre d'emploi`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const horizon_modal_jobdesc_link_label = () => `Lien`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const horizon_modal_jobdesc_none_label = () => `Pas disponible pour l’instant`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const horizon_modal_jobtitle_label = () => `Intitulé du poste`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const horizon_modal_positions_label = () => `Nombre de besoin en CDI`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const horizon_modal_salary_label = () => `Salaire annuel brut du poste`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const horizon_modal_startdate_label = () => `Date de démarrage envisagée`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const horizon_modal_title = () => `Besoin de recruter en CDI`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const horizon_nav_button_desc = () => `Découvrez notre offre !`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const horizon_nav_button_title = () => `Un besoin en CDI ?`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const horizon_pool_argument_0 = () => `Une application mobile <b>dédiée</b> pour les candidats`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const horizon_pool_argument_1 = () => `<b>100K candidats</b> en recherche de CDI en France`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const horizon_pool_argument_2 = () => `Historique des candidats ayant <b>déjà</b> travaillé avec nous`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const horizon_pool_title = () => `Un vivier inédit & motivé`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const horizon_reassurance_0 = () => `Facturé <b>uniquement</b> au succès`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const horizon_reassurance_1 = () => `Basé sur le <b>salaire annuel brut</b> HT`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const horizon_reassurance_2 = () => `Pas d’acompte ni d'exclusivité`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const horizon_reassurance_3 = () => `<b>Garantie</b> 30 jours`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const horizon_step_0 = () => `Soumettez votre besoin`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const horizon_step_1 = () => `Élaborez une offre sur-mesure avec votre expert dédié`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const horizon_step_2 = () => `Recevez les meilleurs CV et rapports d’entretiens`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const horizon_step_3 = () => `Poursuivez votre process de recrutement interne`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const horizon_step_4 = () => `Notre talent est maintenant le vôtre !`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const horizon_steps_title = () => `5 moments clés`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const horizon_subtitle = () => `Recrutez en CDI selon un processus simple, efficace et transparent.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const horizon_title = () => `Recruter en CDI avec l’offre Horizon`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const hrv = () => `Croate`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const hun = () => `Hongrois`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ice = () => `Islandais`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ind = () => `Indonésien`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const industry_logistics = () => `Logistique`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const industry_office = () => `Office`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const industry_retail = () => `Retail`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const intermediate = () => `Intermédiaire`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const invoices_amount = () => `Montant TTC`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const invoices_date = () => `Période`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const invoices_download = () => `Télécharger la facture`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const invoices_download_error = () => `Facture indisponible`


/**
 * @param {{ month: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const invoices_monthly_download = (params) => `Télécharger toutes les factures pour ${params.month}`


/**
 * @param {{ month: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const invoices_monthly_download_failed = (params) => `Télécharger les factures disponibles pour ${params.month}`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const invoices_monthly_error_body = () => `Veuillez contacter le support pour plus d'informations.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const invoices_monthly_error_title = () => `Une ou plusieurs factures n'ont pu être générées.`


/**
 * @param {{ month: NonNullable<unknown>, amount: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const invoices_monthly_notice = (params) => `En ${params.month} vous avez dépensé ${params.amount} € sur la plateforme. Nous avons regroupé toutes vos factures pour votre comptabilité juste ici !`


/**
 * @param {{ month: NonNullable<unknown>, amount: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const invoices_monthly_notice_failed = (params) => `En ${params.month} vous avez dépensé ${params.amount} € sur la plateforme. Nous avons regroupé les factures disponibles pour votre comptabilité juste ici !`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const invoices_number = () => `Numéro de document`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const invoices_sider = () => `Sider`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const invoices_status = () => `Statut`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const invoices_status_paid = () => `Payé`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const invoices_status_unpaid = () => `À payer`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const invoices_task = () => `Prestation`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const invoices_type = () => `Type`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const invoices_type_creditNote = () => `Avoir`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const invoices_type_invoice = () => `Facture`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ita = () => `Italien`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_copy_id = () => `ID copié !`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_create_button = () => `Créer`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_creation_conditions_context_goals_characters = () => `400 caractères`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_creation_conditions_context_goals_placeholder = () => `Vous ferez partie d'une équipe de 5 personnes et votre périmètre inclura...`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_creation_conditions_context_goals_title = () => `Contexte et objectifs`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_creation_conditions_context_subtitle = () => `Si vous le souhaitez, vous pouvez préciser quelques éléments de contexte personnalisés qui permettront aux Siders de se projeter dans la mission.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_creation_conditions_context_title = () => `À propos`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_creation_conditions_equipment_provider_company = () => `Fourni par l'entreprise`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_creation_conditions_equipment_provider_side = () => `Fourni par Side`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_creation_conditions_equipment_provider_sider = () => `Fourni par le Sider`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_creation_conditions_equipment_table_header_label = () => `Type d'équipement`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_creation_conditions_equipment_table_header_value = () => `Détails`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_creation_conditions_equipments_dresscode_placeholder = () => `Si besoin, précisez le dress code demandé`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_creation_conditions_equipments_dresscode_title = () => `Dress code`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_creation_conditions_equipments_other_add_button = () => `Ajouter du matériel`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_creation_conditions_equipments_other_add_custom_button = () => `Ajouter un élement personnalisé`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_creation_conditions_equipments_other_custom_modal_input_label = () => `Nom du matériel`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_creation_conditions_equipments_other_custom_modal_tip = () => `Si vous n'avez pas trouvé l'équipement correspondant à votre besoin dans la liste, vous pouvez en ajouter un nouveau ci-dessous.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_creation_conditions_equipments_other_delete_button = () => `Supprimer ce matériel`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_creation_conditions_equipments_other_subtitle = () => `Sélectionnez le matériel que les Siders devront apporter.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_creation_conditions_equipments_other_title = () => `Autre matériel`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_creation_conditions_equipments_risks_label1 = () => `Facteurs de pénibilité`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_creation_conditions_equipments_risks_label2 = () => `Précisions supplémentaires`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_creation_conditions_equipments_risks_options = () => `Sélectionnez une option`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_creation_conditions_equipments_risks_placeholder = () => `Ajoutez les détails que vous considérez important dans la prise en compte de cette pénibilité`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_creation_conditions_equipments_risks_subtitle = () => `Précisez les facteurs de pénibilité qui s’appliquent à cette mission, ils sont reconnus par le code du travail et nécessitent la mise en place de mesures de prévention.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_creation_conditions_equipments_risks_title = () => `Risques et pénibilité`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_creation_conditions_equipments_security_add_button = () => `Ajouter un EPI`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_creation_conditions_equipments_security_add_custom_button = () => `Ajouter un élement personnalisé`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_creation_conditions_equipments_security_custom_modal_input_label = () => `Nom de l'EPI`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_creation_conditions_equipments_security_custom_modal_tip = () => `Si vous n'avez pas trouvé l'équipement correspondant à votre besoin dans la liste, vous pouvez en ajouter un nouveau ci-dessous.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_creation_conditions_equipments_security_delete_button = () => `Supprimer cet équipement`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_creation_conditions_equipments_security_modal_choice_no = () => `Non`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_creation_conditions_equipments_security_modal_choice_yes = () => `Oui`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_creation_conditions_equipments_security_modal_label = () => `Fournissez-vous cet équipement ?`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_creation_conditions_equipments_security_modal_subtitle = () => `Précisez si cet EPI est fourni par vos soins`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_creation_conditions_equipments_security_subtitle = () => `Sélectionnez les EPI nécessaires pour assurer la sécurité des Siders.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_creation_conditions_equipments_security_title = () => `Équipements de sécurité`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_creation_conditions_equipments_subtitle = () => `Précisez les EPI et autre matériel nécessaires au bon déroulement de la mission.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_creation_conditions_equipments_title = () => `Équipements`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_creation_conditions_step_title = () => ` Conditions de travail`


/**
 * @param {{ numberOfTasks: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_creation_job_jobtitle_subtasks = (params) => `${params.numberOfTasks} tâches sélectionnées`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_creation_job_jobtitle_subtitle = () => `Cette information nous permettra de sélectionner des Siders qualifiés pour le poste.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_creation_job_jobtitle_title = () => `Intitulé du poste`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_creation_job_name_title = () => `Nom de la fiche`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_creation_job_step_title = () => `Poste`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_creation_job_subtasks_custom_add_button = () => `Ajouter un élément personnalisé`


/**
 * @param {{ searchInput: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_creation_job_subtasks_custom_add_button_input = (params) => `Ajouter '${params.searchInput}'`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_creation_job_subtasks_custom_modal_label = () => `Nom de la tâche`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_creation_job_subtasks_custom_modal_placeholder = () => `Tâche personnalisée`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_creation_job_subtasks_custom_modal_subtitle = () => `Si vous n'avez pas trouvé la tâche correspondant à votre besoin dans la liste, vous pouvez en ajouter une nouvelle ci-dessous.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_creation_job_subtasks_custom_modal_title = () => `Ajouter un élément personnalisé`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_creation_job_subtasks_subtitle = () => `Sélectionnez les tâches que les Siders réaliseront lors de cette mission`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_creation_job_subtasks_title = () => `Tâches`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_creation_location_step_title = () => `Lieu`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_creation_salary_input_error = () => `Merci de vérifier votre saisie`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_creation_salary_input_error_underflow = () => `Merci de saisir un montant supérieur ou égal au salaire minimum`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_creation_salary_input_label = () => `Salaire de référence (horaire)`


/**
 * @param {{ minimumHourlyRate: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_creation_salary_input_placeholder = (params) => `Supérieur ou égal à ${params.minimumHourlyRate}€/heure`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_creation_salary_label2 = () => `Coûts horaires et coefficients indicatifs`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_creation_salary_raw = () => `Brut horaire`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_creation_salary_step_title = () => `Paie`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_creation_salary_subtitle = () => `Renseignez le salaire de référence correspondant à ce poste, ceci nous permettra de calculer une estimation du prix de vos futures commandes. Conformément au principe d’égalité de traitement, indiquez le salaire de référence pratiqué à poste égal dans votre entreprise.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_creation_salary_title = () => `Rémunération`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_creation_skills_experience_label = () => `Types d'expériences souhaitées`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_creation_skills_experience_placeholder = () => `Nous recherchons de préférence des profils connaissant l'industrie de...`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_creation_skills_experience_subtitle = () => `Précisez les éventuelles expériences souhaitées, cela permettra aux candidats d'évaluer leur chance d'être sélectionnés.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_creation_skills_experience_title = () => `Expérience`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_creation_skills_languages_add_button = () => `Ajouter une langue`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_creation_skills_languages_edit_disclaimer = () => `Cliquez sur Enregistrer pour supprimer toutes les langues.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_creation_skills_languages_modal_label = () => `Niveau de compétence souhaité`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_creation_skills_languages_modal_placeholder = () => `Sélectionnez un choix`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_creation_skills_languages_modal_subtitle = () => `Précisez le niveau souhaité, celui-ci prendra en compte à la fois l'oral et l'écrit.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_creation_skills_languages_modal_title = () => `Langues`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_creation_skills_languages_subtitles = () => `Sélectionnez les langues que les Siders doivent maîtriser.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_creation_skills_languages_title = () => `Langues`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_creation_skills_languages_tools_subtitle = () => `Précisez les éventuelles compétences linguistiques ou techniques que les Siders doivent maîtriser.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_creation_skills_languages_tools_title = () => `Langues et outils`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_creation_skills_licence_add_button = () => `Ajouter un permis`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_creation_skills_licence_label = () => `Permis & habilitations`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_creation_skills_licence_subtitle = () => `Précisez les permis et habilitations dont les Siders doivent disposer`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_creation_skills_licence_title = () => `Documents`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_creation_skills_step_title = () => `Compétences`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_creation_skills_tools_add_button = () => `Ajouter un outil`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_creation_skills_tools_add_custom_button = () => `Ajouter un élément personnalisé`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_creation_skills_tools_custom_modal_label = () => `Nom de l'outil`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_creation_skills_tools_custom_modal_placeholder = () => `Photoshop, Teams, Salesforce...`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_creation_skills_tools_custom_modal_tip = () => `Si vous n'avez pas trouvé l'outil correspondant à votre besoin dans la liste, vous pouvez en ajouter un nouveau ci-dessous.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_creation_skills_tools_modal_excel_feedback = () => `- Débutant : Saisie de données, formules et calculs simples, impression de document, mise en forme de cellules.
- Intermédiaire : Formules (SI, MAX, MIN, SOMME.SI, NB.SI), graphiques (création, mise en forme), fonctions de tri/filtres, création d’un tableau croisé dynamique, recherche de données, validation de données.
- Avancé : Automatisation de tâches : exécuter des macros enregistrées, calculs avancés (calculs multi-feuilles/classeurs), fonctions avancées et formules imbriquées, fonctions de base de données, graphiques de TCD.
- Expert : VBA, formules complexes (matricielles, financières), maîtrise experte de la mise en forme, calculs, TCD, outils d’analyse.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_creation_skills_tools_modal_placeholder = () => `Sélectionnez un choix`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_creation_skills_tools_modal_subtitle = () => `Précisez le niveau de maîtrise souhaité`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_creation_skills_tools_subtitle = () => `Sélectionnez les outils que les Siders doivent maîtriser.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_creation_skills_tools_title = () => `Outils et logiciels`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_creation_status_subtitle = () => `Le statut de vos Siders sera reporté dans leur contrat de travail et déterminera le montant des cotisations sociales à payer.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_creation_status_title = () => `Statut`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_creation_success = () => `Votre fiche de poste a bien été créée`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_draft = () => `Brouillon`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_edit_context_subtitle = () => `Vous pouvez modifier les éléments de contexte personnalisés qui permettront aux Siders de se projeter dans la mission.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_edit_devices_subtitle = () => `Vous pouvez modifier le matériel que les Siders devront apporter.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_edit_dresscode_subtitle = () => `Vous pouvez modifier le dress code demandé.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_edit_experiences_subtitle = () => `Vous pouvez modifier les éventuelles expériences souhaitées.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_edit_jobTitle_subtitle = () => `Vous pouvez modifier l'intitulé du poste, vous devrez ensuite définir de nouveau les tâches liées à ce poste.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_edit_languages_subtitle = () => `Vous pouvez modifier les compétences linguistiques ou techniques que les Siders doivent maîtriser.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_edit_licences_subtitle = () => `Vous pouvez modifier les permis et habilitations dont les Siders doivent disposer.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_edit_location_subtitle = () => `Vous pouvez modifier les informations liées au lieu de travail.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_edit_manager_subtitle = () => `Vous pouvez modifier le nom du manager pour ce poste.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_edit_name_subtitle = () => `Vous pouvez renommer votre fiche de poste pour la différencier des autres, ce nouveau nom sera utilisé en tant que référence sur vos factures.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_edit_preselection = () => `Préselection de candidats`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_edit_risks_subtitle = () => `Vous pouvez modifier les facteurs de pénibilité qui s'appliquent à cette mission.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_edit_safetyEquipment_subtitle = () => `Vous pouvez modifier les EPI et autre matériel nécessaires au bon déroulement de la mission.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_edit_salary_subtitle = () => `Vous pouvez modifier le salaire de référence correspondant à ce poste.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_edit_subtasks_subtitle = () => `Vous pouvez modifier les tâches que les Siders réaliseront lors de cette mission.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_edit_success = () => `Votre fiche de poste a bien été mise à jour`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_edit_tools_subtitle = () => `Vous pouvez modifier les outils que les Siders doivent maîtriser.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_empty_create_button = () => `Créer une fiche de poste`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_empty_subtitle = () => `Vos fiches de poste vous permettront de facilement passer commande.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_empty_subtitle_link_label = () => `En savoir plus`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_empty_title = () => `Aucune fiche de poste`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_instructions = () => `Pour poster une nouvelle demande de mission, sélectionnez une fiche de poste existante ou créez la fiche de poste correspondant à votre besoin.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_instructions_account = () => `Pour poster une nouvelle demande de mission, sélectionnez une fiche de poste existante ou créez la fiche de poste correspondant à votre besoin.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_instructions_network = () => `Vous pouvez créer et modifier des fiches de poste qui correspondent à vos besoins.\\nElles seront utilisables directement par l’ensemble des comptes de votre groupe.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_jobtitle = () => `Fiche de poste`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_list_network_description = () => `Ces fiches de poste proviennent de votre groupe, vous pouvez les utiliser directement pour créer vos missions.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_new_title = () => `Créer une fiche de poste`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_rename_field_placeholder = () => `Nom personnalisé`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_rename_tip = () => `Ce nouveau nom ne sera pas visible par les Siders.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_status_ETAM = () => `Employé technicien et Agent de Maîtrise (ETAM)`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_status_executive = () => `Cadre`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_status_worker = () => `Ouvrier`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_summary_context_title = () => `Contexte`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_summary_devices_title = () => `Autre matériel`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_summary_dresscode_title = () => `Dress code`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_summary_experiences_title = () => `Expériences`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_summary_jobTitle_title = () => `Intitulé du poste`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_summary_languages_title = () => `Langues`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_summary_licences_title = () => `Permis`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_summary_location_title = () => `Adresse`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_summary_manager_title = () => `Manager`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_summary_name_title = () => `Nom de la fiche`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_summary_risks_title = () => `Risques et pénibilité`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_summary_safetyEquipment_title = () => `Équipements de sécurité`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_summary_salary_title = () => `Rémunération`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_summary_status_title = () => `Statut`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_summary_subtasks_title = () => `Tâches`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_summary_tools_title = () => `Outils et logiciels`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_unauthorized_custom = () => `Vous ne pouvez pas modifier vos fiches de poste car vous avez bénéficié d’une configuration particulière. Contactez-nous pour effectuer des changements`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_unauthorized_description = () => `Vous n’êtes pas autorisé à modifier cette fiche de poste`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_unauthorized_manager_account = () => `Veuillez en sélectionner un nouveau afin d’utiliser cette fiche de poste.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_unauthorized_manager_missing = () => `Le manager sélectionné ne fait plus partie de l’entreprise.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_unauthorized_manager_network = () => `Le manager doit être modifié depuis l'espace du groupe.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_unauthorized_network = () => `Cette fiche de poste provient de votre groupe, vous n’êtes pas autorisé à la modifier`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_unauthorized_network_redirect_description = () => `Vous pouvez modifier cette fiche de poste depuis l’espace du groupe`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_unauthorized_network_redirect_title = () => `Action indisponible`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_types_contact_content = () => `Contactez-nous par chat ou écrivez-nous à <a href='mailto:in@side.co'>in@side.co</a>, nous avons certainement une solution pour vous !`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_types_contact_title = () => `Vous ne trouvez pas ce que vous cherchez ?`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_types_more = () => `Et plus...`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_types_subtitle = () => `Choisissez votre prestation, Side s'occupe ensuite de la sélection des meilleurs profils et simplifie les démarches administratives.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_types_title = () => `Démarrer une nouvelle prestation`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const jpn = () => `Japonais`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const just_posted_section_row_status_cell_subtitle = () => `Analyse de votre besoin en cours...`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const just_posted_section_row_status_cell_title = () => `La sélection va bientôt commencer...`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const just_posted_section_row_task_cell_managed_by = () => `Gérée par `


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const just_posted_section_table_header_start_date = () => `Date de début`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const just_posted_section_table_header_status = () => `Information`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const just_posted_section_table_header_task = () => `Mission`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const just_posted_section_title = () => `Mission en préparation`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const khm = () => `Khmer`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const kon = () => `Kikongo`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const kor = () => `Coréen`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const label_continue = () => `Continuer`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const label_delete = () => `Supprimer`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const label_new = () => `Nouveau`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const latest_task_title = () => `Dernière mission`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const lav = () => `Letton`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const learn_more = () => `En savoir plus`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const lin = () => `Lingala`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const lit = () => `Lituanien`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const location_form_favorite_list_addition = () => `L'adresse a bien été ajoutée à votre liste de favoris`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const location_form_favorite_list_deletion = () => `L'adresse a bien été supprimée de votre liste de favoris`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const location_form_favorite_list_update = () => `Votre liste de favoris a bien été mise à jour`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const location_form_no_street_number = () => `Précisez un numéro de rue`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const location_form_workplace_favorite_input_label = () => `Adresse`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const location_form_workplace_favorite_input_title_label = () => `Intitulé`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const location_form_workplace_favorite_input_title_placeholder = () => `Site A`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const location_form_workplace_favorite_label = () => `Adresses favorites`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const location_form_workplace_favorite_placeholder = () => `Saisissez l'adresse`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const location_form_workplace_favorite_subtitle = () => `Entrez les adresses les plus souvent utilisées pour vos missions.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const location_form_workplace_favorite_title = () => `Ajouter une adresse favorite`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const location_form_workplace_label1 = () => `Adresse`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const location_form_workplace_label2 = () => `Informations complémentaires`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const location_form_workplace_option1 = () => `Possibilité de télétravail`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const location_form_workplace_option2 = () => `Le Sider devra être motorisé`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const location_form_workplace_placeholder1 = () => `Saisissez l'adresse`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const location_form_workplace_placeholder2 = () => `Précisez les informations qui seront utiles aux Siders pour trouver leur chemin le premier jour.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const location_form_workplace_subtitle = () => `Ces informations nous permettront de sélectionner des Siders pouvant se rendre facilement sur le lieu de travail.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const location_form_workplace_title = () => `Lieu de travail`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const logas_impersonate_button = () => `Se connecter en tant que `


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const logas_input_label = () => `Saisir un nom, email ou id`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const logas_logout_button = () => `Se déconnecter`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mac = () => `Macédonien`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const manager_form_choice_later = () => `Choisir plus tard`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const manager_form_choice_now = () => `Choisir maintenant`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const manager_form_label = () => `Sélectionnez le manager`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const manager_form_subtitle = () => `Sélectionnez la personne en charge de ce poste, elle recevra les communications et sera en charge de valider les heures.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const manager_form_title = () => `Manager`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const manager_update_success = () => `Manager modifié !`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const may = () => `Malais`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mlg = () => `Malgache`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const modal_confirmation_answer_1 = () => `Une fois votre demande validée par notre équipe, la sélection des Siders est lancée. Vous recevrez un e-mail de confirmation vous indiquant la date et l’heure prévues de finalisation de la sélection. Vous pourrez également suivre l’avancée du processus directement sur votre compte Side. `


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const modal_confirmation_answer_2 = () => `Une fois votre mission validée, nous vous garantissons de remplir l’ensemble des créneaux demandés. En l'absence de l’un des Siders pour cas de force majeure, il sera remplacé par un indépendant tout aussi qualifié. 90% des missions Side sont remplies en moins de 24h.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const modal_confirmation_answer_3 = () => `Lors de leur inscription, chaque Sider renseigne le détail de ses formations et expériences. Il s’entretient avec un membre de l’équipe et passe des tests ciblés en fonction de chaque type de missions. L’analyse de ces données permet de sélectionner les profils correspondant le mieux à votre demande.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const modal_confirmation_answer_4 = () => `Sur Side, le paiement suit un rythme hebdomadaire. Lors de leurs missions, vos Siders entrent les heures travaillées du lundi au dimanche grâce à un système de check-in/check-out. Ces heures sont automatiquement envoyées sur votre plateforme entreprise et vous pouvez les modifier en cas de besoin. Les paiements sont validés tous les mardi et une facture à la semaine est générée automatiquement.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const modal_confirmation_description = () => `Et maintenant ?<br /> Voici les réponses à 4 questions que vous pourriez vous poser à propos des prochaines étapes.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const modal_confirmation_faq = () => `Pour plus d'informations, <a href='https://support.side.co/b2b/fr/' target='_blank'>consultez notre FAQ</a>. En cas d'urgence, une équipe est à votre disposition sur notre chat en ligne pour répondre à vos questions en un rien de temps.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const modal_confirmation_question_1 = () => `Que se passe-t-il une fois ma mission postée ? `


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const modal_confirmation_question_2 = () => `Comment m'assurer que ma mission sera bien remplie ?`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const modal_confirmation_question_3 = () => `Quel est le processus de sélection ?`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const modal_confirmation_question_4 = () => `Comment se passe le paiement ?`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const modal_confirmation_subtitle = () => `C'est parti`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const modal_confirmation_tip_4 = () => `Si vous souhaitez mettre en place un règlement sur mesure adapté à vos contraintes comptables, écrivez-nous à l’adresse in@side.co. Un membre de l'équipe étudiera votre demande rapidement.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const modal_confirmation_title = () => `Demande bien reçue !`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const modal_delete_description = () => `Êtes-vous sûr de vouloir supprimer ce brouillon ?`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const modal_delete_job_description_content = () => `Une fois supprimée, vous ne pourrez plus accéder à cette fiche de poste`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const modal_delete_job_description_title = () => `Souhaitez-vous vraiment supprimer cette fiche de poste ?`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const modal_delete_title = () => `Supprimer le brouillon`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const modal_duplicate_description = () => `Pas d'inquiétude, vous pourrez l'éditer avant de la publier !`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const modal_duplicate_title = () => `Êtes-vous sûr de vouloir dupliquer cette mission ?`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const modal_manager_update_field_tip = () => `Cette personne aura la responsabilité de valider les heures travaillées par les Siders, veuillez vous assurer qu'elle sera bien disponible pour le faire.
<br>Sans cette validation, nous ne pouvons pas payer les Siders.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const modal_redirect_button = () => `Accéder aux fiches de poste`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const modal_redirect_description = () => `Le système de commande de missions a changé. Pour effectuer une nouvelle demande suivez nos conseils.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const modal_redirect_step_1 = () => `Rendez-vous sur la page <em>Fiches de poste</em>`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const modal_redirect_step_2 = () => `Créez une nouvelle fiche de poste ou utilisez celle de votre choix pour poster vos futurs créneaux.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const modal_redirect_title = () => `Duplication impossible`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const modal_rename_button_cancel = () => `Annuler`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const modal_rename_button_ok = () => `Continuer`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const modal_rename_description = () => `Si vous le souhaitez, vous pouvez personnaliser le nom de votre mission pour la différencier des autres et vous organiser. Ce nouveau nom sera utilisé en tant que référence sur vos factures.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const modal_rename_error_too_long = () => `Le nouveau nom doit faire 25 caractères maximum.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const modal_rename_field_label = () => `Nom de la mission`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const modal_rename_field_placeholder = () => `Nom personnalisé`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const modal_rename_field_tip = () => `Ce nouveau nom ne sera pas visible par les Siders.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const modal_rename_title = () => `Comment voulez-vous nommer cette mission ?`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const modal_update_manager_input_label = () => `Gestionnaire`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const modal_update_manager_title = () => `Changer le manager`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const modify = () => `Modifier`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mon = () => `Mongol`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const motive_activity_increase_explaination_link = () => `https://support.side.co/b2b/fr/articles/9113082-quels-sont-les-motifs-possibles-de-recours-au-travail-temporaire`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const motive_activity_increase_explaination_link_label = () => `dans cet article`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const motive_activity_increase_explaination_tip = () => `La loi impose d'indiquer au contrat la \\\\\\"justification précise\\\\\\" de votre accroissement d'activité pour vérifier que le recours à un emploi temporaire est justifié. Plus d'informations `


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const motive_activity_increase_label = () => `Justification`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const motive_activity_increase_length = () => `caractères restants`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const motive_activity_increase_placeholder = () => `Ex : Pic d’activité, soldes, inventaire annuel de magasin...`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const motive_activity_increase_subtitle = () => `Vous devez ici préciser au maximum la raison pour laquelle l'entreprise connaît une surcharge de travail temporaire. Plus d’informations`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const motive_activity_increase_title = () => `Accroissement temporaire d'activité`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const motive_activity_increase_validation = () => `Saisir au minimum 6 caractères`


/**
 * @param {{ index: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const motive_recruitment_count = (params) => `Personne recrutée n°${params.index}`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const motive_recruitment_date_label = () => `Date d'arrivée`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const motive_recruitment_date_placeholder = () => `jj/mm/aaaa`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const motive_recruitment_explaination_link = () => `https://support.side.co/b2b/fr/articles/9113082-quels-sont-les-motifs-possibles-de-recours-au-travail-temporaire`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const motive_recruitment_explaination_link_label = () => `dans cet article`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const motive_recruitment_explaination_tip = () => `Ce motif de recours est possible uniquement dans l'attente de l'arrivée d'une personne déjà recrutée en CDI. Plus d'informations `


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const motive_recruitment_name_label = () => `Nom et prénom du futur salarié`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const motive_recruitment_name_placeholder = () => `Ex: Hugo Marchand`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const motive_recruitment_position_label = () => `Poste du futur salarié`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const motive_recruitment_position_placeholder = () => `Ex : vendeur en magasin`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const motive_recruitment_subtitle = () => `Ce motif de recours est possible uniquement dans l'attente de l'arrivée d'une personne déjà recrutée en CDI. Plus d’informations`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const motive_recruitment_title = () => `En attente d'un recrutement`


/**
 * @param {{ index: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const motive_replacement_count = (params) => `Personne à remplacer n°${params.index}`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const motive_replacement_explaination_link = () => `https://support.side.co/b2b/fr/articles/9113082-quels-sont-les-motifs-possibles-de-recours-au-travail-temporaire`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const motive_replacement_explaination_link_label = () => `dans cet article`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const motive_replacement_explaination_tip = () => `Pour que le contrat soit valide, la loi impose de fournir les informations suivantes concernant le salarié à remplacer. Plus d'informations `


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const motive_replacement_name_label = () => `Nom et prénom`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const motive_replacement_name_placeholder = () => `Ex : Hugo Marchand`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const motive_replacement_position_label = () => `Poste de la personne remplacée`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const motive_replacement_position_placeholder = () => `Ex : vendeur en magasin`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const motive_replacement_reason_label = () => `Motif de l’absence`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const motive_replacement_reason_placeholder = () => `Ex : congé maladie`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const motive_replacement_subtitle = () => `Vous devez obligatoirement indiquer dans le contrat de votre Sider qui il doit remplacer et pourquoi cette personne est absente. Plus d’informations`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const motive_replacement_title = () => `Remplacement d’un salarié absent`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const motive_seasonal_activity_explaination_link = () => `https://support.side.co/b2b/fr/articles/9113082-quels-sont-les-motifs-possibles-de-recours-au-travail-temporaire`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const motive_seasonal_activity_explaination_link_label = () => `dans cet article`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const motive_seasonal_activity_explaination_tip = () => `Les secteurs concernés par les emplois saisonniers sont définis par décret (agriculture, le tourisme et l'agro-alimentaire). Plus d'informations `


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const motive_seasonal_activity_label = () => `Justifiez votre motif de recours à Side`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const motive_seasonal_activity_length = () => `caractères restants`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const motive_seasonal_activity_placeholder = () => `Ex : Vendanges de septembre, sport d'hiver (moniteur de ski)...`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const motive_seasonal_activity_subtitle = () => `Vous ne pouvez recourir à ce motif que pour certaines activités qui se répètent chaque année à la même période. Plus d’informations`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const motive_seasonal_activity_title = () => `Emploi à caractère saisonnier`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const motive_seasonal_activity_validation = () => `Saisir au minimum 6 caractères`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const motive_temporary_contract_explaination_link = () => `https://support.side.co/b2b/fr/articles/9113082-quels-sont-les-motifs-possibles-de-recours-au-travail-temporaire`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const motive_temporary_contract_explaination_link_label = () => `dans cet article`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const motive_temporary_contract_explaination_tip = () => `Les travaux temporaires par usage sont compris dans la liste de l'article D. 1251-1 du code du travail et l'accord interprofessionnel du 24/03/90. Plus d'informations `


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const motive_temporary_contract_label = () => `Justifiez votre motif de recours à Side`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const motive_temporary_contract_length = () => `caractères restants`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const motive_temporary_contract_placeholder = () => `Ex : Centres de loisirs et de vacances, audiovisuel...`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const motive_temporary_contract_subtitle = () => `Les travaux temporaires d'usages ne sont possibles que dans des cas strictement limités et determinés par l'article D1251-1 du code du travail. Plus d’informations`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const motive_temporary_contract_title = () => `Contrat temporaire d'usage`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const motive_temporary_contract_validation = () => `Saisir au minimum 6 caractères`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const native = () => `Langue maternelle`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const nld = () => `Néerlandais`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const no = () => `Non`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const no_invoice_company = () => `Vous n'avez pas encore reçu de facture pour le moment.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const no_invoice_month = () => `Vous n'avez pas reçu de factures pour ce mois.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const no_result = () => `Aucun résultat.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const nor = () => `Norvégien`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const oci = () => `Occitan`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const onboarding_modal_intro = () => `Vous êtes le premier administrateur de votre compte entreprise. Vous pouvez dès maintenant inviter vos collaborateurs en leur attribuant le rôle adéquat`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const onboarding_modal_steps_1_content = () => `Complétez votre profil, les paramètres de votre compte et mettez en ligne votre première mission.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const onboarding_modal_steps_1_title = () => `Partagez vos besoins`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const onboarding_modal_steps_2_content = () => `Side s’occupe de trouver exactement les profils qu’il vous faut en quelques heures. Vous pourrez ensuite accéder à votre planning en temps réel et signer vos contrats en quelques clics.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const onboarding_modal_steps_2_title = () => `Suivez la sélection et signez vos contrats`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const onboarding_modal_steps_3_content = () => `Retrouvez toutes les heures de vos Siders en un seul et même endroit. Bénéficiez de notre système de validation simplifié et recevez vos factures automatiquement chaque semaine.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const onboarding_modal_steps_3_title = () => `Validez vos heures et suivez vos factures`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const onboarding_modal_title = () => `Bienvenue sur Side !`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const onboarding_modal_understood = () => `C'est compris`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ongoing_section_display_planning = () => `Afficher le planning`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ongoing_section_display_preselection = () => `Afficher la sélection`


/**
 * @param {{ siderNumber: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ongoing_section_row_status_cell_subtitle_contact_plural = (params) => `Voir les ${params.siderNumber} Siders`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ongoing_section_row_status_cell_subtitle_contact_singular = () => `Voir le Sider`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ongoing_section_row_status_cell_title_selection_done = () => `Sélection finalisée !`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ongoing_section_row_task_cell_managed_by = () => `Gérée par `


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ongoing_section_title = () => `Missions en cours`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const optional = () => `facultatif`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_details_label = () => `Infos supplémentaires & mesures sanitaires en place (optionnel)`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_details_placeholder = () => `Ex : masque, gel et gants, les déplacements peuvent être pris en charge etc.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_details_subtitle = () => `Profitez de cet espace pour préciser des infos supplémentaires que les Siders devraient avoir.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_details_title = () => `Informations supplémentaires`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_justifications_add = () => `Ajouter une nouvelle justification`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_justifications_create_action = () => `Créer une justification`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_justifications_delete_confirmation = () => `Etes-vous sûr(e) de vouloir supprimer ?`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_justifications_label = () => `Choix d'une justification`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_justifications_placeholder = () => `Sélectionnez une justification parmi la liste`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_justifications_tip = () => `Le choix de la justification sera reporté dans le contrat de vos siders. Si vous renouvelez un contrat en utilisant la même justification, un avenant de prolongation sera généré automatiquement.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_motives_add = () => `Ajouter une personne à remplacer`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_motives_error_6_chars = () => `Saisir au minimum 6 caractères`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_motives_label = () => `Choix d'un motif`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_motives_label_activity_increase = () => `Accroissement temporaire d'activité`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_motives_label_replacement = () => `Remplacement d'un salarié absent`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_motives_label_seasonal_activity = () => `Emploi à caractère saisonnier`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_motives_label_temporary_contract = () => `Contrat temporaire d'usage`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_motives_label_waiting_recruitment = () => `En attente d'un recrutement`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_motives_placeholder = () => `Sélectionnez un motif parmi la liste`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_motives_show_details = () => `Voir le détail`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_motives_subtitle = () => `Veuillez indiquer ci-dessous la raison pour laquelle vous avez recours à Side`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_motives_title = () => `Motif de recours`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_noresults_button_label = () => `Continuer`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_noresults_subtitle = () => `Vous retrouverez ici les profils avec lesquels vous avez apprécié travailler la prochaine fois.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_noresults_title = () => `Pas encore de Siders ?`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_preliminary_details_cancel_label = () => `Annuler`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_preliminary_details_coefficient_column = () => `Coefficient`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_preliminary_details_delegation_description = () => `Nos recruteurs vous accompagnent dans le recrutement d’intérimaires, avec lesquels vous avez déjà travaillés ou non.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_preliminary_details_delegation_label = () => `Je recherche des intérimaires`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_preliminary_details_gestion_description = () => `Ajoutez sur Side les profils que vous sourcez et automatisez 100% de vos démarches administratives.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_preliminary_details_gestion_label = () => `J'ai déjà mes profils`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_preliminary_details_hourlyrate_column = () => `Coût`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_preliminary_details_hourtype_column = () => `Type d'heure`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_preliminary_details_increaserate_column = () => `Majoration`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_preliminary_details_modal_title = () => `Créer une nouvelle mission`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_preliminary_details_pricing_table_title = () => `Coûts horaires et coefficients indicatifs`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_preliminary_details_subtitle = () => `Choisissez l'option qui correspond à votre besoin`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_preliminary_details_taskname_label = () => `Personnaliser le nom`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_preliminary_details_taskname_optional = () => `facultatif`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_preliminary_details_taskname_placeholder = () => `Ajouter un suffixe au nom de la mission`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_preliminary_details_taskname_tip = () => `La personnalisation du nom de la mission ne sera pas visible par les Siders.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_preliminary_details_tasktype_label = () => `Définir mon besoin`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_preliminary_details_title = () => `Nouvelle demande`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_preliminary_details_type_delegation_label = () => `Side Source (Délégation)`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_preliminary_details_type_gestion_label = () => `Side Flash (Gestion)`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_preliminary_details_type_label = () => `Type de mission`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_preliminary_details_validate_label = () => `Continuer`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_quotation_price = () => `Coût (€/h)`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_quotation_quantity = () => `Qté`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_quotation_total_ht = () => `Total HT`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_quotation_type = () => `Type`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_shifts_subtitle = () => `Détaillez les créneaux et le nombre de Siders que vous prévoyez pour cette mission`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_shifts_title = () => `Créneaux`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_stepper_details = () => `Détails`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_stepper_motive = () => `Motif`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_stepper_planning = () => `Planning`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_stepper_shifts = () => `Créneaux`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_stepper_summary = () => `Récapitulatif`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_stepper_workers = () => `Siders`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_succes_display_planning = () => `Afficher mon planning`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_success_item_1 = () => `Votre mission est en ligne et la sélection démarre automatiquement`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_success_item_2 = () => `Notre équipe sélectionne les Siders le plus pertinents pour cette mission`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_success_item_3 = () => `Vous pouvez suivre l'avancée de la sélection depuis votre planning`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_success_title = () => `Votre mission est postée`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_summary_address_additional_infos = () => `Adresse - infos complémentaires`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_summary_address_title = () => `Adresse`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_summary_base_wage_disclaimer = () => `Le salaire de référence pour cette mission est configuré au niveau de votre fiche de poste`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_summary_confirmation_button = () => `Confirmer`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_summary_confirmation_cancellation_fees = () => `Une fois votre demande validée, si vous décidez de l'annuler ou de la modifier, des frais d'annulation seront automatiquement débités`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_summary_confirmation_checkbox = () => `J’ai compris les conditions d'annulation`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_summary_confirmation_footnote = () => `Vous serez facturé à intervalle régulier en fonction des heures validées de vos intérimaires. Une fois votre besoin validé, vous recevrez une confirmation par email.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_summary_confirmation_sidenote = () => `Commentaire pour l'équipe Side`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_summary_confirmation_sidenote_placeholder = () => `Saisissez votre commentaire`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_summary_confirmation_title = () => `Confirmez votre demande`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_summary_estimate_base_wage = () => `Salaire de référence (heures normales)`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_summary_estimate_details = () => `Afficher le détail`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_summary_estimate_disclaimer = () => `Ce prix inclut les indemnités de fin de mission et de congés payés mais ne prend pas en compte les primes et indemnités spécifiques. Ces éléments peuvent impacter le prix final. `


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_summary_estimate_planning_tip = () => `Cette mission ayant un planning flexible, l'estimation totale est susceptible d'évoluer en fonction des heures effectivement travaillées.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_summary_estimate_title = () => `Estimation du prix`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_summary_estimate_total_with_taxes = () => `Estimation Totale (20% TVA)`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_summary_estimate_total_without_taxes = () => `Estimation Totale HT`


/**
 * @param {{ startDate: NonNullable<unknown>, endDate: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_summary_from_to_date = (params) => `de ${params.startDate} à ${params.endDate}`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_summary_info_title = () => `Informations complémentaires`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_summary_justification_title = () => `Justification`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_summary_manager_title = () => `Manager`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_summary_motive_title = () => `Motif de recours`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_summary_motorized_title = () => `Sider motorisé`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_summary_name_title = () => `Nom de la mission`


/**
 * @param {{ siders: NonNullable<unknown>, weeklyVolume: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_summary_planning_sentence_1 = (params) => `${params.siders} à ${params.weeklyVolume}h par semaine`


/**
 * @param {{ startDate: NonNullable<unknown>, endDate: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_summary_planning_sentence_2 = (params) => `du ${params.startDate} au ${params.endDate}`


/**
 * @param {{ amplitudeStart: NonNullable<unknown>, amplitudeEnd: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_summary_planning_sentence_3 = (params) => `de ${params.amplitudeStart} à ${params.amplitudeEnd} maximum`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_summary_planning_sentence_and = () => `et`


/**
 * @param {{ total: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_summary_planning_sentence_figures = (params) => `Soit ${params.total}h sur`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_summary_planning_sentence_holidays = () => `(incl. jours fériés)`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_summary_planning_sentence_saturday = () => `le samedi`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_summary_planning_sentence_sunday = () => `le dimanche`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_summary_planning_sentence_weekDays = () => `la semaine`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_summary_planning_title = () => `Planning`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_summary_remote_title = () => `Télétravail`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_summary_risks_additionnal_infos = () => `Risques - précisions complémentaires`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_summary_shifts_title = () => `Créneaux`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_summary_shifts_warning_FAQ_link = () => `https://support.side.co/b2b/fr/`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_summary_shifts_warning_holiday = () => `Vous avez ajouté un créneau sur un jour férié dont la rémunération est majorée selon votre politique de rémunération. Vérifiez que ce jour est bien travaillé dans votre entreprise.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_summary_shifts_warning_holiday_interval = () => `Votre période de mission comprend un jour férié. Les jours fériés non travaillés sont rémunérés au tarif habituel s'ils font partie des jours habituels de mission.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_summary_shifts_warning_link_text = () => `Consultez notre FAQ pour plus d'informations.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_summary_title = () => `Récapitulatif de commande`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_summary_total_week = () => `Total Semaine`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_summary_type_delegation_description = () => `Vous travaillez avec des profils recrutés par nos équipes`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_summary_type_delegation_title = () => `Délégation`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_summary_type_gestion_description = () => `Vous avez trouvé des profils par vous-mêmes, nous gérons uniquement l’administratif.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_summary_type_gestion_title = () => `Gestion`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_summary_type_title = () => `Type de mission`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_summary_workers_title = () => `Siders demandés en priorité`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_summary_workers_wa_warning = () => `Seuls les Siders que vous avez sélectionnés pourront postuler et être positionnés sur la mission. Veuillez confirmer avec eux leurs disponibilités. S'ils ne sont pas disponibles, nous annulerons la mission.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_summary_yes = () => `Oui`


/**
 * @param {{ numberOfFilters: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_workers_filters_button_label = (params) => `Filtres (${params.numberOfFilters})`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_workers_filters_modal_button_delete = () => `Réinitialisez les filtres`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_workers_filters_modal_experience_label = () => `Expérience`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_workers_filters_modal_experience_placeholder = () => `Ajoutez un intitulé de poste`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_workers_filters_modal_isrecommended_false = () => `Afficher tous les Siders`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_workers_filters_modal_isrecommended_label = () => `Avis`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_workers_filters_modal_isrecommended_placeholder = () => `Sélectionnez un type d'avis`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_workers_filters_modal_isrecommended_true = () => `Afficher seulement les Siders avec un avis positif`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_workers_filters_modal_last_task_1 = () => `Moins d'un mois`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_workers_filters_modal_last_task_3 = () => `Moins de 3 mois`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_workers_filters_modal_last_task_all = () => `Afficher tous les Siders`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_workers_filters_modal_last_task_label = () => `Dernière mission réalisée`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_workers_filters_modal_last_task_placeholder = () => `Sélectionnez une période`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_workers_filters_modal_subtitle = () => `Appliquez des filtres pour préciser votre recherche `


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_workers_filters_modal_title = () => `Filtrer les Siders`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_workers_filters_noresults_button_label = () => `Modifier les filtres`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_workers_filters_noresults_subtitle = () => `Modifiez les filtres pour afficher plus de Siders`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_workers_filters_noresults_title = () => `Aucun résultat`


/**
 * @param {{ numberOfRemainingSlots: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_workers_gestion_amount = (params) => `Sélectionnez encore ${params.numberOfRemainingSlots} Siders pour pouvoir continuer`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_workers_gestion_noresults_subtitle = () => `Ajoutez vos profils afin de travailler avec eux sur cette mission`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_workers_gestion_noresults_title = () => `Pas encore de Siders ?`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_workers_gestion_profile_add_button_label = () => `Ajouter un profil`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_workers_gestion_profile_add_modal_error = () => `Ce profil a déjà un compte sur Side. Si vous souhaitez le recruter, contactez le support via le chat ou à l’adresse care@sidetemp.com`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_workers_gestion_profile_add_modal_firstname_label = () => `Prénom`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_workers_gestion_profile_add_modal_firstname_placeholder = () => `Elise`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_workers_gestion_profile_add_modal_lastname_label = () => `Nom`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_workers_gestion_profile_add_modal_lastname_placeholder = () => `Dupont`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_workers_gestion_profile_add_modal_phone_label = () => `Numéro de téléphone`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_workers_gestion_profile_add_modal_phone_placeholder = () => `(0)6 07 08 09 10`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_workers_gestion_profile_add_modal_subtitle = () => `Complétez les informations du profil avec lequel vous souhaitez travailler, nous nous chargeons du reste.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_workers_gestion_profile_add_modal_title = () => `Ajouter un profil`


/**
 * @param {{ numberOfSharedWorkers: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_workers_list_network_label = (params) => `Mon réseau (${params.numberOfSharedWorkers})`


/**
 * @param {{ numberOfOrganisationWorkers: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_workers_list_orga_label = (params) => `Mes Siders (${params.numberOfOrganisationWorkers})`


/**
 * @param {{ numberOfSelectedWorkers: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_workers_list_selected_label = (params) => `Sélection (${params.numberOfSelectedWorkers})`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_workers_network_noresults_button_label = () => `Voir les Siders de mon réseau`


/**
 * @param {{ numberOfWorkers: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_workers_network_noresults_counter = (params) => `${params.numberOfWorkers} Siders disponibles`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_workers_network_noresults_subtitle = () => `Faites appel aux Siders déjà formés de votre réseau !`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_workers_network_noresults_title = () => `Pas encore de Siders ?`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_workers_search_noresults_subtitle = () => `Modifiez votre recherche pour afficher plus de Siders`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_workers_search_noresults_title = () => `Aucun résultat`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_workers_search_placeholder = () => `Nom du Sider`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_workers_subtitle = () => `Travaillez à nouveau avec vos Siders`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_workers_title = () => `Siders`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_workers_wa_only_modal_subtitle = () => `Vous avez choisi l’option d'ouvrir la mission exclusivement aux Siders sélectionnés, seuls ces siders pourront postuler et effectuer la mission.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_workers_wa_only_modal_title = () => `Ouvrir la mission exclusivement aux Siders sélectionnés`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_workers_wa_only_modal_warning = () => `Attention, cette option nécessite que les Siders sélectionnés soient bien disponibles pour la mission. Ne confirmez que si vous en avez parlé avec eux. S'ils ne sont pas disponibles, aucun Sider ne sera sélectionné.`


/**
 * @param {{ numberOfRemainingSlots: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_workers_wa_only_toggle_amount = (params) => `Sélectionnez encore ${params.numberOfRemainingSlots} Siders pour pouvoir utiliser cette option`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_workers_wa_only_toggle_label = () => `Ouvrir la mission exclusivement aux Siders sélectionnés`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_workers_wa_only_toggle_tooltip_content_activated = () => `Seuls les Siders auxquels vous avez refait appel pourront postuler et être sélectionnés sur la mission. Side ne complètera pas la sélection avec d’autres Siders si ces derniers ne sont pas disponibles.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_workers_wa_only_toggle_tooltip_content_deactivated = () => `En activant cette option, seulement les siders que vous avez cochés dans la liste pourront postuler à la mission. Ne cochez cette case que si vous avez validé avec ces siders qu'ils sont bien disponibles.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_workers_wa_only_toggle_tooltip_title_activated = () => `Option activée`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_workers_wa_only_toggle_tooltip_title_deactivated = () => `Option désactivée`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const per = () => `Persan`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const personal_informations = () => `Informations personnelles`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const personal_informations_email = () => `Email`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const personal_informations_email_placeholder = () => `votremail@nomdedomaine.com`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const personal_informations_error_incorrect_password = () => `Mot de passe incorrect`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const personal_informations_error_invalid_password = () => `Mot de passe invalide`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const personal_informations_error_invalid_password_format = () => `Le mot de passe doit contenir au moins un caractère spécial`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const personal_informations_error_invalid_password_length = () => `Le mot de passe doit avoir au moins 9 charactères`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const personal_informations_error_invalid_phone = () => `Le format de votre numéro de téléphone n'est pas valide`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const personal_informations_firstname = () => `Prénom`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const personal_informations_firstname_placeholder = () => `Votre prénom`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const personal_informations_general_information_description = () => `Ces informations sont vos informations de contact et nous permettent d’échanger avec vous.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const personal_informations_general_information_title = () => `Informations générales`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const personal_informations_lastname = () => `Nom de famille`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const personal_informations_lastname_placeholder = () => `Votre nom de famille`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const personal_informations_new_password = () => `Nouveau mot de passe`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const personal_informations_new_password_confirmation = () => `Confirmation`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const personal_informations_new_password_confirmation_placeholder = () => `**********`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const personal_informations_new_password_placeholder = () => `**********`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const personal_informations_password_button = () => `Modifier le mot de passe`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const personal_informations_password_modal_confirm_field = () => `Confirmer le mot de passe`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const personal_informations_password_modal_new_field = () => `Nouveau mot de passe`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const personal_informations_password_modal_old_field = () => `Ancien mot de passe`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const personal_informations_password_modal_save_button = () => `Enregistrer la modification`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const personal_informations_password_modal_title = () => `Modifier le mot de passe`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const personal_informations_password_success = () => `Le mot de passe a été mis à jour`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const personal_informations_password_tip = () => `Le nouveau mot de passe doit comprendre au moins 9 caractères dont un caractère spécial`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const personal_informations_password_title = () => `Mot de passe`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const personal_informations_phone = () => `Numéro de téléphone`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const personal_informations_phone_placeholder = () => `06 XX XX XX XX`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const personal_informations_profile_saved = () => `Informations sauvegardées`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const planning_amplitude_label = () => `Amplitude horaire maximum`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const planning_amplitude_max_placeholder = () => `Horaire maximum`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const planning_amplitude_min_placeholder = () => `Horaire minimum`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const planning_amplitude_tip = () => `Il est important que l’amplitude horaire d’un salarié à temps partiel reste raisonnable, au risque d’une requalification à temps complet. `


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const planning_dates_info = () => `La mission durera`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const planning_dates_label = () => `Période de la mission`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const planning_dates_length_error = () => `La mission doit avoir une durée comprise entre 1 semaine et 18 mois.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const planning_dates_reversed_error = () => `La date de début ne peut pas être postérieure à la date de fin.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const planning_dates_tip = () => `Il est important de toujours transmettre les créneaux précis aux salariés <i>a minima</i> 7 jours avant leur début, afin de respecter le délai légal de prévenance.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const planning_day_type_error_empty = () => `Au moins un type de jours doit être sélectionné.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const planning_day_type_error_more = () => `Au moins un autre type de jours doit être sélectionné.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const planning_day_type_holidays = () => `Jours fériés`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const planning_day_type_label = () => `Type(s) de jours inclus dans la période`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const planning_day_type_saturday = () => `Samedi`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const planning_day_type_sunday = () => `Dimanche`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const planning_day_type_week = () => `Semaine`


/**
 * @param {{ downloadFormat: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const planning_download_as = (params) => `Télécharger en ${params.downloadFormat}`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const planning_hourly_volume_amplitude_error = () => `L'amplitude horaire est incompatible avec le volume horaire et/ou le type de jours sélectionnés.`


/**
 * @param {{ hours: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const planning_hourly_volume_error = (params) => `Le volume horaire ne peut pas dépasser ${params.hours}h au vu des jours inclus. `


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const planning_hourly_volume_label = () => `Volume horaire par Sider par semaine`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const planning_legal_tip_1 = () => `Il est important de toujours respecter les durées légales de travail et de repos, dès lors que vos créneaux se préciseront.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const planning_legal_tip_2 = () => `Consultez notre FAQ pour plus d'informations.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const planning_select_current_week = () => `Aujourd'hui`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const planning_sheet_empty_user = () => `Sélection en cours`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const planning_sheet_view_task = () => `Consulter la mission`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const planning_short_week = () => `S `


/**
 * @param {{ siderName: NonNullable<unknown>, workedHoursInWeek: NonNullable<unknown>, plural: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const planning_sider_worked_hours_week = (params) => `${params.siderName} travaille ${params.workedHoursInWeek} heure${params.plural} cette semaine`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const planning_siders_error = () => `Le nombre de Siders doit être compris entre 1 et 50.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const planning_siders_label = () => `Nombre de Siders`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const planning_type_accurate_desc = () => `Facilitez le recrutement en créant un planning précis à partir de ces créneaux.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const planning_type_accurate_subtitle = () => `Vous connaissez tous vos créneaux`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const planning_type_accurate_title = () => `Planning Précis`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const planning_type_desc = () => `Intégrez à votre mission un planning précis ou flexible, selon votre besoin.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const planning_type_flexible_button_create = () => `Créer`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const planning_type_flexible_desc = () => `Restez agile en créant un planning flexible à partir d’un volume horaire.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const planning_type_flexible_subtitle = () => `Vous ne connaissez pas tous vos créneaux`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const planning_type_flexible_title = () => `Planning Flexible`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const planning_type_title = () => `Créer votre planning`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const planning_volume_day_type_error = () => `Le volume horaire relatif aux types de jours est incompatible.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const planning_volume_error = () => `Le volume horaire doit être compris entre 7h et 48h.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const pol = () => `Polonais`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const por = () => `Portugais`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const preselect_actions_tooltip_approve = () => `Approuver ce candidat`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const preselect_actions_tooltip_download = () => `Télécharger le CV`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const preselect_actions_tooltip_refuse = () => `Refuser ce candidat`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const preselect_actions_tooltip_upload = () => `Téléverser le CV`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const preselect_availabilities_from = () => `À partir du`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const preselect_availabilities_until = () => `Jusqu'au`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const preselect_cv_uploaded = () => `CV téléversé avec succès`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const preselect_no_result_client = () => `Nous préparons votre mission et reviendrons très prochainement vers vous avec des candidats sélectionnés par nos soins`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const preselect_no_result_logas = () => `Sélection en cours`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const preselect_refusal_modal_button = () => `Refuser ce candidat`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const preselect_refusal_modal_description = () => `Expliquez-nous pourquoi ce candidat ne correspond pas afin que nous puissions affiner notre recherche. (ex : ce candidat m’a déjà été présenté)`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const preselect_refusal_modal_placeholder = () => `Expliquez-nous...`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const preselect_refusal_modal_title = () => `Ce candidat ne vous convient pas ?`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const preselect_textarea_hint = () => `500 charactères maximum`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const preselect_textarea_placeholder = () => `Ecrivez une note...`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const preselect_tip_no_visible = () => `Attention, aucun candidat n’est visible par votre client ! Pour donner de la visibilité à votre client, vous devez cliquer sur l’icone “oeil” qui se trouve à gauche du tableau.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const preselection_h1 = () => `Candidats préselectionnés`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const preselection_status_approved = () => `Approuvé`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const preselection_status_refused = () => `Refusé`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const preselection_status_sent = () => `À valider`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const preselection_table_header_applicant = () => `Candidat`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const preselection_table_header_availabilities = () => `Disponibilité`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const preselection_table_header_notes = () => `Notes`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const preselection_table_header_status = () => `Statut`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const preselection_table_header_visibility = () => `Visibilité`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const que = () => `Quechua`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const rename = () => `Renommer`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const rename_task = () => `Renommer la mission`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const rum = () => `Roumain`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const rus = () => `Russe`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const save = () => `Enregistrer`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const save_quit = () => `Enregistrer et quitter`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const search = () => `Rechercher`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const send = () => `Envoyer`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_actions_content = () => `Vous avez modifié certaines informations, voulez-vous enregistrer ces changements ?`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_actions_discard = () => `Annuler`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_actions_save = () => `Enregistrer`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_legal_ape = () => `Code APE`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_legal_ape_placeholder = () => `0000A`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_legal_ape_tip = () => `le code APE est composé de quatre chiffres et d’une lettre, et correspond à une classification, la Nomenclature des Activités Françaises (NAF), raison pour laquelle on parle également de code NAF. Il se trouve aussi dans la rubrique \\"activité\\" sur le site https://www.societe.com`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_legal_business_name = () => `Raison sociale`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_legal_business_name_detail = () => `(si différente)`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_legal_business_name_placeholder = () => `Raison sociale`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_legal_business_name_tip = () => `C'est le nom de l'entité qui figurera sur les contrats et sera facturée`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_legal_error_ape_format = () => `Le code APE doit comporter 4 chiffres suivis d'une lettre`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_legal_error_siret_format = () => `Le format de ce champ n'est pas valide`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_legal_name = () => `Nom de l'entreprise`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_legal_name_placeholder = () => `Nom de l'entreprise`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_legal_siret = () => `SIRET`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_legal_siret_placeholder = () => `000 000 000 00000`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_legal_siret_tip = () => `Le numéro de SIRET est un identifiant de 14 chiffres de votre entreprise, vous pouvez le trouver facilement sur le site https://www.societe.com`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_legal_subtitle = () => `Ces informations figureront sur les contrats de mise à disposition`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_legal_title = () => `Informations légales`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_nav_account = () => `Mon profil`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_nav_company_info = () => `Profil de l'entreprise`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_nav_legal_info = () => `Informations légales`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_nav_payment_info = () => `Paiement`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_nav_payment_info_accounting = () => `Contact comptabilité`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_nav_payment_info_billing = () => `Adresse de facturation`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_nav_payment_info_method = () => `Mode de paiement`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_nav_payment_info_remuneration = () => `Politique de rémunération`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_nav_payment_info_remuneration_compensations = () => `Primes et indemnités`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_nav_payment_info_remuneration_hours = () => `Types d'heures`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_nav_team = () => `Équipe`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_payment_accounting_email = () => `Email`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_payment_accounting_email_placeholder = () => `nom@entreprise.com`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_payment_accounting_firstname = () => `Prénom`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_payment_accounting_firstname_placeholder = () => `Prénom`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_payment_accounting_name = () => `Nom`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_payment_accounting_name_placeholder = () => `Nom`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_payment_accounting_phone_number = () => `Numéro de téléphone`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_payment_accounting_phone_number_placeholder = () => `06 00 00 00 00`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_payment_accounting_subtitle = () => `Renseignez les informations de la personne qui recevra les factures.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_payment_accounting_title = () => `Contact comptabilité`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_payment_api_error = () => `Une erreur s'est produite, veuillez réessayer ultérieurement ou contacter le service client.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_payment_billing_address = () => `Adresse`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_payment_billing_address_placeholder = () => `Entrez votre adresse`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_payment_billing_city = () => `Ville`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_payment_billing_city_placeholder = () => `Ville`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_payment_billing_country = () => `Pays`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_payment_billing_country_placeholder = () => `Selectionnez un pays`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_payment_billing_postal_code = () => `Code Postal`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_payment_billing_postal_code_placeholder = () => `00000`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_payment_billing_subtitle = () => `Ces informations nous permettront de créer automatiquement vos factures aux normes comptables en fin de prestation.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_payment_billing_title = () => `Adresse de facturation`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_payment_billing_tva_intro = () => `Vous utilisez une adresse de facturation située dans un pays étranger, veuillez indiquer votre numéro de TVA intracommunautaire.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_payment_billing_tva_label = () => `Numéro de TVA intra-communautaire`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_payment_billing_tva_placeholder = () => `XX 0000000000000`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_payment_billing_tva_tip = () => `This numéro permettra la bonne déclaration de TVA`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_payment_error_card_check = () => `Il y a un problème avec cette carte bancaire.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_payment_error_card_format = () => `Ce numéro de Carte Bleue n'est pas valide.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_payment_error_city_format = () => `Le format de ce champ n'est pas valide`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_payment_error_country_format = () => `Le format de ce champ n'est pas valide`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_payment_error_digits_only_format = () => `Ce champ ne peut contenir que des chiffres.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_payment_error_email_format = () => `Le format de votre email n'est pas valide`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_payment_error_exp_date_format = () => `Veuillez entrer une date valide.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_payment_error_iban_format = () => `Cet IBAN n'est pas valide.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_payment_error_phone_number_format = () => `Le format de votre numéro de téléphone n'est pas valide`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_payment_error_postal_code_format = () => `Le code postal doit être composé de 5 chiffres`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_payment_error_tos = () => `Veuillez accepter les conditions d'utilisation.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_payment_error_tva_number_format = () => `Le format de votre numéro de TVA n'est pas valide, il doit commencer par 2 lettres`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_payment_method_card_cvv = () => `CVV`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_payment_method_card_cvv_placeholder = () => `123`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_payment_method_card_desc = () => `le montant de vos factures sera prelevé sur le compte bancaire associé à cette carte.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_payment_method_card_exp_date = () => ` Date d’expiration`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_payment_method_card_exp_date_placeholder = () => `MM/AA`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_payment_method_card_label = () => `Prélèvement carte bancaire`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_payment_method_card_modify = () => `Modifier la carte`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_payment_method_card_number = () => `Numéro carte`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_payment_method_card_number_placeholder = () => `0000 - 0000 - 0000 - 0000`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_payment_method_card_saved = () => `Carte enregistrée`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_payment_method_cs_desc = () => `Vous êtes responsable du paiement de vos factures à échéance`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_payment_method_cs_label = () => `Virement manuel`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_payment_method_label = () => `Je souhaiterais payer par…`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_payment_method_order_form = () => `Je souhaiterais des préfactures`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_payment_method_sepa = () => `IBAN`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_payment_method_sepa_confirmation = () => `En cliquant ici, vous acceptez les conditions d’utilisation du prélèvement <a href=\\"https://s3.eu-central-1.amazonaws.com/weslash-static/pdfs/sepa-mandate-fr.pdf\\" target=\\"_blank\\"> SEPA Direct Debit Stripe</a>.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_payment_method_sepa_country = () => `Pays`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_payment_method_sepa_country_placeholder = () => `Selectionnez un pays`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_payment_method_sepa_currency = () => `Devise`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_payment_method_sepa_currency_placeholder = () => `Selectionnez une devise`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_payment_method_sepa_desc = () => `Le montant de vos factures sera prelevé sur votre compte bancaire.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_payment_method_sepa_direct_debit_mandate = () => `En fournissant vos informations de paiement, vous autorisez (A) Side et Stripe, notre prestataire de services de paiement et/ou PPRO, son prestataire de services local, à envoyer des instructions à votre banque pour débiter votre compte et (B) votre banque à débiter votre compte conformément à ces instructions. Vous avez, entre autres, le droit de vous faire rembourser par votre banque selon les modalités et conditions du contrat conclu avec votre banque. La demande de remboursement doit être soumise dans un délai de 8 semaines à compter de la date à laquelle votre compte a été débité. Vos droits sont expliqués dans une déclaration disponible auprès de votre banque. Vous acceptez de recevoir des notifications des débits à venir dans les 2 jours précédant leur réalisation.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_payment_method_sepa_euros = () => `Euros`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_payment_method_sepa_france = () => `France`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_payment_method_sepa_gb = () => `Grande-Bretagne`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_payment_method_sepa_label = () => `Prélèvement SEPA`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_payment_method_sepa_modify = () => `Modifier l'IBAN`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_payment_method_sepa_placeholder = () => `XX00 0000 0000 0000 0000 0000 000`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_payment_method_sepa_pounds = () => `Livres`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_payment_method_sepa_saved = () => `IBAN enregistré`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_payment_method_stripe = () => `Le paiement et le stockage sont entièrement gérés par Stripe, nous ne stockons aucune donnée.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_payment_method_subtitle = () => `Ces informations sont nécessaires pour lancer la sélection des Siders. Pas de panique, vous ne serez prélevé qu’en fin de prestation.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_payment_method_title = () => `Mode de paiement`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_payment_title = () => `Informations de paiement`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_personal_email = () => `Email`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_personal_email_placeholder = () => `nom@entreprise.com`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_personal_firstname = () => `Prénom`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_personal_firstname_placeholder = () => `Prénom`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_personal_lastname = () => `Nom`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_personal_lastname_placeholder = () => `Nom`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_personal_password_confirm = () => `Confirmer`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_personal_password_new = () => `Nouveau mot de passe`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_personal_password_placeholder = () => `•••••••••••`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_personal_phonenumber = () => `Numéro de téléphone`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_personal_phonenumber_placeholder = () => `06 00 00 00 00`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_personal_subtitle = () => `Ces informations vous permettent de vous connecter à la plateforme`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_personal_title = () => `Mon compte`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_profile_title = () => `Profil de l'entreprise`


/**
 * @param {{ months: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_hours_after_months_seniority = (params) => `<b>Après ${params.months} mois</b> d’ancienneté.`


/**
 * @param {{ months: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_hours_before_months_seniority = (params) => `<b>Avant ${params.months} mois</b> d’ancienneté.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_hours_edit_tooltip = () => `Modifier les paramètres de ce type d’heure`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_hours_extra_content = () => `Majoration appliquée lorsqu'un Sider travaille :`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_hours_extra_title = () => `Heures supplémentaires`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_hours_help = () => `Vous ne retrouvez pas tous vos types d’heures dans la liste ci-dessus ? Contactez-nous sur billing@sidetemp.com.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_hours_holiday_content = () => `Majoration appliquée lorsqu'un Sider travaille lors d’un jour férié.`


/**
 * @param {{ solidarity: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_hours_holiday_solidarity = (params) => `<b>Journée de solidarité : </b> ${params.solidarity}`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_hours_holiday_solidarity_undefined = () => `non précisée`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_hours_holiday_title = () => `Heures jours fériés travaillés`


/**
 * @param {{ count: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_hours_hour_threshold = (params) => `<b>Plus de ${params.count}h</b> sur une semaine.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_hours_in_zti = () => `Lorsque la mission se passe dans une <b>ZTI</b>.`


/**
 * @param {{ startTime: NonNullable<unknown>, endTime: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_hours_night_content = (params) => `Majoration appliquée lorsqu'un Sider travaille <b>entre ${params.startTime} et ${params.endTime}</b>.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_hours_night_title = () => `Heures de nuit`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_hours_normal_content = () => `Type d'heure par défaut quand aucun autre type d'heure ne s'applique. Le Sider sera payé sur la base du salaire de référence sans majoration.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_hours_normal_title = () => `Heures normales`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_hours_not_zti = () => `Lorsque la mission se passe hors <b>ZTI</b>.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_hours_see_holidays = () => `Voir la liste des jours fériés`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_hours_subtitle = () => `Définissez les règles de rémunération des Siders. Si les règles par défaut ne correspondent pas à celles de votre entreprise, vous pouvez les modifier.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_hours_sunday_content = () => `Majoration appliquée lorsqu'un Sider travaille le dimanche.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_hours_sunday_title = () => `Heures dimanches`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_hours_table_type = () => `Type d’heure et conditions d’application`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_hours_table_value = () => `Majoration`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_hours_title = () => `Types d’heures`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_hours_types_custom_disclaimer = () => `Votre configuration spécifique a nécessité un paramétrage manuel de la part de notre équipe (c'est pourquoi vous ne voyez rien s'afficher ici), mais elle a bien été prise en compte. Merci de vous rapprocher de votre contact chez Side en cas de question.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_hours_validation_button = () => `Confirmer`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_hours_validation_content = () => `Avant de pouvoir poster une mission, vérifier les informations les types d'heures et majorations associées sont bien celles qui s'appliquent dans votre entreprise.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_hours_validation_label = () => `Les informations ci-dessous sont correctes`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_hours_validation_title = () => `Valider la configuration de vos types d’heures`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_add_label = () => `Quel type d’indemnité souhaitez-vous ajouter ?`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_add_option_lunch = () => `Indemnité repas`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_add_option_other = () => `Autre`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_add_option_transport = () => `Indemnité transport`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_add_placeholder = () => `Sélectionnez un type d'indémnité`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_add_title = () => `Ajouter une indemnité`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_bank_holidays_hours_rate_error_max = () => `La majoration ne peut excéder +200%`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_bank_holidays_hours_rate_error_min = () => `La majoration doit être supérieure à 0%`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_bank_holidays_rate = () => `Les majorations que vous définissez ici s'appliquent à toutes les heures travaillées sur des jours fériés`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_bank_holidays_solidarity = () => `Sélectionnez votre journée de solidarité (jour férié payé au même tarif qu'un jour normal)`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_bank_holidays_solidarity_placeholder = () => `Journée de solidarité`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_bank_holidays_subtitle = () => `Ce type d’heure s’appliquera par défaut en fonction de ses règles d’utilisation lorqu’aucun autre, mieux rémunéré, ne peut être appliqué. `


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_bank_holidays_title = () => `Heures jours fériés travaillés`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_custom_disclaimer = () => `Votre configuration spécifique a nécessité un paramétrage manuel de la part de notre équipe (c'est pourquoi vous ne voyez rien s'afficher ici), mais elle a bien été prise en compte. Merci de vous rapprocher de votre contact chez Side en cas de question.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_edit_tooltip = () => `Modifier les paramètres de cette indemnité`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_empty = () => `Vous n'avez pas encore défini de prime ou indemnité`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_extra_hour_increase_error_max = () => `La majoration ne peut exceder +200%`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_extra_hour_increase_error_min = () => `La majoration doit être supérieure à 0%`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_extra_hour_min = () => `Veuillez renseigner au moins 1 seuil`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_extra_hour_threshold_format = () => `Merci de saisir une valeur comprise entre 35 et 48h`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_extra_hour_threshold_must_be_higher = () => `Ce seuil doit être supérieur au précédent`


/**
 * @param {{ number: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_extra_hours_extra_threshold = (params) => `Seuil n°${params.number} à partir duquel se déclenchent les heures supplémentaires`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_extra_hours_rate = () => `Majoration (par rapport aux heures normales)`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_extra_hours_subtitle = () => `Les majorations que vous définissez ici s'appliquent à toutes les heures travaillées au delà de la durée hebdomadaire, et donc considérées comme heures supplémentaires`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_extra_hours_threshold = () => `Seuil à partir duquel se déclenchent les heures supplémentaires ?`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_extra_hours_threshold_unit = () => `heures travaillées`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_extra_hours_title = () => `Heures supplémentaires`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_help = () => `Vous ne parvenez pas à configurer l’une de vos indemnités à l’aide des options qui vous sont proposées ? Contactez-nous sur billing@sidetemp.com`


/**
 * @param {{ amount: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_lunch_equivalent_amount = (params) => `soit ${params.amount}€`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_lunch_indeminity_option = () => `Verser une indemnité repas`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_lunch_indemnity_label = () => `Montant de l’indemnité (par jour)`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_lunch_indemnity_type = () => `Versement d’une indemnité repas`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_lunch_max_error = () => `Le montant doit être inférieur à 30 euros`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_lunch_min_error = () => `Le montant doit être supérieur à 0 euros`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_lunch_subtitle = () => `Voici les règles qui s'appliqueront pour les indemnités repas.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_lunch_ticket_type = () => `Distribution de ticket restaurant (par l’entreprise)`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_lunch_title = () => `Repas`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_lunch_tr_option = () => `Donner des tickets restaurants`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_lunch_tr_rate = () => `Part patronale (montant pris en charge par vous)`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_lunch_tr_rate_format_error = () => `La part patronale doit être un nombre compris entre 0 et 100`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_lunch_tr_tip = () => `Les Tickets restaurants doivent être remis par l’entreprise utilisatrice. Side s'occupera de déduire la part salariale de la paie du salarié et de votre facture. Si vous ne pouvez pas les fournir, choississez \\"indemnités repas\\" ci-dessus.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_lunch_tr_value_label = () => `Valeur du titre repas (par jour)`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_lunch_type_error = () => `Veuillez sélectionner un type d'indemnité repas`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_modal_cancel = () => `Annuler`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_modal_delete = () => `Supprimer cette indemnité`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_modal_save = () => `Enregistrer`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_night_hour_end = () => `Fin des heures de nuit`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_night_hour_end_before_start_error = () => `Les heures de nuit ne peuvent se terminer avant qu'elles ne commencent`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_night_hour_end_error = () => `Les heures de nuit ne peuvent se terminer après 9:00`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_night_hour_increase_error = () => `La majoration ne peut excéder +200%`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_night_hour_rate = () => `Majoration (par rapport aux heures normales)`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_night_hour_start = () => `Début des heures de nuit`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_night_hour_start_error = () => `Les heures de nuit ne peuvent commencer avant 18:00`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_night_hour_subtitle = () => `Les majorations que vous définissez ici s'appliquent à toutes les heures travaillées durant la plage d'horaires que vous définissez être la plage de nuit.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_night_hour_title = () => `Heures de nuit`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_option_button = () => `Ajouter`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_other_amount = () => `Montant de l’indemnité`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_other_category = () => `Indemnité liée`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_other_category_select_placeholder = () => `Sélectionnez une valeur`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_other_events_christmas_bonus_indemnity_title = () => `Prime de noël`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_other_events_indemnity_select_label = () => `A un événement`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_other_events_indemnity_title = () => `Événement`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_other_events_other_bonus_indemnity_title = () => `Autre`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_other_events_year_end_bonus_indemnity_title = () => `Prime de fin d'année`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_other_frequency = () => `Fréquence d’application`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_other_frequency_daily = () => `Chaque jour`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_other_frequency_monthly = () => `Chaque mois`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_other_frequency_once = () => `Une seule fois`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_other_frequency_select_placeholder = () => `Sélectionnez une valeur`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_other_frequency_weekly = () => `Chaque semaine`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_other_labor_agreement_holiday_bonus_indemnity_title = () => `Prime de vacances`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_other_labor_agreement_indemnity_select_label = () => `À la convention collective`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_other_labor_agreement_indemnity_title = () => `Convention collective`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_other_labor_agreement_other_bonus_indemnity_title = () => `Autre`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_other_labor_agreement_thirteenth_month_bonus_indemnity_title = () => `Prime 13ème mois`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_other_other_indemnity_select_label = () => `À autre chose`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_other_other_indemnity_title = () => `Autre`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_other_other_other_bonus_indemnity_title = () => `Prime exceptionnelle`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_other_other_parameters_hours_per_day = () => `L’indemnité dépend du nombre d’heures travaillées par jour`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_other_other_parameters_hours_per_day_label = () => `Nombre d’heures à effectuer pour bénéficier de l’indemnité`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_other_other_parameters_hours_per_day_unit = () => `heures travaillées`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_other_other_parameters_jobtype = () => `L’indemnité dépend du type de mission`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_other_other_parameters_jobtype_label = () => `Sélectionnez les missions concernées`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_other_other_parameters_seniority = () => `L’indemnité dépend de l’ancienneté`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_other_other_parameters_seniority_threshold = () => `Seuil d’application de l’indemnité`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_other_other_parameters_seniority_threshold_unit = () => `jours d'ancienneté (travaillés)`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_other_other_parameters_switch = () => `L’indemnité dépend d'autres paramètres`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_other_other_parameters_zti = () => `L’indemnité s'applique en Zone Touristique Internationale (ZTI)`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_other_parameters_hours_per_day_error = () => `Ce nombre doit être compris entre 1 et 12`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_other_parameters_job_types_error = () => `Vous devez sélectionner au moins un type de mission`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_other_parameters_seniority_error_more_than_0 = () => `Ce nombre doit être supérieur à 0`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_other_performances_diligence_bonus_indemnity_title = () => `Prime d'assiduité`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_other_performances_indemnity_select_label = () => `Aux performances`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_other_performances_indemnity_title = () => `Performances`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_other_performances_other_bonus_indemnity_title = () => `Autre`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_other_performances_productivity_bonus_indemnity_title = () => `Prime de rendement`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_other_performances_target_based_bonus_indemnity_title = () => `Prime sur objectifs`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_other_subtitle = () => `Configurez les paramètres d’application de votre indémnité.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_other_title = () => `Ajouter une indémnité`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_other_type = () => `Nom de la prime`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_other_type_error_already_exists = () => `Vous ne pouvez ajouter cette indemnité car vous en avez déjà défini une du même type. Vous pouvez modifier celle existante si besoin.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_other_type_select_placeholder = () => `Sélectionnez une valeur`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_other_work_condition_cold_bonus_indemnity_title = () => `Prime de froid`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_other_work_condition_hardship_bonus_indemnity_title = () => `Prime de pénibilité`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_other_work_condition_indemnity_select_label = () => `Au type de travail`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_other_work_condition_indemnity_title = () => `Type de travail`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_other_work_condition_other_bonus_indemnity_title = () => `Autre`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_other_work_condition_risk_bonus_indemnity_title = () => `Prime de risque/danger`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_other_work_condition_unhealthy_bonus_indemnity_title = () => `Prime d'insalubrité ou de salissure`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_per_day = () => `/jour`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_per_month = () => `/mois`


/**
 * @param {{ duration: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_seniority_condition_after = (params) => `Après ${params.duration}.`


/**
 * @param {{ duration: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_seniority_condition_before = (params) => `Avant ${params.duration}.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_seniority_rate = () => `Majoration (par rapport aux heures normales)`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_seniority_switch = () => `La majoration dépend de l’ancienneté`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_seniority_threshold = () => `Seuil d’application de la majoration`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_seniority_unit = () => `jours d'ancienneté (jours travaillés)`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_subtitle = () => `Définissez les indémnités que peuvent percevoir les Siders et leurs règles d’application (indemnités de repas ou transports, primes)`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_sunday_hours_rate = () => `Majoration (par rapport aux heures normales)`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_sunday_hours_rate_error_max = () => `La majoration ne peut excéder +200%`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_sunday_hours_rate_error_min = () => `La majoration doit être supérieure à 0%`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_sunday_hours_subtitle = () => `Les majorations que vous définissez ici s'appliquent à toutes les heures travaillées les dimanches`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_sunday_hours_title = () => `Heures dimanche`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_sunday_hours_zti = () => `Une majoration spéciale s'applique en Zone Touristique Internationale`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_table_type = () => `Type d’indemnité et conditions d’application`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_table_value = () => `Valeur`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_threshold_addition = () => `Ajouter un seuil`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_threshold_deletion_tooltip = () => `Supprimer ce seuil`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_threshold_number = () => `Seuil n°`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_title = () => `Primes et indemnités`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_transport_personal_switch = () => `Indemniser les trajets domicile - travail en transport personnel`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_transport_personal_tip = () => `S’applique si la résidence habituelle du salarié ou votre lieu de travail sont situés en dehors de la région Île-de-France et d'une zone desservie par les transports urbains, ou que l'utilisation d'un véhicule personne est indispensable en raison des horaires de travail.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_transport_personal_type = () => `Prise en charge transport personnel`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_transport_public_rate = () => `Taux de prise en charge de l'abonnement aux transports publics ou vélos en libre service`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_transport_public_rate_error_less_than_hundred = () => `Le taux ne peut pas être supérieur à 100%`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_transport_public_rate_error_more_than_fifty = () => `Le taux doit être supérieur ou égal à 50%`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_transport_public_type = () => `Prise en charge transport public`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_transport_subtitle = () => `Paramétrez ci-dessous votre participation aux frais de transport domicile - travail.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_transport_title = () => `Transport`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_validation_button = () => `Confirmer`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_validation_content = () => `Avant de pouvoir poster une mission, vérifier les informations relatives aux indemnités.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_validation_label = () => `Les informations ci-dessous sont correctes`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_validation_title = () => `Valider la configuration de vos primes et indemnités`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_zti_condition = () => ` si le lieu de la mission se trouve en ZTI`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_non_worked_holidays_help = () => `Attention : les jours fériés non travaillés sont dûs aux intérimaires sur une base de 7h maximum payées au taux des heures normales.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_non_worked_holidays_help_link = () => `Plus d'informations ici.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_non_worked_holidays_title = () => `Jours fériés non travaillés`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_title = () => `Politique de rémunération`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_submit_success = () => `Vos changements ont été enregistrés avec succès !`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_team_add_user = () => `Inviter un utilisateur`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_team_add_user_email = () => `Email`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_team_add_user_email_placeholder = () => `Email`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_team_add_user_firstname = () => `Prénom`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_team_add_user_firstname_placeholder = () => `Prénom`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_team_add_user_lastname = () => `Nom`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_team_add_user_lastname_placeholder = () => `Nom`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_team_add_user_role = () => `Rôle`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_team_add_user_role_placeholder = () => `Rôle`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_team_add_user_success = () => `Utilisateur invité !`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_team_deactivate_user = () => `Retirer l'utilisateur de votre équipe`


/**
 * @param {{ name: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_team_deactivate_user_dialog_content = (params) => `Voulez-vous vraiment retirer ${params.name} de votre équipe ? Il ne pourra plus gérer vos missions en cours ou valider les heures de vos intérimaires.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_team_deactivate_user_success = () => `Utilisateur retiré de votre équipe`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_team_edit_role = () => `Modifier le rôle de l'utilisateur`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_team_edit_user = () => `Modifier le rôle de l'utilisateur`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_team_edit_user_role = () => `Rôle`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_team_edit_user_role_placeholder = () => `Rôle`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_team_edit_user_success = () => `Utilisateur modifié !`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_team_profile_description = () => `Description`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_team_profile_description_placeholder = () => `Présentez votre entreprise en quelques mots...`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_team_profile_media = () => `Media`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_team_profile_media_picture = () => `Utiliser une image`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_team_profile_media_upload = () => `Ajouter un fichier`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_team_profile_media_valid_formats = () => `Format de fichier acceptés: .jpg, .png`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_team_profile_media_video = () => `Utiliser une vidéo youtube`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_team_profile_name = () => `Nom de l'entreprise`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_team_profile_name_placeholder = () => `Mon entreprise`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_team_profile_saved = () => `Vos changements ont été enregistrés`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_team_profile_upload_logo = () => `Uploader une image`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_team_profile_upload_logo_label = () => `Logo de votre entreprise`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_team_profile_upload_logo_search_tooltip = () => `Chercher sur google`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_team_profile_website = () => `Site internet`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_team_profile_website_error = () => `Votre site internet doit être un URL sécurisé valide (https)`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_team_profile_website_placeholder = () => `https://`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_team_profile_youtube_url = () => `URL youtube de votre vidéo`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_team_profile_youtube_url_error = () => `L'URL doit être un lien youtube valide`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_team_profile_youtube_url_placeholder = () => `https://youtube.com...`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_team_reinvited_success = () => `Lien ré-envoyé !`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_team_resend_activation_link = () => `Renvoyer le lien d'activation`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_team_role = () => `Rôle`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_team_role_admin = () => `Administrateur`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_team_role_admin_description = () => `Tous les droits`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_team_role_collaborator = () => `Collaborateur`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_team_role_collaborator_description = () => `Validation des heures et évaluation des Siders sur toutes les missions`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_team_role_description_title = () => `Description des rôles`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_team_role_missionManager = () => `Utilisateur`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_team_role_missionManager_description = () => `Configuration des paramètres du compte et administration des missions dont il est gestionnaire`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_team_title = () => `Mon équipe`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_team_user = () => `Utilisateur`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_title = () => `Paramètres`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_warning_save_dialog_content = () => `Les modifications que vous avez effectuées seront perdues si vous quittez la page sans enregistrer.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_warning_save_dialog_discard = () => `Quitter`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_warning_save_dialog_save = () => `Enregistrer`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_warning_save_dialog_title = () => `Vous n’avez pas enregistré vos modifications !`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const shift_warning_holiday_FAQ_link = () => `https://support.side.co/b2b/fr/articles/9113087-jours-feries-travailles-et-non-travailles-comment-ca-marche`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const shifts_add_shift = () => `Créer un créneau`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const shifts_break_105_label = () => `1h45 min`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const shifts_break_120_label = () => `2h`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const shifts_break_135_label = () => `2h15 min`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const shifts_break_150_label = () => `2h30 min`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const shifts_break_165_label = () => `2h45 min`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const shifts_break_180_label = () => `3h`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const shifts_break_195_label = () => `3h15 min`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const shifts_break_20_label = () => `20 min`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const shifts_break_210_label = () => `3h30 min`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const shifts_break_225_label = () => `3h45 min`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const shifts_break_240_label = () => `4h`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const shifts_break_255_label = () => `4h15 min`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const shifts_break_270_label = () => `4h30 min`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const shifts_break_285_label = () => `4h45 min`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const shifts_break_300_label = () => `5h`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const shifts_break_30_label = () => `30 min`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const shifts_break_45_label = () => `45 min`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const shifts_break_60_label = () => `60 min`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const shifts_break_75_label = () => `1h15 min`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const shifts_break_90_label = () => `1h30 min`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const shifts_break_label = () => `Pause`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const shifts_break_none_label = () => `Aucune`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const shifts_break_placeholder = () => `Durée`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const shifts_date_label = () => `Date`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const shifts_duplicate_modal_subtitle = () => `Sélectionnez les dates pour lesquelles vous souhaitez dupliquer ce créneau`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const shifts_duplicate_modal_success = () => `Le ou les créneaux ont bien été dupliqués.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const shifts_duplicate_modal_title = () => `Dupliquer le créneau`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const shifts_endTime_label = () => `Fin`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const shifts_siders_count = () => `Nb de Siders`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const shifts_startTime_label = () => `Début`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const shifts_summary_column_break = () => `Pause`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const shifts_summary_column_date = () => `Date`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const shifts_summary_column_total_hours = () => `Total d'heures`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const shifts_summary_column_total_number = () => `Siders`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const shifts_summary_time_from = () => `de`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const shifts_summary_time_to = () => `à`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const shifts_warning_7_consecutive = () => `Vous avez ajouté des créneaux sur 7 jours consécutifs. Nous ne pourrons attribuer tous vos créneaux à la même personne (les journées de travail consécutives sont limitées à 6). `


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const shifts_warning_breaktime_subtitle = () => `La loi prévoit un temps de pause de 11h entre 2 jours travaillés (hors accord spécifique). Le cas échéant, nous pourrons positionner deux profils différents.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const shifts_warning_breaktime_title = () => `Temps de repos obligatoire`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const shifts_warning_new_year_day_subtitle = () => `Le jour de l'an est férié`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const shifts_warning_new_year_day_title = () => `Jour de l'an`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const shifts_warning_public_holiday_period = () => `Votre période de mission comprend un jour férié. Les jours fériés non travaillés sont rémunérés au tarif habituel s'ils font partie des jours habituels de mission.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const shifts_warning_public_holiday_selected = () => `Vous avez ajouté un créneau sur un jour férié dont la rémunération est majorée selon votre politique de rémunération. Vérifiez que ce jour est bien travaillé dans votre entreprise.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const sider_profile_card_ongoing = () => `En cours`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const sider_profile_copy_email = () => `Copier l'email du Sider`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const sider_profile_email_copied = () => `L'email a bien été copié`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const sider_profile_panel_educations = () => `Formations`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const sider_profile_panel_experiences = () => `Expériences`


/**
 * @param {{ plural: NonNullable<unknown>, industryName: NonNullable<unknown>, count: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const sider_profile_panel_experiences_industry = (params) => `Expérience${params.plural} passée${params.plural} en ${params.industryName} (${params.count})`


/**
 * @param {{ plural: NonNullable<unknown>, count: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const sider_profile_panel_experiences_other = (params) => `Expérience${params.plural} passée${params.plural} (${params.count})`


/**
 * @param {{ numberOfHours: NonNullable<unknown>, plural: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const sider_profile_panel_hours = (params) => `${params.numberOfHours} heure${params.plural}`


/**
 * @param {{ numberOfMonths: NonNullable<unknown>, plural: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const sider_profile_panel_months = (params) => `${params.numberOfMonths} mois`


/**
 * @param {{ plural: NonNullable<unknown>, numberOfTasks: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const sider_profile_panel_tasks = (params) => `${params.numberOfTasks} mission${params.plural}`


/**
 * @param {{ plural: NonNullable<unknown>, companyName: NonNullable<unknown>, count: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const sider_profile_panel_tasks_company = (params) => `Mission${params.plural} passée${params.plural} chez ${params.companyName} (${params.count})`


/**
 * @param {{ plural: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const sider_profile_panel_tasks_ongoing = (params) => `Mission${params.plural} en cours`


/**
 * @param {{ plural: NonNullable<unknown>, count: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const sider_profile_panel_tasks_other = (params) => `Mission${params.plural} passée${params.plural} (${params.count})`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const sider_profile_panel_tasks_side = () => `Missions Side`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const sider_profile_panel_weeks = () => `< 1 mois`


/**
 * @param {{ numberOfYears: NonNullable<unknown>, plural: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const sider_profile_panel_years = (params) => `${params.numberOfYears} an${params.plural}`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const sign_in_create_account = () => `Créer un compte entreprise`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const sign_in_email_placeholder = () => `vous@entreprise.com`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const sign_in_forgot_password = () => `J'ai oublié mon mot de passe`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const sign_in_form_title = () => `Se connecter`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const sign_in_log_in = () => `Se connecter`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const sign_in_logo_company = () => `Entreprise`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const sign_in_no_account_yet = () => `Vous n'avez pas encore de compte entreprise ?`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const sign_in_or = () => `ou`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const sign_in_password_placeholder = () => `Mot de passe`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const sign_in_title = () => `Connectez-vous à Side`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const sign_in_with_google = () => `Se connecter avec Google`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const sign_in_work_on_side = () => `Je suis un talent`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const sign_up_accept = () => `J'accepte`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const sign_up_company_label = () => `Nom de l'entreprise`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const sign_up_company_message_label = () => `Avez-vous un message pour notre équipe ?`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const sign_up_company_need_label = () => `Votre besoin`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const sign_up_company_need_learn_about_side = () => `Je cherche à en savoir plus sur Side`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const sign_up_company_need_less_than_7_days = () => `Je cherche à recruter rapidement (< 7 jours)`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const sign_up_company_need_more_than_7_days = () => `Je cherche à recruter prochainement (> 7 jours)`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const sign_up_company_need_placeholder = () => `Je cherche à en savoir plus sur Side`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const sign_up_company_placeholder = () => `Le nom de votre entreprise`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const sign_up_company_size_label = () => `Nombre d'employés`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const sign_up_company_size_placeholder = () => `Nombre d'employés`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const sign_up_create_account = () => `Créer un compte `


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const sign_up_email_label = () => `Email`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const sign_up_email_placeholder = () => `vous@entreprise.com`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const sign_up_form_subtitle_second_step = () => `Dites nous-en un peu plus à propos de vous et votre entreprise avant de créer une mission`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const sign_up_form_title = () => `Créez votre compte entreprise`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const sign_up_form_title_second_step = () => `Une dernière chose...`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const sign_up_fullname_label = () => `Prénom et nom`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const sign_up_fullname_placeholder = () => `Votre prénom et nom`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const sign_up_industry_fashion = () => `Fashion`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const sign_up_industry_gsa = () => `Grandes Surfaces Alimentaires`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const sign_up_industry_gss = () => `Grandes Surfaces Spécialisées`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const sign_up_industry_label = () => `industrie`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const sign_up_industry_logistics = () => `Logistique/Transports`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const sign_up_industry_other = () => `Autres`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const sign_up_industry_placeholder = () => `Votre industrie`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const sign_up_industry_services = () => `Services`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const sign_up_industry_tech = () => `Technologie`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const sign_up_log_in = () => `Connectez-vous`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const sign_up_logo_company = () => `Entreprise`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const sign_up_no_account_yet = () => `Vous avez déjà un compte entreprise ?`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const sign_up_or = () => `ou`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const sign_up_password_label = () => `Mot de passe`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const sign_up_password_placeholder = () => `Choisissez un mot de passe`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const sign_up_phone_label = () => `Numéro de téléphone`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const sign_up_phone_placeholder = () => `Votre numéro de téléphone`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const sign_up_size_to = () => `à`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const sign_up_temp_worker = () => `S'inscrire en tant que travailleur intérimaire`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const sign_up_terms_and_conditions = () => `les termes et conditions`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const sign_up_title = () => `Créez votre compte entreprise`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const sign_up_with_google = () => `Créez un compte entreprise avec Google`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const sign_up_work_on_side = () => `Travailler sur Side`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const signup_error_invalid_email = () => `Entrez un email valide`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const slo = () => `Slovaque`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const slv = () => `Slovène`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const smo = () => `Samoa`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const sot = () => `Sesotho`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const spa = () => `Espagnol`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const sr_alert_disputed = () => `Vous avez ajusté ces heures, la modification est en cours d'acceptation par le Sider.`


/**
 * @param {{ name: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const sr_alert_forgotten = (params) => `${params.name} a modifié manuellement le détail de ses heures pour ce créneau. Nous vous conseillons de faire une vérification supplémentaire.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const srp = () => `Serbe`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const submit = () => `Soumettre`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const swa = () => `Swahili`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const swe = () => `Suédois`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const table_header_end_date = () => `Date de fin`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const table_header_start_date = () => `Date de début`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const table_header_status = () => `Statut`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const table_header_task = () => `Mission`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const tam = () => `Tamoul`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const task_edit_contract_warning = () => `Des Siders ont déjà été recrutés sur cette mission.
La plupart des informations ne sont donc plus modifiables.
Contactez le support client pour toute question ou assistance.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const task_edit_custom_equipment_desc = () => `Si vous n'avez pas trouvé l'équipement correspondant à votre besoin dans la liste, vous pouvez en ajouter un nouveau ci-dessous.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const task_edit_custom_equipment_name_placeholder = () => `Visière`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const task_edit_custom_equipment_other_desc = () => `Sélectionnez le matériel que les Siders devront apporter.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const task_edit_custom_equipment_providedBy_placeholder = () => `Indiquez qui doit fournir cet équipement`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const task_edit_custom_equipment_title = () => `Ajout d’un élément personnalisé`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const task_edit_dress_code_desc = () => `Veuillez indiquer toute information relative au dress code.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const task_edit_experiences_placeholder = () => `Ex: Nous recherchons de préférence des profils connaissant l’industrie de...`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const task_edit_languages_no_result = () => `Modifiez votre recherche pour afficher plus de langues.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const task_edit_location_desc = () => `Vous pouvez modifier les informations liées au lieu de travail qui aideront les Siders à se rendre sur place.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const task_edit_location_desc_placeholder = () => `Précisez les informations qui seront utiles aux Siders pour trouver leur chemin le premier jour.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const task_edit_location_workplace_title = () => `Adresse - Lieu de travail`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const task_edit_motorized_desc = () => `Veuillez indiquer si le Sider doit être motorisé.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const task_edit_remote_desc = () => `Veuillez indiquer si le Sider a possibilité de faire du télétravail.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const task_edit_requested_siders_warning = () => `L’adresse ne peut pas être modifiée car des Siders sont demandés sur cette mission. Pour pouvoir modifier l’adresse, retirez les Siders demandés en priorité.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const task_edit_salary_invalid_decimal_error = () => `Le salaire doit avoir au plus 2 décimales`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const task_edit_salary_invalid_number_error = () => `Numéro invalide`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const task_edit_salary_too_low_error = () => `Impossible de baisser le salaire, saisissez un montant égal ou supérieur au montant initial`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const task_edit_sidenote_desc = () => `Veuillez renseigner toutes informations destinées à Side.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const task_edit_success_toast = () => `Le champ a bien été modifié`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const task_edit_suffix_desc = () => `Vous pouvez renommer votre mission pour la différencier des autres, ce nouveau nom sera utilisé en tant que référence sur vos factures.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const task_edit_suffix_title = () => `Nom de la mission`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const task_edit_tools_custom_label = () => `Nom de l’outil`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const task_edit_workconditions_desc = () => `Veuillez préciser les informations supplémentaires liées au risques.`


/**
 * @param {{ numberOfRemainingSlots: NonNullable<unknown>, plural: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const task_edit_workers_wao_hint = (params) => `Ajoutez ${params.numberOfRemainingSlots} Sider${params.plural} pour activer`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const task_edit_workers_wao_label = () => `Exclusif aux Siders selectionnés`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const task_shifts_add = () => `Ajouter des créneaux`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const task_shifts_cost_estimation = () => `Estimation totale HT`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const task_shifts_dialog_message = () => `Cette action impacterait les créneaux de la mission.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const task_shifts_dialog_title = () => `Êtes-vous sûr(e) ?`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const task_shifts_footer_delete_button = () => `Supprimer les créneaux`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const task_shifts_modal_break_label = () => `Pause`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const task_shifts_modal_break_placeholder = () => `Aucune`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const task_shifts_modal_break_tip_label = () => `(non rémunérée)`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const task_shifts_modal_bulk_selection_all_shifts = () => `Tous les créneaux de la répétition`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const task_shifts_modal_bulk_selection_label_delete = () => `Quel(s) créneau(x) souhaitez-vous supprimer ?`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const task_shifts_modal_bulk_selection_label_edit = () => `Quel(s) créneau(x) souhaitez-vous éditer ?`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const task_shifts_modal_bulk_selection_this_shift_and_following = () => `Ce créneau et les créneaux suivants de la répétition`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const task_shifts_modal_bulk_selection_this_shift_only = () => `Ce créneau seulement`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const task_shifts_modal_creation_title = () => `Ajouter un ou plusieurs créneaux`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const task_shifts_modal_date_label = () => `Date`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const task_shifts_modal_days_error = () => `Au moins un jour doit-être sélectionné.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const task_shifts_modal_deletion_title = () => `Supprimer un ou plusieurs créneaux`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const task_shifts_modal_duration_error = () => `La durée de travail effectif doit être comprise entre 2h et 10h.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const task_shifts_modal_edition_title = () => `Modifier un ou plusieurs créneaux`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const task_shifts_modal_label_each = () => `Chaque`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const task_shifts_modal_label_every = () => `Tous les`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const task_shifts_modal_label_until = () => `Jusqu'au`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const task_shifts_modal_option_monthly = () => `Mois`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const task_shifts_modal_option_weekly = () => `Semaine`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const task_shifts_modal_radio_1_of_the_month = () => `Premiers`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const task_shifts_modal_radio_2_of_the_month = () => `Seconds`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const task_shifts_modal_radio_3_of_the_month = () => `Troisièmes`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const task_shifts_modal_radio_4_of_the_month = () => `Quatrièmes`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const task_shifts_modal_radio_last_of_the_month = () => `Derniers`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const task_shifts_modal_radio_of_the_month = () => `du mois`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const task_shifts_modal_schedule_end_label = () => `Horaire de fin`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const task_shifts_modal_schedule_start_label = () => `Horaire de début`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const task_shifts_modal_siders_label = () => `Nombre de Siders`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const task_shifts_modal_slots_error = () => `Le nombre de slots doit être compris entre 1 et 50.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const task_shifts_modal_switch_holidays = () => `Inclut les jours fériés`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const task_shifts_modal_switch_repeat = () => `Se répète`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const task_shifts_modal_until_error = () => `La date de fin doit être postérieure à la date de début.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const task_shifts_no_shifts_description = () => `Ajoutez vos créneaux, individuellement ou en série.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const task_shifts_no_shifts_title = () => `Aucun créneau pour le moment`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const task_shifts_success_toast_creation = () => `Le ou les créneaux ont bien été créés.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const task_shifts_success_toast_deletion = () => `Le ou les créneaux ont bien été supprimés.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const task_shifts_success_toast_edition = () => `Le ou les créneaux ont bien été modifiés.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const task_shifts_table_break = () => `Pause`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const task_shifts_table_hours = () => `Horaires`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const task_shifts_table_shifts = () => `Créneaux`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const task_shifts_title = () => `Créneaux`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const task_status_closed = () => `Terminé`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const task_status_done = () => `Terminé`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const task_status_draft = () => `Brouillon`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const task_status_new = () => `Nouveau`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const task_status_ongoing = () => `En cours`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const task_status_upcoming = () => `À venir`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const te_dispute_add_break = () => `Ajouter une pause`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const te_dispute_break = () => `Pauses`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const te_dispute_cancel = () => `Annuler`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const te_dispute_did_not_work = () => `Le sider n’a pas travaillé`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const te_dispute_did_not_work_tip_after_confirmed = () => `Vous avez déclaré que ce Sider n'a pas travaillé sur ce créneau. L'information a été remontée aux équipes de Side.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const te_dispute_did_not_work_tip_confirmation = () => `Vous êtes sur le point de contester l'ensemble des heures déclarées par le Sider sur ce créneau. Cette action est irréversible.`


/**
 * @param {{ managerName: NonNullable<unknown>, date: NonNullable<unknown>, hour: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const te_dispute_review_info = (params) => `Heures validées par ${params.managerName} le ${params.date} à ${params.hour}.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const te_dispute_send = () => `Envoyer`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const te_dispute_shift = () => `Heure de début - Heure de fin`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const te_dispute_shift_end = () => `Fin`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const te_dispute_shift_start = () => `Début`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const te_dispute_subtitle = () => `Modifier les informations n’étant pas correctes.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const te_dispute_title = () => `Heures déclarées`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const te_tip_disputed_content = () => `Vous ne pouvez pas modifier ces heures une seconde fois.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const te_tip_edit_content = () => `Si vous êtes en désaccord, modifiez simplement les heures ici.`


/**
 * @param {{ name: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const te_tip_howto_content = (params) => `Ces ajustements sont envoyés à ${params.name} pour validation.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const te_tip_review_content = () => `Ces heures ont été ajustées et sont en cours de validation.`


/**
 * @param {{ s: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const textarea_counter_label = (params) => `caractère${params.s} restant${params.s}`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const tha = () => `Thaï`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const top_menu_dropdown_default = () => `Tous les collaborateurs`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const top_menu_filter_all = () => `Missions`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const top_menu_filter_all_invoices = () => `Toutes les factures`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const top_menu_filter_completed = () => `Terminées`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const top_menu_filter_draft = () => `Brouillons`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const top_menu_filter_ongoing = () => `En cours`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const top_menu_filter_paid_ts = () => `Payés`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const top_menu_filter_siders = () => `Tous les Siders`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const top_menu_filter_to_be_paid = () => `Ouverts à la relecture`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const top_menu_filter_to_review = () => `À vérifier`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const top_menu_filter_upcoming = () => `À venir`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const top_menu_filter_validated = () => `Validées`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const top_menu_planning = () => `Planning`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_attendance_disputed_success = () => `Les heures ont été modifiées avec succès.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_confirm_attendances = () => `Valider les relevés`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_confirm_attendances_mobile = () => `Valider`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_edit_form_break_error_after = () => `La pause se termine après la fin du créneau, merci de modifier votre saisie.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_edit_form_break_error_before = () => `La pause commence avant le début du créneau, merci de modifier votre saisie.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_edit_form_break_title = () => `Pauses déclarées`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_edit_form_breaks_add = () => `Ajouter une pause`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_edit_form_description = () => `Éditer les informations n'étant pas correctes.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_edit_form_hours_24_hours_error = () => `Le créneau déclaré est supérieur à 24 heures ! `


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_edit_form_hours_label = () => `Début & fin`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_edit_form_hours_title = () => `Heures déclarées`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_export_csv = () => `Télécharger le relevé d'heures en CSV`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_export_csv_error_body = () => `Veuillez contacter le support pour plus d'informations.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_export_csv_error_title = () => `Le fichier CSV n'a pu être généré.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_file_import_button = () => `Importer`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_file_import_dropzone_button = () => `Télécharger un fichier`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_file_import_dropzone_hint = () => `Télécharger un csv, maximum 1Mo.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_file_import_dropzone_text = () => `ou glisser/déposer ici`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_file_import_modal_all_error_title = () => `L’ensemble de votre fichier contient des erreurs`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_file_import_modal_button = () => `Importer`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_file_import_modal_cancel = () => `Annuler`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_file_import_modal_column_error = () => `Colonnes manquantes :`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_file_import_modal_db_att_duplicate_error = () => `Les lignes suivantes ont déjà été importés :`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_file_import_modal_description = () => `Le fichier doit être au format csv et contenir les colonnes suivantes : siderId, startDate, endDate, breakDuration.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_file_import_modal_empty_error = () => `Les lignes suivantes contiennent des cellules vides :`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_file_import_modal_end_before_start_error = () => `Les lignes suivantes contiennent une date de fin antérieure à la date de début :`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_file_import_modal_error_all_lines = () => `Vous ne pouvez pas poursuivre l’import car toutes les lignes de votre fichier sont en erreur.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_file_import_modal_file_att_duplicate_error = () => `Les lignes suivantes sont des doublons d'une autre ligne du fichier, merci de les supprimer avant de réimporter :`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_file_import_modal_format_error = () => `Les lignes suivantes contiennent des formats invalides :`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_file_import_modal_import = () => `Importer`


/**
 * @param {{ count: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_file_import_modal_import_success = (params) => `${params.count} relevés d'heures ont été importés avec succès`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_file_import_modal_partial_error_title = () => `Ce fichier contient des erreurs. Poursuivre l’import ?`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_file_import_modal_rest_of_lines = () => `Le reste des lignes du fichier sont prêtes à être importées.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_file_import_modal_shifts_not_found_error = () => `Les lignes suivantes ne correspondent à aucun shift paramétré :`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_file_import_modal_sider_id_error = () => `Les lignes suivantes contiennent des siderIds invalides :`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_file_import_modal_template_link = () => `Accéder au template du fichier`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_file_import_modal_title = () => `Importer un fichier Excel (.csv uniquement)`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_file_import_modal_with_error_main_button = () => `Poursuivre l'import`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_file_import_modal_with_error_secondary_button = () => `Importer un autre fichier`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_filters_all = () => `Toutes les dates`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_filters_day = () => `Jour`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_filters_month = () => `Mois`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_filters_search_item_empty = () => `Pas de résultats trouvés`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_filters_search_manager = () => `Recherche par nom du Task manager`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_filters_search_sider = () => `Recherche par nom du Sider`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_filters_status = () => `Statut`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_filters_type = () => `Type`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_filters_type_hours_different_description = () => ` N’affichera que les Siders ayant saisies des heures différentes aux heures prévues lors de la planification de la mission.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_filters_type_hours_different_label = () => `Heures saisies différentes des heures prévues.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_filters_type_hours_similar_description = () => `N’affichera que les Siders ayant saisies des heures similaires aux heures prévues lors de la planification de la mission.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_filters_type_hours_similar_label = () => `Heures saisies similaires aux heures prévues`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_filters_week = () => `Semaine`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_footer_paragraph = () => `Sélectionnez un/des Siders pour relever leurs heures ou justifier qu'ils n'ont pas travaillé.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_footer_selected_sider = () => `1 relevé sélectionné`


/**
 * @param {{ count: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_footer_selected_siders = (params) => `${params.count} relevé sélectionnés`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_footer_sider_didnt_work = () => `Le Sider n'a pas travaillé`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_footer_siders_didnt_work = () => `Les Siders n'ont pas travaillé`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_footer_tovalidate_attendance = () => `Valider 1 relevé`


/**
 * @param {{ count: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_footer_tovalidate_attendances = (params) => `Valider les ${params.count} relevés`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_footer_validate_hours = () => `Valider les heures`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_hours_validation_faq_label = () => `Pourquoi dois-je valider les relevés?`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_hours_validation_faq_link = () => `https://support.side.co/b2b/fr/articles/9113131-comment-verifier-les-heures-declarees-par-les-siders`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_hours_validation_modal_agreement = () => `Je suis d'accord avec toutes les heures ci-dessus et je les valide définitivement`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_hours_validation_modal_attendances_count = () => `Nombre de relevés`


/**
 * @param {{ count: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_hours_validation_modal_failure = (params) => `${params.count} relevé n’a pas pu être validé. Nous vous invitons à retenter de l'envoyer dans quelques minutes.`


/**
 * @param {{ count: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_hours_validation_modal_failure_plural = (params) => `${params.count} relevés n’ont pas pu être validés. Nous vous invitons à retenter de les envoyer dans quelques minutes.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_hours_validation_modal_submit_button = () => `Valider et envoyer`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_hours_validation_modal_submit_button_mobile = () => `Valider`


/**
 * @param {{ count: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_hours_validation_modal_success = (params) => `${params.count} relevé a été validé avec succès. `


/**
 * @param {{ count: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_hours_validation_modal_success_plural = (params) => `${params.count} relevés ont été validés avec succès. `


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_hours_validation_modal_tip_content = () => `Une fois qu'un relevé d'heures est validé, cela signifie que vous ne pouvez plus modifier les heures et que vous vous engagez à les payer à Side`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_hours_validation_modal_tip_title = () => `Confirmation`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_hours_validation_modal_title = () => `Validation définitive des heures`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_hours_validation_modal_worked_hours = () => `Total des heures`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_modal_confirm_didntwork_content = () => `Vous vous apprêtez à contester l'ensemble des heures déclarées sur le(s) relevé(s) sélectionné(s). `


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_modal_confirm_didntwork_tip = () => `Votre déclaration sera envoyée au Sider pour confirmation. Cette action est irréversible.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_modal_confirm_didntwork_title = () => `Confirmation d'absence`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_modal_confirm_submit = () => `Confirmer et envoyer`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_modal_confirm_validation_content = () => `Veuillez confirmer que vous souhaitez valider définitivement le(s) relevé(s) sélectionné(s). `


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_modal_confirm_validation_tip = () => `Vous ne pouvez plus modifier les relevés validés. Ils apparaîtront dans votre prochaine facture. `


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_modal_confirm_validation_title = () => `Confirmation`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_panel_add_break = () => `Ajouter une pause`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_panel_imported_hours = () => `Heures importées par l'entreprise`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_panel_scheduled_hours = () => `Heures prévues`


/**
 * @param {{ plural: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_panel_sider_breaks = (params) => `Pause${params.plural}`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_panel_sider_didnt_work = () => `Le Sider n'a pas travaillé`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_panel_sider_hours = () => `Heures saisies par le Sider`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_panel_submit = () => `Envoyer mes modifications`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_select = () => `Sélectionner`


/**
 * @param {{ count: NonNullable<unknown>, plural: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_selected_attendances_count = (params) => `${params.count} relevé${params.plural} à valider`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_shifts_break = () => `de pause`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_shifts_break_hour = () => `1 heure de pause`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_shifts_break_mins = () => `min de pause`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_shifts_deadline = () => `Deadline:`


/**
 * @param {{ hours: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_shifts_expected_hours = (params) => `environ ${params.hours} heures `


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_shifts_hide_entries = () => `Cacher les heures déjà vérifiés`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_shifts_in = () => `Créneaux de la mission`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_shifts_mark_seen = () => `Marquer comme lu`


/**
 * @param {{ date: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_shifts_no_time_day = (params) => `Aucune heure déclarée le ${params.date}.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_shifts_no_time_shift = () => `Pas de nouvelles heures déclarées`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_shifts_show_entries = () => `Voir les heures déjà vérifiées`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_shifts_unplanned_day = () => `Créneaux supplémentaires du`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_shifts_unplanned_in = () => `Créneaux supplémentaires pour la mission`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_table_add_comment = () => `Ajouter un commentaire sur ce Sider.`


/**
 * @param {{ siderName: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_table_conflict_company_description = (params) => `Vous avez modifié les heures déclarées pour ce créneau. Votre version est en attente de validation par ${params.siderName}.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_table_conflict_company_title = () => `En attente de validation`


/**
 * @param {{ siderName: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_table_conflict_insider_description = (params) => `Vous avez modifié les heures déclarées pour ce créneau. Votre version est en attente de validation par ${params.siderName}.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_table_conflict_insider_title = () => `En attente de validation`


/**
 * @param {{ siderName: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_table_conflict_sider_description = (params) => `${params.siderName} a refusé votre modification pour ce créneau. Contactez le support pour débloquer la situation.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_table_conflict_sider_title = () => `Version refusée`


/**
 * @param {{ delta: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_table_diff_break_description = (params) => `Il y a une différence de ${params.delta} par rapport aux pauses prévues.`


/**
 * @param {{ delta: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_table_diff_break_label = (params) => `${params.delta} de différence`


/**
 * @param {{ delta: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_table_diff_hours_description = (params) => `Il y a une différence de ${params.delta} par rapport aux heures prévues.`


/**
 * @param {{ delta: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_table_diff_hours_label = (params) => `${params.delta} de différence`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_table_edit_comment = () => `Editer le commentaire`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_table_head_duration = () => `Durée `


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_table_head_duration_tag = () => `Durée totale saisie par les Siders`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_table_head_mission = () => `Mission`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_table_head_sider_breaks = () => `Pause(s)`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_table_head_sider_hours = () => `Heures saisies`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_table_head_start_end = () => `Début & fin`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_table_head_status = () => `Statut`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_table_no_attendances_description = () => `Modifiez vos critères de recherche ou revenez plus tard.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_table_no_attendances_title = () => `Aucun créneau à afficher`


/**
 * @param {{ validationDate: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_table_validated_tag_content = (params) => `Ce créneau a été validé le ${params.validationDate}.`


/**
 * @param {{ validatedBy: NonNullable<unknown>, validationDate: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_table_validated_tag_content_validated_by = (params) => `Ce créneau a été validé par ${params.validatedBy} le ${params.validationDate}.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_table_validated_tag_title = () => `Créneau validé`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_tag_disputed = () => `Contesté`


/**
 * @param {{ date: NonNullable<unknown>, time: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_tag_event_log = (params) => `Fichier importé le ${params.date} à ${params.time}`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_tag_external_file_origin = () => `Fichier externe`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_tag_validated = () => `Validé`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_tag_waiting = () => `À valider`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_tip_archives_content = () => `Cet onglet récapitule l'ensemble des paiements envoyés aux Siders. Pour rappel, vous pouvez ajuster les heures déclarées par les Siders et les paiements sont ensuite validés tous les mardi. `


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_tip_archives_title = () => `C'est payé !`


/**
 * @param {{ date: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_tip_date_review = (params) => `Vous avez jusqu'au ${params.date} à 22h pour vérifier les relevés d'heures placés ci-dessous. Après cette date, les paiements seront validés automatiquement.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_tip_hours_validation_content = () => `Pour que l'on puisse payer les Siders, vous devez vérifier puis valider définitivement toutes les heures qu'ils ont déclarées`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_tip_hours_validation_title = () => `Vérification des heures`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_tip_review_content = () => `Gardez le fil de vos relevés d'heures en cochant<i class=\\"icon-checkmark\\"></i>les créneaux déjà vérifiés.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_tip_review_title = () => `Marquer comme lu`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_tip_unplanned_content = () => `Cette partie récapitule les heures travaillées sur des créneaux que vous n'avez initialement pas entrés sur la plateforme.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_tip_unplanned_title = () => `Créneaux supplémentaires`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_to_validate_blank_state_content = () => `Vous n'avez aucune heure à vérifier pour le moment.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_to_validate_blank_state_title = () => `C'est tout bon !`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_unselect_all_attendances = () => `Tout déselectionner`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_update = () => `Modifier`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_validated_blank_state_content = () => `Vous n'avez aucun relevé validé pour le moment.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_validated_blank_state_title = () => `Vous êtes tranquille !`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_warning_cicos_content = () => `Certains Siders n'ont pas enregistré leurs heures le jour même. Assurez-vous de bien vérifier toutes les heures marquées du tag `


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_warning_cicos_tab_tooltip = () => `Certaines heures ont été ajoutées en retard. Nous vous conseillons de faire une vérification supplémentaire.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_warning_cicos_tag = () => `Ajouté en retard`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_warning_cicos_tag_tooltip = () => `Ces heures n'ont pas été enregistrées le jour même.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_warning_cicos_title = () => `Certaines heures ont été ajoutées en retard`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const tur = () => `Turc`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ukr = () => `Ukrainien`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const undo = () => `Annuler`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const until = () => `jusqu'à`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const until_date = () => `jusqu'au`


/**
 * @param {{ numberOfNotDisplayedUpcoming: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const upcoming_section_button_view_more = (params) => `Voir toutes les missions à venir (${params.numberOfNotDisplayedUpcoming})`


/**
 * @param {{ siderNumber: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const upcoming_section_row_status_cell_subtitle_contact_plural = (params) => `Contacter les ${params.siderNumber} Siders`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const upcoming_section_row_status_cell_subtitle_contact_singular = () => `Contacter le Sider`


/**
 * @param {{ hiringEndDateDay: NonNullable<unknown>, hiringEndDateHour: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const upcoming_section_row_status_cell_subtitle_selection_ongoing = (params) => `Fin prévue le ${params.hiringEndDateDay} à ${params.hiringEndDateHour}`


/**
 * @param {{ selectedSiderNumber: NonNullable<unknown>, totalSidersNumberExpected: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const upcoming_section_row_status_cell_title_selection_almost_done = (params) => `${params.selectedSiderNumber}/${params.totalSidersNumberExpected} Siders sélectionnés`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const upcoming_section_row_status_cell_title_selection_done = () => `Sélection terminée`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const upcoming_section_row_status_cell_title_selection_ongoing = () => `Sélection en cours`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const upcoming_section_row_task_cell_managed_by = () => `Gérée par `


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const upcoming_section_title = () => `Missions à venir`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const update_manager = () => `Modifier le manager`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const urd = () => `Ourdou`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const use = () => `Utiliser`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const uzb = () => `Ouzbek`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const various_geolocation_multiple_places = () => ` lieux différents`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const various_geolocation_online = () => `En ligne`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const various_time_deadline = () => `Date limite`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const vie = () => `Vietnamien`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const view_invoices = () => `Voir les factures & paiements`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const view_siders = () => `Voir les Siders`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const view_task = () => `Voir la mission`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const warning_is_google_login = () => `Vous êtes connecté via Google, vous ne pouvez pas modifier le mot de passe`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const warning_is_log_as = () => `Vous êtes connecté en Log as, vous ne pouvez pas modifier le mot de passe`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const week = () => `Semaine`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const yes = () => `Oui`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const zgh = () => `Amazigh (berbère)`
