import { useEffect, useState } from "react";

import { i18n } from "@lib/i18n";

import TableRow from "./TableRow";

import "./TableLayout.scss";

const TableLayout = ({ items, status, latestTask, match }) => {
    const [sortedItems, setSortedItems] = useState(items);

    useEffect(() => {
        setSortedItems(items);
    }, [items]);

    return (
        <div className='wrapper table'>
            <div className='table__head row'>
                <div
                    className={`table__col offset-sm-1 col-sm-2 ${
                        items.length === 1 ? "m-hidden" : ""
                    }`}
                >
                    <div className='table__col__inner'>
                        <span className='table__head__link'>
                            {status === "completed"
                                ? i18n.table_header_end_date()
                                : i18n.table_header_start_date()}
                        </span>
                    </div>
                </div>
                <div className='table__col col-sm-5 m-hidden'>
                    <div className='table__col__inner'>{i18n.table_header_task()}</div>
                </div>
                <div className='table__col col-sm-7 m-hidden'>
                    <div className='table__col__inner'>
                        <span className='table__head__link'>{i18n.table_header_status()}</span>
                    </div>
                </div>
            </div>

            <div className='table__body'>
                {sortedItems.map((item) => {
                    return (
                        <TableRow
                            key={item.id}
                            item={item}
                            status={status}
                            singleTask={items.length === 1}
                            latestTask={latestTask}
                            match={match}
                        />
                    );
                })}
            </div>
        </div>
    );
};

export default TableLayout;
