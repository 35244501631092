import { useEffect, useRef, useState } from "react";
import clsx from "clsx";
import { atom, useAtom, useAtomValue, useSetAtom } from "jotai";
import { ArrowLeft, ArrowRight, Info, Plus, Trash2 } from "lucide-react";
import { Button, Loader, useConfirmDialog } from "sui";

import { queryClient } from "@App";
import { Task } from "@lib/api";
import { postFlexiblePlanning, PostFlexiblePlanningParams } from "@lib/api/postFlexiblePlanning";
import { useLocation } from "@lib/hooks/useLocation";
import useOnClickOutside from "@lib/hooks/useOnClickOutside";
import { i18n } from "@lib/i18n";
import { usePatchTask } from "@lib/mutations/usePatchTask";
import { queries } from "@lib/queries";
import trackEvent from "@lib/trackers";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useMatchRoute, useNavigate } from "@tanstack/react-router";
import { useFlag } from "@unleash/proxy-client-react";

import Quotation from "./Quotation/Quotation";
import { modalShiftsStateAtom } from "./ShiftsStep/ShiftsStep";
import { shiftsToDeleteAtom } from "./ShiftsStep/ShiftsTable/ShiftsTable";
import { useShiftAPI } from "./ShiftsStep/useShiftAPI";
import { taskFormRoute } from "./route";

import styles from "./TaskFormFooter.module.css";
export const orderDataToSendAtom = atom({});
export const disabledSubmitAtom = atom(false);

export function TaskFormFooter() {
    const isFlexiblePlanningEnabled = useFlag("flexible-planning");
    const setModalShistsStateAtom = useSetAtom(modalShiftsStateAtom);
    const shiftsToDeleteAtomValue = useAtomValue(shiftsToDeleteAtom);
    const matchRoute = useMatchRoute();
    const { taskId } = taskFormRoute.useParams();
    const { data: task, isLoading: isTaskLoading } = useQuery(queries.task.detail(taskId));

    const [orderDataToSend, setOrderDataToSend] = useAtom(orderDataToSendAtom);
    const disabledSubmit = useAtomValue(disabledSubmitAtom);

    const isFlexiblePlanningPage = !!matchRoute({ to: "/taskPosting/$taskId/flexible-planning" });

    const location = useLocation();
    const step = location.pathname.split("/")[3];
    const navigate = useNavigate();

    const confirm = useConfirmDialog();

    const { removeShifts } = useShiftAPI();

    const { patchTask: updateTask, isPending: isUpdateTaskPending } = usePatchTask({
        onSuccess: goToNextStep,
    });
    const { mutate: createPlanning, isPending: isCreatePlanningPending } = useMutation({
        mutationFn: postFlexiblePlanning,
        onSuccess: () => {
            goToNextStep();
        },
        onSettled: () => {
            queryClient.removeQueries(queries.quotation.detail(taskId));
            queryClient.invalidateQueries(queries.task.detail(taskId));
        },
    });
    const isPending = isUpdateTaskPending || isCreatePlanningPending;

    function handleValidation() {
        if (isFlexiblePlanningPage) {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const { isValid, isDirty, ...flexiblePlanningPayload } = orderDataToSend as any;
            if (isDirty && isValid) {
                createPlanning({
                    taskId,
                    config: flexiblePlanningPayload as PostFlexiblePlanningParams["config"],
                });
            } else {
                goToNextStep();
            }
        } else {
            updateTask({
                id: taskId,
                ...orderDataToSend,
            });
        }
    }

    function goToNextStep() {
        setOrderDataToSend({});
        switch (step) {
            case "motive":
                navigate({
                    to: (() => {
                        if (
                            !isFlexiblePlanningEnabled ||
                            (task?.flexiblePlanning === false && task?.shifts?.length)
                        ) {
                            return `/taskPosting/$taskId/shifts`;
                        }
                        if (task?.flexiblePlanning === true && task?.flexiblePlanningId) {
                            return `/taskPosting/$taskId/flexible-planning`;
                        }
                        return `/taskPosting/$taskId/planning-type`;
                    })(),
                    params: { taskId },
                });
                break;
            case "shifts":
            case "flexible-planning":
                navigate({
                    to: `/taskPosting/$taskId/details`,
                    params: { taskId },
                });
                break;
            case "details":
                navigate({
                    to: `/taskPosting/$taskId/workers`,
                    params: { taskId },
                });
                break;
            case "workers":
                navigate({
                    to: `/taskPosting/$taskId/summary`,
                    params: { taskId },
                });
                break;
            default:
                navigate({
                    to: `/taskPosting/$taskId/motive`,
                    params: { taskId },
                });
                break;
        }
    }

    function goToPreviousStep() {
        setOrderDataToSend({});
        switch (step) {
            case "summary":
                navigate({
                    to: `/taskPosting/$taskId/workers`,
                    params: { taskId },
                });
                break;
            case "workers":
                navigate({
                    to: `/taskPosting/$taskId/details`,
                    params: { taskId },
                });
                break;
            case "details":
                navigate({
                    to: (() => {
                        if (!isFlexiblePlanningEnabled || task?.flexiblePlanning === false) {
                            return `/taskPosting/$taskId/shifts`;
                        }
                        if (task?.flexiblePlanning === true) {
                            return `/taskPosting/$taskId/flexible-planning`;
                        }
                    })(),
                    params: { taskId },
                });
                break;
            case "shifts":
            case "flexible-planning":
                navigate({
                    to: `/taskPosting/$taskId/motive`,
                    params: { taskId },
                });
                break;
            default:
                navigate({ to: `/job-descriptions` });
                break;
        }
    }
    const isShiftPage = !!matchRoute({ to: "/taskPosting/$taskId/shifts" });

    if (isShiftPage && task?.shifts?.length === 0) return null;

    return (
        <footer className={styles.footer}>
            {shiftsToDeleteAtomValue.length > 0 ? (
                <div className={styles.flex}>
                    <Button
                        shape='outlined'
                        intention='danger'
                        onClick={() =>
                            confirm({
                                title: i18n.task_shifts_dialog_title(),
                                message: i18n.task_shifts_dialog_message(),
                            }).then(() => removeShifts({ taskId, ids: shiftsToDeleteAtomValue }))
                        }
                        icon={<Trash2 className='h-5 w-5' />}
                        iconDisposition='left'
                    >
                        {i18n.task_shifts_footer_delete_button()}
                    </Button>
                </div>
            ) : (
                <>
                    <div className={styles.flex}>
                        <Button
                            shape='outlined'
                            intention='secondary'
                            onClick={goToPreviousStep}
                            icon={<ArrowLeft className='h-5 w-5' />}
                            iconDisposition='left'
                        >
                            {i18n.back()}
                        </Button>
                        <div className={styles.shiftsEstimationAction}>
                            <QuotationFooterComponent
                                task={task}
                                isFlexiblePlanningPage={isFlexiblePlanningPage}
                                step={step}
                            />
                            {isShiftPage && (
                                <Button
                                    onClick={() =>
                                        setModalShistsStateAtom({
                                            open: true,
                                            mode: "creation",
                                            shift: null,
                                        })
                                    }
                                    icon={<Plus className='h-5 w-5' />}
                                    iconDisposition='left'
                                    shape='outlined'
                                    intention='secondary'
                                >
                                    {i18n.task_shifts_add()}
                                </Button>
                            )}
                        </div>
                    </div>
                    <Button
                        disabled={disabledSubmit}
                        loading={isTaskLoading || isPending}
                        onClick={() => handleValidation()}
                        icon={<ArrowRight className='h-5 w-5' />}
                    >
                        {i18n.label_continue()}
                    </Button>
                </>
            )}
        </footer>
    );
}

function QuotationFooterComponent({
    task,
    isFlexiblePlanningPage,
    step,
}: {
    task?: Task;
    isFlexiblePlanningPage: boolean;
    step: string;
}) {
    const { taskId } = taskFormRoute.useParams();
    const [priceEstimateTooltipVisible, setPriceEstimateTooltipVisible] = useState(false);
    const priceEstimateRef = useRef(null);
    useOnClickOutside(priceEstimateRef, () => setPriceEstimateTooltipVisible(false));
    const isFlexiblePlanningEnabled = useFlag("flexible-planning");
    const orderDataToSend = useAtomValue(orderDataToSendAtom) as any;
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { isValid, isDirty, ...flexiblePlanningPayload } = orderDataToSend as any;

    const {
        data: quotationReal,
        refetch: refetchQuotationReal,
        isLoading: isQuotationRealLoading,
    } = useQuery({
        ...queries.quotation.detail(taskId),
        enabled: !!task?.shifts?.length,
    });
    const {
        data: flexiblePlanningQuotation,
        refetch: refetchFlexiblePlanningQuotation,
        isLoading: isFlexiblePlanningQuotationLoading,
    } = useQuery({
        ...queries.task.flexiblePlanningQuotation({
            taskId,
            config: flexiblePlanningPayload,
        }),
        enabled:
            !!Object.keys(orderDataToSend).length &&
            isFlexiblePlanningEnabled &&
            isFlexiblePlanningPage,
    });

    const isQuotationLoading =
        isFlexiblePlanningPage && orderDataToSend.isDirty
            ? isFlexiblePlanningQuotationLoading
            : isQuotationRealLoading;

    const quotation =
        isFlexiblePlanningPage && orderDataToSend.isDirty
            ? flexiblePlanningQuotation
            : quotationReal;

    const quotationTotal =
        isFlexiblePlanningPage && orderDataToSend.isDirty
            ? flexiblePlanningQuotation?.price
            : quotationReal?.price;

    useEffect(() => {
        if (task?.shifts?.length) {
            refetchQuotationReal();
        } else {
            queryClient.removeQueries(queries.quotation.detail(taskId));
        }
    }, [task?.shifts]);

    useEffect(() => {
        if (isFlexiblePlanningPage && !!Object.keys(flexiblePlanningPayload).length && isValid) {
            refetchFlexiblePlanningQuotation();
        } else if (!isFlexiblePlanningPage && flexiblePlanningQuotation !== null) {
            queryClient.removeQueries({ queryKey: "flexiblePlanningQuotation" });
        }
    }, [orderDataToSend, isFlexiblePlanningPage]);

    const shouldDisplayQuotation =
        ((task?.shifts?.length ?? 0) > 0 && quotationReal !== null) ||
        (isFlexiblePlanningPage && flexiblePlanningQuotation !== null);

    if (!shouldDisplayQuotation) return null;

    const hasValidValue = !!(
        ((isFlexiblePlanningPage && isValid) || !isFlexiblePlanningPage) &&
        quotationTotal
    );

    return (
        <div ref={priceEstimateRef} className={styles.priceEstimate}>
            {priceEstimateTooltipVisible && (
                <div className={styles.tooltip}>
                    <Quotation quotation={quotation} withTaxes={false} taskId={taskId} />
                </div>
            )}
            <div className={clsx(styles.priceEstimateLabel, "typography-body-m-semibold")}>
                {i18n.order_summary_estimate_total_without_taxes()}
            </div>
            <div className={clsx(styles.priceEstimateAmount, "typography-heading-s-semibold")}>
                {isQuotationLoading ? (
                    <Loader />
                ) : hasValidValue ? (
                    parseFloat(quotationTotal).toFixed(2) + "€"
                ) : (
                    "-"
                )}
            </div>
            <Button
                onClick={() => {
                    trackEvent({
                        name: "jobposting - estimation clicked",
                        params: {
                            taskId,
                            step,
                        },
                    });
                    setPriceEstimateTooltipVisible(!priceEstimateTooltipVisible);
                }}
                icon={<Info className='h-5 w-5' />}
                shape='outlined'
                intention='secondary'
                disabled={isQuotationLoading || !hasValidValue}
            />
        </div>
    );
}
