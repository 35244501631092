import { useState } from "react";
import { differenceInMinutes, format } from "date-fns";
import { Info } from "lucide-react";
import { Overlay, SlidingWrapper } from "side-ui";
import { Tooltip, TooltipContent, TooltipTrigger } from "sui";

import { i18n } from "@lib/i18n";
import { getLocale } from "@lib/utils/getLocale";

import { ATT_VALIDATED, ATT_WAITING } from "../../../utils";
import { convertMinutesToHours } from "../../../utils/dates";

import EditCell from "./TimesheetsTableCells/EditCell";
import TimesheetsTablePanel from "./TimesheetsTablePanel/TimesheetsTablePanel";
import {
    BreakCell,
    CheckboxCell,
    ConflictCell,
    DurationCell,
    FeedbackCell,
    SiderCell,
    SlotCell,
    StatusCell,
} from "./TimesheetsTableCells";

const TimesheetsTableNestedRow = ({
    id,
    status,
    history,
    sider,
    task,
    slotConflict,
    breakConflict,
    breakSumInMinutes,
    selectAttendance,
    selectedAttendances,
    taskName,
    siderDidntWork,
    setSiderDidntWork,
    disputeAttendance,
    feedback = null,
    companyName,
    shiftId,
    readOnly,
}) => {
    const [displayPanel, setDisplayPanel] = useState(false);
    const currentVersion = history[history.length - 1];
    const isFromExternalFile = history[1].origin === "company";
    const slotNetDuration = convertMinutesToHours(
        Math.abs(
            differenceInMinutes(
                new Date(currentVersion.endDate),
                new Date(currentVersion.startDate),
            ) - breakSumInMinutes,
        ),
    )
        .replace(/\s?minutes?/, "")
        .replace(" ", "");
    const locale = getLocale();

    return (
        <>
            <div className='timesheets-table__cell__inner-wrapper'>
                <div className='timesheets-table__cell__inner-wrapper__siderstatus'>
                    <StatusCell
                        status={status}
                        validation={
                            status === ATT_VALIDATED
                                ? history.find((h) => {
                                      if (h.actions) {
                                          return h.actions.find((a) => a.type === "validation");
                                      }

                                      return h;
                                  })
                                : null
                        }
                    />
                    <div className='timesheets-table__cell__inner-wrapper__siderstatus__sider-details'>
                        <SiderCell
                            pictureUrl={sider?.pictureUrl}
                            lastName={sider?.lastName}
                            firstName={sider?.firstName}
                        />

                        <FeedbackCell sider={sider} task={task} feedback={feedback} />
                    </div>
                </div>

                <div className='timesheets-table__cell__slot--withcaption'>
                    <p className='timesheets-table__cell--mobile'>
                        {i18n.ts_table_head_sider_hours()}
                    </p>
                    <SlotCell
                        startDate={currentVersion?.startDate}
                        endDate={currentVersion?.endDate}
                        conflict={slotConflict}
                        siderDidntWork={siderDidntWork}
                    />
                </div>
                <div className='timesheets-table__cell__break--withcaption'>
                    <p className='timesheets-table__cell--mobile'>
                        {i18n.ts_table_head_sider_breaks()}
                    </p>
                    <BreakCell
                        breakSum={breakSumInMinutes}
                        conflict={breakConflict}
                        siderDidntWork={siderDidntWork}
                    />
                </div>
                <div className='timesheets-table__cell__duration--withcaption'>
                    <p className='timesheets-table__cell--mobile'>
                        {i18n.ts_table_head_duration()}
                    </p>
                    <DurationCell
                        duration={slotNetDuration}
                        conflict={breakConflict || slotConflict?.startDate || slotConflict?.endDate}
                        siderDidntWork={siderDidntWork}
                    />
                </div>

                <div className='timesheets-table__cell__actions'>
                    {status === ATT_WAITING && !isFromExternalFile ? (
                        <EditCell setDisplayPanel={setDisplayPanel} />
                    ) : !siderDidntWork ? (
                        <ConflictCell
                            status={status}
                            siderName={`${sider?.firstName} ${sider?.lastName}`}
                            currentVersion={currentVersion}
                        />
                    ) : null}

                    {status === ATT_WAITING && history[1].origin !== "company" ? (
                        <CheckboxCell
                            selectAttendance={selectAttendance}
                            id={id}
                            checked={selectedAttendances.includes(id)}
                        />
                    ) : null}
                </div>
                {isFromExternalFile ? (
                    <div className='timesheets-table__cell__external-file'>
                        <Tooltip enabled={isFromExternalFile} placement='bottom-start'>
                            <TooltipTrigger>
                                <Info className='h-4 w-4 !fill-none text-gray-300' />
                            </TooltipTrigger>
                            <TooltipContent>
                                {i18n.ts_tag_event_log({
                                    date: format(new Date(history[0].updatedAt), "P", {
                                        locale,
                                    }),
                                    time: format(new Date(history[0].updatedAt), "HH:mm", {
                                        locale,
                                    }),
                                })}
                            </TooltipContent>
                        </Tooltip>
                    </div>
                ) : null}
            </div>
            <>
                {/* micro opti => do not render components if not on waiting status */}
                {status === ATT_WAITING && displayPanel && history[1].origin !== "company" && (
                    <Overlay
                        isVisible={displayPanel}
                        toggleVisibility={() => {
                            setDisplayPanel(false);
                            if (selectedAttendances.includes(id)) {
                                // unselect attendance
                                selectAttendance(id);
                            }
                        }}
                    />
                )}

                {status === ATT_WAITING ? (
                    <SlidingWrapper isVisible={displayPanel} position='right'>
                        {displayPanel ? (
                            <TimesheetsTablePanel
                                siderName={`${sider?.firstName} ${sider?.lastName}`}
                                history={history}
                                attendanceId={id}
                                closePanel={() => {
                                    setDisplayPanel(false);
                                    if (selectedAttendances.includes(id)) {
                                        // unselect attendance
                                        selectAttendance(id);
                                    }
                                }}
                                taskName={taskName}
                                status={status}
                                slotDuration={slotNetDuration}
                                slotConflict={slotConflict}
                                breakConflict={breakConflict}
                                selectAttendance={selectAttendance}
                                setSiderDidntWork={setSiderDidntWork}
                                disputeAttendance={disputeAttendance}
                                // trackers prop
                                companyName={companyName}
                                shiftId={shiftId}
                                // end trackers prop
                                readOnly={readOnly}
                            />
                        ) : null}
                    </SlidingWrapper>
                ) : null}
            </>
        </>
    );
};

export default TimesheetsTableNestedRow;
