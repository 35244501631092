import { X } from "lucide-react";
import { Tooltip, TooltipContent, TooltipTrigger } from "sui";

import "./FormCard.scss";

const FormCard = ({ title, children, tooltipLabel, iconAction }) => (
    <div className='form-card'>
        {iconAction && tooltipLabel && (
            <div className='form-card__icon' onClick={iconAction}>
                <Tooltip>
                    <TooltipTrigger>
                        <X className='h-4 w-4 text-blue-500' />
                    </TooltipTrigger>
                    <TooltipContent>{tooltipLabel}</TooltipContent>
                </Tooltip>
            </div>
        )}
        {iconAction && !tooltipLabel && (
            <div className='form-card__icon' onClick={iconAction}>
                <X className='h-4 w-4 text-blue-500' />
            </div>
        )}
        {title && <div className='form-card__title'>{title}</div>}
        {children}
    </div>
);

export default FormCard;
