import { setDefaultOptions } from "date-fns";
import { createRoot } from "react-dom/client";

import { getLocale } from "@lib/utils/getLocale";

import initTranslations from "./config/i18n";
import createStore from "./store/createStore";
import App from "./AppContainer";

import "./styles/core.scss";

initTranslations();

setDefaultOptions({ locale: getLocale(), weekStartsOn: 1, firstWeekContainsDate: 4 });

export const store = createStore();

const container = document.getElementById("root");
const root = createRoot(container);
root.render(<App store={store} />);
