// deactivating flow as it's too ancient to manage useState, we need to update flow
import { useEffect, useRef, useState } from "react";
import { Building2, Pencil } from "lucide-react";
import { Button, SelectableSearch } from "side-ui";
import { Tooltip, TooltipContent, TooltipTrigger } from "sui";

import { queryClient } from "@App";
import * as backend from "@lib/api";
import { patchOrganisationProfile } from "@lib/api/patchOrganisationProfile";
import { uploadOrganisationPicture } from "@lib/api/uploadOrganisationPicture";
import { i18n } from "@lib/i18n";
import { queries } from "@lib/queries";
import { handleError } from "@lib/utils/error";
import { debug } from "@side.co/client-debug";
import { useQuery } from "@tanstack/react-query";
import { useNavigate } from "@tanstack/react-router";

import "./Home.scss";

const Home = () => {
    const navigate = useNavigate();
    const [selectedChildToConnect, setSelectedChildToConnect] = useState();
    const [children, setChildren] = useState([{}]);
    const [errorLoadingImg, setErrorLoadingImg] = useState(false);

    const { data: companyInfoData } = useQuery(queries.company.detail());
    const groupLogo = companyInfoData?.organisation?.logoUrl;
    const groupName = companyInfoData?.parent || companyInfoData?.organisation.name;

    useEffect(() => {
        if (localStorage.getItem(`side_team_activeOrganisationId`)) {
            localStorage.removeItem(`side_team_activeOrganisationId`);
            queryClient.invalidateQueries(queries.company.detail());
        }
    }, []);

    const connectToChild = () => {
        localStorage.setItem(`side_team_activeOrganisationId`, selectedChildToConnect.objectID);
        queryClient.invalidateQueries(queries.company.detail());
        navigate({ to: `/planning` }).then(() => {
            window.location.reload();
        });
    };

    const normalizeChildren = (data) => {
        let normalizedChildren = [];
        if (data?.Organisations.length > 0) {
            normalizedChildren = data.Organisations.map((child) => ({
                objectID: child.id,
                sublabel: child.address,
                label: child.name,
                pictureUrl: child.pictureURL,
            }));
        }
        return normalizedChildren;
    };

    async function getChildren(searchFieldValue) {
        let res;
        try {
            res = await backend.searchOrganisation({
                q: searchFieldValue,
            });
        } catch (e) {
            handleError(e.toString());
        }
        if (res) {
            if (res.status >= 400) {
                debug.log(`API call error: ${res.statusText}`, {
                    level: `warning`,
                    extra: {
                        res: JSON.stringify(res),
                    },
                });
                handleError(res.statusText);
                return;
            }

            const json = await res.json();
            const normalizedData = normalizeChildren(json);
            if (json) {
                setChildren(normalizedData);
            }
        }
    }

    const [logoURL, setLogoURL] = useState("");
    const logoUploadRef = useRef();

    useEffect(() => {
        setLogoURL(groupLogo);
    }, [groupLogo]);

    function handleFileSelection(e) {
        if (!e) {
            return;
        }
        if (!e.target.files || e.target.files.length === 0 || !e.target.files[0]) {
            return;
        }

        const data = new FormData();
        data.append("picture", e.target.files[0]);

        uploadOrganisationPicture(data, "logo").then((res) => {
            if (res) {
                patchOrganisationProfile({
                    logoURL: res.URL,
                });
                // reloading the page so that the logo is updated everywhere with the store (vertical menu etc...)
                window.location.reload();
            }
            return;
        });
    }

    const imgSuccessfullyLoaded = !errorLoadingImg && groupLogo && groupLogo.startsWith("https://");

    return (
        <div className='home'>
            {
                <div
                    className={
                        imgSuccessfullyLoaded
                            ? "home__company-logo"
                            : "home__company-logo__placeholder"
                    }
                >
                    {imgSuccessfullyLoaded ? (
                        <img
                            alt={`${groupName} logo`}
                            src={logoURL}
                            onError={() => setErrorLoadingImg(true)}
                        />
                    ) : (
                        <Building2 className='!fill-none' />
                    )}
                    <div className='home__company-logo__edit-container'>
                        <div className='home__company-logo__overlay'>
                            <Tooltip placement='right'>
                                <TooltipTrigger>
                                    <button
                                        type='button'
                                        className='home__company-logo__button'
                                        onClick={() => {
                                            if (!logoUploadRef.current) {
                                                return;
                                            }
                                            logoUploadRef.current.click();
                                        }}
                                    >
                                        <Pencil className='h-4 w-4 !fill-none text-white' />
                                    </button>
                                </TooltipTrigger>
                                <TooltipContent type='action'>
                                    {i18n.home_company_logo_button_tooltip()}
                                </TooltipContent>
                            </Tooltip>
                        </div>
                    </div>
                    <input
                        ref={logoUploadRef}
                        type='file'
                        id='upload-logo'
                        accept='image/png, image/jpeg, image/webp'
                        className='home__company-logo__upload'
                        onChange={(e) => handleFileSelection(e)}
                    />
                </div>
            }
            <div className='home__welcome'>
                <p className='home__welcome__title'>{i18n.home_welcome_title({ groupName })}</p>
                <p className='home__welcome__subtitle'>{i18n.home_welcome_subtitle()}</p>
            </div>
            <div className='home__input'>
                <SelectableSearch
                    label={i18n.home_search_child_label()}
                    searchAsYouType={true}
                    options={children}
                    placeholder={i18n.home_search_child_placeholder()}
                    value={selectedChildToConnect ? selectedChildToConnect.label : ""}
                    onChange={(value) => getChildren(value)}
                    onOptionClick={(option) => setSelectedChildToConnect(option)}
                />
            </div>
            <div className='home__actions'>
                <Button
                    color='blue'
                    iconAfter='ArrowRight'
                    disabled={!selectedChildToConnect}
                    action={() => connectToChild()}
                >
                    {i18n.home_connecttochild_button()}
                </Button>
            </div>
        </div>
    );
};

export default Home;
