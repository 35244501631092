import { useEffect, useRef, useState } from "react";
import clsx from "clsx";
import { Trash2 } from "lucide-react";
import { Button } from "saphir";
import {
    Breadcrumb,
    BreadcrumbItem,
    Label,
    Panel,
    PanelCloseButton,
    PanelContent,
    PanelFooter,
    PanelHeader,
    PanelSubtitle,
    PanelTitle,
    Tip,
    Toggle,
    Tooltip,
    TooltipContent,
    TooltipTrigger,
} from "sui";

import { useCheckIfManagerExists } from "@lib/hooks/useCheckIfManagerExists";
import { useCompanyManagers } from "@lib/hooks/useCompanyManagers";
import { i18n, i18nDK } from "@lib/i18n";
import { queries } from "@lib/queries";
import {
    normalizeJobDescription,
    normalizeJobDescriptions,
} from "@lib/queries/normalizeJobDescriptions";
import { useJobDescriptions } from "@routes/JobDescriptions/hooks/useJobDescriptions";
import { useQuery, useQueryClient } from "@tanstack/react-query";

import { DeleteJobDescriptionModal } from "../DeleteJobDescriptionModal";
import { JobDescriptionContext } from "../JobDescriptionContext";
import { JobDescriptionCreation } from "../JobDescriptionCreation";
import { JobDescriptionEdit } from "../JobDescriptionEdit/JobDescriptionEdit";
import { JobDescriptionSummary } from "../JobDescriptionSummary/JobDescriptionSummary";
import { OrganisationCompletionStatusModal } from "../OrganisationCompletionStatusModal/OrganisationCompletionStatusModal";
import { TaskPreliminaryDetailsModal } from "../TaskPreliminaryDetailsModal/TaskPreliminaryDetailsModal";

import styles from "./JobDescriptionPanel.module.css";

const steps = ["job", "work-conditions", "skills", "location", "salary"] as const;

const getInitialStepsState = () =>
    steps.map((step, index) => ({
        label: i18nDK(
            `job_descriptions_creation_${
                step === "work-conditions" ? "conditions" : step
            }_step_title`,
        ),
        value: step,
        active: index === 0,
        enabled: index === 0,
    }));

type JobDescriptionPanelProps = {
    mode: "create" | "edit" | "summary" | "";
    saveAndSwitchToSummary: (mode: "create" | "edit") => void;
    showJobDescriptionEdit: (jobDescription: ReturnType<typeof normalizeJobDescription>) => void;
    duplicateJobDescription: (jobDescription: ReturnType<typeof normalizeJobDescription>) => void;
    organisationType: string;
    unauthorized: boolean;
    unauthorizedTitle: string;
    unauthorizedDescription: string;
};

export const JobDescriptionPanel = ({
    mode,
    saveAndSwitchToSummary,
    showJobDescriptionEdit,
    duplicateJobDescription,
    organisationType,
    unauthorized,
    unauthorizedTitle,
    unauthorizedDescription,
}: JobDescriptionPanelProps) => {
    const [disableSubmit, setDisableSubmit] = useState(false);
    const [dataToUpdate, setDataToUpdate] = useState<Record<string, any> | undefined>();
    const [formToEdit, setFormToEdit] = useState("");
    const [fieldToEdit, setFieldToEdit] = useState(null);
    const [stepsState, setStepsState] = useState(getInitialStepsState());

    const panelContentRef = useRef<HTMLDivElement>(null);
    const TaskPreliminaryDetailsModalRef = useRef<HTMLDialogElement>(null);
    const organisationCompletionStatusModalRef = useRef<HTMLDialogElement>(null);
    const deleteJobDescriptionModalRef = useRef<HTMLDialogElement>(null);

    const {
        setJobDescription,
        jobDescription,
        updateJobDescriptionMutation,
        deleteJobDescriptionMutation,
        isDeletePending,
    } = useJobDescriptions(deleteJobDescriptionModalRef);

    const companyManagers = useCompanyManagers();
    const checkIfManagerExists = useCheckIfManagerExists();
    const managerExists =
        dataToUpdate?.managerId || jobDescription?.managerId
            ? checkIfManagerExists(dataToUpdate?.managerId || jobDescription?.managerId)
            : true;

    const { data: organisationCompletionStatus } = useQuery(queries.company.completionStatus());

    const activeStep = stepsState.find(({ active }) => active) || stepsState[0];
    const hasSubtasks = !!jobDescription?.job?.subtasks?.length;
    const hasLocation = !!jobDescription?.location;
    const jobDescriptionName = `${jobDescription?.job?.jobTitle?.name}${
        jobDescription?.suffix ? ` - ${jobDescription?.suffix}` : ""
    }`;

    useEffect(() => {
        if (jobDescription?.id) {
            setStepsState(getInitialStepsState());
            setDataToUpdate(undefined);
            if (jobDescription?.job?.subtasks?.length && jobDescription?.job?.jobTitle?.id) {
                setDisableSubmit(false);
            } else {
                setDisableSubmit(true);
            }
        }
    }, [jobDescription?.id]);

    const adaptJobDescription = () => adaptJobDescriptionForBackend(jobDescription, dataToUpdate);

    const setActiveStep = (routeIndex: number) => {
        setStepsState((prevState) =>
            prevState.map((route, index) => {
                if (routeIndex === index) {
                    return {
                        ...route,
                        active: true,
                        enabled: true,
                    };
                }
                return {
                    ...route,
                    active: false,
                    enabled:
                        (route.value !== "salary" && (hasSubtasks || hasLocation)) ||
                        (route.value === "salary" && hasSubtasks && hasLocation),
                };
            }),
        );
    };

    const goToNextStep = () => {
        if (activeStep.value === "salary") {
            saveAndSwitchToSummary("create");
        }
        const nextStepIndex = steps.findIndex((step) => step === activeStep.value) + 1;
        setActiveStep(nextStepIndex);
    };

    const handleValidation = () => {
        if (mode === "create") {
            updateJobDescriptionMutation(adaptJobDescription());
            goToNextStep();
        }

        if (mode === "edit") {
            updateJobDescriptionMutation(adaptJobDescription());
            saveAndSwitchToSummary("edit");
        }
        if (mode === "summary") {
            if ((organisationCompletionStatus?.incomplete ?? []).length > 0) {
                organisationCompletionStatusModalRef.current?.showModal();
            } else {
                TaskPreliminaryDetailsModalRef.current?.showModal();
            }
        }
    };

    const queryClient = useQueryClient();

    function togglePreselection() {
        updateJobDescriptionMutation({
            ...adaptJobDescription(),
            preSelection: !jobDescription?.preSelection,
        });
        queryClient.setQueryData(
            queries.jobDescriptions.list().queryKey,
            (oldData: ReturnType<typeof normalizeJobDescriptions>) => {
                return oldData.map((jobDescription) => {
                    if (jobDescription.id === jobDescription?.id) {
                        return {
                            ...jobDescription,
                            preSelection: !jobDescription?.preSelection,
                        };
                    }
                    return jobDescription;
                });
            },
        );
    }

    const handleCancelAction = () => {
        switch (mode) {
            case "summary":
                if (unauthorized) return;
                duplicateJobDescription(adaptJobDescription());
                break;
            case "create":
                if (hasSubtasks) {
                    updateJobDescriptionMutation(adaptJobDescription());
                    setJobDescription(undefined);
                } else if (activeStep.value === "job" && !hasSubtasks) {
                    deleteJobDescriptionMutation(jobDescription!.id);
                }
                break;
            case "edit":
                setJobDescription(undefined);
                break;
        }
    };

    const validationLabel = (() => {
        switch (mode) {
            case "create":
                if (activeStep?.value === "salary") {
                    return i18n.finish();
                }
                break;
            case "edit":
                return i18n.save();
            case "summary":
                return i18n.use();
        }
        return i18n.label_continue();
    })();

    const cancelLabel = (() => {
        switch (mode) {
            case "create":
                if (activeStep?.value === "job" && !hasSubtasks) {
                    return i18n.cancel();
                }
                break;
            case "edit":
                return i18n.cancel();
            case "summary":
                if (unauthorized) return null;
                return i18n.duplicate();
        }
        return i18n.save_quit();
    })();

    const titleLabel = (() => {
        if (mode === "summary" && jobDescription?.job.jobTitle) {
            return jobDescriptionName;
        }
        if (mode === "edit" && formToEdit) {
            return i18nDK(`job_descriptions_summary_${formToEdit}_title`);
        }
        return i18n.job_descriptions_new_title();
    })();

    const subtitleLabel = (() => {
        if (mode === "create") {
            return (
                <Breadcrumb>
                    {stepsState.map((step, index) => (
                        <BreadcrumbItem
                            key={step.value}
                            onClick={() => setActiveStep(index)}
                            active={step.active}
                            disabled={!step.enabled}
                        >
                            {step.label}
                        </BreadcrumbItem>
                    ))}
                </Breadcrumb>
            );
        }
        if (mode === "edit" && formToEdit) {
            return i18nDK(`job_descriptions_edit_${formToEdit}_subtitle`);
        }
        return "";
    })();

    const isLogas = Boolean(localStorage.getItem("side_team_logasId"));

    return (
        <>
            <JobDescriptionContext.Provider value={{ setDataToUpdate, panelContentRef }}>
                <Panel open={!!jobDescription} className={styles.panel}>
                    <PanelHeader className={clsx(styles.header, "panel-header")}>
                        <PanelTitle>
                            {titleLabel}
                            <PanelCloseButton
                                onClick={() =>
                                    mode === "summary" ? setJobDescription() : handleCancelAction()
                                }
                            />
                        </PanelTitle>
                        <PanelSubtitle>{subtitleLabel}</PanelSubtitle>
                    </PanelHeader>
                    <PanelContent className={styles.panelContent}>
                        <div ref={panelContentRef} className={styles.content}>
                            {!managerExists && (
                                <Tip intention='warning' highlight className='mx-6 my-4'>
                                    {i18n.job_descriptions_unauthorized_manager_missing()}
                                    <br />
                                    {organisationType === "network" ||
                                    jobDescription?.origin !== "network"
                                        ? i18n.job_descriptions_unauthorized_manager_account()
                                        : i18n.job_descriptions_unauthorized_manager_network()}
                                </Tip>
                            )}
                            {/* All fields are disabled if the user is unauthorized */}
                            <div className={unauthorized ? "[&_button]:cursor-default" : ""}>
                                {mode === "summary" && jobDescription && (
                                    <>
                                        {/* only show preselection to logas and to clients it is actived for (read-only) */}
                                        {jobDescription.preSelection || isLogas ? (
                                            <Tooltip placement='top'>
                                                <TooltipTrigger>
                                                    <div
                                                        className={clsx(
                                                            styles.inlineFlex,
                                                            styles.preselectionToggle,
                                                        )}
                                                    >
                                                        <Toggle
                                                            id='preselection'
                                                            disabled={
                                                                !isLogas ||
                                                                jobDescription?.origin === "network"
                                                            }
                                                            onChange={togglePreselection}
                                                            checked={Boolean(
                                                                jobDescription.preSelection,
                                                            )}
                                                        />
                                                        <Label htmlFor='preselection'>
                                                            {i18n.job_descriptions_edit_preselection()}
                                                        </Label>
                                                    </div>
                                                </TooltipTrigger>
                                                {unauthorized && (
                                                    <TooltipContent
                                                        type='info'
                                                        intention='error'
                                                        label={unauthorizedTitle}
                                                    >
                                                        {unauthorizedDescription}
                                                    </TooltipContent>
                                                )}
                                            </Tooltip>
                                        ) : null}
                                        <JobDescriptionSummary
                                            jobDescription={jobDescription}
                                            companyManagers={companyManagers}
                                            editSection={(selectedFormToEdit) => {
                                                setFieldToEdit(selectedFormToEdit);
                                                switch (selectedFormToEdit) {
                                                    case "context":
                                                    case "safetyEquipment":
                                                    case "devices":
                                                    case "dresscode":
                                                    case "risks":
                                                        setActiveStep(
                                                            steps.findIndex(
                                                                (step) =>
                                                                    step === "work-conditions",
                                                            ),
                                                        );
                                                        break;
                                                    case "languages":
                                                    case "tools":
                                                    case "licences":
                                                    case "experiences":
                                                        setActiveStep(
                                                            steps.findIndex(
                                                                (step) => step === "skills",
                                                            ),
                                                        );
                                                        break;
                                                    case "location":
                                                    case "manager":
                                                        setActiveStep(
                                                            steps.findIndex(
                                                                (step) => step === "location",
                                                            ),
                                                        );
                                                        break;
                                                    case "salary":
                                                    case "status":
                                                        setActiveStep(
                                                            steps.findIndex(
                                                                (step) => step === "salary",
                                                            ),
                                                        );
                                                        break;
                                                    case "name":
                                                    case "jobTitle":
                                                    case "subtasks":
                                                    default:
                                                        setActiveStep(
                                                            steps.findIndex(
                                                                (step) => step === "job",
                                                            ),
                                                        );
                                                        break;
                                                }

                                                setFormToEdit(selectedFormToEdit);
                                                showJobDescriptionEdit(jobDescription);
                                            }}
                                        />
                                    </>
                                )}

                                {jobDescription?.origin !== "network" &&
                                    (mode === "create" ||
                                        (mode === "edit" && formToEdit !== "name")) && (
                                        <JobDescriptionCreation
                                            disableSubmit={setDisableSubmit}
                                            updateJobDescription={updateJobDescriptionMutation}
                                            setDataToUpdate={setDataToUpdate}
                                            jobDescription={jobDescription}
                                            activeStep={activeStep?.value}
                                            companyManagers={companyManagers}
                                            fieldToEdit={fieldToEdit}
                                        />
                                    )}
                                {jobDescription?.origin !== "network" &&
                                    mode === "edit" &&
                                    formToEdit === "name" && (
                                        <JobDescriptionEdit
                                            formToEdit={formToEdit}
                                            disableSubmit={setDisableSubmit}
                                            updateJobDescription={updateJobDescriptionMutation}
                                            setDataToUpdate={setDataToUpdate}
                                            jobDescription={jobDescription}
                                            companyManagers={companyManagers}
                                        />
                                    )}
                            </div>
                        </div>
                    </PanelContent>
                    <PanelFooter style={{ justifyContent: "space-between" }}>
                        <div>
                            {mode !== "edit" && (
                                <Tooltip placement='top'>
                                    <TooltipTrigger>
                                        <Button
                                            variant='destructive-outlined'
                                            disabled={unauthorized}
                                            onClick={() =>
                                                deleteJobDescriptionModalRef?.current?.showModal()
                                            }
                                        >
                                            {i18n.label_delete()}
                                            <Trash2 />
                                        </Button>
                                    </TooltipTrigger>
                                    {unauthorized && (
                                        <TooltipContent
                                            type='info'
                                            intention='error'
                                            label={unauthorizedTitle}
                                        >
                                            {unauthorizedDescription}
                                        </TooltipContent>
                                    )}
                                </Tooltip>
                            )}
                        </div>
                        <div className='flex'>
                            <Tooltip placement='top'>
                                <TooltipTrigger>
                                    <Button
                                        variant='primary-ghost'
                                        onClick={handleCancelAction}
                                        disabled={!managerExists && mode !== "edit"}
                                    >
                                        {cancelLabel}
                                    </Button>
                                </TooltipTrigger>
                                {!managerExists && mode !== "edit" && (
                                    <TooltipContent
                                        type='info'
                                        intention='error'
                                        label={unauthorizedTitle}
                                    >
                                        {i18n.job_descriptions_unauthorized_manager_missing()}
                                        <br />
                                        {organisationType === "network" ||
                                        jobDescription?.origin !== "network"
                                            ? i18n.job_descriptions_unauthorized_manager_account()
                                            : i18n.job_descriptions_unauthorized_manager_network()}
                                    </TooltipContent>
                                )}
                            </Tooltip>
                            {organisationType === "network" && mode === "summary" ? null : (
                                <Tooltip placement='top'>
                                    <TooltipTrigger>
                                        <Button
                                            disabled={disableSubmit || !managerExists}
                                            onClick={handleValidation}
                                        >
                                            {validationLabel}
                                        </Button>
                                    </TooltipTrigger>
                                    {!managerExists && (
                                        <TooltipContent
                                            type='info'
                                            intention='error'
                                            label={unauthorizedTitle}
                                        >
                                            {i18n.job_descriptions_unauthorized_manager_missing()}
                                            <br />
                                            {organisationType === "network" ||
                                            jobDescription?.origin !== "network"
                                                ? i18n.job_descriptions_unauthorized_manager_account()
                                                : i18n.job_descriptions_unauthorized_manager_network()}
                                        </TooltipContent>
                                    )}
                                </Tooltip>
                            )}
                        </div>
                    </PanelFooter>
                </Panel>
            </JobDescriptionContext.Provider>
            <DeleteJobDescriptionModal
                ref={deleteJobDescriptionModalRef}
                deleteJobDescription={() => deleteJobDescriptionMutation(jobDescription!.id)}
                isDeletePending={isDeletePending}
            />
            <OrganisationCompletionStatusModal
                ref={organisationCompletionStatusModalRef}
                missingDetails={organisationCompletionStatus?.incomplete}
            />
            <TaskPreliminaryDetailsModal
                ref={TaskPreliminaryDetailsModalRef}
                jobDescriptionName={jobDescriptionName}
                jobTitle={jobDescription?.job?.jobTitle?.name}
                jobDescriptionOrganisationId={jobDescription?.organisationId}
                jobDescriptionId={jobDescription?.id}
                jobDescriptionPricing={jobDescription?.pricing}
            />
        </>
    );
};

function adaptJobDescriptionForBackend(jobDescription, dataToUpdate) {
    return {
        id: jobDescription?.id,
        pricingId: jobDescription?.pricing?.id,
        suffix: jobDescription?.suffix,
        jobTitleId: jobDescription?.job?.jobTitle?.id,
        subtasks: jobDescription?.job?.subtasks,
        licenceIds: jobDescription?.skills?.licences?.map((licence) => licence.id),
        tools: jobDescription?.skills?.tools,
        riskIds: jobDescription?.workConditions?.risks?.map((risk) => risk.id),
        languageIds: jobDescription?.skills?.languages?.map((language) => language.id),
        equipment: jobDescription?.workConditions?.devices?.concat(
            jobDescription?.workConditions?.safetyEquipmentList,
        ),
        workConditions: jobDescription?.workConditions?.aboutRisks,
        dressCode: jobDescription?.workConditions?.dressCode,
        purpose: jobDescription?.workConditions?.context,
        experiences: jobDescription?.skills?.experiences,
        location: jobDescription?.location,
        hourlyRate: jobDescription?.hourlyRate,
        managerId: jobDescription?.managerId,
        executiveStatus: jobDescription?.executiveStatus,
        ...dataToUpdate,
    };
}
