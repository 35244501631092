import { forwardRef, useMemo, useState } from "react";
import clsx from "clsx";
import { ArrowRight, ChevronDown, ChevronUp, Info } from "lucide-react";
import { Button } from "saphir";
import {
    ChoiceBox,
    fonts,
    Label,
    Modal,
    ModalContent,
    ModalFooter,
    ModalHeader,
    Radio,
    Tag,
    TextField,
    TruncatedTextReveal,
} from "sui";

import { createTask, GetJobDescriptionsResponse } from "@lib/api";
import { useFormInput, useLocalStorage } from "@lib/hooks";
import { i18n, i18nDK } from "@lib/i18n";
import { queries } from "@lib/queries";
import trackEvent from "@lib/trackers";
import { router } from "@routes";
import { useMutation, useQuery } from "@tanstack/react-query";

import styles from "./TaskPreliminaryDetailsModal.module.css";

type TaskPreliminaryDetailsModalProps = {
    jobDescriptionName?: string;
    jobTitle?: string;
    jobDescriptionOrganisationId?: string;
    jobDescriptionId?: string;
    jobDescriptionPricing?: GetJobDescriptionsResponse[number]["pricing"];
};

const taskTypes = ["delegation", "gestion"] as const;
function getTaskTypeOptions() {
    return [...taskTypes].map(
        (type) =>
            ({
                label: i18nDK(`order_preliminary_details_${type}_label`),
                description: i18nDK(`order_preliminary_details_${type}_description`),
                value: type,
                color: type === "gestion" ? "purple" : "blue",
            }) as const,
    );
}

function formatPricing(
    pricing: {
        hourlyRate: number;
        hourType: string;
        coefficient: number;
        increaseRate?: number;
    }[],
    taskType: "delegation" | "gestion",
) {
    if (!pricing) return;
    const reducedPricing = pricing
        ?.sort((g1, g2) => g1.hourlyRate - g2.hourlyRate)
        .reduce(
            (acc, p) => {
                const key = taskType === "delegation" ? p.increaseRate : p.coefficient;
                const value = {
                    hourType: p.hourType,
                    increaseRate: p.increaseRate
                        ? `+${+((p.increaseRate - 1) * 100).toFixed(2)}%`
                        : "0%",
                    coefficient: p.coefficient.toFixed(2),
                    hourlyRate: `${p.hourlyRate.toFixed(2)}€/h`,
                };
                if (key && acc[key]) {
                    acc[key].push(value);
                } else if (key) {
                    acc[key] = [value];
                }
                return acc;
            },
            {} as Record<
                string,
                {
                    hourType: string;
                    increaseRate: string;
                    coefficient: string;
                    hourlyRate: string;
                }[]
            >,
        );

    const formattedPricing = Object.entries(reducedPricing)
        .sort((ir1, ir2) => Number(ir1[0]) - Number(ir2[0]))
        .map(([, values]) => values) as {
        hourType: string;
        increaseRate: string;
        coefficient: string;
        hourlyRate: string;
    }[][];
    return formattedPricing;
}

const pricingColumns = ["hourType", "increaseRate", "coefficient", "hourlyRate"] as const;
export const TaskPreliminaryDetailsModal = forwardRef<
    HTMLDialogElement,
    TaskPreliminaryDetailsModalProps
>(
    (
        {
            jobDescriptionName,
            jobTitle,
            jobDescriptionOrganisationId,
            jobDescriptionId,
            jobDescriptionPricing,
        },
        ref,
    ) => {
        const taskTypeOptions = useMemo(() => getTaskTypeOptions(), []);
        const [taskType, setTaskType] = useState<(typeof taskTypes)[number]>("delegation");
        const { formInput: taskSuffix, handleChange: setTaskSuffix } = useFormInput("", () => null);
        const [organisationId] = useLocalStorage("side_team_activeOrganisationId");
        const { mutate: createTaskMutation } = useMutation({
            mutationFn: createTask,
            onSuccess: (data) => {
                trackEvent({
                    name: "job-descriptions - use job description",
                    params: {
                        source:
                            organisationId !== jobDescriptionOrganisationId ? "network" : "account",
                        organisationId,
                        jobTitle,
                    },
                });
                router.navigate({
                    to: "/taskPosting/$taskId/motive",
                    params: {
                        taskId: data.id,
                    },
                });
            },
        });
        const { data: company } = useQuery(queries.company.detail());

        function submitNewTask() {
            if (!taskType) return;
            const taskDetails = {
                type: taskType,
                name: `${
                    taskSuffix?.value
                        ? `${jobDescriptionName} - ${taskSuffix?.value}`
                        : jobDescriptionName
                }`,
                attendanceConfigType: company!.organisation!.attendanceConfigType,
            };
            createTaskMutation({ jobDescriptionId: jobDescriptionId || "", taskDetails });
        }

        function sendForm(event) {
            if (event.key === `Enter`) {
                event.preventDefault();
                submitNewTask();
            }
        }

        function onClose() {
            if (ref && "current" in ref) {
                setTaskType("delegation");
                setTaskSuffix("");
                ref?.current?.close();
            }
        }

        if (
            !ref ||
            !("current" in ref) ||
            (!jobDescriptionPricing?.delegation && !jobDescriptionPricing?.gestion)
        )
            return null;

        return (
            <Modal ref={ref} onClose={onClose}>
                <ModalHeader title={i18n.order_preliminary_details_modal_title()} />
                <ModalContent>
                    <Label
                        className={styles.label}
                        icon={<Info className='h-4 w-4 text-gray-300' />}
                        tooltip={i18n.order_preliminary_details_taskname_tip()}
                    >
                        {i18n.order_preliminary_details_taskname_label()}{" "}
                        <span className={clsx(styles.optional, fonts.sans18Regular)}>
                            ({i18n.order_preliminary_details_taskname_optional()})
                        </span>
                    </Label>
                    <TextField
                        className={styles.textField}
                        aria-label={i18n.order_preliminary_details_taskname_label()}
                        placeholder={i18n.order_preliminary_details_taskname_placeholder()}
                        onKeyDown={(event) => taskType && sendForm(event)}
                        error={taskSuffix.touched ? taskSuffix.error! : ""}
                        onChange={(event) => setTaskSuffix(event.target.value)}
                        value={taskSuffix.value}
                        size='small'
                    ></TextField>
                    <TruncatedTextReveal
                        className={styles.suffix}
                    >{`${jobDescriptionName} - ${taskSuffix.value}`}</TruncatedTextReveal>
                    <Label className={styles.label}>
                        {i18n.order_preliminary_details_tasktype_label()}
                    </Label>
                    <div className={styles.options}>
                        {taskTypeOptions.map((option, i) => (
                            <ChoiceBox
                                key={i}
                                className={styles.choiceBox}
                                label={
                                    <div className={styles.radioTitle}>
                                        {option.label}
                                        <Tag color={option.color}>{option.value}</Tag>
                                    </div>
                                }
                                description={
                                    <>
                                        <p className={styles.description}>{option.description}</p>
                                        <Accordion
                                            content={
                                                <div className={styles.pricingTable}>
                                                    <div
                                                        className={clsx(
                                                            styles.pricingHead,
                                                            fonts.sans16Regular,
                                                            taskType && styles[taskType],
                                                        )}
                                                    >
                                                        {pricingColumns.map((column, c) => (
                                                            <div key={c}>
                                                                {i18nDK(
                                                                    `order_preliminary_details_${column.toLowerCase()}_column`,
                                                                )}
                                                            </div>
                                                        ))}
                                                    </div>
                                                    <ul
                                                        className={clsx(
                                                            styles.pricingGroups,
                                                            fonts.sans18Regular,
                                                        )}
                                                    >
                                                        {formatPricing(
                                                            jobDescriptionPricing[option.value],
                                                            option.value,
                                                        )?.map((pricingGroup, g) => (
                                                            <li
                                                                className={styles.pricingGroup}
                                                                key={g}
                                                            >
                                                                <ul>
                                                                    {pricingGroup?.map(
                                                                        (pricingRow, r) => (
                                                                            <li
                                                                                className={clsx(
                                                                                    styles.pricingRow,
                                                                                )}
                                                                                key={r}
                                                                            >
                                                                                {pricingColumns.map(
                                                                                    (column, c) => (
                                                                                        <TruncatedTextReveal
                                                                                            key={c}
                                                                                        >
                                                                                            {
                                                                                                pricingRow[
                                                                                                    column
                                                                                                ]
                                                                                            }
                                                                                        </TruncatedTextReveal>
                                                                                    ),
                                                                                )}
                                                                            </li>
                                                                        ),
                                                                    )}
                                                                </ul>
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </div>
                                            }
                                        />
                                    </>
                                }
                                selectionControl={
                                    <Radio
                                        name='taskType'
                                        value={option.value}
                                        checked={taskType === option.value}
                                        onChange={() => setTaskType(option.value)}
                                    />
                                }
                            />
                        ))}
                    </div>
                </ModalContent>
                <ModalFooter
                    mainButton={
                        <Button disabled={taskType?.length === 0} onClick={submitNewTask}>
                            {i18n.order_preliminary_details_validate_label()}
                            {/* !-mx-1 is a necessary workaround because SVGTrim doesn't work properly in the dialog element scope */}
                            <ArrowRight className='!-mx-1' />
                        </Button>
                    }
                    cancelButtonLabel={i18n.order_preliminary_details_cancel_label()}
                />
            </Modal>
        );
    },
);

function Accordion({ content }: { content: React.ReactNode }) {
    const [open, setOpen] = useState(false);
    return (
        <>
            <div
                className={styles.toggleHead}
                onClick={(e) => {
                    e.stopPropagation();
                    setOpen((prev) => !prev);
                }}
            >
                {i18n.order_preliminary_details_pricing_table_title()}{" "}
                {open ? <ChevronUp className='h-4 w-4' /> : <ChevronDown className='h-4 w-4' />}
            </div>
            {open ? content : null}
        </>
    );
}
