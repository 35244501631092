import fetch from "isomorphic-fetch";
import { z } from "zod";

import { handleError } from "@lib/utils/error";
import { objectToQueryParams } from "@lib/utils/objectToQueryParams";
import { debug } from "@side.co/client-debug";

import { withAuthHeaders } from "./apiHelpers";
import { apiConf } from "./index";

const AssignmentSchema = z.object({
    name: z.string(),
    quantity: z.string(),
    hourlyRate: z.string(),
    price: z.string(),
    rate: z.string(),
    tags: z.array(z.string()),
});

const GetFlexiblePlanningQuotationResponseSchema = z.object({
    salary: z.string(),
    price: z.string(),
    assignments: z.array(AssignmentSchema).nullable(),
});

const FlexiblePlanningSchema = z.object({
    startDate: z
        .date()
        .transform((val) =>
            new Date(
                Date.UTC(val.getFullYear(), val.getMonth(), val.getDate(), 0, 0, 0, 0),
            ).toISOString(),
        ),
    endDate: z
        .date()
        .transform((val) =>
            new Date(
                Date.UTC(val.getFullYear(), val.getMonth(), val.getDate(), 0, 0, 0, 0),
            ).toISOString(),
        ),
    weeklyVolume: z.number().transform((val) => val.toString()),
    siders: z.number().transform((val) => val.toString()),
    amplitudeStart: z.string().min(5),
    amplitudeEnd: z.string().min(5),
    include: z
        .object({
            holidays: z.boolean().transform((val) => val.toString()),
            weekDays: z.boolean().transform((val) => val.toString()),
            saturday: z.boolean().transform((val) => val.toString()),
            sunday: z.boolean().transform((val) => val.toString()),
        })
        .partial(),
});
export const GetFlexiblePlanningQuotationParamsSchema = z.object({
    taskId: z.string(),
    config: FlexiblePlanningSchema,
});
export type GetFlexiblePlanningQuotationParams = z.infer<
    typeof GetFlexiblePlanningQuotationParamsSchema
>;
export async function getFlexiblePlanningQuotation(
    params: z.infer<typeof GetFlexiblePlanningQuotationParamsSchema>,
) {
    debug.addBreadcrumb({
        message: `getFlexiblePlanningQuotation`,
        category: `action`,
        data: {
            taskId: params?.taskId,
        },
    });

    const { config } = GetFlexiblePlanningQuotationParamsSchema.parse(params);

    if (!config) {
        debug.error(`getFlexiblePlanningQuotation: Invalid params`);
        return Promise.reject(new Error("GetFlexiblePlanningQuotation"));
    }

    const qs = objectToQueryParams(config);

    try {
        const resp = await fetch(
            withAuthHeaders(
                `${apiConf.BACK_URL}/tasks/${params?.taskId}/flexible-planning/quotation?${qs}`,
                {
                    method: `GET`,
                },
            ),
        );

        if (resp.status >= 400) {
            handleError(await resp.json(), resp.status);
            return Promise.reject(new Error("GetFlexiblePlanningQuotation"));
        }

        return GetFlexiblePlanningQuotationResponseSchema.parse(await resp.json());
    } catch (e) {
        debug.catch(e);
        handleError(e, 500);
        return Promise.reject(new Error("GetFlexiblePlanningQuotation"));
    }
}
