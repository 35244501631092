import { Lock, Pencil } from "lucide-react";
import { Tooltip, TooltipContent, TooltipTrigger } from "sui";

import "./RemunerationTableSection.scss";

const RemunerationTableSection = ({ title, items, action, tooltipContent, locked }) => (
    <div
        className={`remuneration-table__section ${
            locked ? `remuneration-table__section--locked` : ``
        }`}
    >
        <div className='remuneration-table__tr'>
            <RemunerationTableItem content={title} />
        </div>
        {items.map((item) => (
            <RemunerationTableItem key={item.key} {...item} />
        ))}
        <div className='remuneration-table__section__icon' onClick={action}>
            {locked ? (
                <Lock className='h-4 w-4 text-gray-300' />
            ) : (
                <Tooltip placement='top'>
                    <TooltipTrigger>
                        <Pencil className='h-4 w-4 text-gray-300' />
                    </TooltipTrigger>
                    <TooltipContent type='action'>{tooltipContent}</TooltipContent>
                </Tooltip>
            )}
        </div>
    </div>
);

const RemunerationTableItem = ({ content, value }) => (
    <div className='remuneration-table__tr'>
        {typeof content === `string` ? (
            <div className='remuneration-table__td' dangerouslySetInnerHTML={{ __html: content }} />
        ) : (
            <div className='remuneration-table__td'>{content}</div>
        )}
        {value && <div className='remuneration-table__td'>{value}</div>}
    </div>
);

export default RemunerationTableSection;
