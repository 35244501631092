import { useEffect, useState } from "react";
import clsx from "clsx";
import { Pencil, Plus, X } from "lucide-react";
import { Button } from "saphir";
import { Chip, ModalSimple, Overlay, SectionHeader, Select, SlidingWrapper } from "side-ui";

import { i18n, i18nDK } from "@lib/i18n";
import { queries } from "@lib/queries";
import { normalizeJobDescription, normalizeTools } from "@lib/queries/normalizeJobDescriptions";
import { useQuery } from "@tanstack/react-query";

import { keyPressHandler } from "../../../utils";

import { CustomTool } from "./CustomTool";
import { EditTools } from "./EditTools";

import styles from "../../FormWithSlidingWrapper.module.css";

type Props = {
    currentTools: ReturnType<typeof normalizeJobDescription>["skills"]["tools"];
    submitTools: (selectedTool: any) => void;
};
export function ToolsForm({ submitTools, currentTools = [] }: Props) {
    const [displayPanel, setDisplayPanel] = useState(false);
    const [currentTool, setCurrentTool] = useState<any>(null);
    const [selectedTools, setSelectedTools] = useState(currentTools);
    const [displayModal, setDisplayModal] = useState(false);
    const [displayRecapModal, setDisplayRecapModal] = useState(false);
    const [displayCustomModal, setDisplayCustomModal] = useState(false);

    const { data: tools = [] } = useQuery({
        ...queries.jobDescriptionOptions.list(),
        select: (data) => normalizeTools(data?.tools),
    });

    useEffect(() => {
        // re-render when currentTools ref has changed to display changes
        // meaning when tools have been submitted to SkillsStep
        if (currentTools) {
            setSelectedTools(currentTools);
        }
    }, [currentTools]);

    function selectTool(t) {
        const tool = selectedTools.find(({ id }) => id === t.id);
        // remove it if it already exists
        if (tool) {
            return setSelectedTools((prevState) => prevState.filter((to) => to.id !== tool.id));
        }

        return setSelectedTools([]);
    }

    const excelFeedback = i18n
        .job_descriptions_creation_skills_tools_modal_excel_feedback()
        .split("- ")
        .filter((e) => e);

    return (
        <div className={clsx(styles.section, "tools")}>
            <div className={styles.actions}>
                {!selectedTools?.length ? (
                    <p className={styles.title}>
                        {i18n.job_descriptions_creation_skills_tools_title()}
                    </p>
                ) : (
                    <div
                        className={styles.edit}
                        role='button'
                        tabIndex={0}
                        onClick={() => setDisplayRecapModal(true)}
                        onKeyDown={(event) =>
                            keyPressHandler(event, "Enter", () => setDisplayRecapModal(true))
                        }
                    >
                        <p className={clsx(styles.title)}>
                            {i18n.job_descriptions_creation_skills_tools_title()}

                            <Pencil className='h-4 w-4 text-gray-300' />
                        </p>

                        {selectedTools?.length
                            ? selectedTools.map((selectedTool) => (
                                  <div key={selectedTool.id} className={styles.list} role='list'>
                                      <div className={styles.item} role='listitem'>
                                          <span className={styles.label}>
                                              {i18nDK(selectedTool.name)}
                                          </span>
                                          {selectedTool?.level ? (
                                              <span className={styles.level}>
                                                  {i18nDK(selectedTool.level)}
                                              </span>
                                          ) : null}
                                      </div>
                                  </div>
                              ))
                            : null}
                    </div>
                )}

                <Button variant='primary-outlined' onClick={() => setDisplayPanel(true)}>
                    {i18n.job_descriptions_creation_skills_tools_add_button()}
                    <Plus />
                </Button>
            </div>
            <Overlay isVisible={displayPanel} toggleVisibility={() => setDisplayPanel(false)} />
            <SlidingWrapper isVisible={displayPanel} position='bottom'>
                {displayPanel ? (
                    <SectionHeader
                        title={i18n.job_descriptions_creation_skills_tools_title()}
                        subtitle={i18n.job_descriptions_creation_skills_tools_subtitle()}
                        icon='Tool'
                        closeAction={() => {
                            setDisplayPanel(false);
                        }}
                    />
                ) : null}

                {displayPanel ? (
                    <>
                        <div className={clsx(styles.content, styles.selected)}>
                            {selectedTools?.length ? (
                                <div className={styles.wrapper}>
                                    <ul className={styles.list}>
                                        {selectedTools.map((tool) => (
                                            <li key={tool?.id} className={styles.item}>
                                                <Button
                                                    variant='primary-outlined'
                                                    onClick={() => {
                                                        selectTool(tool);
                                                    }}
                                                >
                                                    {i18nDK(tool?.name)}
                                                    <X />
                                                </Button>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            ) : null}

                            {Object.keys(tools)?.length ? (
                                <div className={styles.list} role='list'>
                                    {Object.keys(tools)
                                        // filter selected tools
                                        .filter(
                                            (tool) =>
                                                !selectedTools.find(({ name }) => tool === name),
                                        )
                                        .sort()
                                        .map((tool) => (
                                            <div
                                                key={tools[tool].id}
                                                className={styles.item}
                                                role='listitem'
                                            >
                                                <Chip
                                                    id={tools[tool].id}
                                                    label={i18nDK(tools[tool].name)}
                                                    name={tools[tool].name}
                                                    value={tools[tool].name}
                                                    checked={
                                                        !!selectedTools.find((t) => t.name === tool)
                                                    }
                                                    onChange={(event) => {
                                                        // important to prevent re-render
                                                        event.preventDefault();

                                                        // display modal for item who
                                                        // have levels only
                                                        if (tools[tool]?.levels) {
                                                            setDisplayModal(true);
                                                        } else {
                                                            // otherwise select the item right away
                                                            setSelectedTools((prevState) =>
                                                                prevState.concat(tools[tool]),
                                                            );
                                                        }

                                                        setCurrentTool(() => ({
                                                            ...tools[tool],
                                                            levels: tools[tool]?.levels,
                                                            level: "",
                                                        }));
                                                    }}
                                                />
                                            </div>
                                        ))}

                                    <Button
                                        variant='primary-outlined'
                                        onClick={() => setDisplayCustomModal(true)}
                                    >
                                        {i18n.job_descriptions_creation_skills_tools_add_custom_button()}
                                        <Plus />
                                    </Button>
                                </div>
                            ) : null}
                        </div>

                        <footer className={styles.footer}>
                            <Button
                                onClick={() => {
                                    // wipe everything when hitting cancel
                                    setDisplayPanel(false);
                                    setSelectedTools([]);
                                    submitTools([]);
                                }}
                                variant='primary-ghost'
                            >
                                {i18n.cancel()}
                            </Button>

                            <Button
                                onClick={() => {
                                    submitTools(selectedTools);
                                    setDisplayPanel(false);
                                }}
                                disabled={!selectedTools?.length}
                            >
                                {i18n.save()}
                            </Button>
                        </footer>
                    </>
                ) : null}
            </SlidingWrapper>
            {displayModal ? (
                <ModalSimple
                    title={i18nDK(currentTool?.name)}
                    subtitle={i18n.job_descriptions_creation_skills_tools_modal_subtitle()}
                    hideModal={() => setDisplayModal(false)}
                    action={() => {
                        setSelectedTools((prevState) => prevState.concat(currentTool));
                        setDisplayModal(false);
                    }}
                    cancelLabel={i18n.cancel()}
                    cancelFunction={() => {
                        // if it exists in selectedTools remove it
                        if (selectedTools.find(({ id }) => id === currentTool?.id)) {
                            selectTool(currentTool);
                        }

                        // otherwise do nothing
                    }}
                    validateLabel={i18n.save()}
                    disableButton={!currentTool?.level}
                >
                    {currentTool?.name === "TO_EXCEL" && (
                        <div className='excelFeedbackContainer'>
                            <ul>
                                {excelFeedback.map((feedback) => (
                                    <li key={feedback}>
                                        <span>{`${feedback.split(": ")[0]}: `}</span>
                                        {feedback.split(": ")[1]}
                                    </li>
                                ))}
                            </ul>
                        </div>
                    )}
                    <Select
                        id={`tool-software-${currentTool?.id}`}
                        name={currentTool?.name}
                        chevronIcon={true}
                        placeholder={i18n.job_descriptions_creation_skills_tools_modal_placeholder()}
                        options={currentTool?.levels.map((level) => ({
                            id: level.id,
                            label: i18nDK(level.label),
                            value: level.value,
                            name: level.name,
                        }))}
                        selectedItem={currentTool?.level && i18nDK(currentTool?.level)}
                        onChange={(_, __, selectedItem) =>
                            setCurrentTool((prevState) => ({
                                ...prevState,
                                level: selectedItem,
                                // passing the level id
                                id: prevState.levels.find(({ value }) => value === selectedItem)
                                    ?.id,
                            }))
                        }
                    />
                </ModalSimple>
            ) : null}
            {displayRecapModal && selectedTools?.length ? (
                <EditTools
                    selectedTools={selectedTools}
                    toolsOptions={tools}
                    hideModal={() => setDisplayRecapModal(false)}
                    submitTools={(updatedTools) => {
                        submitTools(updatedTools);
                        setDisplayRecapModal(false);
                    }}
                />
            ) : null}
            {displayCustomModal ? (
                <CustomTool
                    hideModal={() => setDisplayCustomModal(false)}
                    submitTools={(tool) => setSelectedTools((prevState) => prevState.concat(tool))}
                />
            ) : null}
        </div>
    );
}
