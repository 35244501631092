/* eslint-disable */
import { languageTag } from "./runtime.js"
import * as fr from "./messages/fr.js"
import * as en from "./messages/en.js"


/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const EQ_AUDITIVE_PROTECTION = (params = {}, options = {}) => {
	return {
		en: en.EQ_AUDITIVE_PROTECTION,
		fr: fr.EQ_AUDITIVE_PROTECTION
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const EQ_BIKE = (params = {}, options = {}) => {
	return {
		en: en.EQ_BIKE,
		fr: fr.EQ_BIKE
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const EQ_FACIAL_SCREEN = (params = {}, options = {}) => {
	return {
		en: en.EQ_FACIAL_SCREEN,
		fr: fr.EQ_FACIAL_SCREEN
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const EQ_GLOVES = (params = {}, options = {}) => {
	return {
		en: en.EQ_GLOVES,
		fr: fr.EQ_GLOVES
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const EQ_HARD_HAT = (params = {}, options = {}) => {
	return {
		en: en.EQ_HARD_HAT,
		fr: fr.EQ_HARD_HAT
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const EQ_HEADPHONES_AND_MIC = (params = {}, options = {}) => {
	return {
		en: en.EQ_HEADPHONES_AND_MIC,
		fr: fr.EQ_HEADPHONES_AND_MIC
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const EQ_LAPTOP = (params = {}, options = {}) => {
	return {
		en: en.EQ_LAPTOP,
		fr: fr.EQ_LAPTOP
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const EQ_PROTECTIVE_CLOTHING = (params = {}, options = {}) => {
	return {
		en: en.EQ_PROTECTIVE_CLOTHING,
		fr: fr.EQ_PROTECTIVE_CLOTHING
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const EQ_PROTECTIVE_GLASSES = (params = {}, options = {}) => {
	return {
		en: en.EQ_PROTECTIVE_GLASSES,
		fr: fr.EQ_PROTECTIVE_GLASSES
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const EQ_RESPIRATORY_PROTECTION = (params = {}, options = {}) => {
	return {
		en: en.EQ_RESPIRATORY_PROTECTION,
		fr: fr.EQ_RESPIRATORY_PROTECTION
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const EQ_SAFETY_SHOES = (params = {}, options = {}) => {
	return {
		en: en.EQ_SAFETY_SHOES,
		fr: fr.EQ_SAFETY_SHOES
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const EQ_SMARTPHONE = (params = {}, options = {}) => {
	return {
		en: en.EQ_SMARTPHONE,
		fr: fr.EQ_SMARTPHONE
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const EQ_YELLOW_JACKET = (params = {}, options = {}) => {
	return {
		en: en.EQ_YELLOW_JACKET,
		fr: fr.EQ_YELLOW_JACKET
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const LI_CACES_1 = (params = {}, options = {}) => {
	return {
		en: en.LI_CACES_1,
		fr: fr.LI_CACES_1
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const LI_CACES_3 = (params = {}, options = {}) => {
	return {
		en: en.LI_CACES_3,
		fr: fr.LI_CACES_3
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const LI_CACES_5 = (params = {}, options = {}) => {
	return {
		en: en.LI_CACES_5,
		fr: fr.LI_CACES_5
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const LI_DRIVING_LICENSE_B = (params = {}, options = {}) => {
	return {
		en: en.LI_DRIVING_LICENSE_B,
		fr: fr.LI_DRIVING_LICENSE_B
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const RIO_LOAD_HEAVY = (params = {}, options = {}) => {
	return {
		en: en.RIO_LOAD_HEAVY,
		fr: fr.RIO_LOAD_HEAVY
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const RIO_LOAD_LIGHT = (params = {}, options = {}) => {
	return {
		en: en.RIO_LOAD_LIGHT,
		fr: fr.RIO_LOAD_LIGHT
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const RIO_LOAD_MEDIUM = (params = {}, options = {}) => {
	return {
		en: en.RIO_LOAD_MEDIUM,
		fr: fr.RIO_LOAD_MEDIUM
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const RIO_TEMP_CHILL = (params = {}, options = {}) => {
	return {
		en: en.RIO_TEMP_CHILL,
		fr: fr.RIO_TEMP_CHILL
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const RIO_TEMP_COLD = (params = {}, options = {}) => {
	return {
		en: en.RIO_TEMP_COLD,
		fr: fr.RIO_TEMP_COLD
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const RIO_TEMP_HOT = (params = {}, options = {}) => {
	return {
		en: en.RIO_TEMP_HOT,
		fr: fr.RIO_TEMP_HOT
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const RIO_TEMP_NEGATIVE_COLD = (params = {}, options = {}) => {
	return {
		en: en.RIO_TEMP_NEGATIVE_COLD,
		fr: fr.RIO_TEMP_NEGATIVE_COLD
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const RI_ALTERNATING_TEAMS = (params = {}, options = {}) => {
	return {
		en: en.RI_ALTERNATING_TEAMS,
		fr: fr.RI_ALTERNATING_TEAMS
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const RI_CHEMICALS_DUST_FUMES = (params = {}, options = {}) => {
	return {
		en: en.RI_CHEMICALS_DUST_FUMES,
		fr: fr.RI_CHEMICALS_DUST_FUMES
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const RI_EXTREME_TEMPERATURES = (params = {}, options = {}) => {
	return {
		en: en.RI_EXTREME_TEMPERATURES,
		fr: fr.RI_EXTREME_TEMPERATURES
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const RI_HARD_POSTURES = (params = {}, options = {}) => {
	return {
		en: en.RI_HARD_POSTURES,
		fr: fr.RI_HARD_POSTURES
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const RI_HYPERBARIC_ENV = (params = {}, options = {}) => {
	return {
		en: en.RI_HYPERBARIC_ENV,
		fr: fr.RI_HYPERBARIC_ENV
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const RI_MANUAL_HANDLING = (params = {}, options = {}) => {
	return {
		en: en.RI_MANUAL_HANDLING,
		fr: fr.RI_MANUAL_HANDLING
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const RI_NIGHT_WORK = (params = {}, options = {}) => {
	return {
		en: en.RI_NIGHT_WORK,
		fr: fr.RI_NIGHT_WORK
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const RI_NOISE = (params = {}, options = {}) => {
	return {
		en: en.RI_NOISE,
		fr: fr.RI_NOISE
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const RI_REPETITIVE_WORK = (params = {}, options = {}) => {
	return {
		en: en.RI_REPETITIVE_WORK,
		fr: fr.RI_REPETITIVE_WORK
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const RI_VIBRATIONS = (params = {}, options = {}) => {
	return {
		en: en.RI_VIBRATIONS,
		fr: fr.RI_VIBRATIONS
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const TO_EXCEL = (params = {}, options = {}) => {
	return {
		en: en.TO_EXCEL,
		fr: fr.TO_EXCEL
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const TO_GOOGLE_DRIVE = (params = {}, options = {}) => {
	return {
		en: en.TO_GOOGLE_DRIVE,
		fr: fr.TO_GOOGLE_DRIVE
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const TO_INTERCOM = (params = {}, options = {}) => {
	return {
		en: en.TO_INTERCOM,
		fr: fr.TO_INTERCOM
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const TO_NOTION = (params = {}, options = {}) => {
	return {
		en: en.TO_NOTION,
		fr: fr.TO_NOTION
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const TO_POWER_POINT = (params = {}, options = {}) => {
	return {
		en: en.TO_POWER_POINT,
		fr: fr.TO_POWER_POINT
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const TO_SLACK = (params = {}, options = {}) => {
	return {
		en: en.TO_SLACK,
		fr: fr.TO_SLACK
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const TO_ZENDESK = (params = {}, options = {}) => {
	return {
		en: en.TO_ZENDESK,
		fr: fr.TO_ZENDESK
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const action_cancel = (params = {}, options = {}) => {
	return {
		en: en.action_cancel,
		fr: fr.action_cancel
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const action_confirm = (params = {}, options = {}) => {
	return {
		en: en.action_confirm,
		fr: fr.action_confirm
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const action_unauthorized = (params = {}, options = {}) => {
	return {
		en: en.action_unauthorized,
		fr: fr.action_unauthorized
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const action_unauthorized_extended = (params = {}, options = {}) => {
	return {
		en: en.action_unauthorized_extended,
		fr: fr.action_unauthorized_extended
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const action_unauthorized_extended_logas = (params = {}, options = {}) => {
	return {
		en: en.action_unauthorized_extended_logas,
		fr: fr.action_unauthorized_extended_logas
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const activate_account_form_title = (params = {}, options = {}) => {
	return {
		en: en.activate_account_form_title,
		fr: fr.activate_account_form_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const activate_account_link_broken = (params = {}, options = {}) => {
	return {
		en: en.activate_account_link_broken,
		fr: fr.activate_account_link_broken
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const activate_account_log_in = (params = {}, options = {}) => {
	return {
		en: en.activate_account_log_in,
		fr: fr.activate_account_log_in
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const activate_account_logo_company = (params = {}, options = {}) => {
	return {
		en: en.activate_account_logo_company,
		fr: fr.activate_account_logo_company
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const activate_account_or = (params = {}, options = {}) => {
	return {
		en: en.activate_account_or,
		fr: fr.activate_account_or
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const activate_account_password_placeholder = (params = {}, options = {}) => {
	return {
		en: en.activate_account_password_placeholder,
		fr: fr.activate_account_password_placeholder
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const activate_account_title = (params = {}, options = {}) => {
	return {
		en: en.activate_account_title,
		fr: fr.activate_account_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const activate_account_with_google = (params = {}, options = {}) => {
	return {
		en: en.activate_account_with_google,
		fr: fr.activate_account_with_google
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const add = (params = {}, options = {}) => {
	return {
		en: en.add,
		fr: fr.add
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const advanced = (params = {}, options = {}) => {
	return {
		en: en.advanced,
		fr: fr.advanced
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const alb = (params = {}, options = {}) => {
	return {
		en: en.alb,
		fr: fr.alb
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ara = (params = {}, options = {}) => {
	return {
		en: en.ara,
		fr: fr.ara
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const arm = (params = {}, options = {}) => {
	return {
		en: en.arm,
		fr: fr.arm
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const at = (params = {}, options = {}) => {
	return {
		en: en.at,
		fr: fr.at
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const aym = (params = {}, options = {}) => {
	return {
		en: en.aym,
		fr: fr.aym
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const aze = (params = {}, options = {}) => {
	return {
		en: en.aze,
		fr: fr.aze
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const back = (params = {}, options = {}) => {
	return {
		en: en.back,
		fr: fr.back
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const beginner = (params = {}, options = {}) => {
	return {
		en: en.beginner,
		fr: fr.beginner
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const bel = (params = {}, options = {}) => {
	return {
		en: en.bel,
		fr: fr.bel
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ben = (params = {}, options = {}) => {
	return {
		en: en.ben,
		fr: fr.ben
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const bul = (params = {}, options = {}) => {
	return {
		en: en.bul,
		fr: fr.bul
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const bur = (params = {}, options = {}) => {
	return {
		en: en.bur,
		fr: fr.bur
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const cancel = (params = {}, options = {}) => {
	return {
		en: en.cancel,
		fr: fr.cancel
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const cat = (params = {}, options = {}) => {
	return {
		en: en.cat,
		fr: fr.cat
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const chi = (params = {}, options = {}) => {
	return {
		en: en.chi,
		fr: fr.chi
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const cnr = (params = {}, options = {}) => {
	return {
		en: en.cnr,
		fr: fr.cnr
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{ siderNumber: NonNullable<unknown> }} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const completed_section_row_status_cell_subtitle_contact_plural = (params , options = {}) => {
	return {
		en: en.completed_section_row_status_cell_subtitle_contact_plural,
		fr: fr.completed_section_row_status_cell_subtitle_contact_plural
	}[options.languageTag ?? languageTag()](params)
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const completed_section_row_status_cell_subtitle_contact_singular = (params = {}, options = {}) => {
	return {
		en: en.completed_section_row_status_cell_subtitle_contact_singular,
		fr: fr.completed_section_row_status_cell_subtitle_contact_singular
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const completed_section_row_status_cell_title_selection_done = (params = {}, options = {}) => {
	return {
		en: en.completed_section_row_status_cell_title_selection_done,
		fr: fr.completed_section_row_status_cell_title_selection_done
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const completed_section_row_task_cell_managed_by = (params = {}, options = {}) => {
	return {
		en: en.completed_section_row_task_cell_managed_by,
		fr: fr.completed_section_row_task_cell_managed_by
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const completed_section_title = (params = {}, options = {}) => {
	return {
		en: en.completed_section_title,
		fr: fr.completed_section_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const confirm = (params = {}, options = {}) => {
	return {
		en: en.confirm,
		fr: fr.confirm
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const contract_contestation_confirmation_toast = (params = {}, options = {}) => {
	return {
		en: en.contract_contestation_confirmation_toast,
		fr: fr.contract_contestation_confirmation_toast
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const contract_signature_confirmation_block_agreement = (params = {}, options = {}) => {
	return {
		en: en.contract_signature_confirmation_block_agreement,
		fr: fr.contract_signature_confirmation_block_agreement
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const contract_signature_confirmation_block_checkbox = (params = {}, options = {}) => {
	return {
		en: en.contract_signature_confirmation_block_checkbox,
		fr: fr.contract_signature_confirmation_block_checkbox
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const contract_signature_confirmation_block_error = (params = {}, options = {}) => {
	return {
		en: en.contract_signature_confirmation_block_error,
		fr: fr.contract_signature_confirmation_block_error
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const contract_signature_confirmation_block_notice = (params = {}, options = {}) => {
	return {
		en: en.contract_signature_confirmation_block_notice,
		fr: fr.contract_signature_confirmation_block_notice
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const contract_signature_confirmation_block_number = (params = {}, options = {}) => {
	return {
		en: en.contract_signature_confirmation_block_number,
		fr: fr.contract_signature_confirmation_block_number
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const contract_signature_confirmation_block_title = (params = {}, options = {}) => {
	return {
		en: en.contract_signature_confirmation_block_title,
		fr: fr.contract_signature_confirmation_block_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const contract_signature_confirmation_toast = (params = {}, options = {}) => {
	return {
		en: en.contract_signature_confirmation_toast,
		fr: fr.contract_signature_confirmation_toast
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const contract_signature_document_error_button_label = (params = {}, options = {}) => {
	return {
		en: en.contract_signature_document_error_button_label,
		fr: fr.contract_signature_document_error_button_label
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const contract_signature_document_error_subtitle = (params = {}, options = {}) => {
	return {
		en: en.contract_signature_document_error_subtitle,
		fr: fr.contract_signature_document_error_subtitle
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const contract_signature_document_error_title = (params = {}, options = {}) => {
	return {
		en: en.contract_signature_document_error_title,
		fr: fr.contract_signature_document_error_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const contract_signature_pagination_last_page = (params = {}, options = {}) => {
	return {
		en: en.contract_signature_pagination_last_page,
		fr: fr.contract_signature_pagination_last_page
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const contract_signature_phone_number_required = (params = {}, options = {}) => {
	return {
		en: en.contract_signature_phone_number_required,
		fr: fr.contract_signature_phone_number_required
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const contract_signature_regenerate_document_label = (params = {}, options = {}) => {
	return {
		en: en.contract_signature_regenerate_document_label,
		fr: fr.contract_signature_regenerate_document_label
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const contract_signature_regeneration_success = (params = {}, options = {}) => {
	return {
		en: en.contract_signature_regeneration_success,
		fr: fr.contract_signature_regeneration_success
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const contract_signature_report_button_label = (params = {}, options = {}) => {
	return {
		en: en.contract_signature_report_button_label,
		fr: fr.contract_signature_report_button_label
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const contract_signature_see_contestation_button_label = (params = {}, options = {}) => {
	return {
		en: en.contract_signature_see_contestation_button_label,
		fr: fr.contract_signature_see_contestation_button_label
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const contract_signature_sign_button_label = (params = {}, options = {}) => {
	return {
		en: en.contract_signature_sign_button_label,
		fr: fr.contract_signature_sign_button_label
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const contracts_batch_row_mobile_date = (params = {}, options = {}) => {
	return {
		en: en.contracts_batch_row_mobile_date,
		fr: fr.contracts_batch_row_mobile_date
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const contracts_batch_row_mobile_manager = (params = {}, options = {}) => {
	return {
		en: en.contracts_batch_row_mobile_manager,
		fr: fr.contracts_batch_row_mobile_manager
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const contracts_batch_row_mobile_number = (params = {}, options = {}) => {
	return {
		en: en.contracts_batch_row_mobile_number,
		fr: fr.contracts_batch_row_mobile_number
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const contracts_batch_row_status_contested = (params = {}, options = {}) => {
	return {
		en: en.contracts_batch_row_status_contested,
		fr: fr.contracts_batch_row_status_contested
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const contracts_batch_row_status_contested_button_label = (params = {}, options = {}) => {
	return {
		en: en.contracts_batch_row_status_contested_button_label,
		fr: fr.contracts_batch_row_status_contested_button_label
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const contracts_batch_row_status_disputed_tooltip_content = (params = {}, options = {}) => {
	return {
		en: en.contracts_batch_row_status_disputed_tooltip_content,
		fr: fr.contracts_batch_row_status_disputed_tooltip_content
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{ contestationDate: NonNullable<unknown> }} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const contracts_batch_row_status_disputed_tooltip_title = (params , options = {}) => {
	return {
		en: en.contracts_batch_row_status_disputed_tooltip_title,
		fr: fr.contracts_batch_row_status_disputed_tooltip_title
	}[options.languageTag ?? languageTag()](params)
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const contracts_batch_row_status_signed = (params = {}, options = {}) => {
	return {
		en: en.contracts_batch_row_status_signed,
		fr: fr.contracts_batch_row_status_signed
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const contracts_batch_row_status_signed_button_label = (params = {}, options = {}) => {
	return {
		en: en.contracts_batch_row_status_signed_button_label,
		fr: fr.contracts_batch_row_status_signed_button_label
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{ signatureAuthorName: NonNullable<unknown>, signatureDate: NonNullable<unknown>, signatureTime: NonNullable<unknown> }} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const contracts_batch_row_status_signed_tooltip_content = (params , options = {}) => {
	return {
		en: en.contracts_batch_row_status_signed_tooltip_content,
		fr: fr.contracts_batch_row_status_signed_tooltip_content
	}[options.languageTag ?? languageTag()](params)
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const contracts_batch_row_status_tosign = (params = {}, options = {}) => {
	return {
		en: en.contracts_batch_row_status_tosign,
		fr: fr.contracts_batch_row_status_tosign
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const contracts_batch_row_status_tosign_button_label = (params = {}, options = {}) => {
	return {
		en: en.contracts_batch_row_status_tosign_button_label,
		fr: fr.contracts_batch_row_status_tosign_button_label
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const contracts_batch_row_status_tosign_late_tooltip_content = (params = {}, options = {}) => {
	return {
		en: en.contracts_batch_row_status_tosign_late_tooltip_content,
		fr: fr.contracts_batch_row_status_tosign_late_tooltip_content
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{ numberOfDays: NonNullable<unknown> }} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const contracts_batch_row_status_tosign_late_tooltip_title = (params , options = {}) => {
	return {
		en: en.contracts_batch_row_status_tosign_late_tooltip_title,
		fr: fr.contracts_batch_row_status_tosign_late_tooltip_title
	}[options.languageTag ?? languageTag()](params)
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const contracts_batches_list_header_date = (params = {}, options = {}) => {
	return {
		en: en.contracts_batches_list_header_date,
		fr: fr.contracts_batches_list_header_date
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const contracts_batches_list_header_manager = (params = {}, options = {}) => {
	return {
		en: en.contracts_batches_list_header_manager,
		fr: fr.contracts_batches_list_header_manager
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const contracts_batches_list_header_number = (params = {}, options = {}) => {
	return {
		en: en.contracts_batches_list_header_number,
		fr: fr.contracts_batches_list_header_number
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const contracts_batches_list_header_status = (params = {}, options = {}) => {
	return {
		en: en.contracts_batches_list_header_status,
		fr: fr.contracts_batches_list_header_status
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const contracts_batches_list_header_task = (params = {}, options = {}) => {
	return {
		en: en.contracts_batches_list_header_task,
		fr: fr.contracts_batches_list_header_task
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const contracts_batches_signed_blank_state_content = (params = {}, options = {}) => {
	return {
		en: en.contracts_batches_signed_blank_state_content,
		fr: fr.contracts_batches_signed_blank_state_content
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const contracts_batches_signed_blank_state_title = (params = {}, options = {}) => {
	return {
		en: en.contracts_batches_signed_blank_state_title,
		fr: fr.contracts_batches_signed_blank_state_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const contracts_batches_tosign_blank_state_content = (params = {}, options = {}) => {
	return {
		en: en.contracts_batches_tosign_blank_state_content,
		fr: fr.contracts_batches_tosign_blank_state_content
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const contracts_batches_tosign_blank_state_title = (params = {}, options = {}) => {
	return {
		en: en.contracts_batches_tosign_blank_state_title,
		fr: fr.contracts_batches_tosign_blank_state_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const contracts_contestation_modal_cancel = (params = {}, options = {}) => {
	return {
		en: en.contracts_contestation_modal_cancel,
		fr: fr.contracts_contestation_modal_cancel
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{ contestationDate: NonNullable<unknown>, contestationAuthorName: NonNullable<unknown> }} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const contracts_contestation_modal_details = (params , options = {}) => {
	return {
		en: en.contracts_contestation_modal_details,
		fr: fr.contracts_contestation_modal_details
	}[options.languageTag ?? languageTag()](params)
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const contracts_contestation_modal_input_label = (params = {}, options = {}) => {
	return {
		en: en.contracts_contestation_modal_input_label,
		fr: fr.contracts_contestation_modal_input_label
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const contracts_contestation_modal_input_placeholder = (params = {}, options = {}) => {
	return {
		en: en.contracts_contestation_modal_input_placeholder,
		fr: fr.contracts_contestation_modal_input_placeholder
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const contracts_contestation_modal_send = (params = {}, options = {}) => {
	return {
		en: en.contracts_contestation_modal_send,
		fr: fr.contracts_contestation_modal_send
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const contracts_contestation_modal_subtitle_contested = (params = {}, options = {}) => {
	return {
		en: en.contracts_contestation_modal_subtitle_contested,
		fr: fr.contracts_contestation_modal_subtitle_contested
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const contracts_contestation_modal_subtitle_tocontest = (params = {}, options = {}) => {
	return {
		en: en.contracts_contestation_modal_subtitle_tocontest,
		fr: fr.contracts_contestation_modal_subtitle_tocontest
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const contracts_contestation_modal_title = (params = {}, options = {}) => {
	return {
		en: en.contracts_contestation_modal_title,
		fr: fr.contracts_contestation_modal_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const contracts_top_menu_filter_signed = (params = {}, options = {}) => {
	return {
		en: en.contracts_top_menu_filter_signed,
		fr: fr.contracts_top_menu_filter_signed
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const contracts_top_menu_filter_to_sign = (params = {}, options = {}) => {
	return {
		en: en.contracts_top_menu_filter_to_sign,
		fr: fr.contracts_top_menu_filter_to_sign
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const countries_belgium = (params = {}, options = {}) => {
	return {
		en: en.countries_belgium,
		fr: fr.countries_belgium
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const countries_denmark = (params = {}, options = {}) => {
	return {
		en: en.countries_denmark,
		fr: fr.countries_denmark
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const countries_estonia = (params = {}, options = {}) => {
	return {
		en: en.countries_estonia,
		fr: fr.countries_estonia
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const countries_france = (params = {}, options = {}) => {
	return {
		en: en.countries_france,
		fr: fr.countries_france
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const countries_germany = (params = {}, options = {}) => {
	return {
		en: en.countries_germany,
		fr: fr.countries_germany
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const countries_great_britain = (params = {}, options = {}) => {
	return {
		en: en.countries_great_britain,
		fr: fr.countries_great_britain
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const countries_italy = (params = {}, options = {}) => {
	return {
		en: en.countries_italy,
		fr: fr.countries_italy
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const countries_netherlands = (params = {}, options = {}) => {
	return {
		en: en.countries_netherlands,
		fr: fr.countries_netherlands
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const countries_portugal = (params = {}, options = {}) => {
	return {
		en: en.countries_portugal,
		fr: fr.countries_portugal
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const countries_spain = (params = {}, options = {}) => {
	return {
		en: en.countries_spain,
		fr: fr.countries_spain
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_albania = (params = {}, options = {}) => {
	return {
		en: en.country_albania,
		fr: fr.country_albania
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_algeria = (params = {}, options = {}) => {
	return {
		en: en.country_algeria,
		fr: fr.country_algeria
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_andorra = (params = {}, options = {}) => {
	return {
		en: en.country_andorra,
		fr: fr.country_andorra
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_angola = (params = {}, options = {}) => {
	return {
		en: en.country_angola,
		fr: fr.country_angola
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_antigua_and_barbuda = (params = {}, options = {}) => {
	return {
		en: en.country_antigua_and_barbuda,
		fr: fr.country_antigua_and_barbuda
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_argentina = (params = {}, options = {}) => {
	return {
		en: en.country_argentina,
		fr: fr.country_argentina
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_armenia = (params = {}, options = {}) => {
	return {
		en: en.country_armenia,
		fr: fr.country_armenia
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_australia = (params = {}, options = {}) => {
	return {
		en: en.country_australia,
		fr: fr.country_australia
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_austria = (params = {}, options = {}) => {
	return {
		en: en.country_austria,
		fr: fr.country_austria
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_azerbaijan = (params = {}, options = {}) => {
	return {
		en: en.country_azerbaijan,
		fr: fr.country_azerbaijan
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_bahamas = (params = {}, options = {}) => {
	return {
		en: en.country_bahamas,
		fr: fr.country_bahamas
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_bahrain = (params = {}, options = {}) => {
	return {
		en: en.country_bahrain,
		fr: fr.country_bahrain
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_bangladesh = (params = {}, options = {}) => {
	return {
		en: en.country_bangladesh,
		fr: fr.country_bangladesh
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_barbados = (params = {}, options = {}) => {
	return {
		en: en.country_barbados,
		fr: fr.country_barbados
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_belarus = (params = {}, options = {}) => {
	return {
		en: en.country_belarus,
		fr: fr.country_belarus
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_belgium = (params = {}, options = {}) => {
	return {
		en: en.country_belgium,
		fr: fr.country_belgium
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_belize = (params = {}, options = {}) => {
	return {
		en: en.country_belize,
		fr: fr.country_belize
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_benin = (params = {}, options = {}) => {
	return {
		en: en.country_benin,
		fr: fr.country_benin
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_bhutan = (params = {}, options = {}) => {
	return {
		en: en.country_bhutan,
		fr: fr.country_bhutan
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_bolivia = (params = {}, options = {}) => {
	return {
		en: en.country_bolivia,
		fr: fr.country_bolivia
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_bosnia_and_herzegovina = (params = {}, options = {}) => {
	return {
		en: en.country_bosnia_and_herzegovina,
		fr: fr.country_bosnia_and_herzegovina
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_botswana = (params = {}, options = {}) => {
	return {
		en: en.country_botswana,
		fr: fr.country_botswana
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_brazil = (params = {}, options = {}) => {
	return {
		en: en.country_brazil,
		fr: fr.country_brazil
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_brunei = (params = {}, options = {}) => {
	return {
		en: en.country_brunei,
		fr: fr.country_brunei
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_bulgaria = (params = {}, options = {}) => {
	return {
		en: en.country_bulgaria,
		fr: fr.country_bulgaria
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_burkina_faso = (params = {}, options = {}) => {
	return {
		en: en.country_burkina_faso,
		fr: fr.country_burkina_faso
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_burundi = (params = {}, options = {}) => {
	return {
		en: en.country_burundi,
		fr: fr.country_burundi
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_cambodia = (params = {}, options = {}) => {
	return {
		en: en.country_cambodia,
		fr: fr.country_cambodia
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_cameroon = (params = {}, options = {}) => {
	return {
		en: en.country_cameroon,
		fr: fr.country_cameroon
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_canada = (params = {}, options = {}) => {
	return {
		en: en.country_canada,
		fr: fr.country_canada
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_cape_verde = (params = {}, options = {}) => {
	return {
		en: en.country_cape_verde,
		fr: fr.country_cape_verde
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_chad = (params = {}, options = {}) => {
	return {
		en: en.country_chad,
		fr: fr.country_chad
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_chile = (params = {}, options = {}) => {
	return {
		en: en.country_chile,
		fr: fr.country_chile
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_china = (params = {}, options = {}) => {
	return {
		en: en.country_china,
		fr: fr.country_china
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_colombia = (params = {}, options = {}) => {
	return {
		en: en.country_colombia,
		fr: fr.country_colombia
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_comoros = (params = {}, options = {}) => {
	return {
		en: en.country_comoros,
		fr: fr.country_comoros
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_congo_brazzaville = (params = {}, options = {}) => {
	return {
		en: en.country_congo_brazzaville,
		fr: fr.country_congo_brazzaville
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_congo_kinshasa = (params = {}, options = {}) => {
	return {
		en: en.country_congo_kinshasa,
		fr: fr.country_congo_kinshasa
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_cook_islands = (params = {}, options = {}) => {
	return {
		en: en.country_cook_islands,
		fr: fr.country_cook_islands
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_costa_rica = (params = {}, options = {}) => {
	return {
		en: en.country_costa_rica,
		fr: fr.country_costa_rica
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_croatia = (params = {}, options = {}) => {
	return {
		en: en.country_croatia,
		fr: fr.country_croatia
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_cuba = (params = {}, options = {}) => {
	return {
		en: en.country_cuba,
		fr: fr.country_cuba
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_cyprus = (params = {}, options = {}) => {
	return {
		en: en.country_cyprus,
		fr: fr.country_cyprus
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_czech_republic = (params = {}, options = {}) => {
	return {
		en: en.country_czech_republic,
		fr: fr.country_czech_republic
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_denmark = (params = {}, options = {}) => {
	return {
		en: en.country_denmark,
		fr: fr.country_denmark
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_djibouti = (params = {}, options = {}) => {
	return {
		en: en.country_djibouti,
		fr: fr.country_djibouti
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_dominica = (params = {}, options = {}) => {
	return {
		en: en.country_dominica,
		fr: fr.country_dominica
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_ecuador = (params = {}, options = {}) => {
	return {
		en: en.country_ecuador,
		fr: fr.country_ecuador
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_egypt = (params = {}, options = {}) => {
	return {
		en: en.country_egypt,
		fr: fr.country_egypt
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_el_salvador = (params = {}, options = {}) => {
	return {
		en: en.country_el_salvador,
		fr: fr.country_el_salvador
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_equatorial_guinea = (params = {}, options = {}) => {
	return {
		en: en.country_equatorial_guinea,
		fr: fr.country_equatorial_guinea
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_eritrea = (params = {}, options = {}) => {
	return {
		en: en.country_eritrea,
		fr: fr.country_eritrea
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_estonia = (params = {}, options = {}) => {
	return {
		en: en.country_estonia,
		fr: fr.country_estonia
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_ethiopia = (params = {}, options = {}) => {
	return {
		en: en.country_ethiopia,
		fr: fr.country_ethiopia
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_fiji = (params = {}, options = {}) => {
	return {
		en: en.country_fiji,
		fr: fr.country_fiji
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_finland = (params = {}, options = {}) => {
	return {
		en: en.country_finland,
		fr: fr.country_finland
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_france = (params = {}, options = {}) => {
	return {
		en: en.country_france,
		fr: fr.country_france
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_gabon = (params = {}, options = {}) => {
	return {
		en: en.country_gabon,
		fr: fr.country_gabon
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_gambia = (params = {}, options = {}) => {
	return {
		en: en.country_gambia,
		fr: fr.country_gambia
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_georgia = (params = {}, options = {}) => {
	return {
		en: en.country_georgia,
		fr: fr.country_georgia
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_germany = (params = {}, options = {}) => {
	return {
		en: en.country_germany,
		fr: fr.country_germany
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_ghana = (params = {}, options = {}) => {
	return {
		en: en.country_ghana,
		fr: fr.country_ghana
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_greece = (params = {}, options = {}) => {
	return {
		en: en.country_greece,
		fr: fr.country_greece
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_grenada = (params = {}, options = {}) => {
	return {
		en: en.country_grenada,
		fr: fr.country_grenada
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_guatemala = (params = {}, options = {}) => {
	return {
		en: en.country_guatemala,
		fr: fr.country_guatemala
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_guinea = (params = {}, options = {}) => {
	return {
		en: en.country_guinea,
		fr: fr.country_guinea
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_guinea_bissau = (params = {}, options = {}) => {
	return {
		en: en.country_guinea_bissau,
		fr: fr.country_guinea_bissau
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_guyana = (params = {}, options = {}) => {
	return {
		en: en.country_guyana,
		fr: fr.country_guyana
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_haiti = (params = {}, options = {}) => {
	return {
		en: en.country_haiti,
		fr: fr.country_haiti
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_honduras = (params = {}, options = {}) => {
	return {
		en: en.country_honduras,
		fr: fr.country_honduras
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_hungary = (params = {}, options = {}) => {
	return {
		en: en.country_hungary,
		fr: fr.country_hungary
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_iceland = (params = {}, options = {}) => {
	return {
		en: en.country_iceland,
		fr: fr.country_iceland
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_india = (params = {}, options = {}) => {
	return {
		en: en.country_india,
		fr: fr.country_india
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_indonesia = (params = {}, options = {}) => {
	return {
		en: en.country_indonesia,
		fr: fr.country_indonesia
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_iran = (params = {}, options = {}) => {
	return {
		en: en.country_iran,
		fr: fr.country_iran
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_iraq = (params = {}, options = {}) => {
	return {
		en: en.country_iraq,
		fr: fr.country_iraq
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_ireland = (params = {}, options = {}) => {
	return {
		en: en.country_ireland,
		fr: fr.country_ireland
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_israel = (params = {}, options = {}) => {
	return {
		en: en.country_israel,
		fr: fr.country_israel
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_italy = (params = {}, options = {}) => {
	return {
		en: en.country_italy,
		fr: fr.country_italy
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_jamaica = (params = {}, options = {}) => {
	return {
		en: en.country_jamaica,
		fr: fr.country_jamaica
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_japan = (params = {}, options = {}) => {
	return {
		en: en.country_japan,
		fr: fr.country_japan
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_jordan = (params = {}, options = {}) => {
	return {
		en: en.country_jordan,
		fr: fr.country_jordan
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_kazakhstan = (params = {}, options = {}) => {
	return {
		en: en.country_kazakhstan,
		fr: fr.country_kazakhstan
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_kenya = (params = {}, options = {}) => {
	return {
		en: en.country_kenya,
		fr: fr.country_kenya
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_kiribati = (params = {}, options = {}) => {
	return {
		en: en.country_kiribati,
		fr: fr.country_kiribati
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_kosovo = (params = {}, options = {}) => {
	return {
		en: en.country_kosovo,
		fr: fr.country_kosovo
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_kuwait = (params = {}, options = {}) => {
	return {
		en: en.country_kuwait,
		fr: fr.country_kuwait
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_kyrgyzstan = (params = {}, options = {}) => {
	return {
		en: en.country_kyrgyzstan,
		fr: fr.country_kyrgyzstan
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_laos = (params = {}, options = {}) => {
	return {
		en: en.country_laos,
		fr: fr.country_laos
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_latvia = (params = {}, options = {}) => {
	return {
		en: en.country_latvia,
		fr: fr.country_latvia
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_lebanon = (params = {}, options = {}) => {
	return {
		en: en.country_lebanon,
		fr: fr.country_lebanon
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_lesotho = (params = {}, options = {}) => {
	return {
		en: en.country_lesotho,
		fr: fr.country_lesotho
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_liberia = (params = {}, options = {}) => {
	return {
		en: en.country_liberia,
		fr: fr.country_liberia
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_libya = (params = {}, options = {}) => {
	return {
		en: en.country_libya,
		fr: fr.country_libya
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_liechtenstein = (params = {}, options = {}) => {
	return {
		en: en.country_liechtenstein,
		fr: fr.country_liechtenstein
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_lithuania = (params = {}, options = {}) => {
	return {
		en: en.country_lithuania,
		fr: fr.country_lithuania
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_luxembourg = (params = {}, options = {}) => {
	return {
		en: en.country_luxembourg,
		fr: fr.country_luxembourg
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_macedonia = (params = {}, options = {}) => {
	return {
		en: en.country_macedonia,
		fr: fr.country_macedonia
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_madagascar = (params = {}, options = {}) => {
	return {
		en: en.country_madagascar,
		fr: fr.country_madagascar
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_malawi = (params = {}, options = {}) => {
	return {
		en: en.country_malawi,
		fr: fr.country_malawi
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_malaysia = (params = {}, options = {}) => {
	return {
		en: en.country_malaysia,
		fr: fr.country_malaysia
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_maldives = (params = {}, options = {}) => {
	return {
		en: en.country_maldives,
		fr: fr.country_maldives
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_mali = (params = {}, options = {}) => {
	return {
		en: en.country_mali,
		fr: fr.country_mali
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_malta = (params = {}, options = {}) => {
	return {
		en: en.country_malta,
		fr: fr.country_malta
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_marshall_islands = (params = {}, options = {}) => {
	return {
		en: en.country_marshall_islands,
		fr: fr.country_marshall_islands
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_mauritania = (params = {}, options = {}) => {
	return {
		en: en.country_mauritania,
		fr: fr.country_mauritania
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_mauritius = (params = {}, options = {}) => {
	return {
		en: en.country_mauritius,
		fr: fr.country_mauritius
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_mexico = (params = {}, options = {}) => {
	return {
		en: en.country_mexico,
		fr: fr.country_mexico
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_micronesia = (params = {}, options = {}) => {
	return {
		en: en.country_micronesia,
		fr: fr.country_micronesia
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_moldova = (params = {}, options = {}) => {
	return {
		en: en.country_moldova,
		fr: fr.country_moldova
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_monaco = (params = {}, options = {}) => {
	return {
		en: en.country_monaco,
		fr: fr.country_monaco
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_mongolia = (params = {}, options = {}) => {
	return {
		en: en.country_mongolia,
		fr: fr.country_mongolia
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_montenegro = (params = {}, options = {}) => {
	return {
		en: en.country_montenegro,
		fr: fr.country_montenegro
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_morocco = (params = {}, options = {}) => {
	return {
		en: en.country_morocco,
		fr: fr.country_morocco
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_mozambique = (params = {}, options = {}) => {
	return {
		en: en.country_mozambique,
		fr: fr.country_mozambique
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_myanmar = (params = {}, options = {}) => {
	return {
		en: en.country_myanmar,
		fr: fr.country_myanmar
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_namibia = (params = {}, options = {}) => {
	return {
		en: en.country_namibia,
		fr: fr.country_namibia
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_nauru = (params = {}, options = {}) => {
	return {
		en: en.country_nauru,
		fr: fr.country_nauru
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_nepal = (params = {}, options = {}) => {
	return {
		en: en.country_nepal,
		fr: fr.country_nepal
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_netherlands = (params = {}, options = {}) => {
	return {
		en: en.country_netherlands,
		fr: fr.country_netherlands
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_new_zealand = (params = {}, options = {}) => {
	return {
		en: en.country_new_zealand,
		fr: fr.country_new_zealand
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_nicaragua = (params = {}, options = {}) => {
	return {
		en: en.country_nicaragua,
		fr: fr.country_nicaragua
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_niger = (params = {}, options = {}) => {
	return {
		en: en.country_niger,
		fr: fr.country_niger
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_nigeria = (params = {}, options = {}) => {
	return {
		en: en.country_nigeria,
		fr: fr.country_nigeria
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_north_korea = (params = {}, options = {}) => {
	return {
		en: en.country_north_korea,
		fr: fr.country_north_korea
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_norway = (params = {}, options = {}) => {
	return {
		en: en.country_norway,
		fr: fr.country_norway
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_oman = (params = {}, options = {}) => {
	return {
		en: en.country_oman,
		fr: fr.country_oman
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_pakistan = (params = {}, options = {}) => {
	return {
		en: en.country_pakistan,
		fr: fr.country_pakistan
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_palau = (params = {}, options = {}) => {
	return {
		en: en.country_palau,
		fr: fr.country_palau
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_palestine = (params = {}, options = {}) => {
	return {
		en: en.country_palestine,
		fr: fr.country_palestine
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_panama = (params = {}, options = {}) => {
	return {
		en: en.country_panama,
		fr: fr.country_panama
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_papua_new_guinea = (params = {}, options = {}) => {
	return {
		en: en.country_papua_new_guinea,
		fr: fr.country_papua_new_guinea
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_paraguay = (params = {}, options = {}) => {
	return {
		en: en.country_paraguay,
		fr: fr.country_paraguay
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_peru = (params = {}, options = {}) => {
	return {
		en: en.country_peru,
		fr: fr.country_peru
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_philippines = (params = {}, options = {}) => {
	return {
		en: en.country_philippines,
		fr: fr.country_philippines
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_poland = (params = {}, options = {}) => {
	return {
		en: en.country_poland,
		fr: fr.country_poland
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_portugal = (params = {}, options = {}) => {
	return {
		en: en.country_portugal,
		fr: fr.country_portugal
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_qatar = (params = {}, options = {}) => {
	return {
		en: en.country_qatar,
		fr: fr.country_qatar
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_romania = (params = {}, options = {}) => {
	return {
		en: en.country_romania,
		fr: fr.country_romania
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_russia = (params = {}, options = {}) => {
	return {
		en: en.country_russia,
		fr: fr.country_russia
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_rwanda = (params = {}, options = {}) => {
	return {
		en: en.country_rwanda,
		fr: fr.country_rwanda
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_saint_kitts_and_nevis = (params = {}, options = {}) => {
	return {
		en: en.country_saint_kitts_and_nevis,
		fr: fr.country_saint_kitts_and_nevis
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_saint_lucia = (params = {}, options = {}) => {
	return {
		en: en.country_saint_lucia,
		fr: fr.country_saint_lucia
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_saint_vincent_and_the_grenadines = (params = {}, options = {}) => {
	return {
		en: en.country_saint_vincent_and_the_grenadines,
		fr: fr.country_saint_vincent_and_the_grenadines
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_samoa = (params = {}, options = {}) => {
	return {
		en: en.country_samoa,
		fr: fr.country_samoa
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_san_marino = (params = {}, options = {}) => {
	return {
		en: en.country_san_marino,
		fr: fr.country_san_marino
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_sao_tome_and_principe = (params = {}, options = {}) => {
	return {
		en: en.country_sao_tome_and_principe,
		fr: fr.country_sao_tome_and_principe
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_saudi_arabia = (params = {}, options = {}) => {
	return {
		en: en.country_saudi_arabia,
		fr: fr.country_saudi_arabia
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_senegal = (params = {}, options = {}) => {
	return {
		en: en.country_senegal,
		fr: fr.country_senegal
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_serbia = (params = {}, options = {}) => {
	return {
		en: en.country_serbia,
		fr: fr.country_serbia
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_seychelles = (params = {}, options = {}) => {
	return {
		en: en.country_seychelles,
		fr: fr.country_seychelles
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_sierra_leone = (params = {}, options = {}) => {
	return {
		en: en.country_sierra_leone,
		fr: fr.country_sierra_leone
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_singapore = (params = {}, options = {}) => {
	return {
		en: en.country_singapore,
		fr: fr.country_singapore
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_slovakia = (params = {}, options = {}) => {
	return {
		en: en.country_slovakia,
		fr: fr.country_slovakia
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_slovenia = (params = {}, options = {}) => {
	return {
		en: en.country_slovenia,
		fr: fr.country_slovenia
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_solomon_islands = (params = {}, options = {}) => {
	return {
		en: en.country_solomon_islands,
		fr: fr.country_solomon_islands
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_somalia = (params = {}, options = {}) => {
	return {
		en: en.country_somalia,
		fr: fr.country_somalia
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_south_africa = (params = {}, options = {}) => {
	return {
		en: en.country_south_africa,
		fr: fr.country_south_africa
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_south_korea = (params = {}, options = {}) => {
	return {
		en: en.country_south_korea,
		fr: fr.country_south_korea
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_south_sudan = (params = {}, options = {}) => {
	return {
		en: en.country_south_sudan,
		fr: fr.country_south_sudan
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_spain = (params = {}, options = {}) => {
	return {
		en: en.country_spain,
		fr: fr.country_spain
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_sri_lanka = (params = {}, options = {}) => {
	return {
		en: en.country_sri_lanka,
		fr: fr.country_sri_lanka
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_sudan = (params = {}, options = {}) => {
	return {
		en: en.country_sudan,
		fr: fr.country_sudan
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_suriname = (params = {}, options = {}) => {
	return {
		en: en.country_suriname,
		fr: fr.country_suriname
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_swaziland = (params = {}, options = {}) => {
	return {
		en: en.country_swaziland,
		fr: fr.country_swaziland
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_sweden = (params = {}, options = {}) => {
	return {
		en: en.country_sweden,
		fr: fr.country_sweden
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_switzerland = (params = {}, options = {}) => {
	return {
		en: en.country_switzerland,
		fr: fr.country_switzerland
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_syria = (params = {}, options = {}) => {
	return {
		en: en.country_syria,
		fr: fr.country_syria
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_tajikistan = (params = {}, options = {}) => {
	return {
		en: en.country_tajikistan,
		fr: fr.country_tajikistan
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_tanzania = (params = {}, options = {}) => {
	return {
		en: en.country_tanzania,
		fr: fr.country_tanzania
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_thailand = (params = {}, options = {}) => {
	return {
		en: en.country_thailand,
		fr: fr.country_thailand
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_togo = (params = {}, options = {}) => {
	return {
		en: en.country_togo,
		fr: fr.country_togo
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_tonga = (params = {}, options = {}) => {
	return {
		en: en.country_tonga,
		fr: fr.country_tonga
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_trinidad_and_tobago = (params = {}, options = {}) => {
	return {
		en: en.country_trinidad_and_tobago,
		fr: fr.country_trinidad_and_tobago
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_tunisia = (params = {}, options = {}) => {
	return {
		en: en.country_tunisia,
		fr: fr.country_tunisia
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_turkey = (params = {}, options = {}) => {
	return {
		en: en.country_turkey,
		fr: fr.country_turkey
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_turkmenistan = (params = {}, options = {}) => {
	return {
		en: en.country_turkmenistan,
		fr: fr.country_turkmenistan
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_tuvalu = (params = {}, options = {}) => {
	return {
		en: en.country_tuvalu,
		fr: fr.country_tuvalu
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_uganda = (params = {}, options = {}) => {
	return {
		en: en.country_uganda,
		fr: fr.country_uganda
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_ukraine = (params = {}, options = {}) => {
	return {
		en: en.country_ukraine,
		fr: fr.country_ukraine
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_united_arab_emirates = (params = {}, options = {}) => {
	return {
		en: en.country_united_arab_emirates,
		fr: fr.country_united_arab_emirates
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_united_kingdom = (params = {}, options = {}) => {
	return {
		en: en.country_united_kingdom,
		fr: fr.country_united_kingdom
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_united_states = (params = {}, options = {}) => {
	return {
		en: en.country_united_states,
		fr: fr.country_united_states
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_uruguay = (params = {}, options = {}) => {
	return {
		en: en.country_uruguay,
		fr: fr.country_uruguay
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_uzbekistan = (params = {}, options = {}) => {
	return {
		en: en.country_uzbekistan,
		fr: fr.country_uzbekistan
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_vanuatu = (params = {}, options = {}) => {
	return {
		en: en.country_vanuatu,
		fr: fr.country_vanuatu
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_vatican = (params = {}, options = {}) => {
	return {
		en: en.country_vatican,
		fr: fr.country_vatican
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_venezuela = (params = {}, options = {}) => {
	return {
		en: en.country_venezuela,
		fr: fr.country_venezuela
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_vietnam = (params = {}, options = {}) => {
	return {
		en: en.country_vietnam,
		fr: fr.country_vietnam
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_yemen = (params = {}, options = {}) => {
	return {
		en: en.country_yemen,
		fr: fr.country_yemen
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_zambia = (params = {}, options = {}) => {
	return {
		en: en.country_zambia,
		fr: fr.country_zambia
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const country_zimbabwe = (params = {}, options = {}) => {
	return {
		en: en.country_zimbabwe,
		fr: fr.country_zimbabwe
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const cze = (params = {}, options = {}) => {
	return {
		en: en.cze,
		fr: fr.cze
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const dan = (params = {}, options = {}) => {
	return {
		en: en.dan,
		fr: fr.dan
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const deadline = (params = {}, options = {}) => {
	return {
		en: en.deadline,
		fr: fr.deadline
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const delete_draft = (params = {}, options = {}) => {
	return {
		en: en.delete_draft,
		fr: fr.delete_draft
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const details_completion_hoursTypesInformations = (params = {}, options = {}) => {
	return {
		en: en.details_completion_hoursTypesInformations,
		fr: fr.details_completion_hoursTypesInformations
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const details_completion_indemnitiesInformations = (params = {}, options = {}) => {
	return {
		en: en.details_completion_indemnitiesInformations,
		fr: fr.details_completion_indemnitiesInformations
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const details_completion_legalInformations = (params = {}, options = {}) => {
	return {
		en: en.details_completion_legalInformations,
		fr: fr.details_completion_legalInformations
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const details_completion_paymentInformations = (params = {}, options = {}) => {
	return {
		en: en.details_completion_paymentInformations,
		fr: fr.details_completion_paymentInformations
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const details_completion_profile = (params = {}, options = {}) => {
	return {
		en: en.details_completion_profile,
		fr: fr.details_completion_profile
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const details_completion_status_incomplete = (params = {}, options = {}) => {
	return {
		en: en.details_completion_status_incomplete,
		fr: fr.details_completion_status_incomplete
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const details_completion_validate_label = (params = {}, options = {}) => {
	return {
		en: en.details_completion_validate_label,
		fr: fr.details_completion_validate_label
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const doc_title_contracts = (params = {}, options = {}) => {
	return {
		en: en.doc_title_contracts,
		fr: fr.doc_title_contracts
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const doc_title_contracts_signature = (params = {}, options = {}) => {
	return {
		en: en.doc_title_contracts_signature,
		fr: fr.doc_title_contracts_signature
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const doc_title_home = (params = {}, options = {}) => {
	return {
		en: en.doc_title_home,
		fr: fr.doc_title_home
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const doc_title_invoices = (params = {}, options = {}) => {
	return {
		en: en.doc_title_invoices,
		fr: fr.doc_title_invoices
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const doc_title_job_descriptions = (params = {}, options = {}) => {
	return {
		en: en.doc_title_job_descriptions,
		fr: fr.doc_title_job_descriptions
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const doc_title_logas = (params = {}, options = {}) => {
	return {
		en: en.doc_title_logas,
		fr: fr.doc_title_logas
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const doc_title_not_found = (params = {}, options = {}) => {
	return {
		en: en.doc_title_not_found,
		fr: fr.doc_title_not_found
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const doc_title_planning = (params = {}, options = {}) => {
	return {
		en: en.doc_title_planning,
		fr: fr.doc_title_planning
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const doc_title_posting = (params = {}, options = {}) => {
	return {
		en: en.doc_title_posting,
		fr: fr.doc_title_posting
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const doc_title_preselection = (params = {}, options = {}) => {
	return {
		en: en.doc_title_preselection,
		fr: fr.doc_title_preselection
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const doc_title_settings = (params = {}, options = {}) => {
	return {
		en: en.doc_title_settings,
		fr: fr.doc_title_settings
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const doc_title_tasks = (params = {}, options = {}) => {
	return {
		en: en.doc_title_tasks,
		fr: fr.doc_title_tasks
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const doc_title_timesheets = (params = {}, options = {}) => {
	return {
		en: en.doc_title_timesheets,
		fr: fr.doc_title_timesheets
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const draft_deleted_success = (params = {}, options = {}) => {
	return {
		en: en.draft_deleted_success,
		fr: fr.draft_deleted_success
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const draft_section_row_status_cell_subtitle = (params = {}, options = {}) => {
	return {
		en: en.draft_section_row_status_cell_subtitle,
		fr: fr.draft_section_row_status_cell_subtitle
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const draft_section_row_status_cell_title = (params = {}, options = {}) => {
	return {
		en: en.draft_section_row_status_cell_title,
		fr: fr.draft_section_row_status_cell_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const draft_section_row_task_cell_created_by = (params = {}, options = {}) => {
	return {
		en: en.draft_section_row_task_cell_created_by,
		fr: fr.draft_section_row_task_cell_created_by
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const draft_section_title = (params = {}, options = {}) => {
	return {
		en: en.draft_section_title,
		fr: fr.draft_section_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const duplicate = (params = {}, options = {}) => {
	return {
		en: en.duplicate,
		fr: fr.duplicate
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const duplicate_draft = (params = {}, options = {}) => {
	return {
		en: en.duplicate_draft,
		fr: fr.duplicate_draft
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const duplicate_task = (params = {}, options = {}) => {
	return {
		en: en.duplicate_task,
		fr: fr.duplicate_task
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const empty_placeholder_all_subtitle = (params = {}, options = {}) => {
	return {
		en: en.empty_placeholder_all_subtitle,
		fr: fr.empty_placeholder_all_subtitle
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{ firstName: NonNullable<unknown> }} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const empty_placeholder_all_title = (params , options = {}) => {
	return {
		en: en.empty_placeholder_all_title,
		fr: fr.empty_placeholder_all_title
	}[options.languageTag ?? languageTag()](params)
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const empty_placeholder_completed_subtitle = (params = {}, options = {}) => {
	return {
		en: en.empty_placeholder_completed_subtitle,
		fr: fr.empty_placeholder_completed_subtitle
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const empty_placeholder_completed_title = (params = {}, options = {}) => {
	return {
		en: en.empty_placeholder_completed_title,
		fr: fr.empty_placeholder_completed_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const empty_placeholder_draft_subtitle = (params = {}, options = {}) => {
	return {
		en: en.empty_placeholder_draft_subtitle,
		fr: fr.empty_placeholder_draft_subtitle
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const empty_placeholder_draft_title = (params = {}, options = {}) => {
	return {
		en: en.empty_placeholder_draft_title,
		fr: fr.empty_placeholder_draft_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const empty_placeholder_ongoing_subtitle = (params = {}, options = {}) => {
	return {
		en: en.empty_placeholder_ongoing_subtitle,
		fr: fr.empty_placeholder_ongoing_subtitle
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const empty_placeholder_ongoing_title = (params = {}, options = {}) => {
	return {
		en: en.empty_placeholder_ongoing_title,
		fr: fr.empty_placeholder_ongoing_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const empty_placeholder_planning_cta = (params = {}, options = {}) => {
	return {
		en: en.empty_placeholder_planning_cta,
		fr: fr.empty_placeholder_planning_cta
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const empty_placeholder_planning_subtitle = (params = {}, options = {}) => {
	return {
		en: en.empty_placeholder_planning_subtitle,
		fr: fr.empty_placeholder_planning_subtitle
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const empty_placeholder_planning_title = (params = {}, options = {}) => {
	return {
		en: en.empty_placeholder_planning_title,
		fr: fr.empty_placeholder_planning_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const empty_placeholder_upcoming_subtitle = (params = {}, options = {}) => {
	return {
		en: en.empty_placeholder_upcoming_subtitle,
		fr: fr.empty_placeholder_upcoming_subtitle
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const empty_placeholder_upcoming_title = (params = {}, options = {}) => {
	return {
		en: en.empty_placeholder_upcoming_title,
		fr: fr.empty_placeholder_upcoming_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const eng = (params = {}, options = {}) => {
	return {
		en: en.eng,
		fr: fr.eng
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const error_confirmation_not_equal_password = (params = {}, options = {}) => {
	return {
		en: en.error_confirmation_not_equal_password,
		fr: fr.error_confirmation_not_equal_password
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const error_field_is_required = (params = {}, options = {}) => {
	return {
		en: en.error_field_is_required,
		fr: fr.error_field_is_required
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const error_field_is_required_checkbox = (params = {}, options = {}) => {
	return {
		en: en.error_field_is_required_checkbox,
		fr: fr.error_field_is_required_checkbox
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const error_field_required = (params = {}, options = {}) => {
	return {
		en: en.error_field_required,
		fr: fr.error_field_required
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{ maxSize: NonNullable<unknown> }} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const error_file_size = (params , options = {}) => {
	return {
		en: en.error_file_size,
		fr: fr.error_file_size
	}[options.languageTag ?? languageTag()](params)
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const error_invalid_format = (params = {}, options = {}) => {
	return {
		en: en.error_invalid_format,
		fr: fr.error_invalid_format
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const error_invalid_shift = (params = {}, options = {}) => {
	return {
		en: en.error_invalid_shift,
		fr: fr.error_invalid_shift
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const error_no_auth = (params = {}, options = {}) => {
	return {
		en: en.error_no_auth,
		fr: fr.error_no_auth
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const error_password_length_8 = (params = {}, options = {}) => {
	return {
		en: en.error_password_length_8,
		fr: fr.error_password_length_8
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const error_password_special_char = (params = {}, options = {}) => {
	return {
		en: en.error_password_special_char,
		fr: fr.error_password_special_char
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const error_phone_number_invalid = (params = {}, options = {}) => {
	return {
		en: en.error_phone_number_invalid,
		fr: fr.error_phone_number_invalid
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const errors_email_conflict = (params = {}, options = {}) => {
	return {
		en: en.errors_email_conflict,
		fr: fr.errors_email_conflict
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const errors_generic = (params = {}, options = {}) => {
	return {
		en: en.errors_generic,
		fr: fr.errors_generic
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const errors_organisation_forbidden_blocked = (params = {}, options = {}) => {
	return {
		en: en.errors_organisation_forbidden_blocked,
		fr: fr.errors_organisation_forbidden_blocked
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const errors_shift_conflict_duplicated = (params = {}, options = {}) => {
	return {
		en: en.errors_shift_conflict_duplicated,
		fr: fr.errors_shift_conflict_duplicated
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const errors_shift_forbidden_post_in_the_past = (params = {}, options = {}) => {
	return {
		en: en.errors_shift_forbidden_post_in_the_past,
		fr: fr.errors_shift_forbidden_post_in_the_past
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const errors_sider_conflict_already_exists = (params = {}, options = {}) => {
	return {
		en: en.errors_sider_conflict_already_exists,
		fr: fr.errors_sider_conflict_already_exists
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const errors_slot_forbidden_requested_siders_count = (params = {}, options = {}) => {
	return {
		en: en.errors_slot_forbidden_requested_siders_count,
		fr: fr.errors_slot_forbidden_requested_siders_count
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const errors_task_forbidden_not_enough_siders_selected = (params = {}, options = {}) => {
	return {
		en: en.errors_task_forbidden_not_enough_siders_selected,
		fr: fr.errors_task_forbidden_not_enough_siders_selected
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const errors_task_salary_forbidden_too_low = (params = {}, options = {}) => {
	return {
		en: en.errors_task_salary_forbidden_too_low,
		fr: fr.errors_task_salary_forbidden_too_low
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const errors_token_forbidden = (params = {}, options = {}) => {
	return {
		en: en.errors_token_forbidden,
		fr: fr.errors_token_forbidden
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const errors_user_unauthorized_login_failed = (params = {}, options = {}) => {
	return {
		en: en.errors_user_unauthorized_login_failed,
		fr: fr.errors_user_unauthorized_login_failed
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const est = (params = {}, options = {}) => {
	return {
		en: en.est,
		fr: fr.est
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const expert = (params = {}, options = {}) => {
	return {
		en: en.expert,
		fr: fr.expert
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const feedback_dialog_content = (params = {}, options = {}) => {
	return {
		en: en.feedback_dialog_content,
		fr: fr.feedback_dialog_content
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const feedback_dialog_title = (params = {}, options = {}) => {
	return {
		en: en.feedback_dialog_title,
		fr: fr.feedback_dialog_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{ name: NonNullable<unknown> }} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const feedback_form_comment_question = (params , options = {}) => {
	return {
		en: en.feedback_form_comment_question,
		fr: fr.feedback_form_comment_question
	}[options.languageTag ?? languageTag()](params)
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const feedback_form_comment_question_label = (params = {}, options = {}) => {
	return {
		en: en.feedback_form_comment_question_label,
		fr: fr.feedback_form_comment_question_label
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const feedback_form_comment_question_placeholder = (params = {}, options = {}) => {
	return {
		en: en.feedback_form_comment_question_placeholder,
		fr: fr.feedback_form_comment_question_placeholder
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const feedback_form_general_answer_1 = (params = {}, options = {}) => {
	return {
		en: en.feedback_form_general_answer_1,
		fr: fr.feedback_form_general_answer_1
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const feedback_form_general_answer_1_notice = (params = {}, options = {}) => {
	return {
		en: en.feedback_form_general_answer_1_notice,
		fr: fr.feedback_form_general_answer_1_notice
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const feedback_form_general_answer_2 = (params = {}, options = {}) => {
	return {
		en: en.feedback_form_general_answer_2,
		fr: fr.feedback_form_general_answer_2
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const feedback_form_general_answer_2_notice = (params = {}, options = {}) => {
	return {
		en: en.feedback_form_general_answer_2_notice,
		fr: fr.feedback_form_general_answer_2_notice
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{ name: NonNullable<unknown> }} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const feedback_form_general_question = (params , options = {}) => {
	return {
		en: en.feedback_form_general_question,
		fr: fr.feedback_form_general_question
	}[options.languageTag ?? languageTag()](params)
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const feedback_form_issue_choice_1 = (params = {}, options = {}) => {
	return {
		en: en.feedback_form_issue_choice_1,
		fr: fr.feedback_form_issue_choice_1
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const feedback_form_issue_choice_1_notice = (params = {}, options = {}) => {
	return {
		en: en.feedback_form_issue_choice_1_notice,
		fr: fr.feedback_form_issue_choice_1_notice
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const feedback_form_issue_choice_2 = (params = {}, options = {}) => {
	return {
		en: en.feedback_form_issue_choice_2,
		fr: fr.feedback_form_issue_choice_2
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const feedback_form_issue_choice_2_notice = (params = {}, options = {}) => {
	return {
		en: en.feedback_form_issue_choice_2_notice,
		fr: fr.feedback_form_issue_choice_2_notice
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const feedback_form_issue_description = (params = {}, options = {}) => {
	return {
		en: en.feedback_form_issue_description,
		fr: fr.feedback_form_issue_description
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{ name: NonNullable<unknown> }} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const feedback_form_issue_question = (params , options = {}) => {
	return {
		en: en.feedback_form_issue_question,
		fr: fr.feedback_form_issue_question
	}[options.languageTag ?? languageTag()](params)
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const feedback_form_send_no_comment = (params = {}, options = {}) => {
	return {
		en: en.feedback_form_send_no_comment,
		fr: fr.feedback_form_send_no_comment
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const feedback_modal_exit_description = (params = {}, options = {}) => {
	return {
		en: en.feedback_modal_exit_description,
		fr: fr.feedback_modal_exit_description
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const feedback_modal_exit_title = (params = {}, options = {}) => {
	return {
		en: en.feedback_modal_exit_title,
		fr: fr.feedback_modal_exit_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const feedback_modal_title = (params = {}, options = {}) => {
	return {
		en: en.feedback_modal_title,
		fr: fr.feedback_modal_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const feedback_tip_description = (params = {}, options = {}) => {
	return {
		en: en.feedback_tip_description,
		fr: fr.feedback_tip_description
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const feedback_tip_description_rated = (params = {}, options = {}) => {
	return {
		en: en.feedback_tip_description_rated,
		fr: fr.feedback_tip_description_rated
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const feedback_tip_title = (params = {}, options = {}) => {
	return {
		en: en.feedback_tip_title,
		fr: fr.feedback_tip_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const feedback_tip_title_rated = (params = {}, options = {}) => {
	return {
		en: en.feedback_tip_title_rated,
		fr: fr.feedback_tip_title_rated
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{ name: NonNullable<unknown> }} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const feedback_toast_not_satisfied = (params , options = {}) => {
	return {
		en: en.feedback_toast_not_satisfied,
		fr: fr.feedback_toast_not_satisfied
	}[options.languageTag ?? languageTag()](params)
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{ name: NonNullable<unknown> }} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const feedback_toast_not_saved = (params , options = {}) => {
	return {
		en: en.feedback_toast_not_saved,
		fr: fr.feedback_toast_not_saved
	}[options.languageTag ?? languageTag()](params)
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{ name: NonNullable<unknown> }} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const feedback_toast_satisfied = (params , options = {}) => {
	return {
		en: en.feedback_toast_satisfied,
		fr: fr.feedback_toast_satisfied
	}[options.languageTag ?? languageTag()](params)
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const feedback_tooltip_not_satisfied = (params = {}, options = {}) => {
	return {
		en: en.feedback_tooltip_not_satisfied,
		fr: fr.feedback_tooltip_not_satisfied
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const feedback_tooltip_satisfied = (params = {}, options = {}) => {
	return {
		en: en.feedback_tooltip_satisfied,
		fr: fr.feedback_tooltip_satisfied
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const fin = (params = {}, options = {}) => {
	return {
		en: en.fin,
		fr: fr.fin
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const finish = (params = {}, options = {}) => {
	return {
		en: en.finish,
		fr: fr.finish
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const finish_draft = (params = {}, options = {}) => {
	return {
		en: en.finish_draft,
		fr: fr.finish_draft
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const fluent = (params = {}, options = {}) => {
	return {
		en: en.fluent,
		fr: fr.fluent
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const fre = (params = {}, options = {}) => {
	return {
		en: en.fre,
		fr: fr.fre
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ger = (params = {}, options = {}) => {
	return {
		en: en.ger,
		fr: fr.ger
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const go_to_contracts_signature_page = (params = {}, options = {}) => {
	return {
		en: en.go_to_contracts_signature_page,
		fr: fr.go_to_contracts_signature_page
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const gre = (params = {}, options = {}) => {
	return {
		en: en.gre,
		fr: fr.gre
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const grn = (params = {}, options = {}) => {
	return {
		en: en.grn,
		fr: fr.grn
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const guard_modal_incomplete = (params = {}, options = {}) => {
	return {
		en: en.guard_modal_incomplete,
		fr: fr.guard_modal_incomplete
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const guard_modal_legal = (params = {}, options = {}) => {
	return {
		en: en.guard_modal_legal,
		fr: fr.guard_modal_legal
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const guard_modal_payment = (params = {}, options = {}) => {
	return {
		en: en.guard_modal_payment,
		fr: fr.guard_modal_payment
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const guard_modal_policy_hours = (params = {}, options = {}) => {
	return {
		en: en.guard_modal_policy_hours,
		fr: fr.guard_modal_policy_hours
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const guard_modal_policy_indemnities = (params = {}, options = {}) => {
	return {
		en: en.guard_modal_policy_indemnities,
		fr: fr.guard_modal_policy_indemnities
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const guard_modal_profile = (params = {}, options = {}) => {
	return {
		en: en.guard_modal_profile,
		fr: fr.guard_modal_profile
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const guard_modal_submit = (params = {}, options = {}) => {
	return {
		en: en.guard_modal_submit,
		fr: fr.guard_modal_submit
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const guard_modal_subtitle = (params = {}, options = {}) => {
	return {
		en: en.guard_modal_subtitle,
		fr: fr.guard_modal_subtitle
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const guard_modal_title = (params = {}, options = {}) => {
	return {
		en: en.guard_modal_title,
		fr: fr.guard_modal_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const header_backhome = (params = {}, options = {}) => {
	return {
		en: en.header_backhome,
		fr: fr.header_backhome
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const header_contracts = (params = {}, options = {}) => {
	return {
		en: en.header_contracts,
		fr: fr.header_contracts
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const header_conversations = (params = {}, options = {}) => {
	return {
		en: en.header_conversations,
		fr: fr.header_conversations
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const header_cta_task = (params = {}, options = {}) => {
	return {
		en: en.header_cta_task,
		fr: fr.header_cta_task
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const header_cta_team = (params = {}, options = {}) => {
	return {
		en: en.header_cta_team,
		fr: fr.header_cta_team
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const header_dashboard = (params = {}, options = {}) => {
	return {
		en: en.header_dashboard,
		fr: fr.header_dashboard
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const header_dropdown_help = (params = {}, options = {}) => {
	return {
		en: en.header_dropdown_help,
		fr: fr.header_dropdown_help
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const header_dropdown_language = (params = {}, options = {}) => {
	return {
		en: en.header_dropdown_language,
		fr: fr.header_dropdown_language
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const header_dropdown_logout = (params = {}, options = {}) => {
	return {
		en: en.header_dropdown_logout,
		fr: fr.header_dropdown_logout
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const header_dropdown_payment_settings = (params = {}, options = {}) => {
	return {
		en: en.header_dropdown_payment_settings,
		fr: fr.header_dropdown_payment_settings
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const header_dropdown_personal_settings = (params = {}, options = {}) => {
	return {
		en: en.header_dropdown_personal_settings,
		fr: fr.header_dropdown_personal_settings
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const header_dropdown_profile = (params = {}, options = {}) => {
	return {
		en: en.header_dropdown_profile,
		fr: fr.header_dropdown_profile
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const header_dropdown_settings = (params = {}, options = {}) => {
	return {
		en: en.header_dropdown_settings,
		fr: fr.header_dropdown_settings
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const header_dropdown_team = (params = {}, options = {}) => {
	return {
		en: en.header_dropdown_team,
		fr: fr.header_dropdown_team
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const header_invoices = (params = {}, options = {}) => {
	return {
		en: en.header_invoices,
		fr: fr.header_invoices
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const header_job_descriptions = (params = {}, options = {}) => {
	return {
		en: en.header_job_descriptions,
		fr: fr.header_job_descriptions
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const header_payments = (params = {}, options = {}) => {
	return {
		en: en.header_payments,
		fr: fr.header_payments
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const header_planning = (params = {}, options = {}) => {
	return {
		en: en.header_planning,
		fr: fr.header_planning
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const header_search_company = (params = {}, options = {}) => {
	return {
		en: en.header_search_company,
		fr: fr.header_search_company
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const header_settings = (params = {}, options = {}) => {
	return {
		en: en.header_settings,
		fr: fr.header_settings
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const header_siders = (params = {}, options = {}) => {
	return {
		en: en.header_siders,
		fr: fr.header_siders
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const header_switch_search_placeholder = (params = {}, options = {}) => {
	return {
		en: en.header_switch_search_placeholder,
		fr: fr.header_switch_search_placeholder
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const header_tasks = (params = {}, options = {}) => {
	return {
		en: en.header_tasks,
		fr: fr.header_tasks
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const header_team = (params = {}, options = {}) => {
	return {
		en: en.header_team,
		fr: fr.header_team
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const header_timesheets = (params = {}, options = {}) => {
	return {
		en: en.header_timesheets,
		fr: fr.header_timesheets
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const heb = (params = {}, options = {}) => {
	return {
		en: en.heb,
		fr: fr.heb
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const hin = (params = {}, options = {}) => {
	return {
		en: en.hin,
		fr: fr.hin
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const home_company_logo_button_tooltip = (params = {}, options = {}) => {
	return {
		en: en.home_company_logo_button_tooltip,
		fr: fr.home_company_logo_button_tooltip
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const home_connecttochild_button = (params = {}, options = {}) => {
	return {
		en: en.home_connecttochild_button,
		fr: fr.home_connecttochild_button
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const home_search_child_label = (params = {}, options = {}) => {
	return {
		en: en.home_search_child_label,
		fr: fr.home_search_child_label
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const home_search_child_placeholder = (params = {}, options = {}) => {
	return {
		en: en.home_search_child_placeholder,
		fr: fr.home_search_child_placeholder
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const home_welcome_subtitle = (params = {}, options = {}) => {
	return {
		en: en.home_welcome_subtitle,
		fr: fr.home_welcome_subtitle
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{ groupName: NonNullable<unknown> }} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const home_welcome_title = (params , options = {}) => {
	return {
		en: en.home_welcome_title,
		fr: fr.home_welcome_title
	}[options.languageTag ?? languageTag()](params)
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const horizon_acquisition_argument_0 = (params = {}, options = {}) => {
	return {
		en: en.horizon_acquisition_argument_0,
		fr: fr.horizon_acquisition_argument_0
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const horizon_acquisition_argument_1 = (params = {}, options = {}) => {
	return {
		en: en.horizon_acquisition_argument_1,
		fr: fr.horizon_acquisition_argument_1
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const horizon_acquisition_argument_2 = (params = {}, options = {}) => {
	return {
		en: en.horizon_acquisition_argument_2,
		fr: fr.horizon_acquisition_argument_2
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const horizon_acquisition_title = (params = {}, options = {}) => {
	return {
		en: en.horizon_acquisition_title,
		fr: fr.horizon_acquisition_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const horizon_dialog_message = (params = {}, options = {}) => {
	return {
		en: en.horizon_dialog_message,
		fr: fr.horizon_dialog_message
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const horizon_dialog_primary_action = (params = {}, options = {}) => {
	return {
		en: en.horizon_dialog_primary_action,
		fr: fr.horizon_dialog_primary_action
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const horizon_dialog_secondary_action = (params = {}, options = {}) => {
	return {
		en: en.horizon_dialog_secondary_action,
		fr: fr.horizon_dialog_secondary_action
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const horizon_dialog_title = (params = {}, options = {}) => {
	return {
		en: en.horizon_dialog_title,
		fr: fr.horizon_dialog_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const horizon_differentiate_title = (params = {}, options = {}) => {
	return {
		en: en.horizon_differentiate_title,
		fr: fr.horizon_differentiate_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const horizon_modal_address_label = (params = {}, options = {}) => {
	return {
		en: en.horizon_modal_address_label,
		fr: fr.horizon_modal_address_label
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const horizon_modal_commentary_label = (params = {}, options = {}) => {
	return {
		en: en.horizon_modal_commentary_label,
		fr: fr.horizon_modal_commentary_label
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const horizon_modal_commentary_placeholder = (params = {}, options = {}) => {
	return {
		en: en.horizon_modal_commentary_placeholder,
		fr: fr.horizon_modal_commentary_placeholder
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const horizon_modal_confirmation = (params = {}, options = {}) => {
	return {
		en: en.horizon_modal_confirmation,
		fr: fr.horizon_modal_confirmation
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const horizon_modal_jobdesc_document_label = (params = {}, options = {}) => {
	return {
		en: en.horizon_modal_jobdesc_document_label,
		fr: fr.horizon_modal_jobdesc_document_label
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const horizon_modal_jobdesc_label = (params = {}, options = {}) => {
	return {
		en: en.horizon_modal_jobdesc_label,
		fr: fr.horizon_modal_jobdesc_label
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const horizon_modal_jobdesc_link_label = (params = {}, options = {}) => {
	return {
		en: en.horizon_modal_jobdesc_link_label,
		fr: fr.horizon_modal_jobdesc_link_label
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const horizon_modal_jobdesc_none_label = (params = {}, options = {}) => {
	return {
		en: en.horizon_modal_jobdesc_none_label,
		fr: fr.horizon_modal_jobdesc_none_label
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const horizon_modal_jobtitle_label = (params = {}, options = {}) => {
	return {
		en: en.horizon_modal_jobtitle_label,
		fr: fr.horizon_modal_jobtitle_label
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const horizon_modal_positions_label = (params = {}, options = {}) => {
	return {
		en: en.horizon_modal_positions_label,
		fr: fr.horizon_modal_positions_label
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const horizon_modal_salary_label = (params = {}, options = {}) => {
	return {
		en: en.horizon_modal_salary_label,
		fr: fr.horizon_modal_salary_label
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const horizon_modal_startdate_label = (params = {}, options = {}) => {
	return {
		en: en.horizon_modal_startdate_label,
		fr: fr.horizon_modal_startdate_label
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const horizon_modal_title = (params = {}, options = {}) => {
	return {
		en: en.horizon_modal_title,
		fr: fr.horizon_modal_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const horizon_nav_button_desc = (params = {}, options = {}) => {
	return {
		en: en.horizon_nav_button_desc,
		fr: fr.horizon_nav_button_desc
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const horizon_nav_button_title = (params = {}, options = {}) => {
	return {
		en: en.horizon_nav_button_title,
		fr: fr.horizon_nav_button_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const horizon_pool_argument_0 = (params = {}, options = {}) => {
	return {
		en: en.horizon_pool_argument_0,
		fr: fr.horizon_pool_argument_0
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const horizon_pool_argument_1 = (params = {}, options = {}) => {
	return {
		en: en.horizon_pool_argument_1,
		fr: fr.horizon_pool_argument_1
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const horizon_pool_argument_2 = (params = {}, options = {}) => {
	return {
		en: en.horizon_pool_argument_2,
		fr: fr.horizon_pool_argument_2
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const horizon_pool_title = (params = {}, options = {}) => {
	return {
		en: en.horizon_pool_title,
		fr: fr.horizon_pool_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const horizon_reassurance_0 = (params = {}, options = {}) => {
	return {
		en: en.horizon_reassurance_0,
		fr: fr.horizon_reassurance_0
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const horizon_reassurance_1 = (params = {}, options = {}) => {
	return {
		en: en.horizon_reassurance_1,
		fr: fr.horizon_reassurance_1
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const horizon_reassurance_2 = (params = {}, options = {}) => {
	return {
		en: en.horizon_reassurance_2,
		fr: fr.horizon_reassurance_2
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const horizon_reassurance_3 = (params = {}, options = {}) => {
	return {
		en: en.horizon_reassurance_3,
		fr: fr.horizon_reassurance_3
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const horizon_step_0 = (params = {}, options = {}) => {
	return {
		en: en.horizon_step_0,
		fr: fr.horizon_step_0
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const horizon_step_1 = (params = {}, options = {}) => {
	return {
		en: en.horizon_step_1,
		fr: fr.horizon_step_1
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const horizon_step_2 = (params = {}, options = {}) => {
	return {
		en: en.horizon_step_2,
		fr: fr.horizon_step_2
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const horizon_step_3 = (params = {}, options = {}) => {
	return {
		en: en.horizon_step_3,
		fr: fr.horizon_step_3
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const horizon_step_4 = (params = {}, options = {}) => {
	return {
		en: en.horizon_step_4,
		fr: fr.horizon_step_4
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const horizon_steps_title = (params = {}, options = {}) => {
	return {
		en: en.horizon_steps_title,
		fr: fr.horizon_steps_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const horizon_subtitle = (params = {}, options = {}) => {
	return {
		en: en.horizon_subtitle,
		fr: fr.horizon_subtitle
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const horizon_title = (params = {}, options = {}) => {
	return {
		en: en.horizon_title,
		fr: fr.horizon_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const hrv = (params = {}, options = {}) => {
	return {
		en: en.hrv,
		fr: fr.hrv
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const hun = (params = {}, options = {}) => {
	return {
		en: en.hun,
		fr: fr.hun
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ice = (params = {}, options = {}) => {
	return {
		en: en.ice,
		fr: fr.ice
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ind = (params = {}, options = {}) => {
	return {
		en: en.ind,
		fr: fr.ind
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const industry_logistics = (params = {}, options = {}) => {
	return {
		en: en.industry_logistics,
		fr: fr.industry_logistics
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const industry_office = (params = {}, options = {}) => {
	return {
		en: en.industry_office,
		fr: fr.industry_office
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const industry_retail = (params = {}, options = {}) => {
	return {
		en: en.industry_retail,
		fr: fr.industry_retail
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const intermediate = (params = {}, options = {}) => {
	return {
		en: en.intermediate,
		fr: fr.intermediate
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const invoices_amount = (params = {}, options = {}) => {
	return {
		en: en.invoices_amount,
		fr: fr.invoices_amount
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const invoices_date = (params = {}, options = {}) => {
	return {
		en: en.invoices_date,
		fr: fr.invoices_date
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const invoices_download = (params = {}, options = {}) => {
	return {
		en: en.invoices_download,
		fr: fr.invoices_download
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const invoices_download_error = (params = {}, options = {}) => {
	return {
		en: en.invoices_download_error,
		fr: fr.invoices_download_error
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{ month: NonNullable<unknown> }} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const invoices_monthly_download = (params , options = {}) => {
	return {
		en: en.invoices_monthly_download,
		fr: fr.invoices_monthly_download
	}[options.languageTag ?? languageTag()](params)
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{ month: NonNullable<unknown> }} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const invoices_monthly_download_failed = (params , options = {}) => {
	return {
		en: en.invoices_monthly_download_failed,
		fr: fr.invoices_monthly_download_failed
	}[options.languageTag ?? languageTag()](params)
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const invoices_monthly_error_body = (params = {}, options = {}) => {
	return {
		en: en.invoices_monthly_error_body,
		fr: fr.invoices_monthly_error_body
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const invoices_monthly_error_title = (params = {}, options = {}) => {
	return {
		en: en.invoices_monthly_error_title,
		fr: fr.invoices_monthly_error_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{ month: NonNullable<unknown>, amount: NonNullable<unknown> }} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const invoices_monthly_notice = (params , options = {}) => {
	return {
		en: en.invoices_monthly_notice,
		fr: fr.invoices_monthly_notice
	}[options.languageTag ?? languageTag()](params)
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{ month: NonNullable<unknown>, amount: NonNullable<unknown> }} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const invoices_monthly_notice_failed = (params , options = {}) => {
	return {
		en: en.invoices_monthly_notice_failed,
		fr: fr.invoices_monthly_notice_failed
	}[options.languageTag ?? languageTag()](params)
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const invoices_number = (params = {}, options = {}) => {
	return {
		en: en.invoices_number,
		fr: fr.invoices_number
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const invoices_sider = (params = {}, options = {}) => {
	return {
		en: en.invoices_sider,
		fr: fr.invoices_sider
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const invoices_status = (params = {}, options = {}) => {
	return {
		en: en.invoices_status,
		fr: fr.invoices_status
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const invoices_status_paid = (params = {}, options = {}) => {
	return {
		en: en.invoices_status_paid,
		fr: fr.invoices_status_paid
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const invoices_status_unpaid = (params = {}, options = {}) => {
	return {
		en: en.invoices_status_unpaid,
		fr: fr.invoices_status_unpaid
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const invoices_task = (params = {}, options = {}) => {
	return {
		en: en.invoices_task,
		fr: fr.invoices_task
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const invoices_type = (params = {}, options = {}) => {
	return {
		en: en.invoices_type,
		fr: fr.invoices_type
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const invoices_type_creditNote = (params = {}, options = {}) => {
	return {
		en: en.invoices_type_creditNote,
		fr: fr.invoices_type_creditNote
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const invoices_type_invoice = (params = {}, options = {}) => {
	return {
		en: en.invoices_type_invoice,
		fr: fr.invoices_type_invoice
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ita = (params = {}, options = {}) => {
	return {
		en: en.ita,
		fr: fr.ita
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_copy_id = (params = {}, options = {}) => {
	return {
		en: en.job_descriptions_copy_id,
		fr: fr.job_descriptions_copy_id
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_create_button = (params = {}, options = {}) => {
	return {
		en: en.job_descriptions_create_button,
		fr: fr.job_descriptions_create_button
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_creation_conditions_context_goals_characters = (params = {}, options = {}) => {
	return {
		en: en.job_descriptions_creation_conditions_context_goals_characters,
		fr: fr.job_descriptions_creation_conditions_context_goals_characters
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_creation_conditions_context_goals_placeholder = (params = {}, options = {}) => {
	return {
		en: en.job_descriptions_creation_conditions_context_goals_placeholder,
		fr: fr.job_descriptions_creation_conditions_context_goals_placeholder
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_creation_conditions_context_goals_title = (params = {}, options = {}) => {
	return {
		en: en.job_descriptions_creation_conditions_context_goals_title,
		fr: fr.job_descriptions_creation_conditions_context_goals_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_creation_conditions_context_subtitle = (params = {}, options = {}) => {
	return {
		en: en.job_descriptions_creation_conditions_context_subtitle,
		fr: fr.job_descriptions_creation_conditions_context_subtitle
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_creation_conditions_context_title = (params = {}, options = {}) => {
	return {
		en: en.job_descriptions_creation_conditions_context_title,
		fr: fr.job_descriptions_creation_conditions_context_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_creation_conditions_equipment_provider_company = (params = {}, options = {}) => {
	return {
		en: en.job_descriptions_creation_conditions_equipment_provider_company,
		fr: fr.job_descriptions_creation_conditions_equipment_provider_company
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_creation_conditions_equipment_provider_side = (params = {}, options = {}) => {
	return {
		en: en.job_descriptions_creation_conditions_equipment_provider_side,
		fr: fr.job_descriptions_creation_conditions_equipment_provider_side
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_creation_conditions_equipment_provider_sider = (params = {}, options = {}) => {
	return {
		en: en.job_descriptions_creation_conditions_equipment_provider_sider,
		fr: fr.job_descriptions_creation_conditions_equipment_provider_sider
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_creation_conditions_equipment_table_header_label = (params = {}, options = {}) => {
	return {
		en: en.job_descriptions_creation_conditions_equipment_table_header_label,
		fr: fr.job_descriptions_creation_conditions_equipment_table_header_label
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_creation_conditions_equipment_table_header_value = (params = {}, options = {}) => {
	return {
		en: en.job_descriptions_creation_conditions_equipment_table_header_value,
		fr: fr.job_descriptions_creation_conditions_equipment_table_header_value
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_creation_conditions_equipments_dresscode_placeholder = (params = {}, options = {}) => {
	return {
		en: en.job_descriptions_creation_conditions_equipments_dresscode_placeholder,
		fr: fr.job_descriptions_creation_conditions_equipments_dresscode_placeholder
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_creation_conditions_equipments_dresscode_title = (params = {}, options = {}) => {
	return {
		en: en.job_descriptions_creation_conditions_equipments_dresscode_title,
		fr: fr.job_descriptions_creation_conditions_equipments_dresscode_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_creation_conditions_equipments_other_add_button = (params = {}, options = {}) => {
	return {
		en: en.job_descriptions_creation_conditions_equipments_other_add_button,
		fr: fr.job_descriptions_creation_conditions_equipments_other_add_button
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_creation_conditions_equipments_other_add_custom_button = (params = {}, options = {}) => {
	return {
		en: en.job_descriptions_creation_conditions_equipments_other_add_custom_button,
		fr: fr.job_descriptions_creation_conditions_equipments_other_add_custom_button
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_creation_conditions_equipments_other_custom_modal_input_label = (params = {}, options = {}) => {
	return {
		en: en.job_descriptions_creation_conditions_equipments_other_custom_modal_input_label,
		fr: fr.job_descriptions_creation_conditions_equipments_other_custom_modal_input_label
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_creation_conditions_equipments_other_custom_modal_tip = (params = {}, options = {}) => {
	return {
		en: en.job_descriptions_creation_conditions_equipments_other_custom_modal_tip,
		fr: fr.job_descriptions_creation_conditions_equipments_other_custom_modal_tip
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_creation_conditions_equipments_other_delete_button = (params = {}, options = {}) => {
	return {
		en: en.job_descriptions_creation_conditions_equipments_other_delete_button,
		fr: fr.job_descriptions_creation_conditions_equipments_other_delete_button
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_creation_conditions_equipments_other_subtitle = (params = {}, options = {}) => {
	return {
		en: en.job_descriptions_creation_conditions_equipments_other_subtitle,
		fr: fr.job_descriptions_creation_conditions_equipments_other_subtitle
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_creation_conditions_equipments_other_title = (params = {}, options = {}) => {
	return {
		en: en.job_descriptions_creation_conditions_equipments_other_title,
		fr: fr.job_descriptions_creation_conditions_equipments_other_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_creation_conditions_equipments_risks_label1 = (params = {}, options = {}) => {
	return {
		en: en.job_descriptions_creation_conditions_equipments_risks_label1,
		fr: fr.job_descriptions_creation_conditions_equipments_risks_label1
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_creation_conditions_equipments_risks_label2 = (params = {}, options = {}) => {
	return {
		en: en.job_descriptions_creation_conditions_equipments_risks_label2,
		fr: fr.job_descriptions_creation_conditions_equipments_risks_label2
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_creation_conditions_equipments_risks_options = (params = {}, options = {}) => {
	return {
		en: en.job_descriptions_creation_conditions_equipments_risks_options,
		fr: fr.job_descriptions_creation_conditions_equipments_risks_options
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_creation_conditions_equipments_risks_placeholder = (params = {}, options = {}) => {
	return {
		en: en.job_descriptions_creation_conditions_equipments_risks_placeholder,
		fr: fr.job_descriptions_creation_conditions_equipments_risks_placeholder
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_creation_conditions_equipments_risks_subtitle = (params = {}, options = {}) => {
	return {
		en: en.job_descriptions_creation_conditions_equipments_risks_subtitle,
		fr: fr.job_descriptions_creation_conditions_equipments_risks_subtitle
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_creation_conditions_equipments_risks_title = (params = {}, options = {}) => {
	return {
		en: en.job_descriptions_creation_conditions_equipments_risks_title,
		fr: fr.job_descriptions_creation_conditions_equipments_risks_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_creation_conditions_equipments_security_add_button = (params = {}, options = {}) => {
	return {
		en: en.job_descriptions_creation_conditions_equipments_security_add_button,
		fr: fr.job_descriptions_creation_conditions_equipments_security_add_button
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_creation_conditions_equipments_security_add_custom_button = (params = {}, options = {}) => {
	return {
		en: en.job_descriptions_creation_conditions_equipments_security_add_custom_button,
		fr: fr.job_descriptions_creation_conditions_equipments_security_add_custom_button
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_creation_conditions_equipments_security_custom_modal_input_label = (params = {}, options = {}) => {
	return {
		en: en.job_descriptions_creation_conditions_equipments_security_custom_modal_input_label,
		fr: fr.job_descriptions_creation_conditions_equipments_security_custom_modal_input_label
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_creation_conditions_equipments_security_custom_modal_tip = (params = {}, options = {}) => {
	return {
		en: en.job_descriptions_creation_conditions_equipments_security_custom_modal_tip,
		fr: fr.job_descriptions_creation_conditions_equipments_security_custom_modal_tip
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_creation_conditions_equipments_security_delete_button = (params = {}, options = {}) => {
	return {
		en: en.job_descriptions_creation_conditions_equipments_security_delete_button,
		fr: fr.job_descriptions_creation_conditions_equipments_security_delete_button
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_creation_conditions_equipments_security_modal_choice_no = (params = {}, options = {}) => {
	return {
		en: en.job_descriptions_creation_conditions_equipments_security_modal_choice_no,
		fr: fr.job_descriptions_creation_conditions_equipments_security_modal_choice_no
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_creation_conditions_equipments_security_modal_choice_yes = (params = {}, options = {}) => {
	return {
		en: en.job_descriptions_creation_conditions_equipments_security_modal_choice_yes,
		fr: fr.job_descriptions_creation_conditions_equipments_security_modal_choice_yes
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_creation_conditions_equipments_security_modal_label = (params = {}, options = {}) => {
	return {
		en: en.job_descriptions_creation_conditions_equipments_security_modal_label,
		fr: fr.job_descriptions_creation_conditions_equipments_security_modal_label
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_creation_conditions_equipments_security_modal_subtitle = (params = {}, options = {}) => {
	return {
		en: en.job_descriptions_creation_conditions_equipments_security_modal_subtitle,
		fr: fr.job_descriptions_creation_conditions_equipments_security_modal_subtitle
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_creation_conditions_equipments_security_subtitle = (params = {}, options = {}) => {
	return {
		en: en.job_descriptions_creation_conditions_equipments_security_subtitle,
		fr: fr.job_descriptions_creation_conditions_equipments_security_subtitle
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_creation_conditions_equipments_security_title = (params = {}, options = {}) => {
	return {
		en: en.job_descriptions_creation_conditions_equipments_security_title,
		fr: fr.job_descriptions_creation_conditions_equipments_security_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_creation_conditions_equipments_subtitle = (params = {}, options = {}) => {
	return {
		en: en.job_descriptions_creation_conditions_equipments_subtitle,
		fr: fr.job_descriptions_creation_conditions_equipments_subtitle
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_creation_conditions_equipments_title = (params = {}, options = {}) => {
	return {
		en: en.job_descriptions_creation_conditions_equipments_title,
		fr: fr.job_descriptions_creation_conditions_equipments_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_creation_conditions_step_title = (params = {}, options = {}) => {
	return {
		en: en.job_descriptions_creation_conditions_step_title,
		fr: fr.job_descriptions_creation_conditions_step_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{ numberOfTasks: NonNullable<unknown> }} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_creation_job_jobtitle_subtasks = (params , options = {}) => {
	return {
		en: en.job_descriptions_creation_job_jobtitle_subtasks,
		fr: fr.job_descriptions_creation_job_jobtitle_subtasks
	}[options.languageTag ?? languageTag()](params)
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_creation_job_jobtitle_subtitle = (params = {}, options = {}) => {
	return {
		en: en.job_descriptions_creation_job_jobtitle_subtitle,
		fr: fr.job_descriptions_creation_job_jobtitle_subtitle
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_creation_job_jobtitle_title = (params = {}, options = {}) => {
	return {
		en: en.job_descriptions_creation_job_jobtitle_title,
		fr: fr.job_descriptions_creation_job_jobtitle_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_creation_job_name_title = (params = {}, options = {}) => {
	return {
		en: en.job_descriptions_creation_job_name_title,
		fr: fr.job_descriptions_creation_job_name_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_creation_job_step_title = (params = {}, options = {}) => {
	return {
		en: en.job_descriptions_creation_job_step_title,
		fr: fr.job_descriptions_creation_job_step_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_creation_job_subtasks_custom_add_button = (params = {}, options = {}) => {
	return {
		en: en.job_descriptions_creation_job_subtasks_custom_add_button,
		fr: fr.job_descriptions_creation_job_subtasks_custom_add_button
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{ searchInput: NonNullable<unknown> }} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_creation_job_subtasks_custom_add_button_input = (params , options = {}) => {
	return {
		en: en.job_descriptions_creation_job_subtasks_custom_add_button_input,
		fr: fr.job_descriptions_creation_job_subtasks_custom_add_button_input
	}[options.languageTag ?? languageTag()](params)
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_creation_job_subtasks_custom_modal_label = (params = {}, options = {}) => {
	return {
		en: en.job_descriptions_creation_job_subtasks_custom_modal_label,
		fr: fr.job_descriptions_creation_job_subtasks_custom_modal_label
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_creation_job_subtasks_custom_modal_placeholder = (params = {}, options = {}) => {
	return {
		en: en.job_descriptions_creation_job_subtasks_custom_modal_placeholder,
		fr: fr.job_descriptions_creation_job_subtasks_custom_modal_placeholder
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_creation_job_subtasks_custom_modal_subtitle = (params = {}, options = {}) => {
	return {
		en: en.job_descriptions_creation_job_subtasks_custom_modal_subtitle,
		fr: fr.job_descriptions_creation_job_subtasks_custom_modal_subtitle
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_creation_job_subtasks_custom_modal_title = (params = {}, options = {}) => {
	return {
		en: en.job_descriptions_creation_job_subtasks_custom_modal_title,
		fr: fr.job_descriptions_creation_job_subtasks_custom_modal_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_creation_job_subtasks_subtitle = (params = {}, options = {}) => {
	return {
		en: en.job_descriptions_creation_job_subtasks_subtitle,
		fr: fr.job_descriptions_creation_job_subtasks_subtitle
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_creation_job_subtasks_title = (params = {}, options = {}) => {
	return {
		en: en.job_descriptions_creation_job_subtasks_title,
		fr: fr.job_descriptions_creation_job_subtasks_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_creation_location_step_title = (params = {}, options = {}) => {
	return {
		en: en.job_descriptions_creation_location_step_title,
		fr: fr.job_descriptions_creation_location_step_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_creation_salary_input_error = (params = {}, options = {}) => {
	return {
		en: en.job_descriptions_creation_salary_input_error,
		fr: fr.job_descriptions_creation_salary_input_error
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_creation_salary_input_error_underflow = (params = {}, options = {}) => {
	return {
		en: en.job_descriptions_creation_salary_input_error_underflow,
		fr: fr.job_descriptions_creation_salary_input_error_underflow
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_creation_salary_input_label = (params = {}, options = {}) => {
	return {
		en: en.job_descriptions_creation_salary_input_label,
		fr: fr.job_descriptions_creation_salary_input_label
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{ minimumHourlyRate: NonNullable<unknown> }} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_creation_salary_input_placeholder = (params , options = {}) => {
	return {
		en: en.job_descriptions_creation_salary_input_placeholder,
		fr: fr.job_descriptions_creation_salary_input_placeholder
	}[options.languageTag ?? languageTag()](params)
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_creation_salary_label2 = (params = {}, options = {}) => {
	return {
		en: en.job_descriptions_creation_salary_label2,
		fr: fr.job_descriptions_creation_salary_label2
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_creation_salary_raw = (params = {}, options = {}) => {
	return {
		en: en.job_descriptions_creation_salary_raw,
		fr: fr.job_descriptions_creation_salary_raw
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_creation_salary_step_title = (params = {}, options = {}) => {
	return {
		en: en.job_descriptions_creation_salary_step_title,
		fr: fr.job_descriptions_creation_salary_step_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_creation_salary_subtitle = (params = {}, options = {}) => {
	return {
		en: en.job_descriptions_creation_salary_subtitle,
		fr: fr.job_descriptions_creation_salary_subtitle
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_creation_salary_title = (params = {}, options = {}) => {
	return {
		en: en.job_descriptions_creation_salary_title,
		fr: fr.job_descriptions_creation_salary_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_creation_skills_experience_label = (params = {}, options = {}) => {
	return {
		en: en.job_descriptions_creation_skills_experience_label,
		fr: fr.job_descriptions_creation_skills_experience_label
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_creation_skills_experience_placeholder = (params = {}, options = {}) => {
	return {
		en: en.job_descriptions_creation_skills_experience_placeholder,
		fr: fr.job_descriptions_creation_skills_experience_placeholder
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_creation_skills_experience_subtitle = (params = {}, options = {}) => {
	return {
		en: en.job_descriptions_creation_skills_experience_subtitle,
		fr: fr.job_descriptions_creation_skills_experience_subtitle
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_creation_skills_experience_title = (params = {}, options = {}) => {
	return {
		en: en.job_descriptions_creation_skills_experience_title,
		fr: fr.job_descriptions_creation_skills_experience_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_creation_skills_languages_add_button = (params = {}, options = {}) => {
	return {
		en: en.job_descriptions_creation_skills_languages_add_button,
		fr: fr.job_descriptions_creation_skills_languages_add_button
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_creation_skills_languages_edit_disclaimer = (params = {}, options = {}) => {
	return {
		en: en.job_descriptions_creation_skills_languages_edit_disclaimer,
		fr: fr.job_descriptions_creation_skills_languages_edit_disclaimer
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_creation_skills_languages_modal_label = (params = {}, options = {}) => {
	return {
		en: en.job_descriptions_creation_skills_languages_modal_label,
		fr: fr.job_descriptions_creation_skills_languages_modal_label
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_creation_skills_languages_modal_placeholder = (params = {}, options = {}) => {
	return {
		en: en.job_descriptions_creation_skills_languages_modal_placeholder,
		fr: fr.job_descriptions_creation_skills_languages_modal_placeholder
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_creation_skills_languages_modal_subtitle = (params = {}, options = {}) => {
	return {
		en: en.job_descriptions_creation_skills_languages_modal_subtitle,
		fr: fr.job_descriptions_creation_skills_languages_modal_subtitle
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_creation_skills_languages_modal_title = (params = {}, options = {}) => {
	return {
		en: en.job_descriptions_creation_skills_languages_modal_title,
		fr: fr.job_descriptions_creation_skills_languages_modal_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_creation_skills_languages_subtitles = (params = {}, options = {}) => {
	return {
		en: en.job_descriptions_creation_skills_languages_subtitles,
		fr: fr.job_descriptions_creation_skills_languages_subtitles
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_creation_skills_languages_title = (params = {}, options = {}) => {
	return {
		en: en.job_descriptions_creation_skills_languages_title,
		fr: fr.job_descriptions_creation_skills_languages_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_creation_skills_languages_tools_subtitle = (params = {}, options = {}) => {
	return {
		en: en.job_descriptions_creation_skills_languages_tools_subtitle,
		fr: fr.job_descriptions_creation_skills_languages_tools_subtitle
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_creation_skills_languages_tools_title = (params = {}, options = {}) => {
	return {
		en: en.job_descriptions_creation_skills_languages_tools_title,
		fr: fr.job_descriptions_creation_skills_languages_tools_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_creation_skills_licence_add_button = (params = {}, options = {}) => {
	return {
		en: en.job_descriptions_creation_skills_licence_add_button,
		fr: fr.job_descriptions_creation_skills_licence_add_button
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_creation_skills_licence_label = (params = {}, options = {}) => {
	return {
		en: en.job_descriptions_creation_skills_licence_label,
		fr: fr.job_descriptions_creation_skills_licence_label
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_creation_skills_licence_subtitle = (params = {}, options = {}) => {
	return {
		en: en.job_descriptions_creation_skills_licence_subtitle,
		fr: fr.job_descriptions_creation_skills_licence_subtitle
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_creation_skills_licence_title = (params = {}, options = {}) => {
	return {
		en: en.job_descriptions_creation_skills_licence_title,
		fr: fr.job_descriptions_creation_skills_licence_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_creation_skills_step_title = (params = {}, options = {}) => {
	return {
		en: en.job_descriptions_creation_skills_step_title,
		fr: fr.job_descriptions_creation_skills_step_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_creation_skills_tools_add_button = (params = {}, options = {}) => {
	return {
		en: en.job_descriptions_creation_skills_tools_add_button,
		fr: fr.job_descriptions_creation_skills_tools_add_button
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_creation_skills_tools_add_custom_button = (params = {}, options = {}) => {
	return {
		en: en.job_descriptions_creation_skills_tools_add_custom_button,
		fr: fr.job_descriptions_creation_skills_tools_add_custom_button
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_creation_skills_tools_custom_modal_label = (params = {}, options = {}) => {
	return {
		en: en.job_descriptions_creation_skills_tools_custom_modal_label,
		fr: fr.job_descriptions_creation_skills_tools_custom_modal_label
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_creation_skills_tools_custom_modal_placeholder = (params = {}, options = {}) => {
	return {
		en: en.job_descriptions_creation_skills_tools_custom_modal_placeholder,
		fr: fr.job_descriptions_creation_skills_tools_custom_modal_placeholder
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_creation_skills_tools_custom_modal_tip = (params = {}, options = {}) => {
	return {
		en: en.job_descriptions_creation_skills_tools_custom_modal_tip,
		fr: fr.job_descriptions_creation_skills_tools_custom_modal_tip
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_creation_skills_tools_modal_excel_feedback = (params = {}, options = {}) => {
	return {
		en: en.job_descriptions_creation_skills_tools_modal_excel_feedback,
		fr: fr.job_descriptions_creation_skills_tools_modal_excel_feedback
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_creation_skills_tools_modal_placeholder = (params = {}, options = {}) => {
	return {
		en: en.job_descriptions_creation_skills_tools_modal_placeholder,
		fr: fr.job_descriptions_creation_skills_tools_modal_placeholder
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_creation_skills_tools_modal_subtitle = (params = {}, options = {}) => {
	return {
		en: en.job_descriptions_creation_skills_tools_modal_subtitle,
		fr: fr.job_descriptions_creation_skills_tools_modal_subtitle
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_creation_skills_tools_subtitle = (params = {}, options = {}) => {
	return {
		en: en.job_descriptions_creation_skills_tools_subtitle,
		fr: fr.job_descriptions_creation_skills_tools_subtitle
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_creation_skills_tools_title = (params = {}, options = {}) => {
	return {
		en: en.job_descriptions_creation_skills_tools_title,
		fr: fr.job_descriptions_creation_skills_tools_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_creation_status_subtitle = (params = {}, options = {}) => {
	return {
		en: en.job_descriptions_creation_status_subtitle,
		fr: fr.job_descriptions_creation_status_subtitle
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_creation_status_title = (params = {}, options = {}) => {
	return {
		en: en.job_descriptions_creation_status_title,
		fr: fr.job_descriptions_creation_status_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_creation_success = (params = {}, options = {}) => {
	return {
		en: en.job_descriptions_creation_success,
		fr: fr.job_descriptions_creation_success
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_draft = (params = {}, options = {}) => {
	return {
		en: en.job_descriptions_draft,
		fr: fr.job_descriptions_draft
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_edit_context_subtitle = (params = {}, options = {}) => {
	return {
		en: en.job_descriptions_edit_context_subtitle,
		fr: fr.job_descriptions_edit_context_subtitle
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_edit_devices_subtitle = (params = {}, options = {}) => {
	return {
		en: en.job_descriptions_edit_devices_subtitle,
		fr: fr.job_descriptions_edit_devices_subtitle
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_edit_dresscode_subtitle = (params = {}, options = {}) => {
	return {
		en: en.job_descriptions_edit_dresscode_subtitle,
		fr: fr.job_descriptions_edit_dresscode_subtitle
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_edit_experiences_subtitle = (params = {}, options = {}) => {
	return {
		en: en.job_descriptions_edit_experiences_subtitle,
		fr: fr.job_descriptions_edit_experiences_subtitle
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_edit_jobTitle_subtitle = (params = {}, options = {}) => {
	return {
		en: en.job_descriptions_edit_jobTitle_subtitle,
		fr: fr.job_descriptions_edit_jobTitle_subtitle
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_edit_languages_subtitle = (params = {}, options = {}) => {
	return {
		en: en.job_descriptions_edit_languages_subtitle,
		fr: fr.job_descriptions_edit_languages_subtitle
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_edit_licences_subtitle = (params = {}, options = {}) => {
	return {
		en: en.job_descriptions_edit_licences_subtitle,
		fr: fr.job_descriptions_edit_licences_subtitle
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_edit_location_subtitle = (params = {}, options = {}) => {
	return {
		en: en.job_descriptions_edit_location_subtitle,
		fr: fr.job_descriptions_edit_location_subtitle
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_edit_manager_subtitle = (params = {}, options = {}) => {
	return {
		en: en.job_descriptions_edit_manager_subtitle,
		fr: fr.job_descriptions_edit_manager_subtitle
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_edit_name_subtitle = (params = {}, options = {}) => {
	return {
		en: en.job_descriptions_edit_name_subtitle,
		fr: fr.job_descriptions_edit_name_subtitle
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_edit_preselection = (params = {}, options = {}) => {
	return {
		en: en.job_descriptions_edit_preselection,
		fr: fr.job_descriptions_edit_preselection
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_edit_risks_subtitle = (params = {}, options = {}) => {
	return {
		en: en.job_descriptions_edit_risks_subtitle,
		fr: fr.job_descriptions_edit_risks_subtitle
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_edit_safetyEquipment_subtitle = (params = {}, options = {}) => {
	return {
		en: en.job_descriptions_edit_safetyEquipment_subtitle,
		fr: fr.job_descriptions_edit_safetyEquipment_subtitle
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_edit_salary_subtitle = (params = {}, options = {}) => {
	return {
		en: en.job_descriptions_edit_salary_subtitle,
		fr: fr.job_descriptions_edit_salary_subtitle
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_edit_subtasks_subtitle = (params = {}, options = {}) => {
	return {
		en: en.job_descriptions_edit_subtasks_subtitle,
		fr: fr.job_descriptions_edit_subtasks_subtitle
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_edit_success = (params = {}, options = {}) => {
	return {
		en: en.job_descriptions_edit_success,
		fr: fr.job_descriptions_edit_success
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_edit_tools_subtitle = (params = {}, options = {}) => {
	return {
		en: en.job_descriptions_edit_tools_subtitle,
		fr: fr.job_descriptions_edit_tools_subtitle
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_empty_create_button = (params = {}, options = {}) => {
	return {
		en: en.job_descriptions_empty_create_button,
		fr: fr.job_descriptions_empty_create_button
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_empty_subtitle = (params = {}, options = {}) => {
	return {
		en: en.job_descriptions_empty_subtitle,
		fr: fr.job_descriptions_empty_subtitle
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_empty_subtitle_link_label = (params = {}, options = {}) => {
	return {
		en: en.job_descriptions_empty_subtitle_link_label,
		fr: fr.job_descriptions_empty_subtitle_link_label
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_empty_title = (params = {}, options = {}) => {
	return {
		en: en.job_descriptions_empty_title,
		fr: fr.job_descriptions_empty_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_instructions = (params = {}, options = {}) => {
	return {
		en: en.job_descriptions_instructions,
		fr: fr.job_descriptions_instructions
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_instructions_account = (params = {}, options = {}) => {
	return {
		en: en.job_descriptions_instructions_account,
		fr: fr.job_descriptions_instructions_account
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_instructions_network = (params = {}, options = {}) => {
	return {
		en: en.job_descriptions_instructions_network,
		fr: fr.job_descriptions_instructions_network
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_jobtitle = (params = {}, options = {}) => {
	return {
		en: en.job_descriptions_jobtitle,
		fr: fr.job_descriptions_jobtitle
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_list_network_description = (params = {}, options = {}) => {
	return {
		en: en.job_descriptions_list_network_description,
		fr: fr.job_descriptions_list_network_description
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_new_title = (params = {}, options = {}) => {
	return {
		en: en.job_descriptions_new_title,
		fr: fr.job_descriptions_new_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_rename_field_placeholder = (params = {}, options = {}) => {
	return {
		en: en.job_descriptions_rename_field_placeholder,
		fr: fr.job_descriptions_rename_field_placeholder
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_rename_tip = (params = {}, options = {}) => {
	return {
		en: en.job_descriptions_rename_tip,
		fr: fr.job_descriptions_rename_tip
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_status_ETAM = (params = {}, options = {}) => {
	return {
		en: en.job_descriptions_status_ETAM,
		fr: fr.job_descriptions_status_ETAM
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_status_executive = (params = {}, options = {}) => {
	return {
		en: en.job_descriptions_status_executive,
		fr: fr.job_descriptions_status_executive
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_status_worker = (params = {}, options = {}) => {
	return {
		en: en.job_descriptions_status_worker,
		fr: fr.job_descriptions_status_worker
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_summary_context_title = (params = {}, options = {}) => {
	return {
		en: en.job_descriptions_summary_context_title,
		fr: fr.job_descriptions_summary_context_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_summary_devices_title = (params = {}, options = {}) => {
	return {
		en: en.job_descriptions_summary_devices_title,
		fr: fr.job_descriptions_summary_devices_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_summary_dresscode_title = (params = {}, options = {}) => {
	return {
		en: en.job_descriptions_summary_dresscode_title,
		fr: fr.job_descriptions_summary_dresscode_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_summary_experiences_title = (params = {}, options = {}) => {
	return {
		en: en.job_descriptions_summary_experiences_title,
		fr: fr.job_descriptions_summary_experiences_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_summary_jobTitle_title = (params = {}, options = {}) => {
	return {
		en: en.job_descriptions_summary_jobTitle_title,
		fr: fr.job_descriptions_summary_jobTitle_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_summary_languages_title = (params = {}, options = {}) => {
	return {
		en: en.job_descriptions_summary_languages_title,
		fr: fr.job_descriptions_summary_languages_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_summary_licences_title = (params = {}, options = {}) => {
	return {
		en: en.job_descriptions_summary_licences_title,
		fr: fr.job_descriptions_summary_licences_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_summary_location_title = (params = {}, options = {}) => {
	return {
		en: en.job_descriptions_summary_location_title,
		fr: fr.job_descriptions_summary_location_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_summary_manager_title = (params = {}, options = {}) => {
	return {
		en: en.job_descriptions_summary_manager_title,
		fr: fr.job_descriptions_summary_manager_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_summary_name_title = (params = {}, options = {}) => {
	return {
		en: en.job_descriptions_summary_name_title,
		fr: fr.job_descriptions_summary_name_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_summary_risks_title = (params = {}, options = {}) => {
	return {
		en: en.job_descriptions_summary_risks_title,
		fr: fr.job_descriptions_summary_risks_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_summary_safetyEquipment_title = (params = {}, options = {}) => {
	return {
		en: en.job_descriptions_summary_safetyEquipment_title,
		fr: fr.job_descriptions_summary_safetyEquipment_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_summary_salary_title = (params = {}, options = {}) => {
	return {
		en: en.job_descriptions_summary_salary_title,
		fr: fr.job_descriptions_summary_salary_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_summary_status_title = (params = {}, options = {}) => {
	return {
		en: en.job_descriptions_summary_status_title,
		fr: fr.job_descriptions_summary_status_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_summary_subtasks_title = (params = {}, options = {}) => {
	return {
		en: en.job_descriptions_summary_subtasks_title,
		fr: fr.job_descriptions_summary_subtasks_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_summary_tools_title = (params = {}, options = {}) => {
	return {
		en: en.job_descriptions_summary_tools_title,
		fr: fr.job_descriptions_summary_tools_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_unauthorized_custom = (params = {}, options = {}) => {
	return {
		en: en.job_descriptions_unauthorized_custom,
		fr: fr.job_descriptions_unauthorized_custom
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_unauthorized_description = (params = {}, options = {}) => {
	return {
		en: en.job_descriptions_unauthorized_description,
		fr: fr.job_descriptions_unauthorized_description
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_unauthorized_manager_account = (params = {}, options = {}) => {
	return {
		en: en.job_descriptions_unauthorized_manager_account,
		fr: fr.job_descriptions_unauthorized_manager_account
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_unauthorized_manager_missing = (params = {}, options = {}) => {
	return {
		en: en.job_descriptions_unauthorized_manager_missing,
		fr: fr.job_descriptions_unauthorized_manager_missing
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_unauthorized_manager_network = (params = {}, options = {}) => {
	return {
		en: en.job_descriptions_unauthorized_manager_network,
		fr: fr.job_descriptions_unauthorized_manager_network
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_unauthorized_network = (params = {}, options = {}) => {
	return {
		en: en.job_descriptions_unauthorized_network,
		fr: fr.job_descriptions_unauthorized_network
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_unauthorized_network_redirect_description = (params = {}, options = {}) => {
	return {
		en: en.job_descriptions_unauthorized_network_redirect_description,
		fr: fr.job_descriptions_unauthorized_network_redirect_description
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_descriptions_unauthorized_network_redirect_title = (params = {}, options = {}) => {
	return {
		en: en.job_descriptions_unauthorized_network_redirect_title,
		fr: fr.job_descriptions_unauthorized_network_redirect_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_types_contact_content = (params = {}, options = {}) => {
	return {
		en: en.job_types_contact_content,
		fr: fr.job_types_contact_content
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_types_contact_title = (params = {}, options = {}) => {
	return {
		en: en.job_types_contact_title,
		fr: fr.job_types_contact_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_types_more = (params = {}, options = {}) => {
	return {
		en: en.job_types_more,
		fr: fr.job_types_more
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_types_subtitle = (params = {}, options = {}) => {
	return {
		en: en.job_types_subtitle,
		fr: fr.job_types_subtitle
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const job_types_title = (params = {}, options = {}) => {
	return {
		en: en.job_types_title,
		fr: fr.job_types_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const jpn = (params = {}, options = {}) => {
	return {
		en: en.jpn,
		fr: fr.jpn
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const just_posted_section_row_status_cell_subtitle = (params = {}, options = {}) => {
	return {
		en: en.just_posted_section_row_status_cell_subtitle,
		fr: fr.just_posted_section_row_status_cell_subtitle
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const just_posted_section_row_status_cell_title = (params = {}, options = {}) => {
	return {
		en: en.just_posted_section_row_status_cell_title,
		fr: fr.just_posted_section_row_status_cell_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const just_posted_section_row_task_cell_managed_by = (params = {}, options = {}) => {
	return {
		en: en.just_posted_section_row_task_cell_managed_by,
		fr: fr.just_posted_section_row_task_cell_managed_by
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const just_posted_section_table_header_start_date = (params = {}, options = {}) => {
	return {
		en: en.just_posted_section_table_header_start_date,
		fr: fr.just_posted_section_table_header_start_date
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const just_posted_section_table_header_status = (params = {}, options = {}) => {
	return {
		en: en.just_posted_section_table_header_status,
		fr: fr.just_posted_section_table_header_status
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const just_posted_section_table_header_task = (params = {}, options = {}) => {
	return {
		en: en.just_posted_section_table_header_task,
		fr: fr.just_posted_section_table_header_task
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const just_posted_section_title = (params = {}, options = {}) => {
	return {
		en: en.just_posted_section_title,
		fr: fr.just_posted_section_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const khm = (params = {}, options = {}) => {
	return {
		en: en.khm,
		fr: fr.khm
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const kon = (params = {}, options = {}) => {
	return {
		en: en.kon,
		fr: fr.kon
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const kor = (params = {}, options = {}) => {
	return {
		en: en.kor,
		fr: fr.kor
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const label_continue = (params = {}, options = {}) => {
	return {
		en: en.label_continue,
		fr: fr.label_continue
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const label_delete = (params = {}, options = {}) => {
	return {
		en: en.label_delete,
		fr: fr.label_delete
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const label_new = (params = {}, options = {}) => {
	return {
		en: en.label_new,
		fr: fr.label_new
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const latest_task_title = (params = {}, options = {}) => {
	return {
		en: en.latest_task_title,
		fr: fr.latest_task_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const lav = (params = {}, options = {}) => {
	return {
		en: en.lav,
		fr: fr.lav
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const learn_more = (params = {}, options = {}) => {
	return {
		en: en.learn_more,
		fr: fr.learn_more
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const lin = (params = {}, options = {}) => {
	return {
		en: en.lin,
		fr: fr.lin
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const lit = (params = {}, options = {}) => {
	return {
		en: en.lit,
		fr: fr.lit
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const location_form_favorite_list_addition = (params = {}, options = {}) => {
	return {
		en: en.location_form_favorite_list_addition,
		fr: fr.location_form_favorite_list_addition
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const location_form_favorite_list_deletion = (params = {}, options = {}) => {
	return {
		en: en.location_form_favorite_list_deletion,
		fr: fr.location_form_favorite_list_deletion
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const location_form_favorite_list_update = (params = {}, options = {}) => {
	return {
		en: en.location_form_favorite_list_update,
		fr: fr.location_form_favorite_list_update
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const location_form_no_street_number = (params = {}, options = {}) => {
	return {
		en: en.location_form_no_street_number,
		fr: fr.location_form_no_street_number
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const location_form_workplace_favorite_input_label = (params = {}, options = {}) => {
	return {
		en: en.location_form_workplace_favorite_input_label,
		fr: fr.location_form_workplace_favorite_input_label
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const location_form_workplace_favorite_input_title_label = (params = {}, options = {}) => {
	return {
		en: en.location_form_workplace_favorite_input_title_label,
		fr: fr.location_form_workplace_favorite_input_title_label
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const location_form_workplace_favorite_input_title_placeholder = (params = {}, options = {}) => {
	return {
		en: en.location_form_workplace_favorite_input_title_placeholder,
		fr: fr.location_form_workplace_favorite_input_title_placeholder
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const location_form_workplace_favorite_label = (params = {}, options = {}) => {
	return {
		en: en.location_form_workplace_favorite_label,
		fr: fr.location_form_workplace_favorite_label
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const location_form_workplace_favorite_placeholder = (params = {}, options = {}) => {
	return {
		en: en.location_form_workplace_favorite_placeholder,
		fr: fr.location_form_workplace_favorite_placeholder
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const location_form_workplace_favorite_subtitle = (params = {}, options = {}) => {
	return {
		en: en.location_form_workplace_favorite_subtitle,
		fr: fr.location_form_workplace_favorite_subtitle
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const location_form_workplace_favorite_title = (params = {}, options = {}) => {
	return {
		en: en.location_form_workplace_favorite_title,
		fr: fr.location_form_workplace_favorite_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const location_form_workplace_label1 = (params = {}, options = {}) => {
	return {
		en: en.location_form_workplace_label1,
		fr: fr.location_form_workplace_label1
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const location_form_workplace_label2 = (params = {}, options = {}) => {
	return {
		en: en.location_form_workplace_label2,
		fr: fr.location_form_workplace_label2
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const location_form_workplace_option1 = (params = {}, options = {}) => {
	return {
		en: en.location_form_workplace_option1,
		fr: fr.location_form_workplace_option1
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const location_form_workplace_option2 = (params = {}, options = {}) => {
	return {
		en: en.location_form_workplace_option2,
		fr: fr.location_form_workplace_option2
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const location_form_workplace_placeholder1 = (params = {}, options = {}) => {
	return {
		en: en.location_form_workplace_placeholder1,
		fr: fr.location_form_workplace_placeholder1
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const location_form_workplace_placeholder2 = (params = {}, options = {}) => {
	return {
		en: en.location_form_workplace_placeholder2,
		fr: fr.location_form_workplace_placeholder2
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const location_form_workplace_subtitle = (params = {}, options = {}) => {
	return {
		en: en.location_form_workplace_subtitle,
		fr: fr.location_form_workplace_subtitle
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const location_form_workplace_title = (params = {}, options = {}) => {
	return {
		en: en.location_form_workplace_title,
		fr: fr.location_form_workplace_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const logas_impersonate_button = (params = {}, options = {}) => {
	return {
		en: en.logas_impersonate_button,
		fr: fr.logas_impersonate_button
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const logas_input_label = (params = {}, options = {}) => {
	return {
		en: en.logas_input_label,
		fr: fr.logas_input_label
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const logas_logout_button = (params = {}, options = {}) => {
	return {
		en: en.logas_logout_button,
		fr: fr.logas_logout_button
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mac = (params = {}, options = {}) => {
	return {
		en: en.mac,
		fr: fr.mac
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const manager_form_choice_later = (params = {}, options = {}) => {
	return {
		en: en.manager_form_choice_later,
		fr: fr.manager_form_choice_later
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const manager_form_choice_now = (params = {}, options = {}) => {
	return {
		en: en.manager_form_choice_now,
		fr: fr.manager_form_choice_now
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const manager_form_label = (params = {}, options = {}) => {
	return {
		en: en.manager_form_label,
		fr: fr.manager_form_label
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const manager_form_subtitle = (params = {}, options = {}) => {
	return {
		en: en.manager_form_subtitle,
		fr: fr.manager_form_subtitle
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const manager_form_title = (params = {}, options = {}) => {
	return {
		en: en.manager_form_title,
		fr: fr.manager_form_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const manager_update_success = (params = {}, options = {}) => {
	return {
		en: en.manager_update_success,
		fr: fr.manager_update_success
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const may = (params = {}, options = {}) => {
	return {
		en: en.may,
		fr: fr.may
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mlg = (params = {}, options = {}) => {
	return {
		en: en.mlg,
		fr: fr.mlg
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const modal_confirmation_answer_1 = (params = {}, options = {}) => {
	return {
		en: en.modal_confirmation_answer_1,
		fr: fr.modal_confirmation_answer_1
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const modal_confirmation_answer_2 = (params = {}, options = {}) => {
	return {
		en: en.modal_confirmation_answer_2,
		fr: fr.modal_confirmation_answer_2
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const modal_confirmation_answer_3 = (params = {}, options = {}) => {
	return {
		en: en.modal_confirmation_answer_3,
		fr: fr.modal_confirmation_answer_3
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const modal_confirmation_answer_4 = (params = {}, options = {}) => {
	return {
		en: en.modal_confirmation_answer_4,
		fr: fr.modal_confirmation_answer_4
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const modal_confirmation_description = (params = {}, options = {}) => {
	return {
		en: en.modal_confirmation_description,
		fr: fr.modal_confirmation_description
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const modal_confirmation_faq = (params = {}, options = {}) => {
	return {
		en: en.modal_confirmation_faq,
		fr: fr.modal_confirmation_faq
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const modal_confirmation_question_1 = (params = {}, options = {}) => {
	return {
		en: en.modal_confirmation_question_1,
		fr: fr.modal_confirmation_question_1
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const modal_confirmation_question_2 = (params = {}, options = {}) => {
	return {
		en: en.modal_confirmation_question_2,
		fr: fr.modal_confirmation_question_2
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const modal_confirmation_question_3 = (params = {}, options = {}) => {
	return {
		en: en.modal_confirmation_question_3,
		fr: fr.modal_confirmation_question_3
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const modal_confirmation_question_4 = (params = {}, options = {}) => {
	return {
		en: en.modal_confirmation_question_4,
		fr: fr.modal_confirmation_question_4
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const modal_confirmation_subtitle = (params = {}, options = {}) => {
	return {
		en: en.modal_confirmation_subtitle,
		fr: fr.modal_confirmation_subtitle
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const modal_confirmation_tip_4 = (params = {}, options = {}) => {
	return {
		en: en.modal_confirmation_tip_4,
		fr: fr.modal_confirmation_tip_4
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const modal_confirmation_title = (params = {}, options = {}) => {
	return {
		en: en.modal_confirmation_title,
		fr: fr.modal_confirmation_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const modal_delete_description = (params = {}, options = {}) => {
	return {
		en: en.modal_delete_description,
		fr: fr.modal_delete_description
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const modal_delete_job_description_content = (params = {}, options = {}) => {
	return {
		en: en.modal_delete_job_description_content,
		fr: fr.modal_delete_job_description_content
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const modal_delete_job_description_title = (params = {}, options = {}) => {
	return {
		en: en.modal_delete_job_description_title,
		fr: fr.modal_delete_job_description_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const modal_delete_title = (params = {}, options = {}) => {
	return {
		en: en.modal_delete_title,
		fr: fr.modal_delete_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const modal_duplicate_description = (params = {}, options = {}) => {
	return {
		en: en.modal_duplicate_description,
		fr: fr.modal_duplicate_description
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const modal_duplicate_title = (params = {}, options = {}) => {
	return {
		en: en.modal_duplicate_title,
		fr: fr.modal_duplicate_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const modal_manager_update_field_tip = (params = {}, options = {}) => {
	return {
		en: en.modal_manager_update_field_tip,
		fr: fr.modal_manager_update_field_tip
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const modal_redirect_button = (params = {}, options = {}) => {
	return {
		en: en.modal_redirect_button,
		fr: fr.modal_redirect_button
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const modal_redirect_description = (params = {}, options = {}) => {
	return {
		en: en.modal_redirect_description,
		fr: fr.modal_redirect_description
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const modal_redirect_step_1 = (params = {}, options = {}) => {
	return {
		en: en.modal_redirect_step_1,
		fr: fr.modal_redirect_step_1
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const modal_redirect_step_2 = (params = {}, options = {}) => {
	return {
		en: en.modal_redirect_step_2,
		fr: fr.modal_redirect_step_2
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const modal_redirect_title = (params = {}, options = {}) => {
	return {
		en: en.modal_redirect_title,
		fr: fr.modal_redirect_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const modal_rename_button_cancel = (params = {}, options = {}) => {
	return {
		en: en.modal_rename_button_cancel,
		fr: fr.modal_rename_button_cancel
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const modal_rename_button_ok = (params = {}, options = {}) => {
	return {
		en: en.modal_rename_button_ok,
		fr: fr.modal_rename_button_ok
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const modal_rename_description = (params = {}, options = {}) => {
	return {
		en: en.modal_rename_description,
		fr: fr.modal_rename_description
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const modal_rename_error_too_long = (params = {}, options = {}) => {
	return {
		en: en.modal_rename_error_too_long,
		fr: fr.modal_rename_error_too_long
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const modal_rename_field_label = (params = {}, options = {}) => {
	return {
		en: en.modal_rename_field_label,
		fr: fr.modal_rename_field_label
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const modal_rename_field_placeholder = (params = {}, options = {}) => {
	return {
		en: en.modal_rename_field_placeholder,
		fr: fr.modal_rename_field_placeholder
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const modal_rename_field_tip = (params = {}, options = {}) => {
	return {
		en: en.modal_rename_field_tip,
		fr: fr.modal_rename_field_tip
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const modal_rename_title = (params = {}, options = {}) => {
	return {
		en: en.modal_rename_title,
		fr: fr.modal_rename_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const modal_update_manager_input_label = (params = {}, options = {}) => {
	return {
		en: en.modal_update_manager_input_label,
		fr: fr.modal_update_manager_input_label
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const modal_update_manager_title = (params = {}, options = {}) => {
	return {
		en: en.modal_update_manager_title,
		fr: fr.modal_update_manager_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const modify = (params = {}, options = {}) => {
	return {
		en: en.modify,
		fr: fr.modify
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mon = (params = {}, options = {}) => {
	return {
		en: en.mon,
		fr: fr.mon
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const motive_activity_increase_explaination_link = (params = {}, options = {}) => {
	return {
		en: en.motive_activity_increase_explaination_link,
		fr: fr.motive_activity_increase_explaination_link
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const motive_activity_increase_explaination_link_label = (params = {}, options = {}) => {
	return {
		en: en.motive_activity_increase_explaination_link_label,
		fr: fr.motive_activity_increase_explaination_link_label
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const motive_activity_increase_explaination_tip = (params = {}, options = {}) => {
	return {
		en: en.motive_activity_increase_explaination_tip,
		fr: fr.motive_activity_increase_explaination_tip
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const motive_activity_increase_label = (params = {}, options = {}) => {
	return {
		en: en.motive_activity_increase_label,
		fr: fr.motive_activity_increase_label
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const motive_activity_increase_length = (params = {}, options = {}) => {
	return {
		en: en.motive_activity_increase_length,
		fr: fr.motive_activity_increase_length
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const motive_activity_increase_placeholder = (params = {}, options = {}) => {
	return {
		en: en.motive_activity_increase_placeholder,
		fr: fr.motive_activity_increase_placeholder
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const motive_activity_increase_subtitle = (params = {}, options = {}) => {
	return {
		en: en.motive_activity_increase_subtitle,
		fr: fr.motive_activity_increase_subtitle
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const motive_activity_increase_title = (params = {}, options = {}) => {
	return {
		en: en.motive_activity_increase_title,
		fr: fr.motive_activity_increase_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const motive_activity_increase_validation = (params = {}, options = {}) => {
	return {
		en: en.motive_activity_increase_validation,
		fr: fr.motive_activity_increase_validation
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{ index: NonNullable<unknown> }} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const motive_recruitment_count = (params , options = {}) => {
	return {
		en: en.motive_recruitment_count,
		fr: fr.motive_recruitment_count
	}[options.languageTag ?? languageTag()](params)
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const motive_recruitment_date_label = (params = {}, options = {}) => {
	return {
		en: en.motive_recruitment_date_label,
		fr: fr.motive_recruitment_date_label
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const motive_recruitment_date_placeholder = (params = {}, options = {}) => {
	return {
		en: en.motive_recruitment_date_placeholder,
		fr: fr.motive_recruitment_date_placeholder
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const motive_recruitment_explaination_link = (params = {}, options = {}) => {
	return {
		en: en.motive_recruitment_explaination_link,
		fr: fr.motive_recruitment_explaination_link
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const motive_recruitment_explaination_link_label = (params = {}, options = {}) => {
	return {
		en: en.motive_recruitment_explaination_link_label,
		fr: fr.motive_recruitment_explaination_link_label
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const motive_recruitment_explaination_tip = (params = {}, options = {}) => {
	return {
		en: en.motive_recruitment_explaination_tip,
		fr: fr.motive_recruitment_explaination_tip
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const motive_recruitment_name_label = (params = {}, options = {}) => {
	return {
		en: en.motive_recruitment_name_label,
		fr: fr.motive_recruitment_name_label
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const motive_recruitment_name_placeholder = (params = {}, options = {}) => {
	return {
		en: en.motive_recruitment_name_placeholder,
		fr: fr.motive_recruitment_name_placeholder
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const motive_recruitment_position_label = (params = {}, options = {}) => {
	return {
		en: en.motive_recruitment_position_label,
		fr: fr.motive_recruitment_position_label
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const motive_recruitment_position_placeholder = (params = {}, options = {}) => {
	return {
		en: en.motive_recruitment_position_placeholder,
		fr: fr.motive_recruitment_position_placeholder
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const motive_recruitment_subtitle = (params = {}, options = {}) => {
	return {
		en: en.motive_recruitment_subtitle,
		fr: fr.motive_recruitment_subtitle
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const motive_recruitment_title = (params = {}, options = {}) => {
	return {
		en: en.motive_recruitment_title,
		fr: fr.motive_recruitment_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{ index: NonNullable<unknown> }} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const motive_replacement_count = (params , options = {}) => {
	return {
		en: en.motive_replacement_count,
		fr: fr.motive_replacement_count
	}[options.languageTag ?? languageTag()](params)
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const motive_replacement_explaination_link = (params = {}, options = {}) => {
	return {
		en: en.motive_replacement_explaination_link,
		fr: fr.motive_replacement_explaination_link
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const motive_replacement_explaination_link_label = (params = {}, options = {}) => {
	return {
		en: en.motive_replacement_explaination_link_label,
		fr: fr.motive_replacement_explaination_link_label
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const motive_replacement_explaination_tip = (params = {}, options = {}) => {
	return {
		en: en.motive_replacement_explaination_tip,
		fr: fr.motive_replacement_explaination_tip
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const motive_replacement_name_label = (params = {}, options = {}) => {
	return {
		en: en.motive_replacement_name_label,
		fr: fr.motive_replacement_name_label
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const motive_replacement_name_placeholder = (params = {}, options = {}) => {
	return {
		en: en.motive_replacement_name_placeholder,
		fr: fr.motive_replacement_name_placeholder
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const motive_replacement_position_label = (params = {}, options = {}) => {
	return {
		en: en.motive_replacement_position_label,
		fr: fr.motive_replacement_position_label
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const motive_replacement_position_placeholder = (params = {}, options = {}) => {
	return {
		en: en.motive_replacement_position_placeholder,
		fr: fr.motive_replacement_position_placeholder
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const motive_replacement_reason_label = (params = {}, options = {}) => {
	return {
		en: en.motive_replacement_reason_label,
		fr: fr.motive_replacement_reason_label
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const motive_replacement_reason_placeholder = (params = {}, options = {}) => {
	return {
		en: en.motive_replacement_reason_placeholder,
		fr: fr.motive_replacement_reason_placeholder
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const motive_replacement_subtitle = (params = {}, options = {}) => {
	return {
		en: en.motive_replacement_subtitle,
		fr: fr.motive_replacement_subtitle
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const motive_replacement_title = (params = {}, options = {}) => {
	return {
		en: en.motive_replacement_title,
		fr: fr.motive_replacement_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const motive_seasonal_activity_explaination_link = (params = {}, options = {}) => {
	return {
		en: en.motive_seasonal_activity_explaination_link,
		fr: fr.motive_seasonal_activity_explaination_link
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const motive_seasonal_activity_explaination_link_label = (params = {}, options = {}) => {
	return {
		en: en.motive_seasonal_activity_explaination_link_label,
		fr: fr.motive_seasonal_activity_explaination_link_label
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const motive_seasonal_activity_explaination_tip = (params = {}, options = {}) => {
	return {
		en: en.motive_seasonal_activity_explaination_tip,
		fr: fr.motive_seasonal_activity_explaination_tip
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const motive_seasonal_activity_label = (params = {}, options = {}) => {
	return {
		en: en.motive_seasonal_activity_label,
		fr: fr.motive_seasonal_activity_label
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const motive_seasonal_activity_length = (params = {}, options = {}) => {
	return {
		en: en.motive_seasonal_activity_length,
		fr: fr.motive_seasonal_activity_length
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const motive_seasonal_activity_placeholder = (params = {}, options = {}) => {
	return {
		en: en.motive_seasonal_activity_placeholder,
		fr: fr.motive_seasonal_activity_placeholder
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const motive_seasonal_activity_subtitle = (params = {}, options = {}) => {
	return {
		en: en.motive_seasonal_activity_subtitle,
		fr: fr.motive_seasonal_activity_subtitle
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const motive_seasonal_activity_title = (params = {}, options = {}) => {
	return {
		en: en.motive_seasonal_activity_title,
		fr: fr.motive_seasonal_activity_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const motive_seasonal_activity_validation = (params = {}, options = {}) => {
	return {
		en: en.motive_seasonal_activity_validation,
		fr: fr.motive_seasonal_activity_validation
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const motive_temporary_contract_explaination_link = (params = {}, options = {}) => {
	return {
		en: en.motive_temporary_contract_explaination_link,
		fr: fr.motive_temporary_contract_explaination_link
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const motive_temporary_contract_explaination_link_label = (params = {}, options = {}) => {
	return {
		en: en.motive_temporary_contract_explaination_link_label,
		fr: fr.motive_temporary_contract_explaination_link_label
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const motive_temporary_contract_explaination_tip = (params = {}, options = {}) => {
	return {
		en: en.motive_temporary_contract_explaination_tip,
		fr: fr.motive_temporary_contract_explaination_tip
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const motive_temporary_contract_label = (params = {}, options = {}) => {
	return {
		en: en.motive_temporary_contract_label,
		fr: fr.motive_temporary_contract_label
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const motive_temporary_contract_length = (params = {}, options = {}) => {
	return {
		en: en.motive_temporary_contract_length,
		fr: fr.motive_temporary_contract_length
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const motive_temporary_contract_placeholder = (params = {}, options = {}) => {
	return {
		en: en.motive_temporary_contract_placeholder,
		fr: fr.motive_temporary_contract_placeholder
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const motive_temporary_contract_subtitle = (params = {}, options = {}) => {
	return {
		en: en.motive_temporary_contract_subtitle,
		fr: fr.motive_temporary_contract_subtitle
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const motive_temporary_contract_title = (params = {}, options = {}) => {
	return {
		en: en.motive_temporary_contract_title,
		fr: fr.motive_temporary_contract_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const motive_temporary_contract_validation = (params = {}, options = {}) => {
	return {
		en: en.motive_temporary_contract_validation,
		fr: fr.motive_temporary_contract_validation
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const native = (params = {}, options = {}) => {
	return {
		en: en.native,
		fr: fr.native
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const nld = (params = {}, options = {}) => {
	return {
		en: en.nld,
		fr: fr.nld
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const no = (params = {}, options = {}) => {
	return {
		en: en.no,
		fr: fr.no
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const no_invoice_company = (params = {}, options = {}) => {
	return {
		en: en.no_invoice_company,
		fr: fr.no_invoice_company
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const no_invoice_month = (params = {}, options = {}) => {
	return {
		en: en.no_invoice_month,
		fr: fr.no_invoice_month
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const no_result = (params = {}, options = {}) => {
	return {
		en: en.no_result,
		fr: fr.no_result
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const nor = (params = {}, options = {}) => {
	return {
		en: en.nor,
		fr: fr.nor
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const oci = (params = {}, options = {}) => {
	return {
		en: en.oci,
		fr: fr.oci
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const onboarding_modal_intro = (params = {}, options = {}) => {
	return {
		en: en.onboarding_modal_intro,
		fr: fr.onboarding_modal_intro
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const onboarding_modal_steps_1_content = (params = {}, options = {}) => {
	return {
		en: en.onboarding_modal_steps_1_content,
		fr: fr.onboarding_modal_steps_1_content
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const onboarding_modal_steps_1_title = (params = {}, options = {}) => {
	return {
		en: en.onboarding_modal_steps_1_title,
		fr: fr.onboarding_modal_steps_1_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const onboarding_modal_steps_2_content = (params = {}, options = {}) => {
	return {
		en: en.onboarding_modal_steps_2_content,
		fr: fr.onboarding_modal_steps_2_content
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const onboarding_modal_steps_2_title = (params = {}, options = {}) => {
	return {
		en: en.onboarding_modal_steps_2_title,
		fr: fr.onboarding_modal_steps_2_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const onboarding_modal_steps_3_content = (params = {}, options = {}) => {
	return {
		en: en.onboarding_modal_steps_3_content,
		fr: fr.onboarding_modal_steps_3_content
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const onboarding_modal_steps_3_title = (params = {}, options = {}) => {
	return {
		en: en.onboarding_modal_steps_3_title,
		fr: fr.onboarding_modal_steps_3_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const onboarding_modal_title = (params = {}, options = {}) => {
	return {
		en: en.onboarding_modal_title,
		fr: fr.onboarding_modal_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const onboarding_modal_understood = (params = {}, options = {}) => {
	return {
		en: en.onboarding_modal_understood,
		fr: fr.onboarding_modal_understood
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ongoing_section_display_planning = (params = {}, options = {}) => {
	return {
		en: en.ongoing_section_display_planning,
		fr: fr.ongoing_section_display_planning
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ongoing_section_display_preselection = (params = {}, options = {}) => {
	return {
		en: en.ongoing_section_display_preselection,
		fr: fr.ongoing_section_display_preselection
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{ siderNumber: NonNullable<unknown> }} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ongoing_section_row_status_cell_subtitle_contact_plural = (params , options = {}) => {
	return {
		en: en.ongoing_section_row_status_cell_subtitle_contact_plural,
		fr: fr.ongoing_section_row_status_cell_subtitle_contact_plural
	}[options.languageTag ?? languageTag()](params)
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ongoing_section_row_status_cell_subtitle_contact_singular = (params = {}, options = {}) => {
	return {
		en: en.ongoing_section_row_status_cell_subtitle_contact_singular,
		fr: fr.ongoing_section_row_status_cell_subtitle_contact_singular
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ongoing_section_row_status_cell_title_selection_done = (params = {}, options = {}) => {
	return {
		en: en.ongoing_section_row_status_cell_title_selection_done,
		fr: fr.ongoing_section_row_status_cell_title_selection_done
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ongoing_section_row_task_cell_managed_by = (params = {}, options = {}) => {
	return {
		en: en.ongoing_section_row_task_cell_managed_by,
		fr: fr.ongoing_section_row_task_cell_managed_by
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ongoing_section_title = (params = {}, options = {}) => {
	return {
		en: en.ongoing_section_title,
		fr: fr.ongoing_section_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const optional = (params = {}, options = {}) => {
	return {
		en: en.optional,
		fr: fr.optional
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_details_label = (params = {}, options = {}) => {
	return {
		en: en.order_details_label,
		fr: fr.order_details_label
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_details_placeholder = (params = {}, options = {}) => {
	return {
		en: en.order_details_placeholder,
		fr: fr.order_details_placeholder
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_details_subtitle = (params = {}, options = {}) => {
	return {
		en: en.order_details_subtitle,
		fr: fr.order_details_subtitle
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_details_title = (params = {}, options = {}) => {
	return {
		en: en.order_details_title,
		fr: fr.order_details_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_justifications_add = (params = {}, options = {}) => {
	return {
		en: en.order_justifications_add,
		fr: fr.order_justifications_add
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_justifications_create_action = (params = {}, options = {}) => {
	return {
		en: en.order_justifications_create_action,
		fr: fr.order_justifications_create_action
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_justifications_delete_confirmation = (params = {}, options = {}) => {
	return {
		en: en.order_justifications_delete_confirmation,
		fr: fr.order_justifications_delete_confirmation
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_justifications_label = (params = {}, options = {}) => {
	return {
		en: en.order_justifications_label,
		fr: fr.order_justifications_label
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_justifications_placeholder = (params = {}, options = {}) => {
	return {
		en: en.order_justifications_placeholder,
		fr: fr.order_justifications_placeholder
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_justifications_tip = (params = {}, options = {}) => {
	return {
		en: en.order_justifications_tip,
		fr: fr.order_justifications_tip
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_motives_add = (params = {}, options = {}) => {
	return {
		en: en.order_motives_add,
		fr: fr.order_motives_add
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_motives_error_6_chars = (params = {}, options = {}) => {
	return {
		en: en.order_motives_error_6_chars,
		fr: fr.order_motives_error_6_chars
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_motives_label = (params = {}, options = {}) => {
	return {
		en: en.order_motives_label,
		fr: fr.order_motives_label
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_motives_label_activity_increase = (params = {}, options = {}) => {
	return {
		en: en.order_motives_label_activity_increase,
		fr: fr.order_motives_label_activity_increase
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_motives_label_replacement = (params = {}, options = {}) => {
	return {
		en: en.order_motives_label_replacement,
		fr: fr.order_motives_label_replacement
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_motives_label_seasonal_activity = (params = {}, options = {}) => {
	return {
		en: en.order_motives_label_seasonal_activity,
		fr: fr.order_motives_label_seasonal_activity
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_motives_label_temporary_contract = (params = {}, options = {}) => {
	return {
		en: en.order_motives_label_temporary_contract,
		fr: fr.order_motives_label_temporary_contract
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_motives_label_waiting_recruitment = (params = {}, options = {}) => {
	return {
		en: en.order_motives_label_waiting_recruitment,
		fr: fr.order_motives_label_waiting_recruitment
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_motives_placeholder = (params = {}, options = {}) => {
	return {
		en: en.order_motives_placeholder,
		fr: fr.order_motives_placeholder
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_motives_show_details = (params = {}, options = {}) => {
	return {
		en: en.order_motives_show_details,
		fr: fr.order_motives_show_details
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_motives_subtitle = (params = {}, options = {}) => {
	return {
		en: en.order_motives_subtitle,
		fr: fr.order_motives_subtitle
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_motives_title = (params = {}, options = {}) => {
	return {
		en: en.order_motives_title,
		fr: fr.order_motives_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_noresults_button_label = (params = {}, options = {}) => {
	return {
		en: en.order_noresults_button_label,
		fr: fr.order_noresults_button_label
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_noresults_subtitle = (params = {}, options = {}) => {
	return {
		en: en.order_noresults_subtitle,
		fr: fr.order_noresults_subtitle
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_noresults_title = (params = {}, options = {}) => {
	return {
		en: en.order_noresults_title,
		fr: fr.order_noresults_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_preliminary_details_cancel_label = (params = {}, options = {}) => {
	return {
		en: en.order_preliminary_details_cancel_label,
		fr: fr.order_preliminary_details_cancel_label
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_preliminary_details_coefficient_column = (params = {}, options = {}) => {
	return {
		en: en.order_preliminary_details_coefficient_column,
		fr: fr.order_preliminary_details_coefficient_column
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_preliminary_details_delegation_description = (params = {}, options = {}) => {
	return {
		en: en.order_preliminary_details_delegation_description,
		fr: fr.order_preliminary_details_delegation_description
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_preliminary_details_delegation_label = (params = {}, options = {}) => {
	return {
		en: en.order_preliminary_details_delegation_label,
		fr: fr.order_preliminary_details_delegation_label
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_preliminary_details_gestion_description = (params = {}, options = {}) => {
	return {
		en: en.order_preliminary_details_gestion_description,
		fr: fr.order_preliminary_details_gestion_description
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_preliminary_details_gestion_label = (params = {}, options = {}) => {
	return {
		en: en.order_preliminary_details_gestion_label,
		fr: fr.order_preliminary_details_gestion_label
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_preliminary_details_hourlyrate_column = (params = {}, options = {}) => {
	return {
		en: en.order_preliminary_details_hourlyrate_column,
		fr: fr.order_preliminary_details_hourlyrate_column
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_preliminary_details_hourtype_column = (params = {}, options = {}) => {
	return {
		en: en.order_preliminary_details_hourtype_column,
		fr: fr.order_preliminary_details_hourtype_column
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_preliminary_details_increaserate_column = (params = {}, options = {}) => {
	return {
		en: en.order_preliminary_details_increaserate_column,
		fr: fr.order_preliminary_details_increaserate_column
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_preliminary_details_modal_title = (params = {}, options = {}) => {
	return {
		en: en.order_preliminary_details_modal_title,
		fr: fr.order_preliminary_details_modal_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_preliminary_details_pricing_table_title = (params = {}, options = {}) => {
	return {
		en: en.order_preliminary_details_pricing_table_title,
		fr: fr.order_preliminary_details_pricing_table_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_preliminary_details_subtitle = (params = {}, options = {}) => {
	return {
		en: en.order_preliminary_details_subtitle,
		fr: fr.order_preliminary_details_subtitle
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_preliminary_details_taskname_label = (params = {}, options = {}) => {
	return {
		en: en.order_preliminary_details_taskname_label,
		fr: fr.order_preliminary_details_taskname_label
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_preliminary_details_taskname_optional = (params = {}, options = {}) => {
	return {
		en: en.order_preliminary_details_taskname_optional,
		fr: fr.order_preliminary_details_taskname_optional
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_preliminary_details_taskname_placeholder = (params = {}, options = {}) => {
	return {
		en: en.order_preliminary_details_taskname_placeholder,
		fr: fr.order_preliminary_details_taskname_placeholder
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_preliminary_details_taskname_tip = (params = {}, options = {}) => {
	return {
		en: en.order_preliminary_details_taskname_tip,
		fr: fr.order_preliminary_details_taskname_tip
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_preliminary_details_tasktype_label = (params = {}, options = {}) => {
	return {
		en: en.order_preliminary_details_tasktype_label,
		fr: fr.order_preliminary_details_tasktype_label
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_preliminary_details_title = (params = {}, options = {}) => {
	return {
		en: en.order_preliminary_details_title,
		fr: fr.order_preliminary_details_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_preliminary_details_type_delegation_label = (params = {}, options = {}) => {
	return {
		en: en.order_preliminary_details_type_delegation_label,
		fr: fr.order_preliminary_details_type_delegation_label
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_preliminary_details_type_gestion_label = (params = {}, options = {}) => {
	return {
		en: en.order_preliminary_details_type_gestion_label,
		fr: fr.order_preliminary_details_type_gestion_label
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_preliminary_details_type_label = (params = {}, options = {}) => {
	return {
		en: en.order_preliminary_details_type_label,
		fr: fr.order_preliminary_details_type_label
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_preliminary_details_validate_label = (params = {}, options = {}) => {
	return {
		en: en.order_preliminary_details_validate_label,
		fr: fr.order_preliminary_details_validate_label
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_quotation_price = (params = {}, options = {}) => {
	return {
		en: en.order_quotation_price,
		fr: fr.order_quotation_price
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_quotation_quantity = (params = {}, options = {}) => {
	return {
		en: en.order_quotation_quantity,
		fr: fr.order_quotation_quantity
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_quotation_total_ht = (params = {}, options = {}) => {
	return {
		en: en.order_quotation_total_ht,
		fr: fr.order_quotation_total_ht
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_quotation_type = (params = {}, options = {}) => {
	return {
		en: en.order_quotation_type,
		fr: fr.order_quotation_type
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_shifts_subtitle = (params = {}, options = {}) => {
	return {
		en: en.order_shifts_subtitle,
		fr: fr.order_shifts_subtitle
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_shifts_title = (params = {}, options = {}) => {
	return {
		en: en.order_shifts_title,
		fr: fr.order_shifts_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_stepper_details = (params = {}, options = {}) => {
	return {
		en: en.order_stepper_details,
		fr: fr.order_stepper_details
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_stepper_motive = (params = {}, options = {}) => {
	return {
		en: en.order_stepper_motive,
		fr: fr.order_stepper_motive
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_stepper_planning = (params = {}, options = {}) => {
	return {
		en: en.order_stepper_planning,
		fr: fr.order_stepper_planning
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_stepper_shifts = (params = {}, options = {}) => {
	return {
		en: en.order_stepper_shifts,
		fr: fr.order_stepper_shifts
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_stepper_summary = (params = {}, options = {}) => {
	return {
		en: en.order_stepper_summary,
		fr: fr.order_stepper_summary
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_stepper_workers = (params = {}, options = {}) => {
	return {
		en: en.order_stepper_workers,
		fr: fr.order_stepper_workers
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_succes_display_planning = (params = {}, options = {}) => {
	return {
		en: en.order_succes_display_planning,
		fr: fr.order_succes_display_planning
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_success_item_1 = (params = {}, options = {}) => {
	return {
		en: en.order_success_item_1,
		fr: fr.order_success_item_1
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_success_item_2 = (params = {}, options = {}) => {
	return {
		en: en.order_success_item_2,
		fr: fr.order_success_item_2
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_success_item_3 = (params = {}, options = {}) => {
	return {
		en: en.order_success_item_3,
		fr: fr.order_success_item_3
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_success_title = (params = {}, options = {}) => {
	return {
		en: en.order_success_title,
		fr: fr.order_success_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_summary_address_additional_infos = (params = {}, options = {}) => {
	return {
		en: en.order_summary_address_additional_infos,
		fr: fr.order_summary_address_additional_infos
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_summary_address_title = (params = {}, options = {}) => {
	return {
		en: en.order_summary_address_title,
		fr: fr.order_summary_address_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_summary_base_wage_disclaimer = (params = {}, options = {}) => {
	return {
		en: en.order_summary_base_wage_disclaimer,
		fr: fr.order_summary_base_wage_disclaimer
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_summary_confirmation_button = (params = {}, options = {}) => {
	return {
		en: en.order_summary_confirmation_button,
		fr: fr.order_summary_confirmation_button
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_summary_confirmation_cancellation_fees = (params = {}, options = {}) => {
	return {
		en: en.order_summary_confirmation_cancellation_fees,
		fr: fr.order_summary_confirmation_cancellation_fees
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_summary_confirmation_checkbox = (params = {}, options = {}) => {
	return {
		en: en.order_summary_confirmation_checkbox,
		fr: fr.order_summary_confirmation_checkbox
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_summary_confirmation_footnote = (params = {}, options = {}) => {
	return {
		en: en.order_summary_confirmation_footnote,
		fr: fr.order_summary_confirmation_footnote
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_summary_confirmation_sidenote = (params = {}, options = {}) => {
	return {
		en: en.order_summary_confirmation_sidenote,
		fr: fr.order_summary_confirmation_sidenote
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_summary_confirmation_sidenote_placeholder = (params = {}, options = {}) => {
	return {
		en: en.order_summary_confirmation_sidenote_placeholder,
		fr: fr.order_summary_confirmation_sidenote_placeholder
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_summary_confirmation_title = (params = {}, options = {}) => {
	return {
		en: en.order_summary_confirmation_title,
		fr: fr.order_summary_confirmation_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_summary_estimate_base_wage = (params = {}, options = {}) => {
	return {
		en: en.order_summary_estimate_base_wage,
		fr: fr.order_summary_estimate_base_wage
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_summary_estimate_details = (params = {}, options = {}) => {
	return {
		en: en.order_summary_estimate_details,
		fr: fr.order_summary_estimate_details
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_summary_estimate_disclaimer = (params = {}, options = {}) => {
	return {
		en: en.order_summary_estimate_disclaimer,
		fr: fr.order_summary_estimate_disclaimer
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_summary_estimate_planning_tip = (params = {}, options = {}) => {
	return {
		en: en.order_summary_estimate_planning_tip,
		fr: fr.order_summary_estimate_planning_tip
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_summary_estimate_title = (params = {}, options = {}) => {
	return {
		en: en.order_summary_estimate_title,
		fr: fr.order_summary_estimate_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_summary_estimate_total_with_taxes = (params = {}, options = {}) => {
	return {
		en: en.order_summary_estimate_total_with_taxes,
		fr: fr.order_summary_estimate_total_with_taxes
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_summary_estimate_total_without_taxes = (params = {}, options = {}) => {
	return {
		en: en.order_summary_estimate_total_without_taxes,
		fr: fr.order_summary_estimate_total_without_taxes
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{ startDate: NonNullable<unknown>, endDate: NonNullable<unknown> }} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_summary_from_to_date = (params , options = {}) => {
	return {
		en: en.order_summary_from_to_date,
		fr: fr.order_summary_from_to_date
	}[options.languageTag ?? languageTag()](params)
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_summary_info_title = (params = {}, options = {}) => {
	return {
		en: en.order_summary_info_title,
		fr: fr.order_summary_info_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_summary_justification_title = (params = {}, options = {}) => {
	return {
		en: en.order_summary_justification_title,
		fr: fr.order_summary_justification_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_summary_manager_title = (params = {}, options = {}) => {
	return {
		en: en.order_summary_manager_title,
		fr: fr.order_summary_manager_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_summary_motive_title = (params = {}, options = {}) => {
	return {
		en: en.order_summary_motive_title,
		fr: fr.order_summary_motive_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_summary_motorized_title = (params = {}, options = {}) => {
	return {
		en: en.order_summary_motorized_title,
		fr: fr.order_summary_motorized_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_summary_name_title = (params = {}, options = {}) => {
	return {
		en: en.order_summary_name_title,
		fr: fr.order_summary_name_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{ siders: NonNullable<unknown>, weeklyVolume: NonNullable<unknown> }} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_summary_planning_sentence_1 = (params , options = {}) => {
	return {
		en: en.order_summary_planning_sentence_1,
		fr: fr.order_summary_planning_sentence_1
	}[options.languageTag ?? languageTag()](params)
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{ startDate: NonNullable<unknown>, endDate: NonNullable<unknown> }} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_summary_planning_sentence_2 = (params , options = {}) => {
	return {
		en: en.order_summary_planning_sentence_2,
		fr: fr.order_summary_planning_sentence_2
	}[options.languageTag ?? languageTag()](params)
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{ amplitudeStart: NonNullable<unknown>, amplitudeEnd: NonNullable<unknown> }} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_summary_planning_sentence_3 = (params , options = {}) => {
	return {
		en: en.order_summary_planning_sentence_3,
		fr: fr.order_summary_planning_sentence_3
	}[options.languageTag ?? languageTag()](params)
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_summary_planning_sentence_and = (params = {}, options = {}) => {
	return {
		en: en.order_summary_planning_sentence_and,
		fr: fr.order_summary_planning_sentence_and
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{ total: NonNullable<unknown> }} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_summary_planning_sentence_figures = (params , options = {}) => {
	return {
		en: en.order_summary_planning_sentence_figures,
		fr: fr.order_summary_planning_sentence_figures
	}[options.languageTag ?? languageTag()](params)
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_summary_planning_sentence_holidays = (params = {}, options = {}) => {
	return {
		en: en.order_summary_planning_sentence_holidays,
		fr: fr.order_summary_planning_sentence_holidays
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_summary_planning_sentence_saturday = (params = {}, options = {}) => {
	return {
		en: en.order_summary_planning_sentence_saturday,
		fr: fr.order_summary_planning_sentence_saturday
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_summary_planning_sentence_sunday = (params = {}, options = {}) => {
	return {
		en: en.order_summary_planning_sentence_sunday,
		fr: fr.order_summary_planning_sentence_sunday
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_summary_planning_sentence_weekDays = (params = {}, options = {}) => {
	return {
		en: en.order_summary_planning_sentence_weekDays,
		fr: fr.order_summary_planning_sentence_weekDays
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_summary_planning_title = (params = {}, options = {}) => {
	return {
		en: en.order_summary_planning_title,
		fr: fr.order_summary_planning_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_summary_remote_title = (params = {}, options = {}) => {
	return {
		en: en.order_summary_remote_title,
		fr: fr.order_summary_remote_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_summary_risks_additionnal_infos = (params = {}, options = {}) => {
	return {
		en: en.order_summary_risks_additionnal_infos,
		fr: fr.order_summary_risks_additionnal_infos
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_summary_shifts_title = (params = {}, options = {}) => {
	return {
		en: en.order_summary_shifts_title,
		fr: fr.order_summary_shifts_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_summary_shifts_warning_FAQ_link = (params = {}, options = {}) => {
	return {
		en: en.order_summary_shifts_warning_FAQ_link,
		fr: fr.order_summary_shifts_warning_FAQ_link
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_summary_shifts_warning_holiday = (params = {}, options = {}) => {
	return {
		en: en.order_summary_shifts_warning_holiday,
		fr: fr.order_summary_shifts_warning_holiday
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_summary_shifts_warning_holiday_interval = (params = {}, options = {}) => {
	return {
		en: en.order_summary_shifts_warning_holiday_interval,
		fr: fr.order_summary_shifts_warning_holiday_interval
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_summary_shifts_warning_link_text = (params = {}, options = {}) => {
	return {
		en: en.order_summary_shifts_warning_link_text,
		fr: fr.order_summary_shifts_warning_link_text
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_summary_title = (params = {}, options = {}) => {
	return {
		en: en.order_summary_title,
		fr: fr.order_summary_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_summary_total_week = (params = {}, options = {}) => {
	return {
		en: en.order_summary_total_week,
		fr: fr.order_summary_total_week
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_summary_type_delegation_description = (params = {}, options = {}) => {
	return {
		en: en.order_summary_type_delegation_description,
		fr: fr.order_summary_type_delegation_description
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_summary_type_delegation_title = (params = {}, options = {}) => {
	return {
		en: en.order_summary_type_delegation_title,
		fr: fr.order_summary_type_delegation_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_summary_type_gestion_description = (params = {}, options = {}) => {
	return {
		en: en.order_summary_type_gestion_description,
		fr: fr.order_summary_type_gestion_description
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_summary_type_gestion_title = (params = {}, options = {}) => {
	return {
		en: en.order_summary_type_gestion_title,
		fr: fr.order_summary_type_gestion_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_summary_type_title = (params = {}, options = {}) => {
	return {
		en: en.order_summary_type_title,
		fr: fr.order_summary_type_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_summary_workers_title = (params = {}, options = {}) => {
	return {
		en: en.order_summary_workers_title,
		fr: fr.order_summary_workers_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_summary_workers_wa_warning = (params = {}, options = {}) => {
	return {
		en: en.order_summary_workers_wa_warning,
		fr: fr.order_summary_workers_wa_warning
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_summary_yes = (params = {}, options = {}) => {
	return {
		en: en.order_summary_yes,
		fr: fr.order_summary_yes
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{ numberOfFilters: NonNullable<unknown> }} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_workers_filters_button_label = (params , options = {}) => {
	return {
		en: en.order_workers_filters_button_label,
		fr: fr.order_workers_filters_button_label
	}[options.languageTag ?? languageTag()](params)
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_workers_filters_modal_button_delete = (params = {}, options = {}) => {
	return {
		en: en.order_workers_filters_modal_button_delete,
		fr: fr.order_workers_filters_modal_button_delete
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_workers_filters_modal_experience_label = (params = {}, options = {}) => {
	return {
		en: en.order_workers_filters_modal_experience_label,
		fr: fr.order_workers_filters_modal_experience_label
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_workers_filters_modal_experience_placeholder = (params = {}, options = {}) => {
	return {
		en: en.order_workers_filters_modal_experience_placeholder,
		fr: fr.order_workers_filters_modal_experience_placeholder
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_workers_filters_modal_isrecommended_false = (params = {}, options = {}) => {
	return {
		en: en.order_workers_filters_modal_isrecommended_false,
		fr: fr.order_workers_filters_modal_isrecommended_false
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_workers_filters_modal_isrecommended_label = (params = {}, options = {}) => {
	return {
		en: en.order_workers_filters_modal_isrecommended_label,
		fr: fr.order_workers_filters_modal_isrecommended_label
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_workers_filters_modal_isrecommended_placeholder = (params = {}, options = {}) => {
	return {
		en: en.order_workers_filters_modal_isrecommended_placeholder,
		fr: fr.order_workers_filters_modal_isrecommended_placeholder
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_workers_filters_modal_isrecommended_true = (params = {}, options = {}) => {
	return {
		en: en.order_workers_filters_modal_isrecommended_true,
		fr: fr.order_workers_filters_modal_isrecommended_true
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_workers_filters_modal_last_task_1 = (params = {}, options = {}) => {
	return {
		en: en.order_workers_filters_modal_last_task_1,
		fr: fr.order_workers_filters_modal_last_task_1
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_workers_filters_modal_last_task_3 = (params = {}, options = {}) => {
	return {
		en: en.order_workers_filters_modal_last_task_3,
		fr: fr.order_workers_filters_modal_last_task_3
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_workers_filters_modal_last_task_all = (params = {}, options = {}) => {
	return {
		en: en.order_workers_filters_modal_last_task_all,
		fr: fr.order_workers_filters_modal_last_task_all
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_workers_filters_modal_last_task_label = (params = {}, options = {}) => {
	return {
		en: en.order_workers_filters_modal_last_task_label,
		fr: fr.order_workers_filters_modal_last_task_label
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_workers_filters_modal_last_task_placeholder = (params = {}, options = {}) => {
	return {
		en: en.order_workers_filters_modal_last_task_placeholder,
		fr: fr.order_workers_filters_modal_last_task_placeholder
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_workers_filters_modal_subtitle = (params = {}, options = {}) => {
	return {
		en: en.order_workers_filters_modal_subtitle,
		fr: fr.order_workers_filters_modal_subtitle
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_workers_filters_modal_title = (params = {}, options = {}) => {
	return {
		en: en.order_workers_filters_modal_title,
		fr: fr.order_workers_filters_modal_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_workers_filters_noresults_button_label = (params = {}, options = {}) => {
	return {
		en: en.order_workers_filters_noresults_button_label,
		fr: fr.order_workers_filters_noresults_button_label
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_workers_filters_noresults_subtitle = (params = {}, options = {}) => {
	return {
		en: en.order_workers_filters_noresults_subtitle,
		fr: fr.order_workers_filters_noresults_subtitle
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_workers_filters_noresults_title = (params = {}, options = {}) => {
	return {
		en: en.order_workers_filters_noresults_title,
		fr: fr.order_workers_filters_noresults_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{ numberOfRemainingSlots: NonNullable<unknown> }} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_workers_gestion_amount = (params , options = {}) => {
	return {
		en: en.order_workers_gestion_amount,
		fr: fr.order_workers_gestion_amount
	}[options.languageTag ?? languageTag()](params)
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_workers_gestion_noresults_subtitle = (params = {}, options = {}) => {
	return {
		en: en.order_workers_gestion_noresults_subtitle,
		fr: fr.order_workers_gestion_noresults_subtitle
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_workers_gestion_noresults_title = (params = {}, options = {}) => {
	return {
		en: en.order_workers_gestion_noresults_title,
		fr: fr.order_workers_gestion_noresults_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_workers_gestion_profile_add_button_label = (params = {}, options = {}) => {
	return {
		en: en.order_workers_gestion_profile_add_button_label,
		fr: fr.order_workers_gestion_profile_add_button_label
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_workers_gestion_profile_add_modal_error = (params = {}, options = {}) => {
	return {
		en: en.order_workers_gestion_profile_add_modal_error,
		fr: fr.order_workers_gestion_profile_add_modal_error
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_workers_gestion_profile_add_modal_firstname_label = (params = {}, options = {}) => {
	return {
		en: en.order_workers_gestion_profile_add_modal_firstname_label,
		fr: fr.order_workers_gestion_profile_add_modal_firstname_label
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_workers_gestion_profile_add_modal_firstname_placeholder = (params = {}, options = {}) => {
	return {
		en: en.order_workers_gestion_profile_add_modal_firstname_placeholder,
		fr: fr.order_workers_gestion_profile_add_modal_firstname_placeholder
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_workers_gestion_profile_add_modal_lastname_label = (params = {}, options = {}) => {
	return {
		en: en.order_workers_gestion_profile_add_modal_lastname_label,
		fr: fr.order_workers_gestion_profile_add_modal_lastname_label
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_workers_gestion_profile_add_modal_lastname_placeholder = (params = {}, options = {}) => {
	return {
		en: en.order_workers_gestion_profile_add_modal_lastname_placeholder,
		fr: fr.order_workers_gestion_profile_add_modal_lastname_placeholder
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_workers_gestion_profile_add_modal_phone_label = (params = {}, options = {}) => {
	return {
		en: en.order_workers_gestion_profile_add_modal_phone_label,
		fr: fr.order_workers_gestion_profile_add_modal_phone_label
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_workers_gestion_profile_add_modal_phone_placeholder = (params = {}, options = {}) => {
	return {
		en: en.order_workers_gestion_profile_add_modal_phone_placeholder,
		fr: fr.order_workers_gestion_profile_add_modal_phone_placeholder
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_workers_gestion_profile_add_modal_subtitle = (params = {}, options = {}) => {
	return {
		en: en.order_workers_gestion_profile_add_modal_subtitle,
		fr: fr.order_workers_gestion_profile_add_modal_subtitle
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_workers_gestion_profile_add_modal_title = (params = {}, options = {}) => {
	return {
		en: en.order_workers_gestion_profile_add_modal_title,
		fr: fr.order_workers_gestion_profile_add_modal_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{ numberOfSharedWorkers: NonNullable<unknown> }} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_workers_list_network_label = (params , options = {}) => {
	return {
		en: en.order_workers_list_network_label,
		fr: fr.order_workers_list_network_label
	}[options.languageTag ?? languageTag()](params)
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{ numberOfOrganisationWorkers: NonNullable<unknown> }} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_workers_list_orga_label = (params , options = {}) => {
	return {
		en: en.order_workers_list_orga_label,
		fr: fr.order_workers_list_orga_label
	}[options.languageTag ?? languageTag()](params)
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{ numberOfSelectedWorkers: NonNullable<unknown> }} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_workers_list_selected_label = (params , options = {}) => {
	return {
		en: en.order_workers_list_selected_label,
		fr: fr.order_workers_list_selected_label
	}[options.languageTag ?? languageTag()](params)
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_workers_network_noresults_button_label = (params = {}, options = {}) => {
	return {
		en: en.order_workers_network_noresults_button_label,
		fr: fr.order_workers_network_noresults_button_label
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{ numberOfWorkers: NonNullable<unknown> }} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_workers_network_noresults_counter = (params , options = {}) => {
	return {
		en: en.order_workers_network_noresults_counter,
		fr: fr.order_workers_network_noresults_counter
	}[options.languageTag ?? languageTag()](params)
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_workers_network_noresults_subtitle = (params = {}, options = {}) => {
	return {
		en: en.order_workers_network_noresults_subtitle,
		fr: fr.order_workers_network_noresults_subtitle
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_workers_network_noresults_title = (params = {}, options = {}) => {
	return {
		en: en.order_workers_network_noresults_title,
		fr: fr.order_workers_network_noresults_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_workers_search_noresults_subtitle = (params = {}, options = {}) => {
	return {
		en: en.order_workers_search_noresults_subtitle,
		fr: fr.order_workers_search_noresults_subtitle
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_workers_search_noresults_title = (params = {}, options = {}) => {
	return {
		en: en.order_workers_search_noresults_title,
		fr: fr.order_workers_search_noresults_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_workers_search_placeholder = (params = {}, options = {}) => {
	return {
		en: en.order_workers_search_placeholder,
		fr: fr.order_workers_search_placeholder
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_workers_subtitle = (params = {}, options = {}) => {
	return {
		en: en.order_workers_subtitle,
		fr: fr.order_workers_subtitle
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_workers_title = (params = {}, options = {}) => {
	return {
		en: en.order_workers_title,
		fr: fr.order_workers_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_workers_wa_only_modal_subtitle = (params = {}, options = {}) => {
	return {
		en: en.order_workers_wa_only_modal_subtitle,
		fr: fr.order_workers_wa_only_modal_subtitle
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_workers_wa_only_modal_title = (params = {}, options = {}) => {
	return {
		en: en.order_workers_wa_only_modal_title,
		fr: fr.order_workers_wa_only_modal_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_workers_wa_only_modal_warning = (params = {}, options = {}) => {
	return {
		en: en.order_workers_wa_only_modal_warning,
		fr: fr.order_workers_wa_only_modal_warning
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{ numberOfRemainingSlots: NonNullable<unknown> }} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_workers_wa_only_toggle_amount = (params , options = {}) => {
	return {
		en: en.order_workers_wa_only_toggle_amount,
		fr: fr.order_workers_wa_only_toggle_amount
	}[options.languageTag ?? languageTag()](params)
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_workers_wa_only_toggle_label = (params = {}, options = {}) => {
	return {
		en: en.order_workers_wa_only_toggle_label,
		fr: fr.order_workers_wa_only_toggle_label
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_workers_wa_only_toggle_tooltip_content_activated = (params = {}, options = {}) => {
	return {
		en: en.order_workers_wa_only_toggle_tooltip_content_activated,
		fr: fr.order_workers_wa_only_toggle_tooltip_content_activated
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_workers_wa_only_toggle_tooltip_content_deactivated = (params = {}, options = {}) => {
	return {
		en: en.order_workers_wa_only_toggle_tooltip_content_deactivated,
		fr: fr.order_workers_wa_only_toggle_tooltip_content_deactivated
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_workers_wa_only_toggle_tooltip_title_activated = (params = {}, options = {}) => {
	return {
		en: en.order_workers_wa_only_toggle_tooltip_title_activated,
		fr: fr.order_workers_wa_only_toggle_tooltip_title_activated
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const order_workers_wa_only_toggle_tooltip_title_deactivated = (params = {}, options = {}) => {
	return {
		en: en.order_workers_wa_only_toggle_tooltip_title_deactivated,
		fr: fr.order_workers_wa_only_toggle_tooltip_title_deactivated
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const per = (params = {}, options = {}) => {
	return {
		en: en.per,
		fr: fr.per
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const personal_informations = (params = {}, options = {}) => {
	return {
		en: en.personal_informations,
		fr: fr.personal_informations
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const personal_informations_email = (params = {}, options = {}) => {
	return {
		en: en.personal_informations_email,
		fr: fr.personal_informations_email
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const personal_informations_email_placeholder = (params = {}, options = {}) => {
	return {
		en: en.personal_informations_email_placeholder,
		fr: fr.personal_informations_email_placeholder
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const personal_informations_error_incorrect_password = (params = {}, options = {}) => {
	return {
		en: en.personal_informations_error_incorrect_password,
		fr: fr.personal_informations_error_incorrect_password
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const personal_informations_error_invalid_password = (params = {}, options = {}) => {
	return {
		en: en.personal_informations_error_invalid_password,
		fr: fr.personal_informations_error_invalid_password
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const personal_informations_error_invalid_password_format = (params = {}, options = {}) => {
	return {
		en: en.personal_informations_error_invalid_password_format,
		fr: fr.personal_informations_error_invalid_password_format
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const personal_informations_error_invalid_password_length = (params = {}, options = {}) => {
	return {
		en: en.personal_informations_error_invalid_password_length,
		fr: fr.personal_informations_error_invalid_password_length
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const personal_informations_error_invalid_phone = (params = {}, options = {}) => {
	return {
		en: en.personal_informations_error_invalid_phone,
		fr: fr.personal_informations_error_invalid_phone
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const personal_informations_firstname = (params = {}, options = {}) => {
	return {
		en: en.personal_informations_firstname,
		fr: fr.personal_informations_firstname
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const personal_informations_firstname_placeholder = (params = {}, options = {}) => {
	return {
		en: en.personal_informations_firstname_placeholder,
		fr: fr.personal_informations_firstname_placeholder
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const personal_informations_general_information_description = (params = {}, options = {}) => {
	return {
		en: en.personal_informations_general_information_description,
		fr: fr.personal_informations_general_information_description
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const personal_informations_general_information_title = (params = {}, options = {}) => {
	return {
		en: en.personal_informations_general_information_title,
		fr: fr.personal_informations_general_information_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const personal_informations_lastname = (params = {}, options = {}) => {
	return {
		en: en.personal_informations_lastname,
		fr: fr.personal_informations_lastname
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const personal_informations_lastname_placeholder = (params = {}, options = {}) => {
	return {
		en: en.personal_informations_lastname_placeholder,
		fr: fr.personal_informations_lastname_placeholder
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const personal_informations_new_password = (params = {}, options = {}) => {
	return {
		en: en.personal_informations_new_password,
		fr: fr.personal_informations_new_password
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const personal_informations_new_password_confirmation = (params = {}, options = {}) => {
	return {
		en: en.personal_informations_new_password_confirmation,
		fr: fr.personal_informations_new_password_confirmation
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const personal_informations_new_password_confirmation_placeholder = (params = {}, options = {}) => {
	return {
		en: en.personal_informations_new_password_confirmation_placeholder,
		fr: fr.personal_informations_new_password_confirmation_placeholder
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const personal_informations_new_password_placeholder = (params = {}, options = {}) => {
	return {
		en: en.personal_informations_new_password_placeholder,
		fr: fr.personal_informations_new_password_placeholder
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const personal_informations_password_button = (params = {}, options = {}) => {
	return {
		en: en.personal_informations_password_button,
		fr: fr.personal_informations_password_button
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const personal_informations_password_modal_confirm_field = (params = {}, options = {}) => {
	return {
		en: en.personal_informations_password_modal_confirm_field,
		fr: fr.personal_informations_password_modal_confirm_field
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const personal_informations_password_modal_new_field = (params = {}, options = {}) => {
	return {
		en: en.personal_informations_password_modal_new_field,
		fr: fr.personal_informations_password_modal_new_field
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const personal_informations_password_modal_old_field = (params = {}, options = {}) => {
	return {
		en: en.personal_informations_password_modal_old_field,
		fr: fr.personal_informations_password_modal_old_field
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const personal_informations_password_modal_save_button = (params = {}, options = {}) => {
	return {
		en: en.personal_informations_password_modal_save_button,
		fr: fr.personal_informations_password_modal_save_button
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const personal_informations_password_modal_title = (params = {}, options = {}) => {
	return {
		en: en.personal_informations_password_modal_title,
		fr: fr.personal_informations_password_modal_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const personal_informations_password_success = (params = {}, options = {}) => {
	return {
		en: en.personal_informations_password_success,
		fr: fr.personal_informations_password_success
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const personal_informations_password_tip = (params = {}, options = {}) => {
	return {
		en: en.personal_informations_password_tip,
		fr: fr.personal_informations_password_tip
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const personal_informations_password_title = (params = {}, options = {}) => {
	return {
		en: en.personal_informations_password_title,
		fr: fr.personal_informations_password_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const personal_informations_phone = (params = {}, options = {}) => {
	return {
		en: en.personal_informations_phone,
		fr: fr.personal_informations_phone
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const personal_informations_phone_placeholder = (params = {}, options = {}) => {
	return {
		en: en.personal_informations_phone_placeholder,
		fr: fr.personal_informations_phone_placeholder
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const personal_informations_profile_saved = (params = {}, options = {}) => {
	return {
		en: en.personal_informations_profile_saved,
		fr: fr.personal_informations_profile_saved
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const planning_amplitude_label = (params = {}, options = {}) => {
	return {
		en: en.planning_amplitude_label,
		fr: fr.planning_amplitude_label
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const planning_amplitude_max_placeholder = (params = {}, options = {}) => {
	return {
		en: en.planning_amplitude_max_placeholder,
		fr: fr.planning_amplitude_max_placeholder
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const planning_amplitude_min_placeholder = (params = {}, options = {}) => {
	return {
		en: en.planning_amplitude_min_placeholder,
		fr: fr.planning_amplitude_min_placeholder
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const planning_amplitude_tip = (params = {}, options = {}) => {
	return {
		en: en.planning_amplitude_tip,
		fr: fr.planning_amplitude_tip
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const planning_dates_info = (params = {}, options = {}) => {
	return {
		en: en.planning_dates_info,
		fr: fr.planning_dates_info
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const planning_dates_label = (params = {}, options = {}) => {
	return {
		en: en.planning_dates_label,
		fr: fr.planning_dates_label
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const planning_dates_length_error = (params = {}, options = {}) => {
	return {
		en: en.planning_dates_length_error,
		fr: fr.planning_dates_length_error
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const planning_dates_reversed_error = (params = {}, options = {}) => {
	return {
		en: en.planning_dates_reversed_error,
		fr: fr.planning_dates_reversed_error
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const planning_dates_tip = (params = {}, options = {}) => {
	return {
		en: en.planning_dates_tip,
		fr: fr.planning_dates_tip
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const planning_day_type_error_empty = (params = {}, options = {}) => {
	return {
		en: en.planning_day_type_error_empty,
		fr: fr.planning_day_type_error_empty
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const planning_day_type_error_more = (params = {}, options = {}) => {
	return {
		en: en.planning_day_type_error_more,
		fr: fr.planning_day_type_error_more
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const planning_day_type_holidays = (params = {}, options = {}) => {
	return {
		en: en.planning_day_type_holidays,
		fr: fr.planning_day_type_holidays
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const planning_day_type_label = (params = {}, options = {}) => {
	return {
		en: en.planning_day_type_label,
		fr: fr.planning_day_type_label
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const planning_day_type_saturday = (params = {}, options = {}) => {
	return {
		en: en.planning_day_type_saturday,
		fr: fr.planning_day_type_saturday
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const planning_day_type_sunday = (params = {}, options = {}) => {
	return {
		en: en.planning_day_type_sunday,
		fr: fr.planning_day_type_sunday
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const planning_day_type_week = (params = {}, options = {}) => {
	return {
		en: en.planning_day_type_week,
		fr: fr.planning_day_type_week
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{ downloadFormat: NonNullable<unknown> }} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const planning_download_as = (params , options = {}) => {
	return {
		en: en.planning_download_as,
		fr: fr.planning_download_as
	}[options.languageTag ?? languageTag()](params)
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const planning_hourly_volume_amplitude_error = (params = {}, options = {}) => {
	return {
		en: en.planning_hourly_volume_amplitude_error,
		fr: fr.planning_hourly_volume_amplitude_error
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{ hours: NonNullable<unknown> }} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const planning_hourly_volume_error = (params , options = {}) => {
	return {
		en: en.planning_hourly_volume_error,
		fr: fr.planning_hourly_volume_error
	}[options.languageTag ?? languageTag()](params)
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const planning_hourly_volume_label = (params = {}, options = {}) => {
	return {
		en: en.planning_hourly_volume_label,
		fr: fr.planning_hourly_volume_label
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const planning_legal_tip_1 = (params = {}, options = {}) => {
	return {
		en: en.planning_legal_tip_1,
		fr: fr.planning_legal_tip_1
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const planning_legal_tip_2 = (params = {}, options = {}) => {
	return {
		en: en.planning_legal_tip_2,
		fr: fr.planning_legal_tip_2
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const planning_select_current_week = (params = {}, options = {}) => {
	return {
		en: en.planning_select_current_week,
		fr: fr.planning_select_current_week
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const planning_sheet_empty_user = (params = {}, options = {}) => {
	return {
		en: en.planning_sheet_empty_user,
		fr: fr.planning_sheet_empty_user
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const planning_sheet_view_task = (params = {}, options = {}) => {
	return {
		en: en.planning_sheet_view_task,
		fr: fr.planning_sheet_view_task
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const planning_short_week = (params = {}, options = {}) => {
	return {
		en: en.planning_short_week,
		fr: fr.planning_short_week
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{ siderName: NonNullable<unknown>, workedHoursInWeek: NonNullable<unknown>, plural: NonNullable<unknown> }} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const planning_sider_worked_hours_week = (params , options = {}) => {
	return {
		en: en.planning_sider_worked_hours_week,
		fr: fr.planning_sider_worked_hours_week
	}[options.languageTag ?? languageTag()](params)
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const planning_siders_error = (params = {}, options = {}) => {
	return {
		en: en.planning_siders_error,
		fr: fr.planning_siders_error
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const planning_siders_label = (params = {}, options = {}) => {
	return {
		en: en.planning_siders_label,
		fr: fr.planning_siders_label
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const planning_type_accurate_desc = (params = {}, options = {}) => {
	return {
		en: en.planning_type_accurate_desc,
		fr: fr.planning_type_accurate_desc
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const planning_type_accurate_subtitle = (params = {}, options = {}) => {
	return {
		en: en.planning_type_accurate_subtitle,
		fr: fr.planning_type_accurate_subtitle
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const planning_type_accurate_title = (params = {}, options = {}) => {
	return {
		en: en.planning_type_accurate_title,
		fr: fr.planning_type_accurate_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const planning_type_desc = (params = {}, options = {}) => {
	return {
		en: en.planning_type_desc,
		fr: fr.planning_type_desc
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const planning_type_flexible_button_create = (params = {}, options = {}) => {
	return {
		en: en.planning_type_flexible_button_create,
		fr: fr.planning_type_flexible_button_create
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const planning_type_flexible_desc = (params = {}, options = {}) => {
	return {
		en: en.planning_type_flexible_desc,
		fr: fr.planning_type_flexible_desc
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const planning_type_flexible_subtitle = (params = {}, options = {}) => {
	return {
		en: en.planning_type_flexible_subtitle,
		fr: fr.planning_type_flexible_subtitle
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const planning_type_flexible_title = (params = {}, options = {}) => {
	return {
		en: en.planning_type_flexible_title,
		fr: fr.planning_type_flexible_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const planning_type_title = (params = {}, options = {}) => {
	return {
		en: en.planning_type_title,
		fr: fr.planning_type_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const planning_volume_day_type_error = (params = {}, options = {}) => {
	return {
		en: en.planning_volume_day_type_error,
		fr: fr.planning_volume_day_type_error
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const planning_volume_error = (params = {}, options = {}) => {
	return {
		en: en.planning_volume_error,
		fr: fr.planning_volume_error
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const pol = (params = {}, options = {}) => {
	return {
		en: en.pol,
		fr: fr.pol
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const por = (params = {}, options = {}) => {
	return {
		en: en.por,
		fr: fr.por
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const preselect_actions_tooltip_approve = (params = {}, options = {}) => {
	return {
		en: en.preselect_actions_tooltip_approve,
		fr: fr.preselect_actions_tooltip_approve
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const preselect_actions_tooltip_download = (params = {}, options = {}) => {
	return {
		en: en.preselect_actions_tooltip_download,
		fr: fr.preselect_actions_tooltip_download
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const preselect_actions_tooltip_refuse = (params = {}, options = {}) => {
	return {
		en: en.preselect_actions_tooltip_refuse,
		fr: fr.preselect_actions_tooltip_refuse
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const preselect_actions_tooltip_upload = (params = {}, options = {}) => {
	return {
		en: en.preselect_actions_tooltip_upload,
		fr: fr.preselect_actions_tooltip_upload
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const preselect_availabilities_from = (params = {}, options = {}) => {
	return {
		en: en.preselect_availabilities_from,
		fr: fr.preselect_availabilities_from
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const preselect_availabilities_until = (params = {}, options = {}) => {
	return {
		en: en.preselect_availabilities_until,
		fr: fr.preselect_availabilities_until
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const preselect_cv_uploaded = (params = {}, options = {}) => {
	return {
		en: en.preselect_cv_uploaded,
		fr: fr.preselect_cv_uploaded
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const preselect_no_result_client = (params = {}, options = {}) => {
	return {
		en: en.preselect_no_result_client,
		fr: fr.preselect_no_result_client
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const preselect_no_result_logas = (params = {}, options = {}) => {
	return {
		en: en.preselect_no_result_logas,
		fr: fr.preselect_no_result_logas
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const preselect_refusal_modal_button = (params = {}, options = {}) => {
	return {
		en: en.preselect_refusal_modal_button,
		fr: fr.preselect_refusal_modal_button
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const preselect_refusal_modal_description = (params = {}, options = {}) => {
	return {
		en: en.preselect_refusal_modal_description,
		fr: fr.preselect_refusal_modal_description
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const preselect_refusal_modal_placeholder = (params = {}, options = {}) => {
	return {
		en: en.preselect_refusal_modal_placeholder,
		fr: fr.preselect_refusal_modal_placeholder
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const preselect_refusal_modal_title = (params = {}, options = {}) => {
	return {
		en: en.preselect_refusal_modal_title,
		fr: fr.preselect_refusal_modal_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const preselect_textarea_hint = (params = {}, options = {}) => {
	return {
		en: en.preselect_textarea_hint,
		fr: fr.preselect_textarea_hint
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const preselect_textarea_placeholder = (params = {}, options = {}) => {
	return {
		en: en.preselect_textarea_placeholder,
		fr: fr.preselect_textarea_placeholder
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const preselect_tip_no_visible = (params = {}, options = {}) => {
	return {
		en: en.preselect_tip_no_visible,
		fr: fr.preselect_tip_no_visible
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const preselection_h1 = (params = {}, options = {}) => {
	return {
		en: en.preselection_h1,
		fr: fr.preselection_h1
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const preselection_status_approved = (params = {}, options = {}) => {
	return {
		en: en.preselection_status_approved,
		fr: fr.preselection_status_approved
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const preselection_status_refused = (params = {}, options = {}) => {
	return {
		en: en.preselection_status_refused,
		fr: fr.preselection_status_refused
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const preselection_status_sent = (params = {}, options = {}) => {
	return {
		en: en.preselection_status_sent,
		fr: fr.preselection_status_sent
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const preselection_table_header_applicant = (params = {}, options = {}) => {
	return {
		en: en.preselection_table_header_applicant,
		fr: fr.preselection_table_header_applicant
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const preselection_table_header_availabilities = (params = {}, options = {}) => {
	return {
		en: en.preselection_table_header_availabilities,
		fr: fr.preselection_table_header_availabilities
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const preselection_table_header_notes = (params = {}, options = {}) => {
	return {
		en: en.preselection_table_header_notes,
		fr: fr.preselection_table_header_notes
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const preselection_table_header_status = (params = {}, options = {}) => {
	return {
		en: en.preselection_table_header_status,
		fr: fr.preselection_table_header_status
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const preselection_table_header_visibility = (params = {}, options = {}) => {
	return {
		en: en.preselection_table_header_visibility,
		fr: fr.preselection_table_header_visibility
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const que = (params = {}, options = {}) => {
	return {
		en: en.que,
		fr: fr.que
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const rename = (params = {}, options = {}) => {
	return {
		en: en.rename,
		fr: fr.rename
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const rename_task = (params = {}, options = {}) => {
	return {
		en: en.rename_task,
		fr: fr.rename_task
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const rum = (params = {}, options = {}) => {
	return {
		en: en.rum,
		fr: fr.rum
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const rus = (params = {}, options = {}) => {
	return {
		en: en.rus,
		fr: fr.rus
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const save = (params = {}, options = {}) => {
	return {
		en: en.save,
		fr: fr.save
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const save_quit = (params = {}, options = {}) => {
	return {
		en: en.save_quit,
		fr: fr.save_quit
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const search = (params = {}, options = {}) => {
	return {
		en: en.search,
		fr: fr.search
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const send = (params = {}, options = {}) => {
	return {
		en: en.send,
		fr: fr.send
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_actions_content = (params = {}, options = {}) => {
	return {
		en: en.settings_actions_content,
		fr: fr.settings_actions_content
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_actions_discard = (params = {}, options = {}) => {
	return {
		en: en.settings_actions_discard,
		fr: fr.settings_actions_discard
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_actions_save = (params = {}, options = {}) => {
	return {
		en: en.settings_actions_save,
		fr: fr.settings_actions_save
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_legal_ape = (params = {}, options = {}) => {
	return {
		en: en.settings_legal_ape,
		fr: fr.settings_legal_ape
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_legal_ape_placeholder = (params = {}, options = {}) => {
	return {
		en: en.settings_legal_ape_placeholder,
		fr: fr.settings_legal_ape_placeholder
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_legal_ape_tip = (params = {}, options = {}) => {
	return {
		en: en.settings_legal_ape_tip,
		fr: fr.settings_legal_ape_tip
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_legal_business_name = (params = {}, options = {}) => {
	return {
		en: en.settings_legal_business_name,
		fr: fr.settings_legal_business_name
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_legal_business_name_detail = (params = {}, options = {}) => {
	return {
		en: en.settings_legal_business_name_detail,
		fr: fr.settings_legal_business_name_detail
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_legal_business_name_placeholder = (params = {}, options = {}) => {
	return {
		en: en.settings_legal_business_name_placeholder,
		fr: fr.settings_legal_business_name_placeholder
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_legal_business_name_tip = (params = {}, options = {}) => {
	return {
		en: en.settings_legal_business_name_tip,
		fr: fr.settings_legal_business_name_tip
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_legal_error_ape_format = (params = {}, options = {}) => {
	return {
		en: en.settings_legal_error_ape_format,
		fr: fr.settings_legal_error_ape_format
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_legal_error_siret_format = (params = {}, options = {}) => {
	return {
		en: en.settings_legal_error_siret_format,
		fr: fr.settings_legal_error_siret_format
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_legal_name = (params = {}, options = {}) => {
	return {
		en: en.settings_legal_name,
		fr: fr.settings_legal_name
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_legal_name_placeholder = (params = {}, options = {}) => {
	return {
		en: en.settings_legal_name_placeholder,
		fr: fr.settings_legal_name_placeholder
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_legal_siret = (params = {}, options = {}) => {
	return {
		en: en.settings_legal_siret,
		fr: fr.settings_legal_siret
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_legal_siret_placeholder = (params = {}, options = {}) => {
	return {
		en: en.settings_legal_siret_placeholder,
		fr: fr.settings_legal_siret_placeholder
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_legal_siret_tip = (params = {}, options = {}) => {
	return {
		en: en.settings_legal_siret_tip,
		fr: fr.settings_legal_siret_tip
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_legal_subtitle = (params = {}, options = {}) => {
	return {
		en: en.settings_legal_subtitle,
		fr: fr.settings_legal_subtitle
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_legal_title = (params = {}, options = {}) => {
	return {
		en: en.settings_legal_title,
		fr: fr.settings_legal_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_nav_account = (params = {}, options = {}) => {
	return {
		en: en.settings_nav_account,
		fr: fr.settings_nav_account
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_nav_company_info = (params = {}, options = {}) => {
	return {
		en: en.settings_nav_company_info,
		fr: fr.settings_nav_company_info
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_nav_legal_info = (params = {}, options = {}) => {
	return {
		en: en.settings_nav_legal_info,
		fr: fr.settings_nav_legal_info
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_nav_payment_info = (params = {}, options = {}) => {
	return {
		en: en.settings_nav_payment_info,
		fr: fr.settings_nav_payment_info
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_nav_payment_info_accounting = (params = {}, options = {}) => {
	return {
		en: en.settings_nav_payment_info_accounting,
		fr: fr.settings_nav_payment_info_accounting
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_nav_payment_info_billing = (params = {}, options = {}) => {
	return {
		en: en.settings_nav_payment_info_billing,
		fr: fr.settings_nav_payment_info_billing
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_nav_payment_info_method = (params = {}, options = {}) => {
	return {
		en: en.settings_nav_payment_info_method,
		fr: fr.settings_nav_payment_info_method
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_nav_payment_info_remuneration = (params = {}, options = {}) => {
	return {
		en: en.settings_nav_payment_info_remuneration,
		fr: fr.settings_nav_payment_info_remuneration
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_nav_payment_info_remuneration_compensations = (params = {}, options = {}) => {
	return {
		en: en.settings_nav_payment_info_remuneration_compensations,
		fr: fr.settings_nav_payment_info_remuneration_compensations
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_nav_payment_info_remuneration_hours = (params = {}, options = {}) => {
	return {
		en: en.settings_nav_payment_info_remuneration_hours,
		fr: fr.settings_nav_payment_info_remuneration_hours
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_nav_team = (params = {}, options = {}) => {
	return {
		en: en.settings_nav_team,
		fr: fr.settings_nav_team
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_payment_accounting_email = (params = {}, options = {}) => {
	return {
		en: en.settings_payment_accounting_email,
		fr: fr.settings_payment_accounting_email
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_payment_accounting_email_placeholder = (params = {}, options = {}) => {
	return {
		en: en.settings_payment_accounting_email_placeholder,
		fr: fr.settings_payment_accounting_email_placeholder
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_payment_accounting_firstname = (params = {}, options = {}) => {
	return {
		en: en.settings_payment_accounting_firstname,
		fr: fr.settings_payment_accounting_firstname
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_payment_accounting_firstname_placeholder = (params = {}, options = {}) => {
	return {
		en: en.settings_payment_accounting_firstname_placeholder,
		fr: fr.settings_payment_accounting_firstname_placeholder
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_payment_accounting_name = (params = {}, options = {}) => {
	return {
		en: en.settings_payment_accounting_name,
		fr: fr.settings_payment_accounting_name
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_payment_accounting_name_placeholder = (params = {}, options = {}) => {
	return {
		en: en.settings_payment_accounting_name_placeholder,
		fr: fr.settings_payment_accounting_name_placeholder
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_payment_accounting_phone_number = (params = {}, options = {}) => {
	return {
		en: en.settings_payment_accounting_phone_number,
		fr: fr.settings_payment_accounting_phone_number
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_payment_accounting_phone_number_placeholder = (params = {}, options = {}) => {
	return {
		en: en.settings_payment_accounting_phone_number_placeholder,
		fr: fr.settings_payment_accounting_phone_number_placeholder
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_payment_accounting_subtitle = (params = {}, options = {}) => {
	return {
		en: en.settings_payment_accounting_subtitle,
		fr: fr.settings_payment_accounting_subtitle
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_payment_accounting_title = (params = {}, options = {}) => {
	return {
		en: en.settings_payment_accounting_title,
		fr: fr.settings_payment_accounting_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_payment_api_error = (params = {}, options = {}) => {
	return {
		en: en.settings_payment_api_error,
		fr: fr.settings_payment_api_error
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_payment_billing_address = (params = {}, options = {}) => {
	return {
		en: en.settings_payment_billing_address,
		fr: fr.settings_payment_billing_address
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_payment_billing_address_placeholder = (params = {}, options = {}) => {
	return {
		en: en.settings_payment_billing_address_placeholder,
		fr: fr.settings_payment_billing_address_placeholder
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_payment_billing_city = (params = {}, options = {}) => {
	return {
		en: en.settings_payment_billing_city,
		fr: fr.settings_payment_billing_city
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_payment_billing_city_placeholder = (params = {}, options = {}) => {
	return {
		en: en.settings_payment_billing_city_placeholder,
		fr: fr.settings_payment_billing_city_placeholder
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_payment_billing_country = (params = {}, options = {}) => {
	return {
		en: en.settings_payment_billing_country,
		fr: fr.settings_payment_billing_country
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_payment_billing_country_placeholder = (params = {}, options = {}) => {
	return {
		en: en.settings_payment_billing_country_placeholder,
		fr: fr.settings_payment_billing_country_placeholder
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_payment_billing_postal_code = (params = {}, options = {}) => {
	return {
		en: en.settings_payment_billing_postal_code,
		fr: fr.settings_payment_billing_postal_code
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_payment_billing_postal_code_placeholder = (params = {}, options = {}) => {
	return {
		en: en.settings_payment_billing_postal_code_placeholder,
		fr: fr.settings_payment_billing_postal_code_placeholder
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_payment_billing_subtitle = (params = {}, options = {}) => {
	return {
		en: en.settings_payment_billing_subtitle,
		fr: fr.settings_payment_billing_subtitle
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_payment_billing_title = (params = {}, options = {}) => {
	return {
		en: en.settings_payment_billing_title,
		fr: fr.settings_payment_billing_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_payment_billing_tva_intro = (params = {}, options = {}) => {
	return {
		en: en.settings_payment_billing_tva_intro,
		fr: fr.settings_payment_billing_tva_intro
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_payment_billing_tva_label = (params = {}, options = {}) => {
	return {
		en: en.settings_payment_billing_tva_label,
		fr: fr.settings_payment_billing_tva_label
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_payment_billing_tva_placeholder = (params = {}, options = {}) => {
	return {
		en: en.settings_payment_billing_tva_placeholder,
		fr: fr.settings_payment_billing_tva_placeholder
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_payment_billing_tva_tip = (params = {}, options = {}) => {
	return {
		en: en.settings_payment_billing_tva_tip,
		fr: fr.settings_payment_billing_tva_tip
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_payment_error_card_check = (params = {}, options = {}) => {
	return {
		en: en.settings_payment_error_card_check,
		fr: fr.settings_payment_error_card_check
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_payment_error_card_format = (params = {}, options = {}) => {
	return {
		en: en.settings_payment_error_card_format,
		fr: fr.settings_payment_error_card_format
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_payment_error_city_format = (params = {}, options = {}) => {
	return {
		en: en.settings_payment_error_city_format,
		fr: fr.settings_payment_error_city_format
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_payment_error_country_format = (params = {}, options = {}) => {
	return {
		en: en.settings_payment_error_country_format,
		fr: fr.settings_payment_error_country_format
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_payment_error_digits_only_format = (params = {}, options = {}) => {
	return {
		en: en.settings_payment_error_digits_only_format,
		fr: fr.settings_payment_error_digits_only_format
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_payment_error_email_format = (params = {}, options = {}) => {
	return {
		en: en.settings_payment_error_email_format,
		fr: fr.settings_payment_error_email_format
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_payment_error_exp_date_format = (params = {}, options = {}) => {
	return {
		en: en.settings_payment_error_exp_date_format,
		fr: fr.settings_payment_error_exp_date_format
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_payment_error_iban_format = (params = {}, options = {}) => {
	return {
		en: en.settings_payment_error_iban_format,
		fr: fr.settings_payment_error_iban_format
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_payment_error_phone_number_format = (params = {}, options = {}) => {
	return {
		en: en.settings_payment_error_phone_number_format,
		fr: fr.settings_payment_error_phone_number_format
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_payment_error_postal_code_format = (params = {}, options = {}) => {
	return {
		en: en.settings_payment_error_postal_code_format,
		fr: fr.settings_payment_error_postal_code_format
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_payment_error_tos = (params = {}, options = {}) => {
	return {
		en: en.settings_payment_error_tos,
		fr: fr.settings_payment_error_tos
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_payment_error_tva_number_format = (params = {}, options = {}) => {
	return {
		en: en.settings_payment_error_tva_number_format,
		fr: fr.settings_payment_error_tva_number_format
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_payment_method_card_cvv = (params = {}, options = {}) => {
	return {
		en: en.settings_payment_method_card_cvv,
		fr: fr.settings_payment_method_card_cvv
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_payment_method_card_cvv_placeholder = (params = {}, options = {}) => {
	return {
		en: en.settings_payment_method_card_cvv_placeholder,
		fr: fr.settings_payment_method_card_cvv_placeholder
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_payment_method_card_desc = (params = {}, options = {}) => {
	return {
		en: en.settings_payment_method_card_desc,
		fr: fr.settings_payment_method_card_desc
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_payment_method_card_exp_date = (params = {}, options = {}) => {
	return {
		en: en.settings_payment_method_card_exp_date,
		fr: fr.settings_payment_method_card_exp_date
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_payment_method_card_exp_date_placeholder = (params = {}, options = {}) => {
	return {
		en: en.settings_payment_method_card_exp_date_placeholder,
		fr: fr.settings_payment_method_card_exp_date_placeholder
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_payment_method_card_label = (params = {}, options = {}) => {
	return {
		en: en.settings_payment_method_card_label,
		fr: fr.settings_payment_method_card_label
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_payment_method_card_modify = (params = {}, options = {}) => {
	return {
		en: en.settings_payment_method_card_modify,
		fr: fr.settings_payment_method_card_modify
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_payment_method_card_number = (params = {}, options = {}) => {
	return {
		en: en.settings_payment_method_card_number,
		fr: fr.settings_payment_method_card_number
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_payment_method_card_number_placeholder = (params = {}, options = {}) => {
	return {
		en: en.settings_payment_method_card_number_placeholder,
		fr: fr.settings_payment_method_card_number_placeholder
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_payment_method_card_saved = (params = {}, options = {}) => {
	return {
		en: en.settings_payment_method_card_saved,
		fr: fr.settings_payment_method_card_saved
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_payment_method_cs_desc = (params = {}, options = {}) => {
	return {
		en: en.settings_payment_method_cs_desc,
		fr: fr.settings_payment_method_cs_desc
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_payment_method_cs_label = (params = {}, options = {}) => {
	return {
		en: en.settings_payment_method_cs_label,
		fr: fr.settings_payment_method_cs_label
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_payment_method_label = (params = {}, options = {}) => {
	return {
		en: en.settings_payment_method_label,
		fr: fr.settings_payment_method_label
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_payment_method_order_form = (params = {}, options = {}) => {
	return {
		en: en.settings_payment_method_order_form,
		fr: fr.settings_payment_method_order_form
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_payment_method_sepa = (params = {}, options = {}) => {
	return {
		en: en.settings_payment_method_sepa,
		fr: fr.settings_payment_method_sepa
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_payment_method_sepa_confirmation = (params = {}, options = {}) => {
	return {
		en: en.settings_payment_method_sepa_confirmation,
		fr: fr.settings_payment_method_sepa_confirmation
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_payment_method_sepa_country = (params = {}, options = {}) => {
	return {
		en: en.settings_payment_method_sepa_country,
		fr: fr.settings_payment_method_sepa_country
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_payment_method_sepa_country_placeholder = (params = {}, options = {}) => {
	return {
		en: en.settings_payment_method_sepa_country_placeholder,
		fr: fr.settings_payment_method_sepa_country_placeholder
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_payment_method_sepa_currency = (params = {}, options = {}) => {
	return {
		en: en.settings_payment_method_sepa_currency,
		fr: fr.settings_payment_method_sepa_currency
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_payment_method_sepa_currency_placeholder = (params = {}, options = {}) => {
	return {
		en: en.settings_payment_method_sepa_currency_placeholder,
		fr: fr.settings_payment_method_sepa_currency_placeholder
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_payment_method_sepa_desc = (params = {}, options = {}) => {
	return {
		en: en.settings_payment_method_sepa_desc,
		fr: fr.settings_payment_method_sepa_desc
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_payment_method_sepa_direct_debit_mandate = (params = {}, options = {}) => {
	return {
		en: en.settings_payment_method_sepa_direct_debit_mandate,
		fr: fr.settings_payment_method_sepa_direct_debit_mandate
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_payment_method_sepa_euros = (params = {}, options = {}) => {
	return {
		en: en.settings_payment_method_sepa_euros,
		fr: fr.settings_payment_method_sepa_euros
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_payment_method_sepa_france = (params = {}, options = {}) => {
	return {
		en: en.settings_payment_method_sepa_france,
		fr: fr.settings_payment_method_sepa_france
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_payment_method_sepa_gb = (params = {}, options = {}) => {
	return {
		en: en.settings_payment_method_sepa_gb,
		fr: fr.settings_payment_method_sepa_gb
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_payment_method_sepa_label = (params = {}, options = {}) => {
	return {
		en: en.settings_payment_method_sepa_label,
		fr: fr.settings_payment_method_sepa_label
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_payment_method_sepa_modify = (params = {}, options = {}) => {
	return {
		en: en.settings_payment_method_sepa_modify,
		fr: fr.settings_payment_method_sepa_modify
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_payment_method_sepa_placeholder = (params = {}, options = {}) => {
	return {
		en: en.settings_payment_method_sepa_placeholder,
		fr: fr.settings_payment_method_sepa_placeholder
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_payment_method_sepa_pounds = (params = {}, options = {}) => {
	return {
		en: en.settings_payment_method_sepa_pounds,
		fr: fr.settings_payment_method_sepa_pounds
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_payment_method_sepa_saved = (params = {}, options = {}) => {
	return {
		en: en.settings_payment_method_sepa_saved,
		fr: fr.settings_payment_method_sepa_saved
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_payment_method_stripe = (params = {}, options = {}) => {
	return {
		en: en.settings_payment_method_stripe,
		fr: fr.settings_payment_method_stripe
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_payment_method_subtitle = (params = {}, options = {}) => {
	return {
		en: en.settings_payment_method_subtitle,
		fr: fr.settings_payment_method_subtitle
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_payment_method_title = (params = {}, options = {}) => {
	return {
		en: en.settings_payment_method_title,
		fr: fr.settings_payment_method_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_payment_title = (params = {}, options = {}) => {
	return {
		en: en.settings_payment_title,
		fr: fr.settings_payment_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_personal_email = (params = {}, options = {}) => {
	return {
		en: en.settings_personal_email,
		fr: fr.settings_personal_email
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_personal_email_placeholder = (params = {}, options = {}) => {
	return {
		en: en.settings_personal_email_placeholder,
		fr: fr.settings_personal_email_placeholder
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_personal_firstname = (params = {}, options = {}) => {
	return {
		en: en.settings_personal_firstname,
		fr: fr.settings_personal_firstname
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_personal_firstname_placeholder = (params = {}, options = {}) => {
	return {
		en: en.settings_personal_firstname_placeholder,
		fr: fr.settings_personal_firstname_placeholder
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_personal_lastname = (params = {}, options = {}) => {
	return {
		en: en.settings_personal_lastname,
		fr: fr.settings_personal_lastname
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_personal_lastname_placeholder = (params = {}, options = {}) => {
	return {
		en: en.settings_personal_lastname_placeholder,
		fr: fr.settings_personal_lastname_placeholder
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_personal_password_confirm = (params = {}, options = {}) => {
	return {
		en: en.settings_personal_password_confirm,
		fr: fr.settings_personal_password_confirm
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_personal_password_new = (params = {}, options = {}) => {
	return {
		en: en.settings_personal_password_new,
		fr: fr.settings_personal_password_new
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_personal_password_placeholder = (params = {}, options = {}) => {
	return {
		en: en.settings_personal_password_placeholder,
		fr: fr.settings_personal_password_placeholder
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_personal_phonenumber = (params = {}, options = {}) => {
	return {
		en: en.settings_personal_phonenumber,
		fr: fr.settings_personal_phonenumber
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_personal_phonenumber_placeholder = (params = {}, options = {}) => {
	return {
		en: en.settings_personal_phonenumber_placeholder,
		fr: fr.settings_personal_phonenumber_placeholder
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_personal_subtitle = (params = {}, options = {}) => {
	return {
		en: en.settings_personal_subtitle,
		fr: fr.settings_personal_subtitle
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_personal_title = (params = {}, options = {}) => {
	return {
		en: en.settings_personal_title,
		fr: fr.settings_personal_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_profile_title = (params = {}, options = {}) => {
	return {
		en: en.settings_profile_title,
		fr: fr.settings_profile_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{ months: NonNullable<unknown> }} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_hours_after_months_seniority = (params , options = {}) => {
	return {
		en: en.settings_remuneration_hours_after_months_seniority,
		fr: fr.settings_remuneration_hours_after_months_seniority
	}[options.languageTag ?? languageTag()](params)
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{ months: NonNullable<unknown> }} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_hours_before_months_seniority = (params , options = {}) => {
	return {
		en: en.settings_remuneration_hours_before_months_seniority,
		fr: fr.settings_remuneration_hours_before_months_seniority
	}[options.languageTag ?? languageTag()](params)
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_hours_edit_tooltip = (params = {}, options = {}) => {
	return {
		en: en.settings_remuneration_hours_edit_tooltip,
		fr: fr.settings_remuneration_hours_edit_tooltip
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_hours_extra_content = (params = {}, options = {}) => {
	return {
		en: en.settings_remuneration_hours_extra_content,
		fr: fr.settings_remuneration_hours_extra_content
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_hours_extra_title = (params = {}, options = {}) => {
	return {
		en: en.settings_remuneration_hours_extra_title,
		fr: fr.settings_remuneration_hours_extra_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_hours_help = (params = {}, options = {}) => {
	return {
		en: en.settings_remuneration_hours_help,
		fr: fr.settings_remuneration_hours_help
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_hours_holiday_content = (params = {}, options = {}) => {
	return {
		en: en.settings_remuneration_hours_holiday_content,
		fr: fr.settings_remuneration_hours_holiday_content
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{ solidarity: NonNullable<unknown> }} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_hours_holiday_solidarity = (params , options = {}) => {
	return {
		en: en.settings_remuneration_hours_holiday_solidarity,
		fr: fr.settings_remuneration_hours_holiday_solidarity
	}[options.languageTag ?? languageTag()](params)
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_hours_holiday_solidarity_undefined = (params = {}, options = {}) => {
	return {
		en: en.settings_remuneration_hours_holiday_solidarity_undefined,
		fr: fr.settings_remuneration_hours_holiday_solidarity_undefined
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_hours_holiday_title = (params = {}, options = {}) => {
	return {
		en: en.settings_remuneration_hours_holiday_title,
		fr: fr.settings_remuneration_hours_holiday_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{ count: NonNullable<unknown> }} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_hours_hour_threshold = (params , options = {}) => {
	return {
		en: en.settings_remuneration_hours_hour_threshold,
		fr: fr.settings_remuneration_hours_hour_threshold
	}[options.languageTag ?? languageTag()](params)
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_hours_in_zti = (params = {}, options = {}) => {
	return {
		en: en.settings_remuneration_hours_in_zti,
		fr: fr.settings_remuneration_hours_in_zti
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{ startTime: NonNullable<unknown>, endTime: NonNullable<unknown> }} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_hours_night_content = (params , options = {}) => {
	return {
		en: en.settings_remuneration_hours_night_content,
		fr: fr.settings_remuneration_hours_night_content
	}[options.languageTag ?? languageTag()](params)
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_hours_night_title = (params = {}, options = {}) => {
	return {
		en: en.settings_remuneration_hours_night_title,
		fr: fr.settings_remuneration_hours_night_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_hours_normal_content = (params = {}, options = {}) => {
	return {
		en: en.settings_remuneration_hours_normal_content,
		fr: fr.settings_remuneration_hours_normal_content
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_hours_normal_title = (params = {}, options = {}) => {
	return {
		en: en.settings_remuneration_hours_normal_title,
		fr: fr.settings_remuneration_hours_normal_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_hours_not_zti = (params = {}, options = {}) => {
	return {
		en: en.settings_remuneration_hours_not_zti,
		fr: fr.settings_remuneration_hours_not_zti
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_hours_see_holidays = (params = {}, options = {}) => {
	return {
		en: en.settings_remuneration_hours_see_holidays,
		fr: fr.settings_remuneration_hours_see_holidays
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_hours_subtitle = (params = {}, options = {}) => {
	return {
		en: en.settings_remuneration_hours_subtitle,
		fr: fr.settings_remuneration_hours_subtitle
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_hours_sunday_content = (params = {}, options = {}) => {
	return {
		en: en.settings_remuneration_hours_sunday_content,
		fr: fr.settings_remuneration_hours_sunday_content
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_hours_sunday_title = (params = {}, options = {}) => {
	return {
		en: en.settings_remuneration_hours_sunday_title,
		fr: fr.settings_remuneration_hours_sunday_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_hours_table_type = (params = {}, options = {}) => {
	return {
		en: en.settings_remuneration_hours_table_type,
		fr: fr.settings_remuneration_hours_table_type
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_hours_table_value = (params = {}, options = {}) => {
	return {
		en: en.settings_remuneration_hours_table_value,
		fr: fr.settings_remuneration_hours_table_value
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_hours_title = (params = {}, options = {}) => {
	return {
		en: en.settings_remuneration_hours_title,
		fr: fr.settings_remuneration_hours_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_hours_types_custom_disclaimer = (params = {}, options = {}) => {
	return {
		en: en.settings_remuneration_hours_types_custom_disclaimer,
		fr: fr.settings_remuneration_hours_types_custom_disclaimer
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_hours_validation_button = (params = {}, options = {}) => {
	return {
		en: en.settings_remuneration_hours_validation_button,
		fr: fr.settings_remuneration_hours_validation_button
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_hours_validation_content = (params = {}, options = {}) => {
	return {
		en: en.settings_remuneration_hours_validation_content,
		fr: fr.settings_remuneration_hours_validation_content
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_hours_validation_label = (params = {}, options = {}) => {
	return {
		en: en.settings_remuneration_hours_validation_label,
		fr: fr.settings_remuneration_hours_validation_label
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_hours_validation_title = (params = {}, options = {}) => {
	return {
		en: en.settings_remuneration_hours_validation_title,
		fr: fr.settings_remuneration_hours_validation_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_add_label = (params = {}, options = {}) => {
	return {
		en: en.settings_remuneration_indemnities_add_label,
		fr: fr.settings_remuneration_indemnities_add_label
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_add_option_lunch = (params = {}, options = {}) => {
	return {
		en: en.settings_remuneration_indemnities_add_option_lunch,
		fr: fr.settings_remuneration_indemnities_add_option_lunch
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_add_option_other = (params = {}, options = {}) => {
	return {
		en: en.settings_remuneration_indemnities_add_option_other,
		fr: fr.settings_remuneration_indemnities_add_option_other
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_add_option_transport = (params = {}, options = {}) => {
	return {
		en: en.settings_remuneration_indemnities_add_option_transport,
		fr: fr.settings_remuneration_indemnities_add_option_transport
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_add_placeholder = (params = {}, options = {}) => {
	return {
		en: en.settings_remuneration_indemnities_add_placeholder,
		fr: fr.settings_remuneration_indemnities_add_placeholder
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_add_title = (params = {}, options = {}) => {
	return {
		en: en.settings_remuneration_indemnities_add_title,
		fr: fr.settings_remuneration_indemnities_add_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_bank_holidays_hours_rate_error_max = (params = {}, options = {}) => {
	return {
		en: en.settings_remuneration_indemnities_bank_holidays_hours_rate_error_max,
		fr: fr.settings_remuneration_indemnities_bank_holidays_hours_rate_error_max
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_bank_holidays_hours_rate_error_min = (params = {}, options = {}) => {
	return {
		en: en.settings_remuneration_indemnities_bank_holidays_hours_rate_error_min,
		fr: fr.settings_remuneration_indemnities_bank_holidays_hours_rate_error_min
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_bank_holidays_rate = (params = {}, options = {}) => {
	return {
		en: en.settings_remuneration_indemnities_bank_holidays_rate,
		fr: fr.settings_remuneration_indemnities_bank_holidays_rate
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_bank_holidays_solidarity = (params = {}, options = {}) => {
	return {
		en: en.settings_remuneration_indemnities_bank_holidays_solidarity,
		fr: fr.settings_remuneration_indemnities_bank_holidays_solidarity
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_bank_holidays_solidarity_placeholder = (params = {}, options = {}) => {
	return {
		en: en.settings_remuneration_indemnities_bank_holidays_solidarity_placeholder,
		fr: fr.settings_remuneration_indemnities_bank_holidays_solidarity_placeholder
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_bank_holidays_subtitle = (params = {}, options = {}) => {
	return {
		en: en.settings_remuneration_indemnities_bank_holidays_subtitle,
		fr: fr.settings_remuneration_indemnities_bank_holidays_subtitle
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_bank_holidays_title = (params = {}, options = {}) => {
	return {
		en: en.settings_remuneration_indemnities_bank_holidays_title,
		fr: fr.settings_remuneration_indemnities_bank_holidays_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_custom_disclaimer = (params = {}, options = {}) => {
	return {
		en: en.settings_remuneration_indemnities_custom_disclaimer,
		fr: fr.settings_remuneration_indemnities_custom_disclaimer
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_edit_tooltip = (params = {}, options = {}) => {
	return {
		en: en.settings_remuneration_indemnities_edit_tooltip,
		fr: fr.settings_remuneration_indemnities_edit_tooltip
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_empty = (params = {}, options = {}) => {
	return {
		en: en.settings_remuneration_indemnities_empty,
		fr: fr.settings_remuneration_indemnities_empty
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_extra_hour_increase_error_max = (params = {}, options = {}) => {
	return {
		en: en.settings_remuneration_indemnities_extra_hour_increase_error_max,
		fr: fr.settings_remuneration_indemnities_extra_hour_increase_error_max
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_extra_hour_increase_error_min = (params = {}, options = {}) => {
	return {
		en: en.settings_remuneration_indemnities_extra_hour_increase_error_min,
		fr: fr.settings_remuneration_indemnities_extra_hour_increase_error_min
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_extra_hour_min = (params = {}, options = {}) => {
	return {
		en: en.settings_remuneration_indemnities_extra_hour_min,
		fr: fr.settings_remuneration_indemnities_extra_hour_min
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_extra_hour_threshold_format = (params = {}, options = {}) => {
	return {
		en: en.settings_remuneration_indemnities_extra_hour_threshold_format,
		fr: fr.settings_remuneration_indemnities_extra_hour_threshold_format
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_extra_hour_threshold_must_be_higher = (params = {}, options = {}) => {
	return {
		en: en.settings_remuneration_indemnities_extra_hour_threshold_must_be_higher,
		fr: fr.settings_remuneration_indemnities_extra_hour_threshold_must_be_higher
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{ number: NonNullable<unknown> }} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_extra_hours_extra_threshold = (params , options = {}) => {
	return {
		en: en.settings_remuneration_indemnities_extra_hours_extra_threshold,
		fr: fr.settings_remuneration_indemnities_extra_hours_extra_threshold
	}[options.languageTag ?? languageTag()](params)
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_extra_hours_rate = (params = {}, options = {}) => {
	return {
		en: en.settings_remuneration_indemnities_extra_hours_rate,
		fr: fr.settings_remuneration_indemnities_extra_hours_rate
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_extra_hours_subtitle = (params = {}, options = {}) => {
	return {
		en: en.settings_remuneration_indemnities_extra_hours_subtitle,
		fr: fr.settings_remuneration_indemnities_extra_hours_subtitle
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_extra_hours_threshold = (params = {}, options = {}) => {
	return {
		en: en.settings_remuneration_indemnities_extra_hours_threshold,
		fr: fr.settings_remuneration_indemnities_extra_hours_threshold
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_extra_hours_threshold_unit = (params = {}, options = {}) => {
	return {
		en: en.settings_remuneration_indemnities_extra_hours_threshold_unit,
		fr: fr.settings_remuneration_indemnities_extra_hours_threshold_unit
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_extra_hours_title = (params = {}, options = {}) => {
	return {
		en: en.settings_remuneration_indemnities_extra_hours_title,
		fr: fr.settings_remuneration_indemnities_extra_hours_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_help = (params = {}, options = {}) => {
	return {
		en: en.settings_remuneration_indemnities_help,
		fr: fr.settings_remuneration_indemnities_help
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{ amount: NonNullable<unknown> }} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_lunch_equivalent_amount = (params , options = {}) => {
	return {
		en: en.settings_remuneration_indemnities_lunch_equivalent_amount,
		fr: fr.settings_remuneration_indemnities_lunch_equivalent_amount
	}[options.languageTag ?? languageTag()](params)
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_lunch_indeminity_option = (params = {}, options = {}) => {
	return {
		en: en.settings_remuneration_indemnities_lunch_indeminity_option,
		fr: fr.settings_remuneration_indemnities_lunch_indeminity_option
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_lunch_indemnity_label = (params = {}, options = {}) => {
	return {
		en: en.settings_remuneration_indemnities_lunch_indemnity_label,
		fr: fr.settings_remuneration_indemnities_lunch_indemnity_label
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_lunch_indemnity_type = (params = {}, options = {}) => {
	return {
		en: en.settings_remuneration_indemnities_lunch_indemnity_type,
		fr: fr.settings_remuneration_indemnities_lunch_indemnity_type
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_lunch_max_error = (params = {}, options = {}) => {
	return {
		en: en.settings_remuneration_indemnities_lunch_max_error,
		fr: fr.settings_remuneration_indemnities_lunch_max_error
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_lunch_min_error = (params = {}, options = {}) => {
	return {
		en: en.settings_remuneration_indemnities_lunch_min_error,
		fr: fr.settings_remuneration_indemnities_lunch_min_error
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_lunch_subtitle = (params = {}, options = {}) => {
	return {
		en: en.settings_remuneration_indemnities_lunch_subtitle,
		fr: fr.settings_remuneration_indemnities_lunch_subtitle
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_lunch_ticket_type = (params = {}, options = {}) => {
	return {
		en: en.settings_remuneration_indemnities_lunch_ticket_type,
		fr: fr.settings_remuneration_indemnities_lunch_ticket_type
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_lunch_title = (params = {}, options = {}) => {
	return {
		en: en.settings_remuneration_indemnities_lunch_title,
		fr: fr.settings_remuneration_indemnities_lunch_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_lunch_tr_option = (params = {}, options = {}) => {
	return {
		en: en.settings_remuneration_indemnities_lunch_tr_option,
		fr: fr.settings_remuneration_indemnities_lunch_tr_option
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_lunch_tr_rate = (params = {}, options = {}) => {
	return {
		en: en.settings_remuneration_indemnities_lunch_tr_rate,
		fr: fr.settings_remuneration_indemnities_lunch_tr_rate
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_lunch_tr_rate_format_error = (params = {}, options = {}) => {
	return {
		en: en.settings_remuneration_indemnities_lunch_tr_rate_format_error,
		fr: fr.settings_remuneration_indemnities_lunch_tr_rate_format_error
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_lunch_tr_tip = (params = {}, options = {}) => {
	return {
		en: en.settings_remuneration_indemnities_lunch_tr_tip,
		fr: fr.settings_remuneration_indemnities_lunch_tr_tip
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_lunch_tr_value_label = (params = {}, options = {}) => {
	return {
		en: en.settings_remuneration_indemnities_lunch_tr_value_label,
		fr: fr.settings_remuneration_indemnities_lunch_tr_value_label
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_lunch_type_error = (params = {}, options = {}) => {
	return {
		en: en.settings_remuneration_indemnities_lunch_type_error,
		fr: fr.settings_remuneration_indemnities_lunch_type_error
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_modal_cancel = (params = {}, options = {}) => {
	return {
		en: en.settings_remuneration_indemnities_modal_cancel,
		fr: fr.settings_remuneration_indemnities_modal_cancel
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_modal_delete = (params = {}, options = {}) => {
	return {
		en: en.settings_remuneration_indemnities_modal_delete,
		fr: fr.settings_remuneration_indemnities_modal_delete
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_modal_save = (params = {}, options = {}) => {
	return {
		en: en.settings_remuneration_indemnities_modal_save,
		fr: fr.settings_remuneration_indemnities_modal_save
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_night_hour_end = (params = {}, options = {}) => {
	return {
		en: en.settings_remuneration_indemnities_night_hour_end,
		fr: fr.settings_remuneration_indemnities_night_hour_end
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_night_hour_end_before_start_error = (params = {}, options = {}) => {
	return {
		en: en.settings_remuneration_indemnities_night_hour_end_before_start_error,
		fr: fr.settings_remuneration_indemnities_night_hour_end_before_start_error
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_night_hour_end_error = (params = {}, options = {}) => {
	return {
		en: en.settings_remuneration_indemnities_night_hour_end_error,
		fr: fr.settings_remuneration_indemnities_night_hour_end_error
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_night_hour_increase_error = (params = {}, options = {}) => {
	return {
		en: en.settings_remuneration_indemnities_night_hour_increase_error,
		fr: fr.settings_remuneration_indemnities_night_hour_increase_error
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_night_hour_rate = (params = {}, options = {}) => {
	return {
		en: en.settings_remuneration_indemnities_night_hour_rate,
		fr: fr.settings_remuneration_indemnities_night_hour_rate
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_night_hour_start = (params = {}, options = {}) => {
	return {
		en: en.settings_remuneration_indemnities_night_hour_start,
		fr: fr.settings_remuneration_indemnities_night_hour_start
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_night_hour_start_error = (params = {}, options = {}) => {
	return {
		en: en.settings_remuneration_indemnities_night_hour_start_error,
		fr: fr.settings_remuneration_indemnities_night_hour_start_error
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_night_hour_subtitle = (params = {}, options = {}) => {
	return {
		en: en.settings_remuneration_indemnities_night_hour_subtitle,
		fr: fr.settings_remuneration_indemnities_night_hour_subtitle
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_night_hour_title = (params = {}, options = {}) => {
	return {
		en: en.settings_remuneration_indemnities_night_hour_title,
		fr: fr.settings_remuneration_indemnities_night_hour_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_option_button = (params = {}, options = {}) => {
	return {
		en: en.settings_remuneration_indemnities_option_button,
		fr: fr.settings_remuneration_indemnities_option_button
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_other_amount = (params = {}, options = {}) => {
	return {
		en: en.settings_remuneration_indemnities_other_amount,
		fr: fr.settings_remuneration_indemnities_other_amount
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_other_category = (params = {}, options = {}) => {
	return {
		en: en.settings_remuneration_indemnities_other_category,
		fr: fr.settings_remuneration_indemnities_other_category
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_other_category_select_placeholder = (params = {}, options = {}) => {
	return {
		en: en.settings_remuneration_indemnities_other_category_select_placeholder,
		fr: fr.settings_remuneration_indemnities_other_category_select_placeholder
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_other_events_christmas_bonus_indemnity_title = (params = {}, options = {}) => {
	return {
		en: en.settings_remuneration_indemnities_other_events_christmas_bonus_indemnity_title,
		fr: fr.settings_remuneration_indemnities_other_events_christmas_bonus_indemnity_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_other_events_indemnity_select_label = (params = {}, options = {}) => {
	return {
		en: en.settings_remuneration_indemnities_other_events_indemnity_select_label,
		fr: fr.settings_remuneration_indemnities_other_events_indemnity_select_label
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_other_events_indemnity_title = (params = {}, options = {}) => {
	return {
		en: en.settings_remuneration_indemnities_other_events_indemnity_title,
		fr: fr.settings_remuneration_indemnities_other_events_indemnity_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_other_events_other_bonus_indemnity_title = (params = {}, options = {}) => {
	return {
		en: en.settings_remuneration_indemnities_other_events_other_bonus_indemnity_title,
		fr: fr.settings_remuneration_indemnities_other_events_other_bonus_indemnity_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_other_events_year_end_bonus_indemnity_title = (params = {}, options = {}) => {
	return {
		en: en.settings_remuneration_indemnities_other_events_year_end_bonus_indemnity_title,
		fr: fr.settings_remuneration_indemnities_other_events_year_end_bonus_indemnity_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_other_frequency = (params = {}, options = {}) => {
	return {
		en: en.settings_remuneration_indemnities_other_frequency,
		fr: fr.settings_remuneration_indemnities_other_frequency
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_other_frequency_daily = (params = {}, options = {}) => {
	return {
		en: en.settings_remuneration_indemnities_other_frequency_daily,
		fr: fr.settings_remuneration_indemnities_other_frequency_daily
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_other_frequency_monthly = (params = {}, options = {}) => {
	return {
		en: en.settings_remuneration_indemnities_other_frequency_monthly,
		fr: fr.settings_remuneration_indemnities_other_frequency_monthly
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_other_frequency_once = (params = {}, options = {}) => {
	return {
		en: en.settings_remuneration_indemnities_other_frequency_once,
		fr: fr.settings_remuneration_indemnities_other_frequency_once
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_other_frequency_select_placeholder = (params = {}, options = {}) => {
	return {
		en: en.settings_remuneration_indemnities_other_frequency_select_placeholder,
		fr: fr.settings_remuneration_indemnities_other_frequency_select_placeholder
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_other_frequency_weekly = (params = {}, options = {}) => {
	return {
		en: en.settings_remuneration_indemnities_other_frequency_weekly,
		fr: fr.settings_remuneration_indemnities_other_frequency_weekly
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_other_labor_agreement_holiday_bonus_indemnity_title = (params = {}, options = {}) => {
	return {
		en: en.settings_remuneration_indemnities_other_labor_agreement_holiday_bonus_indemnity_title,
		fr: fr.settings_remuneration_indemnities_other_labor_agreement_holiday_bonus_indemnity_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_other_labor_agreement_indemnity_select_label = (params = {}, options = {}) => {
	return {
		en: en.settings_remuneration_indemnities_other_labor_agreement_indemnity_select_label,
		fr: fr.settings_remuneration_indemnities_other_labor_agreement_indemnity_select_label
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_other_labor_agreement_indemnity_title = (params = {}, options = {}) => {
	return {
		en: en.settings_remuneration_indemnities_other_labor_agreement_indemnity_title,
		fr: fr.settings_remuneration_indemnities_other_labor_agreement_indemnity_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_other_labor_agreement_other_bonus_indemnity_title = (params = {}, options = {}) => {
	return {
		en: en.settings_remuneration_indemnities_other_labor_agreement_other_bonus_indemnity_title,
		fr: fr.settings_remuneration_indemnities_other_labor_agreement_other_bonus_indemnity_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_other_labor_agreement_thirteenth_month_bonus_indemnity_title = (params = {}, options = {}) => {
	return {
		en: en.settings_remuneration_indemnities_other_labor_agreement_thirteenth_month_bonus_indemnity_title,
		fr: fr.settings_remuneration_indemnities_other_labor_agreement_thirteenth_month_bonus_indemnity_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_other_other_indemnity_select_label = (params = {}, options = {}) => {
	return {
		en: en.settings_remuneration_indemnities_other_other_indemnity_select_label,
		fr: fr.settings_remuneration_indemnities_other_other_indemnity_select_label
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_other_other_indemnity_title = (params = {}, options = {}) => {
	return {
		en: en.settings_remuneration_indemnities_other_other_indemnity_title,
		fr: fr.settings_remuneration_indemnities_other_other_indemnity_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_other_other_other_bonus_indemnity_title = (params = {}, options = {}) => {
	return {
		en: en.settings_remuneration_indemnities_other_other_other_bonus_indemnity_title,
		fr: fr.settings_remuneration_indemnities_other_other_other_bonus_indemnity_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_other_other_parameters_hours_per_day = (params = {}, options = {}) => {
	return {
		en: en.settings_remuneration_indemnities_other_other_parameters_hours_per_day,
		fr: fr.settings_remuneration_indemnities_other_other_parameters_hours_per_day
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_other_other_parameters_hours_per_day_label = (params = {}, options = {}) => {
	return {
		en: en.settings_remuneration_indemnities_other_other_parameters_hours_per_day_label,
		fr: fr.settings_remuneration_indemnities_other_other_parameters_hours_per_day_label
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_other_other_parameters_hours_per_day_unit = (params = {}, options = {}) => {
	return {
		en: en.settings_remuneration_indemnities_other_other_parameters_hours_per_day_unit,
		fr: fr.settings_remuneration_indemnities_other_other_parameters_hours_per_day_unit
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_other_other_parameters_jobtype = (params = {}, options = {}) => {
	return {
		en: en.settings_remuneration_indemnities_other_other_parameters_jobtype,
		fr: fr.settings_remuneration_indemnities_other_other_parameters_jobtype
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_other_other_parameters_jobtype_label = (params = {}, options = {}) => {
	return {
		en: en.settings_remuneration_indemnities_other_other_parameters_jobtype_label,
		fr: fr.settings_remuneration_indemnities_other_other_parameters_jobtype_label
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_other_other_parameters_seniority = (params = {}, options = {}) => {
	return {
		en: en.settings_remuneration_indemnities_other_other_parameters_seniority,
		fr: fr.settings_remuneration_indemnities_other_other_parameters_seniority
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_other_other_parameters_seniority_threshold = (params = {}, options = {}) => {
	return {
		en: en.settings_remuneration_indemnities_other_other_parameters_seniority_threshold,
		fr: fr.settings_remuneration_indemnities_other_other_parameters_seniority_threshold
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_other_other_parameters_seniority_threshold_unit = (params = {}, options = {}) => {
	return {
		en: en.settings_remuneration_indemnities_other_other_parameters_seniority_threshold_unit,
		fr: fr.settings_remuneration_indemnities_other_other_parameters_seniority_threshold_unit
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_other_other_parameters_switch = (params = {}, options = {}) => {
	return {
		en: en.settings_remuneration_indemnities_other_other_parameters_switch,
		fr: fr.settings_remuneration_indemnities_other_other_parameters_switch
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_other_other_parameters_zti = (params = {}, options = {}) => {
	return {
		en: en.settings_remuneration_indemnities_other_other_parameters_zti,
		fr: fr.settings_remuneration_indemnities_other_other_parameters_zti
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_other_parameters_hours_per_day_error = (params = {}, options = {}) => {
	return {
		en: en.settings_remuneration_indemnities_other_parameters_hours_per_day_error,
		fr: fr.settings_remuneration_indemnities_other_parameters_hours_per_day_error
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_other_parameters_job_types_error = (params = {}, options = {}) => {
	return {
		en: en.settings_remuneration_indemnities_other_parameters_job_types_error,
		fr: fr.settings_remuneration_indemnities_other_parameters_job_types_error
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_other_parameters_seniority_error_more_than_0 = (params = {}, options = {}) => {
	return {
		en: en.settings_remuneration_indemnities_other_parameters_seniority_error_more_than_0,
		fr: fr.settings_remuneration_indemnities_other_parameters_seniority_error_more_than_0
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_other_performances_diligence_bonus_indemnity_title = (params = {}, options = {}) => {
	return {
		en: en.settings_remuneration_indemnities_other_performances_diligence_bonus_indemnity_title,
		fr: fr.settings_remuneration_indemnities_other_performances_diligence_bonus_indemnity_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_other_performances_indemnity_select_label = (params = {}, options = {}) => {
	return {
		en: en.settings_remuneration_indemnities_other_performances_indemnity_select_label,
		fr: fr.settings_remuneration_indemnities_other_performances_indemnity_select_label
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_other_performances_indemnity_title = (params = {}, options = {}) => {
	return {
		en: en.settings_remuneration_indemnities_other_performances_indemnity_title,
		fr: fr.settings_remuneration_indemnities_other_performances_indemnity_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_other_performances_other_bonus_indemnity_title = (params = {}, options = {}) => {
	return {
		en: en.settings_remuneration_indemnities_other_performances_other_bonus_indemnity_title,
		fr: fr.settings_remuneration_indemnities_other_performances_other_bonus_indemnity_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_other_performances_productivity_bonus_indemnity_title = (params = {}, options = {}) => {
	return {
		en: en.settings_remuneration_indemnities_other_performances_productivity_bonus_indemnity_title,
		fr: fr.settings_remuneration_indemnities_other_performances_productivity_bonus_indemnity_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_other_performances_target_based_bonus_indemnity_title = (params = {}, options = {}) => {
	return {
		en: en.settings_remuneration_indemnities_other_performances_target_based_bonus_indemnity_title,
		fr: fr.settings_remuneration_indemnities_other_performances_target_based_bonus_indemnity_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_other_subtitle = (params = {}, options = {}) => {
	return {
		en: en.settings_remuneration_indemnities_other_subtitle,
		fr: fr.settings_remuneration_indemnities_other_subtitle
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_other_title = (params = {}, options = {}) => {
	return {
		en: en.settings_remuneration_indemnities_other_title,
		fr: fr.settings_remuneration_indemnities_other_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_other_type = (params = {}, options = {}) => {
	return {
		en: en.settings_remuneration_indemnities_other_type,
		fr: fr.settings_remuneration_indemnities_other_type
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_other_type_error_already_exists = (params = {}, options = {}) => {
	return {
		en: en.settings_remuneration_indemnities_other_type_error_already_exists,
		fr: fr.settings_remuneration_indemnities_other_type_error_already_exists
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_other_type_select_placeholder = (params = {}, options = {}) => {
	return {
		en: en.settings_remuneration_indemnities_other_type_select_placeholder,
		fr: fr.settings_remuneration_indemnities_other_type_select_placeholder
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_other_work_condition_cold_bonus_indemnity_title = (params = {}, options = {}) => {
	return {
		en: en.settings_remuneration_indemnities_other_work_condition_cold_bonus_indemnity_title,
		fr: fr.settings_remuneration_indemnities_other_work_condition_cold_bonus_indemnity_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_other_work_condition_hardship_bonus_indemnity_title = (params = {}, options = {}) => {
	return {
		en: en.settings_remuneration_indemnities_other_work_condition_hardship_bonus_indemnity_title,
		fr: fr.settings_remuneration_indemnities_other_work_condition_hardship_bonus_indemnity_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_other_work_condition_indemnity_select_label = (params = {}, options = {}) => {
	return {
		en: en.settings_remuneration_indemnities_other_work_condition_indemnity_select_label,
		fr: fr.settings_remuneration_indemnities_other_work_condition_indemnity_select_label
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_other_work_condition_indemnity_title = (params = {}, options = {}) => {
	return {
		en: en.settings_remuneration_indemnities_other_work_condition_indemnity_title,
		fr: fr.settings_remuneration_indemnities_other_work_condition_indemnity_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_other_work_condition_other_bonus_indemnity_title = (params = {}, options = {}) => {
	return {
		en: en.settings_remuneration_indemnities_other_work_condition_other_bonus_indemnity_title,
		fr: fr.settings_remuneration_indemnities_other_work_condition_other_bonus_indemnity_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_other_work_condition_risk_bonus_indemnity_title = (params = {}, options = {}) => {
	return {
		en: en.settings_remuneration_indemnities_other_work_condition_risk_bonus_indemnity_title,
		fr: fr.settings_remuneration_indemnities_other_work_condition_risk_bonus_indemnity_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_other_work_condition_unhealthy_bonus_indemnity_title = (params = {}, options = {}) => {
	return {
		en: en.settings_remuneration_indemnities_other_work_condition_unhealthy_bonus_indemnity_title,
		fr: fr.settings_remuneration_indemnities_other_work_condition_unhealthy_bonus_indemnity_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_per_day = (params = {}, options = {}) => {
	return {
		en: en.settings_remuneration_indemnities_per_day,
		fr: fr.settings_remuneration_indemnities_per_day
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_per_month = (params = {}, options = {}) => {
	return {
		en: en.settings_remuneration_indemnities_per_month,
		fr: fr.settings_remuneration_indemnities_per_month
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{ duration: NonNullable<unknown> }} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_seniority_condition_after = (params , options = {}) => {
	return {
		en: en.settings_remuneration_indemnities_seniority_condition_after,
		fr: fr.settings_remuneration_indemnities_seniority_condition_after
	}[options.languageTag ?? languageTag()](params)
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{ duration: NonNullable<unknown> }} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_seniority_condition_before = (params , options = {}) => {
	return {
		en: en.settings_remuneration_indemnities_seniority_condition_before,
		fr: fr.settings_remuneration_indemnities_seniority_condition_before
	}[options.languageTag ?? languageTag()](params)
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_seniority_rate = (params = {}, options = {}) => {
	return {
		en: en.settings_remuneration_indemnities_seniority_rate,
		fr: fr.settings_remuneration_indemnities_seniority_rate
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_seniority_switch = (params = {}, options = {}) => {
	return {
		en: en.settings_remuneration_indemnities_seniority_switch,
		fr: fr.settings_remuneration_indemnities_seniority_switch
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_seniority_threshold = (params = {}, options = {}) => {
	return {
		en: en.settings_remuneration_indemnities_seniority_threshold,
		fr: fr.settings_remuneration_indemnities_seniority_threshold
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_seniority_unit = (params = {}, options = {}) => {
	return {
		en: en.settings_remuneration_indemnities_seniority_unit,
		fr: fr.settings_remuneration_indemnities_seniority_unit
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_subtitle = (params = {}, options = {}) => {
	return {
		en: en.settings_remuneration_indemnities_subtitle,
		fr: fr.settings_remuneration_indemnities_subtitle
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_sunday_hours_rate = (params = {}, options = {}) => {
	return {
		en: en.settings_remuneration_indemnities_sunday_hours_rate,
		fr: fr.settings_remuneration_indemnities_sunday_hours_rate
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_sunday_hours_rate_error_max = (params = {}, options = {}) => {
	return {
		en: en.settings_remuneration_indemnities_sunday_hours_rate_error_max,
		fr: fr.settings_remuneration_indemnities_sunday_hours_rate_error_max
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_sunday_hours_rate_error_min = (params = {}, options = {}) => {
	return {
		en: en.settings_remuneration_indemnities_sunday_hours_rate_error_min,
		fr: fr.settings_remuneration_indemnities_sunday_hours_rate_error_min
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_sunday_hours_subtitle = (params = {}, options = {}) => {
	return {
		en: en.settings_remuneration_indemnities_sunday_hours_subtitle,
		fr: fr.settings_remuneration_indemnities_sunday_hours_subtitle
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_sunday_hours_title = (params = {}, options = {}) => {
	return {
		en: en.settings_remuneration_indemnities_sunday_hours_title,
		fr: fr.settings_remuneration_indemnities_sunday_hours_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_sunday_hours_zti = (params = {}, options = {}) => {
	return {
		en: en.settings_remuneration_indemnities_sunday_hours_zti,
		fr: fr.settings_remuneration_indemnities_sunday_hours_zti
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_table_type = (params = {}, options = {}) => {
	return {
		en: en.settings_remuneration_indemnities_table_type,
		fr: fr.settings_remuneration_indemnities_table_type
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_table_value = (params = {}, options = {}) => {
	return {
		en: en.settings_remuneration_indemnities_table_value,
		fr: fr.settings_remuneration_indemnities_table_value
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_threshold_addition = (params = {}, options = {}) => {
	return {
		en: en.settings_remuneration_indemnities_threshold_addition,
		fr: fr.settings_remuneration_indemnities_threshold_addition
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_threshold_deletion_tooltip = (params = {}, options = {}) => {
	return {
		en: en.settings_remuneration_indemnities_threshold_deletion_tooltip,
		fr: fr.settings_remuneration_indemnities_threshold_deletion_tooltip
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_threshold_number = (params = {}, options = {}) => {
	return {
		en: en.settings_remuneration_indemnities_threshold_number,
		fr: fr.settings_remuneration_indemnities_threshold_number
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_title = (params = {}, options = {}) => {
	return {
		en: en.settings_remuneration_indemnities_title,
		fr: fr.settings_remuneration_indemnities_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_transport_personal_switch = (params = {}, options = {}) => {
	return {
		en: en.settings_remuneration_indemnities_transport_personal_switch,
		fr: fr.settings_remuneration_indemnities_transport_personal_switch
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_transport_personal_tip = (params = {}, options = {}) => {
	return {
		en: en.settings_remuneration_indemnities_transport_personal_tip,
		fr: fr.settings_remuneration_indemnities_transport_personal_tip
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_transport_personal_type = (params = {}, options = {}) => {
	return {
		en: en.settings_remuneration_indemnities_transport_personal_type,
		fr: fr.settings_remuneration_indemnities_transport_personal_type
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_transport_public_rate = (params = {}, options = {}) => {
	return {
		en: en.settings_remuneration_indemnities_transport_public_rate,
		fr: fr.settings_remuneration_indemnities_transport_public_rate
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_transport_public_rate_error_less_than_hundred = (params = {}, options = {}) => {
	return {
		en: en.settings_remuneration_indemnities_transport_public_rate_error_less_than_hundred,
		fr: fr.settings_remuneration_indemnities_transport_public_rate_error_less_than_hundred
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_transport_public_rate_error_more_than_fifty = (params = {}, options = {}) => {
	return {
		en: en.settings_remuneration_indemnities_transport_public_rate_error_more_than_fifty,
		fr: fr.settings_remuneration_indemnities_transport_public_rate_error_more_than_fifty
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_transport_public_type = (params = {}, options = {}) => {
	return {
		en: en.settings_remuneration_indemnities_transport_public_type,
		fr: fr.settings_remuneration_indemnities_transport_public_type
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_transport_subtitle = (params = {}, options = {}) => {
	return {
		en: en.settings_remuneration_indemnities_transport_subtitle,
		fr: fr.settings_remuneration_indemnities_transport_subtitle
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_transport_title = (params = {}, options = {}) => {
	return {
		en: en.settings_remuneration_indemnities_transport_title,
		fr: fr.settings_remuneration_indemnities_transport_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_validation_button = (params = {}, options = {}) => {
	return {
		en: en.settings_remuneration_indemnities_validation_button,
		fr: fr.settings_remuneration_indemnities_validation_button
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_validation_content = (params = {}, options = {}) => {
	return {
		en: en.settings_remuneration_indemnities_validation_content,
		fr: fr.settings_remuneration_indemnities_validation_content
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_validation_label = (params = {}, options = {}) => {
	return {
		en: en.settings_remuneration_indemnities_validation_label,
		fr: fr.settings_remuneration_indemnities_validation_label
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_validation_title = (params = {}, options = {}) => {
	return {
		en: en.settings_remuneration_indemnities_validation_title,
		fr: fr.settings_remuneration_indemnities_validation_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_indemnities_zti_condition = (params = {}, options = {}) => {
	return {
		en: en.settings_remuneration_indemnities_zti_condition,
		fr: fr.settings_remuneration_indemnities_zti_condition
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_non_worked_holidays_help = (params = {}, options = {}) => {
	return {
		en: en.settings_remuneration_non_worked_holidays_help,
		fr: fr.settings_remuneration_non_worked_holidays_help
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_non_worked_holidays_help_link = (params = {}, options = {}) => {
	return {
		en: en.settings_remuneration_non_worked_holidays_help_link,
		fr: fr.settings_remuneration_non_worked_holidays_help_link
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_non_worked_holidays_title = (params = {}, options = {}) => {
	return {
		en: en.settings_remuneration_non_worked_holidays_title,
		fr: fr.settings_remuneration_non_worked_holidays_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_remuneration_title = (params = {}, options = {}) => {
	return {
		en: en.settings_remuneration_title,
		fr: fr.settings_remuneration_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_submit_success = (params = {}, options = {}) => {
	return {
		en: en.settings_submit_success,
		fr: fr.settings_submit_success
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_team_add_user = (params = {}, options = {}) => {
	return {
		en: en.settings_team_add_user,
		fr: fr.settings_team_add_user
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_team_add_user_email = (params = {}, options = {}) => {
	return {
		en: en.settings_team_add_user_email,
		fr: fr.settings_team_add_user_email
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_team_add_user_email_placeholder = (params = {}, options = {}) => {
	return {
		en: en.settings_team_add_user_email_placeholder,
		fr: fr.settings_team_add_user_email_placeholder
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_team_add_user_firstname = (params = {}, options = {}) => {
	return {
		en: en.settings_team_add_user_firstname,
		fr: fr.settings_team_add_user_firstname
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_team_add_user_firstname_placeholder = (params = {}, options = {}) => {
	return {
		en: en.settings_team_add_user_firstname_placeholder,
		fr: fr.settings_team_add_user_firstname_placeholder
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_team_add_user_lastname = (params = {}, options = {}) => {
	return {
		en: en.settings_team_add_user_lastname,
		fr: fr.settings_team_add_user_lastname
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_team_add_user_lastname_placeholder = (params = {}, options = {}) => {
	return {
		en: en.settings_team_add_user_lastname_placeholder,
		fr: fr.settings_team_add_user_lastname_placeholder
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_team_add_user_role = (params = {}, options = {}) => {
	return {
		en: en.settings_team_add_user_role,
		fr: fr.settings_team_add_user_role
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_team_add_user_role_placeholder = (params = {}, options = {}) => {
	return {
		en: en.settings_team_add_user_role_placeholder,
		fr: fr.settings_team_add_user_role_placeholder
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_team_add_user_success = (params = {}, options = {}) => {
	return {
		en: en.settings_team_add_user_success,
		fr: fr.settings_team_add_user_success
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_team_deactivate_user = (params = {}, options = {}) => {
	return {
		en: en.settings_team_deactivate_user,
		fr: fr.settings_team_deactivate_user
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{ name: NonNullable<unknown> }} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_team_deactivate_user_dialog_content = (params , options = {}) => {
	return {
		en: en.settings_team_deactivate_user_dialog_content,
		fr: fr.settings_team_deactivate_user_dialog_content
	}[options.languageTag ?? languageTag()](params)
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_team_deactivate_user_success = (params = {}, options = {}) => {
	return {
		en: en.settings_team_deactivate_user_success,
		fr: fr.settings_team_deactivate_user_success
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_team_edit_role = (params = {}, options = {}) => {
	return {
		en: en.settings_team_edit_role,
		fr: fr.settings_team_edit_role
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_team_edit_user = (params = {}, options = {}) => {
	return {
		en: en.settings_team_edit_user,
		fr: fr.settings_team_edit_user
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_team_edit_user_role = (params = {}, options = {}) => {
	return {
		en: en.settings_team_edit_user_role,
		fr: fr.settings_team_edit_user_role
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_team_edit_user_role_placeholder = (params = {}, options = {}) => {
	return {
		en: en.settings_team_edit_user_role_placeholder,
		fr: fr.settings_team_edit_user_role_placeholder
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_team_edit_user_success = (params = {}, options = {}) => {
	return {
		en: en.settings_team_edit_user_success,
		fr: fr.settings_team_edit_user_success
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_team_profile_description = (params = {}, options = {}) => {
	return {
		en: en.settings_team_profile_description,
		fr: fr.settings_team_profile_description
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_team_profile_description_placeholder = (params = {}, options = {}) => {
	return {
		en: en.settings_team_profile_description_placeholder,
		fr: fr.settings_team_profile_description_placeholder
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_team_profile_media = (params = {}, options = {}) => {
	return {
		en: en.settings_team_profile_media,
		fr: fr.settings_team_profile_media
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_team_profile_media_picture = (params = {}, options = {}) => {
	return {
		en: en.settings_team_profile_media_picture,
		fr: fr.settings_team_profile_media_picture
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_team_profile_media_upload = (params = {}, options = {}) => {
	return {
		en: en.settings_team_profile_media_upload,
		fr: fr.settings_team_profile_media_upload
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_team_profile_media_valid_formats = (params = {}, options = {}) => {
	return {
		en: en.settings_team_profile_media_valid_formats,
		fr: fr.settings_team_profile_media_valid_formats
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_team_profile_media_video = (params = {}, options = {}) => {
	return {
		en: en.settings_team_profile_media_video,
		fr: fr.settings_team_profile_media_video
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_team_profile_name = (params = {}, options = {}) => {
	return {
		en: en.settings_team_profile_name,
		fr: fr.settings_team_profile_name
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_team_profile_name_placeholder = (params = {}, options = {}) => {
	return {
		en: en.settings_team_profile_name_placeholder,
		fr: fr.settings_team_profile_name_placeholder
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_team_profile_saved = (params = {}, options = {}) => {
	return {
		en: en.settings_team_profile_saved,
		fr: fr.settings_team_profile_saved
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_team_profile_upload_logo = (params = {}, options = {}) => {
	return {
		en: en.settings_team_profile_upload_logo,
		fr: fr.settings_team_profile_upload_logo
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_team_profile_upload_logo_label = (params = {}, options = {}) => {
	return {
		en: en.settings_team_profile_upload_logo_label,
		fr: fr.settings_team_profile_upload_logo_label
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_team_profile_upload_logo_search_tooltip = (params = {}, options = {}) => {
	return {
		en: en.settings_team_profile_upload_logo_search_tooltip,
		fr: fr.settings_team_profile_upload_logo_search_tooltip
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_team_profile_website = (params = {}, options = {}) => {
	return {
		en: en.settings_team_profile_website,
		fr: fr.settings_team_profile_website
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_team_profile_website_error = (params = {}, options = {}) => {
	return {
		en: en.settings_team_profile_website_error,
		fr: fr.settings_team_profile_website_error
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_team_profile_website_placeholder = (params = {}, options = {}) => {
	return {
		en: en.settings_team_profile_website_placeholder,
		fr: fr.settings_team_profile_website_placeholder
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_team_profile_youtube_url = (params = {}, options = {}) => {
	return {
		en: en.settings_team_profile_youtube_url,
		fr: fr.settings_team_profile_youtube_url
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_team_profile_youtube_url_error = (params = {}, options = {}) => {
	return {
		en: en.settings_team_profile_youtube_url_error,
		fr: fr.settings_team_profile_youtube_url_error
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_team_profile_youtube_url_placeholder = (params = {}, options = {}) => {
	return {
		en: en.settings_team_profile_youtube_url_placeholder,
		fr: fr.settings_team_profile_youtube_url_placeholder
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_team_reinvited_success = (params = {}, options = {}) => {
	return {
		en: en.settings_team_reinvited_success,
		fr: fr.settings_team_reinvited_success
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_team_resend_activation_link = (params = {}, options = {}) => {
	return {
		en: en.settings_team_resend_activation_link,
		fr: fr.settings_team_resend_activation_link
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_team_role = (params = {}, options = {}) => {
	return {
		en: en.settings_team_role,
		fr: fr.settings_team_role
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_team_role_admin = (params = {}, options = {}) => {
	return {
		en: en.settings_team_role_admin,
		fr: fr.settings_team_role_admin
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_team_role_admin_description = (params = {}, options = {}) => {
	return {
		en: en.settings_team_role_admin_description,
		fr: fr.settings_team_role_admin_description
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_team_role_collaborator = (params = {}, options = {}) => {
	return {
		en: en.settings_team_role_collaborator,
		fr: fr.settings_team_role_collaborator
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_team_role_collaborator_description = (params = {}, options = {}) => {
	return {
		en: en.settings_team_role_collaborator_description,
		fr: fr.settings_team_role_collaborator_description
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_team_role_description_title = (params = {}, options = {}) => {
	return {
		en: en.settings_team_role_description_title,
		fr: fr.settings_team_role_description_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_team_role_missionManager = (params = {}, options = {}) => {
	return {
		en: en.settings_team_role_missionManager,
		fr: fr.settings_team_role_missionManager
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_team_role_missionManager_description = (params = {}, options = {}) => {
	return {
		en: en.settings_team_role_missionManager_description,
		fr: fr.settings_team_role_missionManager_description
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_team_title = (params = {}, options = {}) => {
	return {
		en: en.settings_team_title,
		fr: fr.settings_team_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_team_user = (params = {}, options = {}) => {
	return {
		en: en.settings_team_user,
		fr: fr.settings_team_user
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_title = (params = {}, options = {}) => {
	return {
		en: en.settings_title,
		fr: fr.settings_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_warning_save_dialog_content = (params = {}, options = {}) => {
	return {
		en: en.settings_warning_save_dialog_content,
		fr: fr.settings_warning_save_dialog_content
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_warning_save_dialog_discard = (params = {}, options = {}) => {
	return {
		en: en.settings_warning_save_dialog_discard,
		fr: fr.settings_warning_save_dialog_discard
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_warning_save_dialog_save = (params = {}, options = {}) => {
	return {
		en: en.settings_warning_save_dialog_save,
		fr: fr.settings_warning_save_dialog_save
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const settings_warning_save_dialog_title = (params = {}, options = {}) => {
	return {
		en: en.settings_warning_save_dialog_title,
		fr: fr.settings_warning_save_dialog_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const shift_warning_holiday_FAQ_link = (params = {}, options = {}) => {
	return {
		en: en.shift_warning_holiday_FAQ_link,
		fr: fr.shift_warning_holiday_FAQ_link
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const shifts_add_shift = (params = {}, options = {}) => {
	return {
		en: en.shifts_add_shift,
		fr: fr.shifts_add_shift
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const shifts_break_105_label = (params = {}, options = {}) => {
	return {
		en: en.shifts_break_105_label,
		fr: fr.shifts_break_105_label
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const shifts_break_120_label = (params = {}, options = {}) => {
	return {
		en: en.shifts_break_120_label,
		fr: fr.shifts_break_120_label
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const shifts_break_135_label = (params = {}, options = {}) => {
	return {
		en: en.shifts_break_135_label,
		fr: fr.shifts_break_135_label
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const shifts_break_150_label = (params = {}, options = {}) => {
	return {
		en: en.shifts_break_150_label,
		fr: fr.shifts_break_150_label
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const shifts_break_165_label = (params = {}, options = {}) => {
	return {
		en: en.shifts_break_165_label,
		fr: fr.shifts_break_165_label
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const shifts_break_180_label = (params = {}, options = {}) => {
	return {
		en: en.shifts_break_180_label,
		fr: fr.shifts_break_180_label
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const shifts_break_195_label = (params = {}, options = {}) => {
	return {
		en: en.shifts_break_195_label,
		fr: fr.shifts_break_195_label
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const shifts_break_20_label = (params = {}, options = {}) => {
	return {
		en: en.shifts_break_20_label,
		fr: fr.shifts_break_20_label
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const shifts_break_210_label = (params = {}, options = {}) => {
	return {
		en: en.shifts_break_210_label,
		fr: fr.shifts_break_210_label
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const shifts_break_225_label = (params = {}, options = {}) => {
	return {
		en: en.shifts_break_225_label,
		fr: fr.shifts_break_225_label
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const shifts_break_240_label = (params = {}, options = {}) => {
	return {
		en: en.shifts_break_240_label,
		fr: fr.shifts_break_240_label
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const shifts_break_255_label = (params = {}, options = {}) => {
	return {
		en: en.shifts_break_255_label,
		fr: fr.shifts_break_255_label
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const shifts_break_270_label = (params = {}, options = {}) => {
	return {
		en: en.shifts_break_270_label,
		fr: fr.shifts_break_270_label
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const shifts_break_285_label = (params = {}, options = {}) => {
	return {
		en: en.shifts_break_285_label,
		fr: fr.shifts_break_285_label
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const shifts_break_300_label = (params = {}, options = {}) => {
	return {
		en: en.shifts_break_300_label,
		fr: fr.shifts_break_300_label
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const shifts_break_30_label = (params = {}, options = {}) => {
	return {
		en: en.shifts_break_30_label,
		fr: fr.shifts_break_30_label
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const shifts_break_45_label = (params = {}, options = {}) => {
	return {
		en: en.shifts_break_45_label,
		fr: fr.shifts_break_45_label
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const shifts_break_60_label = (params = {}, options = {}) => {
	return {
		en: en.shifts_break_60_label,
		fr: fr.shifts_break_60_label
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const shifts_break_75_label = (params = {}, options = {}) => {
	return {
		en: en.shifts_break_75_label,
		fr: fr.shifts_break_75_label
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const shifts_break_90_label = (params = {}, options = {}) => {
	return {
		en: en.shifts_break_90_label,
		fr: fr.shifts_break_90_label
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const shifts_break_label = (params = {}, options = {}) => {
	return {
		en: en.shifts_break_label,
		fr: fr.shifts_break_label
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const shifts_break_none_label = (params = {}, options = {}) => {
	return {
		en: en.shifts_break_none_label,
		fr: fr.shifts_break_none_label
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const shifts_break_placeholder = (params = {}, options = {}) => {
	return {
		en: en.shifts_break_placeholder,
		fr: fr.shifts_break_placeholder
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const shifts_date_label = (params = {}, options = {}) => {
	return {
		en: en.shifts_date_label,
		fr: fr.shifts_date_label
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const shifts_duplicate_modal_subtitle = (params = {}, options = {}) => {
	return {
		en: en.shifts_duplicate_modal_subtitle,
		fr: fr.shifts_duplicate_modal_subtitle
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const shifts_duplicate_modal_success = (params = {}, options = {}) => {
	return {
		en: en.shifts_duplicate_modal_success,
		fr: fr.shifts_duplicate_modal_success
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const shifts_duplicate_modal_title = (params = {}, options = {}) => {
	return {
		en: en.shifts_duplicate_modal_title,
		fr: fr.shifts_duplicate_modal_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const shifts_endTime_label = (params = {}, options = {}) => {
	return {
		en: en.shifts_endTime_label,
		fr: fr.shifts_endTime_label
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const shifts_siders_count = (params = {}, options = {}) => {
	return {
		en: en.shifts_siders_count,
		fr: fr.shifts_siders_count
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const shifts_startTime_label = (params = {}, options = {}) => {
	return {
		en: en.shifts_startTime_label,
		fr: fr.shifts_startTime_label
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const shifts_summary_column_break = (params = {}, options = {}) => {
	return {
		en: en.shifts_summary_column_break,
		fr: fr.shifts_summary_column_break
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const shifts_summary_column_date = (params = {}, options = {}) => {
	return {
		en: en.shifts_summary_column_date,
		fr: fr.shifts_summary_column_date
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const shifts_summary_column_total_hours = (params = {}, options = {}) => {
	return {
		en: en.shifts_summary_column_total_hours,
		fr: fr.shifts_summary_column_total_hours
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const shifts_summary_column_total_number = (params = {}, options = {}) => {
	return {
		en: en.shifts_summary_column_total_number,
		fr: fr.shifts_summary_column_total_number
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const shifts_summary_time_from = (params = {}, options = {}) => {
	return {
		en: en.shifts_summary_time_from,
		fr: fr.shifts_summary_time_from
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const shifts_summary_time_to = (params = {}, options = {}) => {
	return {
		en: en.shifts_summary_time_to,
		fr: fr.shifts_summary_time_to
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const shifts_warning_7_consecutive = (params = {}, options = {}) => {
	return {
		en: en.shifts_warning_7_consecutive,
		fr: fr.shifts_warning_7_consecutive
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const shifts_warning_breaktime_subtitle = (params = {}, options = {}) => {
	return {
		en: en.shifts_warning_breaktime_subtitle,
		fr: fr.shifts_warning_breaktime_subtitle
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const shifts_warning_breaktime_title = (params = {}, options = {}) => {
	return {
		en: en.shifts_warning_breaktime_title,
		fr: fr.shifts_warning_breaktime_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const shifts_warning_new_year_day_subtitle = (params = {}, options = {}) => {
	return {
		en: en.shifts_warning_new_year_day_subtitle,
		fr: fr.shifts_warning_new_year_day_subtitle
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const shifts_warning_new_year_day_title = (params = {}, options = {}) => {
	return {
		en: en.shifts_warning_new_year_day_title,
		fr: fr.shifts_warning_new_year_day_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const shifts_warning_public_holiday_period = (params = {}, options = {}) => {
	return {
		en: en.shifts_warning_public_holiday_period,
		fr: fr.shifts_warning_public_holiday_period
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const shifts_warning_public_holiday_selected = (params = {}, options = {}) => {
	return {
		en: en.shifts_warning_public_holiday_selected,
		fr: fr.shifts_warning_public_holiday_selected
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const sider_profile_card_ongoing = (params = {}, options = {}) => {
	return {
		en: en.sider_profile_card_ongoing,
		fr: fr.sider_profile_card_ongoing
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const sider_profile_copy_email = (params = {}, options = {}) => {
	return {
		en: en.sider_profile_copy_email,
		fr: fr.sider_profile_copy_email
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const sider_profile_email_copied = (params = {}, options = {}) => {
	return {
		en: en.sider_profile_email_copied,
		fr: fr.sider_profile_email_copied
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const sider_profile_panel_educations = (params = {}, options = {}) => {
	return {
		en: en.sider_profile_panel_educations,
		fr: fr.sider_profile_panel_educations
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const sider_profile_panel_experiences = (params = {}, options = {}) => {
	return {
		en: en.sider_profile_panel_experiences,
		fr: fr.sider_profile_panel_experiences
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{ plural: NonNullable<unknown>, industryName: NonNullable<unknown>, count: NonNullable<unknown> }} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const sider_profile_panel_experiences_industry = (params , options = {}) => {
	return {
		en: en.sider_profile_panel_experiences_industry,
		fr: fr.sider_profile_panel_experiences_industry
	}[options.languageTag ?? languageTag()](params)
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{ plural: NonNullable<unknown>, count: NonNullable<unknown> }} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const sider_profile_panel_experiences_other = (params , options = {}) => {
	return {
		en: en.sider_profile_panel_experiences_other,
		fr: fr.sider_profile_panel_experiences_other
	}[options.languageTag ?? languageTag()](params)
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{ numberOfHours: NonNullable<unknown>, plural: NonNullable<unknown> }} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const sider_profile_panel_hours = (params , options = {}) => {
	return {
		en: en.sider_profile_panel_hours,
		fr: fr.sider_profile_panel_hours
	}[options.languageTag ?? languageTag()](params)
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{ numberOfMonths: NonNullable<unknown>, plural: NonNullable<unknown> }} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const sider_profile_panel_months = (params , options = {}) => {
	return {
		en: en.sider_profile_panel_months,
		fr: fr.sider_profile_panel_months
	}[options.languageTag ?? languageTag()](params)
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{ plural: NonNullable<unknown>, numberOfTasks: NonNullable<unknown> }} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const sider_profile_panel_tasks = (params , options = {}) => {
	return {
		en: en.sider_profile_panel_tasks,
		fr: fr.sider_profile_panel_tasks
	}[options.languageTag ?? languageTag()](params)
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{ plural: NonNullable<unknown>, companyName: NonNullable<unknown>, count: NonNullable<unknown> }} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const sider_profile_panel_tasks_company = (params , options = {}) => {
	return {
		en: en.sider_profile_panel_tasks_company,
		fr: fr.sider_profile_panel_tasks_company
	}[options.languageTag ?? languageTag()](params)
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{ plural: NonNullable<unknown> }} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const sider_profile_panel_tasks_ongoing = (params , options = {}) => {
	return {
		en: en.sider_profile_panel_tasks_ongoing,
		fr: fr.sider_profile_panel_tasks_ongoing
	}[options.languageTag ?? languageTag()](params)
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{ plural: NonNullable<unknown>, count: NonNullable<unknown> }} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const sider_profile_panel_tasks_other = (params , options = {}) => {
	return {
		en: en.sider_profile_panel_tasks_other,
		fr: fr.sider_profile_panel_tasks_other
	}[options.languageTag ?? languageTag()](params)
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const sider_profile_panel_tasks_side = (params = {}, options = {}) => {
	return {
		en: en.sider_profile_panel_tasks_side,
		fr: fr.sider_profile_panel_tasks_side
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const sider_profile_panel_weeks = (params = {}, options = {}) => {
	return {
		en: en.sider_profile_panel_weeks,
		fr: fr.sider_profile_panel_weeks
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{ numberOfYears: NonNullable<unknown>, plural: NonNullable<unknown> }} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const sider_profile_panel_years = (params , options = {}) => {
	return {
		en: en.sider_profile_panel_years,
		fr: fr.sider_profile_panel_years
	}[options.languageTag ?? languageTag()](params)
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const sign_in_create_account = (params = {}, options = {}) => {
	return {
		en: en.sign_in_create_account,
		fr: fr.sign_in_create_account
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const sign_in_email_placeholder = (params = {}, options = {}) => {
	return {
		en: en.sign_in_email_placeholder,
		fr: fr.sign_in_email_placeholder
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const sign_in_forgot_password = (params = {}, options = {}) => {
	return {
		en: en.sign_in_forgot_password,
		fr: fr.sign_in_forgot_password
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const sign_in_form_title = (params = {}, options = {}) => {
	return {
		en: en.sign_in_form_title,
		fr: fr.sign_in_form_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const sign_in_log_in = (params = {}, options = {}) => {
	return {
		en: en.sign_in_log_in,
		fr: fr.sign_in_log_in
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const sign_in_logo_company = (params = {}, options = {}) => {
	return {
		en: en.sign_in_logo_company,
		fr: fr.sign_in_logo_company
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const sign_in_no_account_yet = (params = {}, options = {}) => {
	return {
		en: en.sign_in_no_account_yet,
		fr: fr.sign_in_no_account_yet
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const sign_in_or = (params = {}, options = {}) => {
	return {
		en: en.sign_in_or,
		fr: fr.sign_in_or
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const sign_in_password_placeholder = (params = {}, options = {}) => {
	return {
		en: en.sign_in_password_placeholder,
		fr: fr.sign_in_password_placeholder
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const sign_in_title = (params = {}, options = {}) => {
	return {
		en: en.sign_in_title,
		fr: fr.sign_in_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const sign_in_with_google = (params = {}, options = {}) => {
	return {
		en: en.sign_in_with_google,
		fr: fr.sign_in_with_google
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const sign_in_work_on_side = (params = {}, options = {}) => {
	return {
		en: en.sign_in_work_on_side,
		fr: fr.sign_in_work_on_side
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const sign_up_accept = (params = {}, options = {}) => {
	return {
		en: en.sign_up_accept,
		fr: fr.sign_up_accept
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const sign_up_company_label = (params = {}, options = {}) => {
	return {
		en: en.sign_up_company_label,
		fr: fr.sign_up_company_label
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const sign_up_company_message_label = (params = {}, options = {}) => {
	return {
		en: en.sign_up_company_message_label,
		fr: fr.sign_up_company_message_label
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const sign_up_company_need_label = (params = {}, options = {}) => {
	return {
		en: en.sign_up_company_need_label,
		fr: fr.sign_up_company_need_label
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const sign_up_company_need_learn_about_side = (params = {}, options = {}) => {
	return {
		en: en.sign_up_company_need_learn_about_side,
		fr: fr.sign_up_company_need_learn_about_side
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const sign_up_company_need_less_than_7_days = (params = {}, options = {}) => {
	return {
		en: en.sign_up_company_need_less_than_7_days,
		fr: fr.sign_up_company_need_less_than_7_days
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const sign_up_company_need_more_than_7_days = (params = {}, options = {}) => {
	return {
		en: en.sign_up_company_need_more_than_7_days,
		fr: fr.sign_up_company_need_more_than_7_days
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const sign_up_company_need_placeholder = (params = {}, options = {}) => {
	return {
		en: en.sign_up_company_need_placeholder,
		fr: fr.sign_up_company_need_placeholder
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const sign_up_company_placeholder = (params = {}, options = {}) => {
	return {
		en: en.sign_up_company_placeholder,
		fr: fr.sign_up_company_placeholder
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const sign_up_company_size_label = (params = {}, options = {}) => {
	return {
		en: en.sign_up_company_size_label,
		fr: fr.sign_up_company_size_label
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const sign_up_company_size_placeholder = (params = {}, options = {}) => {
	return {
		en: en.sign_up_company_size_placeholder,
		fr: fr.sign_up_company_size_placeholder
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const sign_up_create_account = (params = {}, options = {}) => {
	return {
		en: en.sign_up_create_account,
		fr: fr.sign_up_create_account
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const sign_up_email_label = (params = {}, options = {}) => {
	return {
		en: en.sign_up_email_label,
		fr: fr.sign_up_email_label
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const sign_up_email_placeholder = (params = {}, options = {}) => {
	return {
		en: en.sign_up_email_placeholder,
		fr: fr.sign_up_email_placeholder
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const sign_up_form_subtitle_second_step = (params = {}, options = {}) => {
	return {
		en: en.sign_up_form_subtitle_second_step,
		fr: fr.sign_up_form_subtitle_second_step
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const sign_up_form_title = (params = {}, options = {}) => {
	return {
		en: en.sign_up_form_title,
		fr: fr.sign_up_form_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const sign_up_form_title_second_step = (params = {}, options = {}) => {
	return {
		en: en.sign_up_form_title_second_step,
		fr: fr.sign_up_form_title_second_step
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const sign_up_fullname_label = (params = {}, options = {}) => {
	return {
		en: en.sign_up_fullname_label,
		fr: fr.sign_up_fullname_label
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const sign_up_fullname_placeholder = (params = {}, options = {}) => {
	return {
		en: en.sign_up_fullname_placeholder,
		fr: fr.sign_up_fullname_placeholder
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const sign_up_industry_fashion = (params = {}, options = {}) => {
	return {
		en: en.sign_up_industry_fashion,
		fr: fr.sign_up_industry_fashion
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const sign_up_industry_gsa = (params = {}, options = {}) => {
	return {
		en: en.sign_up_industry_gsa,
		fr: fr.sign_up_industry_gsa
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const sign_up_industry_gss = (params = {}, options = {}) => {
	return {
		en: en.sign_up_industry_gss,
		fr: fr.sign_up_industry_gss
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const sign_up_industry_label = (params = {}, options = {}) => {
	return {
		en: en.sign_up_industry_label,
		fr: fr.sign_up_industry_label
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const sign_up_industry_logistics = (params = {}, options = {}) => {
	return {
		en: en.sign_up_industry_logistics,
		fr: fr.sign_up_industry_logistics
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const sign_up_industry_other = (params = {}, options = {}) => {
	return {
		en: en.sign_up_industry_other,
		fr: fr.sign_up_industry_other
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const sign_up_industry_placeholder = (params = {}, options = {}) => {
	return {
		en: en.sign_up_industry_placeholder,
		fr: fr.sign_up_industry_placeholder
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const sign_up_industry_services = (params = {}, options = {}) => {
	return {
		en: en.sign_up_industry_services,
		fr: fr.sign_up_industry_services
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const sign_up_industry_tech = (params = {}, options = {}) => {
	return {
		en: en.sign_up_industry_tech,
		fr: fr.sign_up_industry_tech
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const sign_up_log_in = (params = {}, options = {}) => {
	return {
		en: en.sign_up_log_in,
		fr: fr.sign_up_log_in
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const sign_up_logo_company = (params = {}, options = {}) => {
	return {
		en: en.sign_up_logo_company,
		fr: fr.sign_up_logo_company
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const sign_up_no_account_yet = (params = {}, options = {}) => {
	return {
		en: en.sign_up_no_account_yet,
		fr: fr.sign_up_no_account_yet
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const sign_up_or = (params = {}, options = {}) => {
	return {
		en: en.sign_up_or,
		fr: fr.sign_up_or
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const sign_up_password_label = (params = {}, options = {}) => {
	return {
		en: en.sign_up_password_label,
		fr: fr.sign_up_password_label
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const sign_up_password_placeholder = (params = {}, options = {}) => {
	return {
		en: en.sign_up_password_placeholder,
		fr: fr.sign_up_password_placeholder
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const sign_up_phone_label = (params = {}, options = {}) => {
	return {
		en: en.sign_up_phone_label,
		fr: fr.sign_up_phone_label
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const sign_up_phone_placeholder = (params = {}, options = {}) => {
	return {
		en: en.sign_up_phone_placeholder,
		fr: fr.sign_up_phone_placeholder
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const sign_up_size_to = (params = {}, options = {}) => {
	return {
		en: en.sign_up_size_to,
		fr: fr.sign_up_size_to
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const sign_up_temp_worker = (params = {}, options = {}) => {
	return {
		en: en.sign_up_temp_worker,
		fr: fr.sign_up_temp_worker
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const sign_up_terms_and_conditions = (params = {}, options = {}) => {
	return {
		en: en.sign_up_terms_and_conditions,
		fr: fr.sign_up_terms_and_conditions
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const sign_up_title = (params = {}, options = {}) => {
	return {
		en: en.sign_up_title,
		fr: fr.sign_up_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const sign_up_with_google = (params = {}, options = {}) => {
	return {
		en: en.sign_up_with_google,
		fr: fr.sign_up_with_google
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const sign_up_work_on_side = (params = {}, options = {}) => {
	return {
		en: en.sign_up_work_on_side,
		fr: fr.sign_up_work_on_side
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const signup_error_invalid_email = (params = {}, options = {}) => {
	return {
		en: en.signup_error_invalid_email,
		fr: fr.signup_error_invalid_email
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const slo = (params = {}, options = {}) => {
	return {
		en: en.slo,
		fr: fr.slo
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const slv = (params = {}, options = {}) => {
	return {
		en: en.slv,
		fr: fr.slv
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const smo = (params = {}, options = {}) => {
	return {
		en: en.smo,
		fr: fr.smo
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const sot = (params = {}, options = {}) => {
	return {
		en: en.sot,
		fr: fr.sot
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const spa = (params = {}, options = {}) => {
	return {
		en: en.spa,
		fr: fr.spa
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const sr_alert_disputed = (params = {}, options = {}) => {
	return {
		en: en.sr_alert_disputed,
		fr: fr.sr_alert_disputed
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{ name: NonNullable<unknown> }} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const sr_alert_forgotten = (params , options = {}) => {
	return {
		en: en.sr_alert_forgotten,
		fr: fr.sr_alert_forgotten
	}[options.languageTag ?? languageTag()](params)
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const srp = (params = {}, options = {}) => {
	return {
		en: en.srp,
		fr: fr.srp
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const submit = (params = {}, options = {}) => {
	return {
		en: en.submit,
		fr: fr.submit
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const swa = (params = {}, options = {}) => {
	return {
		en: en.swa,
		fr: fr.swa
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const swe = (params = {}, options = {}) => {
	return {
		en: en.swe,
		fr: fr.swe
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const table_header_end_date = (params = {}, options = {}) => {
	return {
		en: en.table_header_end_date,
		fr: fr.table_header_end_date
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const table_header_start_date = (params = {}, options = {}) => {
	return {
		en: en.table_header_start_date,
		fr: fr.table_header_start_date
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const table_header_status = (params = {}, options = {}) => {
	return {
		en: en.table_header_status,
		fr: fr.table_header_status
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const table_header_task = (params = {}, options = {}) => {
	return {
		en: en.table_header_task,
		fr: fr.table_header_task
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const tam = (params = {}, options = {}) => {
	return {
		en: en.tam,
		fr: fr.tam
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const task_edit_contract_warning = (params = {}, options = {}) => {
	return {
		en: en.task_edit_contract_warning,
		fr: fr.task_edit_contract_warning
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const task_edit_custom_equipment_desc = (params = {}, options = {}) => {
	return {
		en: en.task_edit_custom_equipment_desc,
		fr: fr.task_edit_custom_equipment_desc
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const task_edit_custom_equipment_name_placeholder = (params = {}, options = {}) => {
	return {
		en: en.task_edit_custom_equipment_name_placeholder,
		fr: fr.task_edit_custom_equipment_name_placeholder
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const task_edit_custom_equipment_other_desc = (params = {}, options = {}) => {
	return {
		en: en.task_edit_custom_equipment_other_desc,
		fr: fr.task_edit_custom_equipment_other_desc
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const task_edit_custom_equipment_providedBy_placeholder = (params = {}, options = {}) => {
	return {
		en: en.task_edit_custom_equipment_providedBy_placeholder,
		fr: fr.task_edit_custom_equipment_providedBy_placeholder
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const task_edit_custom_equipment_title = (params = {}, options = {}) => {
	return {
		en: en.task_edit_custom_equipment_title,
		fr: fr.task_edit_custom_equipment_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const task_edit_dress_code_desc = (params = {}, options = {}) => {
	return {
		en: en.task_edit_dress_code_desc,
		fr: fr.task_edit_dress_code_desc
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const task_edit_experiences_placeholder = (params = {}, options = {}) => {
	return {
		en: en.task_edit_experiences_placeholder,
		fr: fr.task_edit_experiences_placeholder
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const task_edit_languages_no_result = (params = {}, options = {}) => {
	return {
		en: en.task_edit_languages_no_result,
		fr: fr.task_edit_languages_no_result
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const task_edit_location_desc = (params = {}, options = {}) => {
	return {
		en: en.task_edit_location_desc,
		fr: fr.task_edit_location_desc
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const task_edit_location_desc_placeholder = (params = {}, options = {}) => {
	return {
		en: en.task_edit_location_desc_placeholder,
		fr: fr.task_edit_location_desc_placeholder
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const task_edit_location_workplace_title = (params = {}, options = {}) => {
	return {
		en: en.task_edit_location_workplace_title,
		fr: fr.task_edit_location_workplace_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const task_edit_motorized_desc = (params = {}, options = {}) => {
	return {
		en: en.task_edit_motorized_desc,
		fr: fr.task_edit_motorized_desc
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const task_edit_remote_desc = (params = {}, options = {}) => {
	return {
		en: en.task_edit_remote_desc,
		fr: fr.task_edit_remote_desc
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const task_edit_requested_siders_warning = (params = {}, options = {}) => {
	return {
		en: en.task_edit_requested_siders_warning,
		fr: fr.task_edit_requested_siders_warning
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const task_edit_salary_invalid_decimal_error = (params = {}, options = {}) => {
	return {
		en: en.task_edit_salary_invalid_decimal_error,
		fr: fr.task_edit_salary_invalid_decimal_error
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const task_edit_salary_invalid_number_error = (params = {}, options = {}) => {
	return {
		en: en.task_edit_salary_invalid_number_error,
		fr: fr.task_edit_salary_invalid_number_error
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const task_edit_salary_too_low_error = (params = {}, options = {}) => {
	return {
		en: en.task_edit_salary_too_low_error,
		fr: fr.task_edit_salary_too_low_error
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const task_edit_sidenote_desc = (params = {}, options = {}) => {
	return {
		en: en.task_edit_sidenote_desc,
		fr: fr.task_edit_sidenote_desc
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const task_edit_success_toast = (params = {}, options = {}) => {
	return {
		en: en.task_edit_success_toast,
		fr: fr.task_edit_success_toast
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const task_edit_suffix_desc = (params = {}, options = {}) => {
	return {
		en: en.task_edit_suffix_desc,
		fr: fr.task_edit_suffix_desc
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const task_edit_suffix_title = (params = {}, options = {}) => {
	return {
		en: en.task_edit_suffix_title,
		fr: fr.task_edit_suffix_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const task_edit_tools_custom_label = (params = {}, options = {}) => {
	return {
		en: en.task_edit_tools_custom_label,
		fr: fr.task_edit_tools_custom_label
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const task_edit_workconditions_desc = (params = {}, options = {}) => {
	return {
		en: en.task_edit_workconditions_desc,
		fr: fr.task_edit_workconditions_desc
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{ numberOfRemainingSlots: NonNullable<unknown>, plural: NonNullable<unknown> }} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const task_edit_workers_wao_hint = (params , options = {}) => {
	return {
		en: en.task_edit_workers_wao_hint,
		fr: fr.task_edit_workers_wao_hint
	}[options.languageTag ?? languageTag()](params)
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const task_edit_workers_wao_label = (params = {}, options = {}) => {
	return {
		en: en.task_edit_workers_wao_label,
		fr: fr.task_edit_workers_wao_label
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const task_shifts_add = (params = {}, options = {}) => {
	return {
		en: en.task_shifts_add,
		fr: fr.task_shifts_add
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const task_shifts_cost_estimation = (params = {}, options = {}) => {
	return {
		en: en.task_shifts_cost_estimation,
		fr: fr.task_shifts_cost_estimation
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const task_shifts_dialog_message = (params = {}, options = {}) => {
	return {
		en: en.task_shifts_dialog_message,
		fr: fr.task_shifts_dialog_message
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const task_shifts_dialog_title = (params = {}, options = {}) => {
	return {
		en: en.task_shifts_dialog_title,
		fr: fr.task_shifts_dialog_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const task_shifts_footer_delete_button = (params = {}, options = {}) => {
	return {
		en: en.task_shifts_footer_delete_button,
		fr: fr.task_shifts_footer_delete_button
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const task_shifts_modal_break_label = (params = {}, options = {}) => {
	return {
		en: en.task_shifts_modal_break_label,
		fr: fr.task_shifts_modal_break_label
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const task_shifts_modal_break_placeholder = (params = {}, options = {}) => {
	return {
		en: en.task_shifts_modal_break_placeholder,
		fr: fr.task_shifts_modal_break_placeholder
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const task_shifts_modal_break_tip_label = (params = {}, options = {}) => {
	return {
		en: en.task_shifts_modal_break_tip_label,
		fr: fr.task_shifts_modal_break_tip_label
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const task_shifts_modal_bulk_selection_all_shifts = (params = {}, options = {}) => {
	return {
		en: en.task_shifts_modal_bulk_selection_all_shifts,
		fr: fr.task_shifts_modal_bulk_selection_all_shifts
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const task_shifts_modal_bulk_selection_label_delete = (params = {}, options = {}) => {
	return {
		en: en.task_shifts_modal_bulk_selection_label_delete,
		fr: fr.task_shifts_modal_bulk_selection_label_delete
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const task_shifts_modal_bulk_selection_label_edit = (params = {}, options = {}) => {
	return {
		en: en.task_shifts_modal_bulk_selection_label_edit,
		fr: fr.task_shifts_modal_bulk_selection_label_edit
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const task_shifts_modal_bulk_selection_this_shift_and_following = (params = {}, options = {}) => {
	return {
		en: en.task_shifts_modal_bulk_selection_this_shift_and_following,
		fr: fr.task_shifts_modal_bulk_selection_this_shift_and_following
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const task_shifts_modal_bulk_selection_this_shift_only = (params = {}, options = {}) => {
	return {
		en: en.task_shifts_modal_bulk_selection_this_shift_only,
		fr: fr.task_shifts_modal_bulk_selection_this_shift_only
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const task_shifts_modal_creation_title = (params = {}, options = {}) => {
	return {
		en: en.task_shifts_modal_creation_title,
		fr: fr.task_shifts_modal_creation_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const task_shifts_modal_date_label = (params = {}, options = {}) => {
	return {
		en: en.task_shifts_modal_date_label,
		fr: fr.task_shifts_modal_date_label
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const task_shifts_modal_days_error = (params = {}, options = {}) => {
	return {
		en: en.task_shifts_modal_days_error,
		fr: fr.task_shifts_modal_days_error
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const task_shifts_modal_deletion_title = (params = {}, options = {}) => {
	return {
		en: en.task_shifts_modal_deletion_title,
		fr: fr.task_shifts_modal_deletion_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const task_shifts_modal_duration_error = (params = {}, options = {}) => {
	return {
		en: en.task_shifts_modal_duration_error,
		fr: fr.task_shifts_modal_duration_error
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const task_shifts_modal_edition_title = (params = {}, options = {}) => {
	return {
		en: en.task_shifts_modal_edition_title,
		fr: fr.task_shifts_modal_edition_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const task_shifts_modal_label_each = (params = {}, options = {}) => {
	return {
		en: en.task_shifts_modal_label_each,
		fr: fr.task_shifts_modal_label_each
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const task_shifts_modal_label_every = (params = {}, options = {}) => {
	return {
		en: en.task_shifts_modal_label_every,
		fr: fr.task_shifts_modal_label_every
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const task_shifts_modal_label_until = (params = {}, options = {}) => {
	return {
		en: en.task_shifts_modal_label_until,
		fr: fr.task_shifts_modal_label_until
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const task_shifts_modal_option_monthly = (params = {}, options = {}) => {
	return {
		en: en.task_shifts_modal_option_monthly,
		fr: fr.task_shifts_modal_option_monthly
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const task_shifts_modal_option_weekly = (params = {}, options = {}) => {
	return {
		en: en.task_shifts_modal_option_weekly,
		fr: fr.task_shifts_modal_option_weekly
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const task_shifts_modal_radio_1_of_the_month = (params = {}, options = {}) => {
	return {
		en: en.task_shifts_modal_radio_1_of_the_month,
		fr: fr.task_shifts_modal_radio_1_of_the_month
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const task_shifts_modal_radio_2_of_the_month = (params = {}, options = {}) => {
	return {
		en: en.task_shifts_modal_radio_2_of_the_month,
		fr: fr.task_shifts_modal_radio_2_of_the_month
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const task_shifts_modal_radio_3_of_the_month = (params = {}, options = {}) => {
	return {
		en: en.task_shifts_modal_radio_3_of_the_month,
		fr: fr.task_shifts_modal_radio_3_of_the_month
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const task_shifts_modal_radio_4_of_the_month = (params = {}, options = {}) => {
	return {
		en: en.task_shifts_modal_radio_4_of_the_month,
		fr: fr.task_shifts_modal_radio_4_of_the_month
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const task_shifts_modal_radio_last_of_the_month = (params = {}, options = {}) => {
	return {
		en: en.task_shifts_modal_radio_last_of_the_month,
		fr: fr.task_shifts_modal_radio_last_of_the_month
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const task_shifts_modal_radio_of_the_month = (params = {}, options = {}) => {
	return {
		en: en.task_shifts_modal_radio_of_the_month,
		fr: fr.task_shifts_modal_radio_of_the_month
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const task_shifts_modal_schedule_end_label = (params = {}, options = {}) => {
	return {
		en: en.task_shifts_modal_schedule_end_label,
		fr: fr.task_shifts_modal_schedule_end_label
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const task_shifts_modal_schedule_start_label = (params = {}, options = {}) => {
	return {
		en: en.task_shifts_modal_schedule_start_label,
		fr: fr.task_shifts_modal_schedule_start_label
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const task_shifts_modal_siders_label = (params = {}, options = {}) => {
	return {
		en: en.task_shifts_modal_siders_label,
		fr: fr.task_shifts_modal_siders_label
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const task_shifts_modal_slots_error = (params = {}, options = {}) => {
	return {
		en: en.task_shifts_modal_slots_error,
		fr: fr.task_shifts_modal_slots_error
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const task_shifts_modal_switch_holidays = (params = {}, options = {}) => {
	return {
		en: en.task_shifts_modal_switch_holidays,
		fr: fr.task_shifts_modal_switch_holidays
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const task_shifts_modal_switch_repeat = (params = {}, options = {}) => {
	return {
		en: en.task_shifts_modal_switch_repeat,
		fr: fr.task_shifts_modal_switch_repeat
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const task_shifts_modal_until_error = (params = {}, options = {}) => {
	return {
		en: en.task_shifts_modal_until_error,
		fr: fr.task_shifts_modal_until_error
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const task_shifts_no_shifts_description = (params = {}, options = {}) => {
	return {
		en: en.task_shifts_no_shifts_description,
		fr: fr.task_shifts_no_shifts_description
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const task_shifts_no_shifts_title = (params = {}, options = {}) => {
	return {
		en: en.task_shifts_no_shifts_title,
		fr: fr.task_shifts_no_shifts_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const task_shifts_success_toast_creation = (params = {}, options = {}) => {
	return {
		en: en.task_shifts_success_toast_creation,
		fr: fr.task_shifts_success_toast_creation
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const task_shifts_success_toast_deletion = (params = {}, options = {}) => {
	return {
		en: en.task_shifts_success_toast_deletion,
		fr: fr.task_shifts_success_toast_deletion
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const task_shifts_success_toast_edition = (params = {}, options = {}) => {
	return {
		en: en.task_shifts_success_toast_edition,
		fr: fr.task_shifts_success_toast_edition
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const task_shifts_table_break = (params = {}, options = {}) => {
	return {
		en: en.task_shifts_table_break,
		fr: fr.task_shifts_table_break
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const task_shifts_table_hours = (params = {}, options = {}) => {
	return {
		en: en.task_shifts_table_hours,
		fr: fr.task_shifts_table_hours
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const task_shifts_table_shifts = (params = {}, options = {}) => {
	return {
		en: en.task_shifts_table_shifts,
		fr: fr.task_shifts_table_shifts
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const task_shifts_title = (params = {}, options = {}) => {
	return {
		en: en.task_shifts_title,
		fr: fr.task_shifts_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const task_status_closed = (params = {}, options = {}) => {
	return {
		en: en.task_status_closed,
		fr: fr.task_status_closed
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const task_status_done = (params = {}, options = {}) => {
	return {
		en: en.task_status_done,
		fr: fr.task_status_done
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const task_status_draft = (params = {}, options = {}) => {
	return {
		en: en.task_status_draft,
		fr: fr.task_status_draft
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const task_status_new = (params = {}, options = {}) => {
	return {
		en: en.task_status_new,
		fr: fr.task_status_new
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const task_status_ongoing = (params = {}, options = {}) => {
	return {
		en: en.task_status_ongoing,
		fr: fr.task_status_ongoing
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const task_status_upcoming = (params = {}, options = {}) => {
	return {
		en: en.task_status_upcoming,
		fr: fr.task_status_upcoming
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const te_dispute_add_break = (params = {}, options = {}) => {
	return {
		en: en.te_dispute_add_break,
		fr: fr.te_dispute_add_break
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const te_dispute_break = (params = {}, options = {}) => {
	return {
		en: en.te_dispute_break,
		fr: fr.te_dispute_break
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const te_dispute_cancel = (params = {}, options = {}) => {
	return {
		en: en.te_dispute_cancel,
		fr: fr.te_dispute_cancel
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const te_dispute_did_not_work = (params = {}, options = {}) => {
	return {
		en: en.te_dispute_did_not_work,
		fr: fr.te_dispute_did_not_work
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const te_dispute_did_not_work_tip_after_confirmed = (params = {}, options = {}) => {
	return {
		en: en.te_dispute_did_not_work_tip_after_confirmed,
		fr: fr.te_dispute_did_not_work_tip_after_confirmed
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const te_dispute_did_not_work_tip_confirmation = (params = {}, options = {}) => {
	return {
		en: en.te_dispute_did_not_work_tip_confirmation,
		fr: fr.te_dispute_did_not_work_tip_confirmation
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{ managerName: NonNullable<unknown>, date: NonNullable<unknown>, hour: NonNullable<unknown> }} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const te_dispute_review_info = (params , options = {}) => {
	return {
		en: en.te_dispute_review_info,
		fr: fr.te_dispute_review_info
	}[options.languageTag ?? languageTag()](params)
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const te_dispute_send = (params = {}, options = {}) => {
	return {
		en: en.te_dispute_send,
		fr: fr.te_dispute_send
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const te_dispute_shift = (params = {}, options = {}) => {
	return {
		en: en.te_dispute_shift,
		fr: fr.te_dispute_shift
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const te_dispute_shift_end = (params = {}, options = {}) => {
	return {
		en: en.te_dispute_shift_end,
		fr: fr.te_dispute_shift_end
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const te_dispute_shift_start = (params = {}, options = {}) => {
	return {
		en: en.te_dispute_shift_start,
		fr: fr.te_dispute_shift_start
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const te_dispute_subtitle = (params = {}, options = {}) => {
	return {
		en: en.te_dispute_subtitle,
		fr: fr.te_dispute_subtitle
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const te_dispute_title = (params = {}, options = {}) => {
	return {
		en: en.te_dispute_title,
		fr: fr.te_dispute_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const te_tip_disputed_content = (params = {}, options = {}) => {
	return {
		en: en.te_tip_disputed_content,
		fr: fr.te_tip_disputed_content
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const te_tip_edit_content = (params = {}, options = {}) => {
	return {
		en: en.te_tip_edit_content,
		fr: fr.te_tip_edit_content
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{ name: NonNullable<unknown> }} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const te_tip_howto_content = (params , options = {}) => {
	return {
		en: en.te_tip_howto_content,
		fr: fr.te_tip_howto_content
	}[options.languageTag ?? languageTag()](params)
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const te_tip_review_content = (params = {}, options = {}) => {
	return {
		en: en.te_tip_review_content,
		fr: fr.te_tip_review_content
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{ s: NonNullable<unknown> }} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const textarea_counter_label = (params , options = {}) => {
	return {
		en: en.textarea_counter_label,
		fr: fr.textarea_counter_label
	}[options.languageTag ?? languageTag()](params)
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const tha = (params = {}, options = {}) => {
	return {
		en: en.tha,
		fr: fr.tha
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const top_menu_dropdown_default = (params = {}, options = {}) => {
	return {
		en: en.top_menu_dropdown_default,
		fr: fr.top_menu_dropdown_default
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const top_menu_filter_all = (params = {}, options = {}) => {
	return {
		en: en.top_menu_filter_all,
		fr: fr.top_menu_filter_all
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const top_menu_filter_all_invoices = (params = {}, options = {}) => {
	return {
		en: en.top_menu_filter_all_invoices,
		fr: fr.top_menu_filter_all_invoices
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const top_menu_filter_completed = (params = {}, options = {}) => {
	return {
		en: en.top_menu_filter_completed,
		fr: fr.top_menu_filter_completed
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const top_menu_filter_draft = (params = {}, options = {}) => {
	return {
		en: en.top_menu_filter_draft,
		fr: fr.top_menu_filter_draft
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const top_menu_filter_ongoing = (params = {}, options = {}) => {
	return {
		en: en.top_menu_filter_ongoing,
		fr: fr.top_menu_filter_ongoing
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const top_menu_filter_paid_ts = (params = {}, options = {}) => {
	return {
		en: en.top_menu_filter_paid_ts,
		fr: fr.top_menu_filter_paid_ts
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const top_menu_filter_siders = (params = {}, options = {}) => {
	return {
		en: en.top_menu_filter_siders,
		fr: fr.top_menu_filter_siders
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const top_menu_filter_to_be_paid = (params = {}, options = {}) => {
	return {
		en: en.top_menu_filter_to_be_paid,
		fr: fr.top_menu_filter_to_be_paid
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const top_menu_filter_to_review = (params = {}, options = {}) => {
	return {
		en: en.top_menu_filter_to_review,
		fr: fr.top_menu_filter_to_review
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const top_menu_filter_upcoming = (params = {}, options = {}) => {
	return {
		en: en.top_menu_filter_upcoming,
		fr: fr.top_menu_filter_upcoming
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const top_menu_filter_validated = (params = {}, options = {}) => {
	return {
		en: en.top_menu_filter_validated,
		fr: fr.top_menu_filter_validated
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const top_menu_planning = (params = {}, options = {}) => {
	return {
		en: en.top_menu_planning,
		fr: fr.top_menu_planning
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_attendance_disputed_success = (params = {}, options = {}) => {
	return {
		en: en.ts_attendance_disputed_success,
		fr: fr.ts_attendance_disputed_success
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_confirm_attendances = (params = {}, options = {}) => {
	return {
		en: en.ts_confirm_attendances,
		fr: fr.ts_confirm_attendances
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_confirm_attendances_mobile = (params = {}, options = {}) => {
	return {
		en: en.ts_confirm_attendances_mobile,
		fr: fr.ts_confirm_attendances_mobile
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_edit_form_break_error_after = (params = {}, options = {}) => {
	return {
		en: en.ts_edit_form_break_error_after,
		fr: fr.ts_edit_form_break_error_after
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_edit_form_break_error_before = (params = {}, options = {}) => {
	return {
		en: en.ts_edit_form_break_error_before,
		fr: fr.ts_edit_form_break_error_before
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_edit_form_break_title = (params = {}, options = {}) => {
	return {
		en: en.ts_edit_form_break_title,
		fr: fr.ts_edit_form_break_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_edit_form_breaks_add = (params = {}, options = {}) => {
	return {
		en: en.ts_edit_form_breaks_add,
		fr: fr.ts_edit_form_breaks_add
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_edit_form_description = (params = {}, options = {}) => {
	return {
		en: en.ts_edit_form_description,
		fr: fr.ts_edit_form_description
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_edit_form_hours_24_hours_error = (params = {}, options = {}) => {
	return {
		en: en.ts_edit_form_hours_24_hours_error,
		fr: fr.ts_edit_form_hours_24_hours_error
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_edit_form_hours_label = (params = {}, options = {}) => {
	return {
		en: en.ts_edit_form_hours_label,
		fr: fr.ts_edit_form_hours_label
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_edit_form_hours_title = (params = {}, options = {}) => {
	return {
		en: en.ts_edit_form_hours_title,
		fr: fr.ts_edit_form_hours_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_export_csv = (params = {}, options = {}) => {
	return {
		en: en.ts_export_csv,
		fr: fr.ts_export_csv
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_export_csv_error_body = (params = {}, options = {}) => {
	return {
		en: en.ts_export_csv_error_body,
		fr: fr.ts_export_csv_error_body
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_export_csv_error_title = (params = {}, options = {}) => {
	return {
		en: en.ts_export_csv_error_title,
		fr: fr.ts_export_csv_error_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_file_import_button = (params = {}, options = {}) => {
	return {
		en: en.ts_file_import_button,
		fr: fr.ts_file_import_button
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_file_import_dropzone_button = (params = {}, options = {}) => {
	return {
		en: en.ts_file_import_dropzone_button,
		fr: fr.ts_file_import_dropzone_button
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_file_import_dropzone_hint = (params = {}, options = {}) => {
	return {
		en: en.ts_file_import_dropzone_hint,
		fr: fr.ts_file_import_dropzone_hint
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_file_import_dropzone_text = (params = {}, options = {}) => {
	return {
		en: en.ts_file_import_dropzone_text,
		fr: fr.ts_file_import_dropzone_text
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_file_import_modal_all_error_title = (params = {}, options = {}) => {
	return {
		en: en.ts_file_import_modal_all_error_title,
		fr: fr.ts_file_import_modal_all_error_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_file_import_modal_button = (params = {}, options = {}) => {
	return {
		en: en.ts_file_import_modal_button,
		fr: fr.ts_file_import_modal_button
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_file_import_modal_cancel = (params = {}, options = {}) => {
	return {
		en: en.ts_file_import_modal_cancel,
		fr: fr.ts_file_import_modal_cancel
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_file_import_modal_column_error = (params = {}, options = {}) => {
	return {
		en: en.ts_file_import_modal_column_error,
		fr: fr.ts_file_import_modal_column_error
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_file_import_modal_db_att_duplicate_error = (params = {}, options = {}) => {
	return {
		en: en.ts_file_import_modal_db_att_duplicate_error,
		fr: fr.ts_file_import_modal_db_att_duplicate_error
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_file_import_modal_description = (params = {}, options = {}) => {
	return {
		en: en.ts_file_import_modal_description,
		fr: fr.ts_file_import_modal_description
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_file_import_modal_empty_error = (params = {}, options = {}) => {
	return {
		en: en.ts_file_import_modal_empty_error,
		fr: fr.ts_file_import_modal_empty_error
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_file_import_modal_end_before_start_error = (params = {}, options = {}) => {
	return {
		en: en.ts_file_import_modal_end_before_start_error,
		fr: fr.ts_file_import_modal_end_before_start_error
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_file_import_modal_error_all_lines = (params = {}, options = {}) => {
	return {
		en: en.ts_file_import_modal_error_all_lines,
		fr: fr.ts_file_import_modal_error_all_lines
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_file_import_modal_file_att_duplicate_error = (params = {}, options = {}) => {
	return {
		en: en.ts_file_import_modal_file_att_duplicate_error,
		fr: fr.ts_file_import_modal_file_att_duplicate_error
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_file_import_modal_format_error = (params = {}, options = {}) => {
	return {
		en: en.ts_file_import_modal_format_error,
		fr: fr.ts_file_import_modal_format_error
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_file_import_modal_import = (params = {}, options = {}) => {
	return {
		en: en.ts_file_import_modal_import,
		fr: fr.ts_file_import_modal_import
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{ count: NonNullable<unknown> }} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_file_import_modal_import_success = (params , options = {}) => {
	return {
		en: en.ts_file_import_modal_import_success,
		fr: fr.ts_file_import_modal_import_success
	}[options.languageTag ?? languageTag()](params)
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_file_import_modal_partial_error_title = (params = {}, options = {}) => {
	return {
		en: en.ts_file_import_modal_partial_error_title,
		fr: fr.ts_file_import_modal_partial_error_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_file_import_modal_rest_of_lines = (params = {}, options = {}) => {
	return {
		en: en.ts_file_import_modal_rest_of_lines,
		fr: fr.ts_file_import_modal_rest_of_lines
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_file_import_modal_shifts_not_found_error = (params = {}, options = {}) => {
	return {
		en: en.ts_file_import_modal_shifts_not_found_error,
		fr: fr.ts_file_import_modal_shifts_not_found_error
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_file_import_modal_sider_id_error = (params = {}, options = {}) => {
	return {
		en: en.ts_file_import_modal_sider_id_error,
		fr: fr.ts_file_import_modal_sider_id_error
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_file_import_modal_template_link = (params = {}, options = {}) => {
	return {
		en: en.ts_file_import_modal_template_link,
		fr: fr.ts_file_import_modal_template_link
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_file_import_modal_title = (params = {}, options = {}) => {
	return {
		en: en.ts_file_import_modal_title,
		fr: fr.ts_file_import_modal_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_file_import_modal_with_error_main_button = (params = {}, options = {}) => {
	return {
		en: en.ts_file_import_modal_with_error_main_button,
		fr: fr.ts_file_import_modal_with_error_main_button
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_file_import_modal_with_error_secondary_button = (params = {}, options = {}) => {
	return {
		en: en.ts_file_import_modal_with_error_secondary_button,
		fr: fr.ts_file_import_modal_with_error_secondary_button
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_filters_all = (params = {}, options = {}) => {
	return {
		en: en.ts_filters_all,
		fr: fr.ts_filters_all
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_filters_day = (params = {}, options = {}) => {
	return {
		en: en.ts_filters_day,
		fr: fr.ts_filters_day
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_filters_month = (params = {}, options = {}) => {
	return {
		en: en.ts_filters_month,
		fr: fr.ts_filters_month
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_filters_search_item_empty = (params = {}, options = {}) => {
	return {
		en: en.ts_filters_search_item_empty,
		fr: fr.ts_filters_search_item_empty
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_filters_search_manager = (params = {}, options = {}) => {
	return {
		en: en.ts_filters_search_manager,
		fr: fr.ts_filters_search_manager
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_filters_search_sider = (params = {}, options = {}) => {
	return {
		en: en.ts_filters_search_sider,
		fr: fr.ts_filters_search_sider
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_filters_status = (params = {}, options = {}) => {
	return {
		en: en.ts_filters_status,
		fr: fr.ts_filters_status
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_filters_type = (params = {}, options = {}) => {
	return {
		en: en.ts_filters_type,
		fr: fr.ts_filters_type
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_filters_type_hours_different_description = (params = {}, options = {}) => {
	return {
		en: en.ts_filters_type_hours_different_description,
		fr: fr.ts_filters_type_hours_different_description
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_filters_type_hours_different_label = (params = {}, options = {}) => {
	return {
		en: en.ts_filters_type_hours_different_label,
		fr: fr.ts_filters_type_hours_different_label
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_filters_type_hours_similar_description = (params = {}, options = {}) => {
	return {
		en: en.ts_filters_type_hours_similar_description,
		fr: fr.ts_filters_type_hours_similar_description
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_filters_type_hours_similar_label = (params = {}, options = {}) => {
	return {
		en: en.ts_filters_type_hours_similar_label,
		fr: fr.ts_filters_type_hours_similar_label
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_filters_week = (params = {}, options = {}) => {
	return {
		en: en.ts_filters_week,
		fr: fr.ts_filters_week
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_footer_paragraph = (params = {}, options = {}) => {
	return {
		en: en.ts_footer_paragraph,
		fr: fr.ts_footer_paragraph
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_footer_selected_sider = (params = {}, options = {}) => {
	return {
		en: en.ts_footer_selected_sider,
		fr: fr.ts_footer_selected_sider
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{ count: NonNullable<unknown> }} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_footer_selected_siders = (params , options = {}) => {
	return {
		en: en.ts_footer_selected_siders,
		fr: fr.ts_footer_selected_siders
	}[options.languageTag ?? languageTag()](params)
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_footer_sider_didnt_work = (params = {}, options = {}) => {
	return {
		en: en.ts_footer_sider_didnt_work,
		fr: fr.ts_footer_sider_didnt_work
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_footer_siders_didnt_work = (params = {}, options = {}) => {
	return {
		en: en.ts_footer_siders_didnt_work,
		fr: fr.ts_footer_siders_didnt_work
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_footer_tovalidate_attendance = (params = {}, options = {}) => {
	return {
		en: en.ts_footer_tovalidate_attendance,
		fr: fr.ts_footer_tovalidate_attendance
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{ count: NonNullable<unknown> }} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_footer_tovalidate_attendances = (params , options = {}) => {
	return {
		en: en.ts_footer_tovalidate_attendances,
		fr: fr.ts_footer_tovalidate_attendances
	}[options.languageTag ?? languageTag()](params)
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_footer_validate_hours = (params = {}, options = {}) => {
	return {
		en: en.ts_footer_validate_hours,
		fr: fr.ts_footer_validate_hours
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_hours_validation_faq_label = (params = {}, options = {}) => {
	return {
		en: en.ts_hours_validation_faq_label,
		fr: fr.ts_hours_validation_faq_label
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_hours_validation_faq_link = (params = {}, options = {}) => {
	return {
		en: en.ts_hours_validation_faq_link,
		fr: fr.ts_hours_validation_faq_link
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_hours_validation_modal_agreement = (params = {}, options = {}) => {
	return {
		en: en.ts_hours_validation_modal_agreement,
		fr: fr.ts_hours_validation_modal_agreement
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_hours_validation_modal_attendances_count = (params = {}, options = {}) => {
	return {
		en: en.ts_hours_validation_modal_attendances_count,
		fr: fr.ts_hours_validation_modal_attendances_count
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{ count: NonNullable<unknown> }} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_hours_validation_modal_failure = (params , options = {}) => {
	return {
		en: en.ts_hours_validation_modal_failure,
		fr: fr.ts_hours_validation_modal_failure
	}[options.languageTag ?? languageTag()](params)
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{ count: NonNullable<unknown> }} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_hours_validation_modal_failure_plural = (params , options = {}) => {
	return {
		en: en.ts_hours_validation_modal_failure_plural,
		fr: fr.ts_hours_validation_modal_failure_plural
	}[options.languageTag ?? languageTag()](params)
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_hours_validation_modal_submit_button = (params = {}, options = {}) => {
	return {
		en: en.ts_hours_validation_modal_submit_button,
		fr: fr.ts_hours_validation_modal_submit_button
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_hours_validation_modal_submit_button_mobile = (params = {}, options = {}) => {
	return {
		en: en.ts_hours_validation_modal_submit_button_mobile,
		fr: fr.ts_hours_validation_modal_submit_button_mobile
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{ count: NonNullable<unknown> }} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_hours_validation_modal_success = (params , options = {}) => {
	return {
		en: en.ts_hours_validation_modal_success,
		fr: fr.ts_hours_validation_modal_success
	}[options.languageTag ?? languageTag()](params)
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{ count: NonNullable<unknown> }} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_hours_validation_modal_success_plural = (params , options = {}) => {
	return {
		en: en.ts_hours_validation_modal_success_plural,
		fr: fr.ts_hours_validation_modal_success_plural
	}[options.languageTag ?? languageTag()](params)
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_hours_validation_modal_tip_content = (params = {}, options = {}) => {
	return {
		en: en.ts_hours_validation_modal_tip_content,
		fr: fr.ts_hours_validation_modal_tip_content
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_hours_validation_modal_tip_title = (params = {}, options = {}) => {
	return {
		en: en.ts_hours_validation_modal_tip_title,
		fr: fr.ts_hours_validation_modal_tip_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_hours_validation_modal_title = (params = {}, options = {}) => {
	return {
		en: en.ts_hours_validation_modal_title,
		fr: fr.ts_hours_validation_modal_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_hours_validation_modal_worked_hours = (params = {}, options = {}) => {
	return {
		en: en.ts_hours_validation_modal_worked_hours,
		fr: fr.ts_hours_validation_modal_worked_hours
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_modal_confirm_didntwork_content = (params = {}, options = {}) => {
	return {
		en: en.ts_modal_confirm_didntwork_content,
		fr: fr.ts_modal_confirm_didntwork_content
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_modal_confirm_didntwork_tip = (params = {}, options = {}) => {
	return {
		en: en.ts_modal_confirm_didntwork_tip,
		fr: fr.ts_modal_confirm_didntwork_tip
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_modal_confirm_didntwork_title = (params = {}, options = {}) => {
	return {
		en: en.ts_modal_confirm_didntwork_title,
		fr: fr.ts_modal_confirm_didntwork_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_modal_confirm_submit = (params = {}, options = {}) => {
	return {
		en: en.ts_modal_confirm_submit,
		fr: fr.ts_modal_confirm_submit
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_modal_confirm_validation_content = (params = {}, options = {}) => {
	return {
		en: en.ts_modal_confirm_validation_content,
		fr: fr.ts_modal_confirm_validation_content
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_modal_confirm_validation_tip = (params = {}, options = {}) => {
	return {
		en: en.ts_modal_confirm_validation_tip,
		fr: fr.ts_modal_confirm_validation_tip
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_modal_confirm_validation_title = (params = {}, options = {}) => {
	return {
		en: en.ts_modal_confirm_validation_title,
		fr: fr.ts_modal_confirm_validation_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_panel_add_break = (params = {}, options = {}) => {
	return {
		en: en.ts_panel_add_break,
		fr: fr.ts_panel_add_break
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_panel_imported_hours = (params = {}, options = {}) => {
	return {
		en: en.ts_panel_imported_hours,
		fr: fr.ts_panel_imported_hours
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_panel_scheduled_hours = (params = {}, options = {}) => {
	return {
		en: en.ts_panel_scheduled_hours,
		fr: fr.ts_panel_scheduled_hours
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{ plural: NonNullable<unknown> }} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_panel_sider_breaks = (params , options = {}) => {
	return {
		en: en.ts_panel_sider_breaks,
		fr: fr.ts_panel_sider_breaks
	}[options.languageTag ?? languageTag()](params)
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_panel_sider_didnt_work = (params = {}, options = {}) => {
	return {
		en: en.ts_panel_sider_didnt_work,
		fr: fr.ts_panel_sider_didnt_work
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_panel_sider_hours = (params = {}, options = {}) => {
	return {
		en: en.ts_panel_sider_hours,
		fr: fr.ts_panel_sider_hours
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_panel_submit = (params = {}, options = {}) => {
	return {
		en: en.ts_panel_submit,
		fr: fr.ts_panel_submit
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_select = (params = {}, options = {}) => {
	return {
		en: en.ts_select,
		fr: fr.ts_select
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{ count: NonNullable<unknown>, plural: NonNullable<unknown> }} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_selected_attendances_count = (params , options = {}) => {
	return {
		en: en.ts_selected_attendances_count,
		fr: fr.ts_selected_attendances_count
	}[options.languageTag ?? languageTag()](params)
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_shifts_break = (params = {}, options = {}) => {
	return {
		en: en.ts_shifts_break,
		fr: fr.ts_shifts_break
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_shifts_break_hour = (params = {}, options = {}) => {
	return {
		en: en.ts_shifts_break_hour,
		fr: fr.ts_shifts_break_hour
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_shifts_break_mins = (params = {}, options = {}) => {
	return {
		en: en.ts_shifts_break_mins,
		fr: fr.ts_shifts_break_mins
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_shifts_deadline = (params = {}, options = {}) => {
	return {
		en: en.ts_shifts_deadline,
		fr: fr.ts_shifts_deadline
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{ hours: NonNullable<unknown> }} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_shifts_expected_hours = (params , options = {}) => {
	return {
		en: en.ts_shifts_expected_hours,
		fr: fr.ts_shifts_expected_hours
	}[options.languageTag ?? languageTag()](params)
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_shifts_hide_entries = (params = {}, options = {}) => {
	return {
		en: en.ts_shifts_hide_entries,
		fr: fr.ts_shifts_hide_entries
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_shifts_in = (params = {}, options = {}) => {
	return {
		en: en.ts_shifts_in,
		fr: fr.ts_shifts_in
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_shifts_mark_seen = (params = {}, options = {}) => {
	return {
		en: en.ts_shifts_mark_seen,
		fr: fr.ts_shifts_mark_seen
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{ date: NonNullable<unknown> }} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_shifts_no_time_day = (params , options = {}) => {
	return {
		en: en.ts_shifts_no_time_day,
		fr: fr.ts_shifts_no_time_day
	}[options.languageTag ?? languageTag()](params)
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_shifts_no_time_shift = (params = {}, options = {}) => {
	return {
		en: en.ts_shifts_no_time_shift,
		fr: fr.ts_shifts_no_time_shift
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_shifts_show_entries = (params = {}, options = {}) => {
	return {
		en: en.ts_shifts_show_entries,
		fr: fr.ts_shifts_show_entries
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_shifts_unplanned_day = (params = {}, options = {}) => {
	return {
		en: en.ts_shifts_unplanned_day,
		fr: fr.ts_shifts_unplanned_day
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_shifts_unplanned_in = (params = {}, options = {}) => {
	return {
		en: en.ts_shifts_unplanned_in,
		fr: fr.ts_shifts_unplanned_in
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_table_add_comment = (params = {}, options = {}) => {
	return {
		en: en.ts_table_add_comment,
		fr: fr.ts_table_add_comment
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{ siderName: NonNullable<unknown> }} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_table_conflict_company_description = (params , options = {}) => {
	return {
		en: en.ts_table_conflict_company_description,
		fr: fr.ts_table_conflict_company_description
	}[options.languageTag ?? languageTag()](params)
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_table_conflict_company_title = (params = {}, options = {}) => {
	return {
		en: en.ts_table_conflict_company_title,
		fr: fr.ts_table_conflict_company_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{ siderName: NonNullable<unknown> }} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_table_conflict_insider_description = (params , options = {}) => {
	return {
		en: en.ts_table_conflict_insider_description,
		fr: fr.ts_table_conflict_insider_description
	}[options.languageTag ?? languageTag()](params)
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_table_conflict_insider_title = (params = {}, options = {}) => {
	return {
		en: en.ts_table_conflict_insider_title,
		fr: fr.ts_table_conflict_insider_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{ siderName: NonNullable<unknown> }} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_table_conflict_sider_description = (params , options = {}) => {
	return {
		en: en.ts_table_conflict_sider_description,
		fr: fr.ts_table_conflict_sider_description
	}[options.languageTag ?? languageTag()](params)
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_table_conflict_sider_title = (params = {}, options = {}) => {
	return {
		en: en.ts_table_conflict_sider_title,
		fr: fr.ts_table_conflict_sider_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{ delta: NonNullable<unknown> }} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_table_diff_break_description = (params , options = {}) => {
	return {
		en: en.ts_table_diff_break_description,
		fr: fr.ts_table_diff_break_description
	}[options.languageTag ?? languageTag()](params)
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{ delta: NonNullable<unknown> }} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_table_diff_break_label = (params , options = {}) => {
	return {
		en: en.ts_table_diff_break_label,
		fr: fr.ts_table_diff_break_label
	}[options.languageTag ?? languageTag()](params)
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{ delta: NonNullable<unknown> }} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_table_diff_hours_description = (params , options = {}) => {
	return {
		en: en.ts_table_diff_hours_description,
		fr: fr.ts_table_diff_hours_description
	}[options.languageTag ?? languageTag()](params)
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{ delta: NonNullable<unknown> }} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_table_diff_hours_label = (params , options = {}) => {
	return {
		en: en.ts_table_diff_hours_label,
		fr: fr.ts_table_diff_hours_label
	}[options.languageTag ?? languageTag()](params)
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_table_edit_comment = (params = {}, options = {}) => {
	return {
		en: en.ts_table_edit_comment,
		fr: fr.ts_table_edit_comment
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_table_head_duration = (params = {}, options = {}) => {
	return {
		en: en.ts_table_head_duration,
		fr: fr.ts_table_head_duration
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_table_head_duration_tag = (params = {}, options = {}) => {
	return {
		en: en.ts_table_head_duration_tag,
		fr: fr.ts_table_head_duration_tag
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_table_head_mission = (params = {}, options = {}) => {
	return {
		en: en.ts_table_head_mission,
		fr: fr.ts_table_head_mission
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_table_head_sider_breaks = (params = {}, options = {}) => {
	return {
		en: en.ts_table_head_sider_breaks,
		fr: fr.ts_table_head_sider_breaks
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_table_head_sider_hours = (params = {}, options = {}) => {
	return {
		en: en.ts_table_head_sider_hours,
		fr: fr.ts_table_head_sider_hours
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_table_head_start_end = (params = {}, options = {}) => {
	return {
		en: en.ts_table_head_start_end,
		fr: fr.ts_table_head_start_end
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_table_head_status = (params = {}, options = {}) => {
	return {
		en: en.ts_table_head_status,
		fr: fr.ts_table_head_status
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_table_no_attendances_description = (params = {}, options = {}) => {
	return {
		en: en.ts_table_no_attendances_description,
		fr: fr.ts_table_no_attendances_description
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_table_no_attendances_title = (params = {}, options = {}) => {
	return {
		en: en.ts_table_no_attendances_title,
		fr: fr.ts_table_no_attendances_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{ validationDate: NonNullable<unknown> }} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_table_validated_tag_content = (params , options = {}) => {
	return {
		en: en.ts_table_validated_tag_content,
		fr: fr.ts_table_validated_tag_content
	}[options.languageTag ?? languageTag()](params)
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{ validatedBy: NonNullable<unknown>, validationDate: NonNullable<unknown> }} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_table_validated_tag_content_validated_by = (params , options = {}) => {
	return {
		en: en.ts_table_validated_tag_content_validated_by,
		fr: fr.ts_table_validated_tag_content_validated_by
	}[options.languageTag ?? languageTag()](params)
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_table_validated_tag_title = (params = {}, options = {}) => {
	return {
		en: en.ts_table_validated_tag_title,
		fr: fr.ts_table_validated_tag_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_tag_disputed = (params = {}, options = {}) => {
	return {
		en: en.ts_tag_disputed,
		fr: fr.ts_tag_disputed
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{ date: NonNullable<unknown>, time: NonNullable<unknown> }} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_tag_event_log = (params , options = {}) => {
	return {
		en: en.ts_tag_event_log,
		fr: fr.ts_tag_event_log
	}[options.languageTag ?? languageTag()](params)
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_tag_external_file_origin = (params = {}, options = {}) => {
	return {
		en: en.ts_tag_external_file_origin,
		fr: fr.ts_tag_external_file_origin
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_tag_validated = (params = {}, options = {}) => {
	return {
		en: en.ts_tag_validated,
		fr: fr.ts_tag_validated
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_tag_waiting = (params = {}, options = {}) => {
	return {
		en: en.ts_tag_waiting,
		fr: fr.ts_tag_waiting
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_tip_archives_content = (params = {}, options = {}) => {
	return {
		en: en.ts_tip_archives_content,
		fr: fr.ts_tip_archives_content
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_tip_archives_title = (params = {}, options = {}) => {
	return {
		en: en.ts_tip_archives_title,
		fr: fr.ts_tip_archives_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{ date: NonNullable<unknown> }} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_tip_date_review = (params , options = {}) => {
	return {
		en: en.ts_tip_date_review,
		fr: fr.ts_tip_date_review
	}[options.languageTag ?? languageTag()](params)
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_tip_hours_validation_content = (params = {}, options = {}) => {
	return {
		en: en.ts_tip_hours_validation_content,
		fr: fr.ts_tip_hours_validation_content
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_tip_hours_validation_title = (params = {}, options = {}) => {
	return {
		en: en.ts_tip_hours_validation_title,
		fr: fr.ts_tip_hours_validation_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_tip_review_content = (params = {}, options = {}) => {
	return {
		en: en.ts_tip_review_content,
		fr: fr.ts_tip_review_content
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_tip_review_title = (params = {}, options = {}) => {
	return {
		en: en.ts_tip_review_title,
		fr: fr.ts_tip_review_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_tip_unplanned_content = (params = {}, options = {}) => {
	return {
		en: en.ts_tip_unplanned_content,
		fr: fr.ts_tip_unplanned_content
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_tip_unplanned_title = (params = {}, options = {}) => {
	return {
		en: en.ts_tip_unplanned_title,
		fr: fr.ts_tip_unplanned_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_to_validate_blank_state_content = (params = {}, options = {}) => {
	return {
		en: en.ts_to_validate_blank_state_content,
		fr: fr.ts_to_validate_blank_state_content
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_to_validate_blank_state_title = (params = {}, options = {}) => {
	return {
		en: en.ts_to_validate_blank_state_title,
		fr: fr.ts_to_validate_blank_state_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_unselect_all_attendances = (params = {}, options = {}) => {
	return {
		en: en.ts_unselect_all_attendances,
		fr: fr.ts_unselect_all_attendances
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_update = (params = {}, options = {}) => {
	return {
		en: en.ts_update,
		fr: fr.ts_update
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_validated_blank_state_content = (params = {}, options = {}) => {
	return {
		en: en.ts_validated_blank_state_content,
		fr: fr.ts_validated_blank_state_content
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_validated_blank_state_title = (params = {}, options = {}) => {
	return {
		en: en.ts_validated_blank_state_title,
		fr: fr.ts_validated_blank_state_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_warning_cicos_content = (params = {}, options = {}) => {
	return {
		en: en.ts_warning_cicos_content,
		fr: fr.ts_warning_cicos_content
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_warning_cicos_tab_tooltip = (params = {}, options = {}) => {
	return {
		en: en.ts_warning_cicos_tab_tooltip,
		fr: fr.ts_warning_cicos_tab_tooltip
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_warning_cicos_tag = (params = {}, options = {}) => {
	return {
		en: en.ts_warning_cicos_tag,
		fr: fr.ts_warning_cicos_tag
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_warning_cicos_tag_tooltip = (params = {}, options = {}) => {
	return {
		en: en.ts_warning_cicos_tag_tooltip,
		fr: fr.ts_warning_cicos_tag_tooltip
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ts_warning_cicos_title = (params = {}, options = {}) => {
	return {
		en: en.ts_warning_cicos_title,
		fr: fr.ts_warning_cicos_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const tur = (params = {}, options = {}) => {
	return {
		en: en.tur,
		fr: fr.tur
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ukr = (params = {}, options = {}) => {
	return {
		en: en.ukr,
		fr: fr.ukr
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const undo = (params = {}, options = {}) => {
	return {
		en: en.undo,
		fr: fr.undo
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const until = (params = {}, options = {}) => {
	return {
		en: en.until,
		fr: fr.until
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const until_date = (params = {}, options = {}) => {
	return {
		en: en.until_date,
		fr: fr.until_date
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{ numberOfNotDisplayedUpcoming: NonNullable<unknown> }} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const upcoming_section_button_view_more = (params , options = {}) => {
	return {
		en: en.upcoming_section_button_view_more,
		fr: fr.upcoming_section_button_view_more
	}[options.languageTag ?? languageTag()](params)
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{ siderNumber: NonNullable<unknown> }} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const upcoming_section_row_status_cell_subtitle_contact_plural = (params , options = {}) => {
	return {
		en: en.upcoming_section_row_status_cell_subtitle_contact_plural,
		fr: fr.upcoming_section_row_status_cell_subtitle_contact_plural
	}[options.languageTag ?? languageTag()](params)
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const upcoming_section_row_status_cell_subtitle_contact_singular = (params = {}, options = {}) => {
	return {
		en: en.upcoming_section_row_status_cell_subtitle_contact_singular,
		fr: fr.upcoming_section_row_status_cell_subtitle_contact_singular
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{ hiringEndDateDay: NonNullable<unknown>, hiringEndDateHour: NonNullable<unknown> }} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const upcoming_section_row_status_cell_subtitle_selection_ongoing = (params , options = {}) => {
	return {
		en: en.upcoming_section_row_status_cell_subtitle_selection_ongoing,
		fr: fr.upcoming_section_row_status_cell_subtitle_selection_ongoing
	}[options.languageTag ?? languageTag()](params)
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{ selectedSiderNumber: NonNullable<unknown>, totalSidersNumberExpected: NonNullable<unknown> }} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const upcoming_section_row_status_cell_title_selection_almost_done = (params , options = {}) => {
	return {
		en: en.upcoming_section_row_status_cell_title_selection_almost_done,
		fr: fr.upcoming_section_row_status_cell_title_selection_almost_done
	}[options.languageTag ?? languageTag()](params)
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const upcoming_section_row_status_cell_title_selection_done = (params = {}, options = {}) => {
	return {
		en: en.upcoming_section_row_status_cell_title_selection_done,
		fr: fr.upcoming_section_row_status_cell_title_selection_done
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const upcoming_section_row_status_cell_title_selection_ongoing = (params = {}, options = {}) => {
	return {
		en: en.upcoming_section_row_status_cell_title_selection_ongoing,
		fr: fr.upcoming_section_row_status_cell_title_selection_ongoing
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const upcoming_section_row_task_cell_managed_by = (params = {}, options = {}) => {
	return {
		en: en.upcoming_section_row_task_cell_managed_by,
		fr: fr.upcoming_section_row_task_cell_managed_by
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const upcoming_section_title = (params = {}, options = {}) => {
	return {
		en: en.upcoming_section_title,
		fr: fr.upcoming_section_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const update_manager = (params = {}, options = {}) => {
	return {
		en: en.update_manager,
		fr: fr.update_manager
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const urd = (params = {}, options = {}) => {
	return {
		en: en.urd,
		fr: fr.urd
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const use = (params = {}, options = {}) => {
	return {
		en: en.use,
		fr: fr.use
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const uzb = (params = {}, options = {}) => {
	return {
		en: en.uzb,
		fr: fr.uzb
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const various_geolocation_multiple_places = (params = {}, options = {}) => {
	return {
		en: en.various_geolocation_multiple_places,
		fr: fr.various_geolocation_multiple_places
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const various_geolocation_online = (params = {}, options = {}) => {
	return {
		en: en.various_geolocation_online,
		fr: fr.various_geolocation_online
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const various_time_deadline = (params = {}, options = {}) => {
	return {
		en: en.various_time_deadline,
		fr: fr.various_time_deadline
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const vie = (params = {}, options = {}) => {
	return {
		en: en.vie,
		fr: fr.vie
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const view_invoices = (params = {}, options = {}) => {
	return {
		en: en.view_invoices,
		fr: fr.view_invoices
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const view_siders = (params = {}, options = {}) => {
	return {
		en: en.view_siders,
		fr: fr.view_siders
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const view_task = (params = {}, options = {}) => {
	return {
		en: en.view_task,
		fr: fr.view_task
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const warning_is_google_login = (params = {}, options = {}) => {
	return {
		en: en.warning_is_google_login,
		fr: fr.warning_is_google_login
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const warning_is_log_as = (params = {}, options = {}) => {
	return {
		en: en.warning_is_log_as,
		fr: fr.warning_is_log_as
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const week = (params = {}, options = {}) => {
	return {
		en: en.week,
		fr: fr.week
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const yes = (params = {}, options = {}) => {
	return {
		en: en.yes,
		fr: fr.yes
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "fr" | "en" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const zgh = (params = {}, options = {}) => {
	return {
		en: en.zgh,
		fr: fr.zgh
	}[options.languageTag ?? languageTag()]()
}

