import { memo } from "react";
import { Frown, Smile, SmilePlus } from "lucide-react";
import { Button } from "saphir";
import { ModalsService } from "side-ui";

import { i18n } from "@lib/i18n";
import FeedbackModal from "@routes/Timesheets/components/FeedbackModal";

type FeedbackCellProps = {
    sider: any;
    task: any;
    feedback: any;
};

const FeedbackCell = memo(function FeedbackCell({
    // eslint-disable-line
    sider,
    task,
    feedback,
}: FeedbackCellProps) {
    const showFeedbackModal = () => {
        ModalsService.openModal({
            id: `FEEDBACK`,
            content: (
                <FeedbackModal
                    sider={sider}
                    task={task}
                    feedbackId={feedback?.id}
                    workAgainValue={feedback?.workAgain}
                />
            ),
        });
    };
    const hasFeedback = feedback && feedback?.id;
    const workAgainStatus =
        feedback?.workAgain > 0 ? <Smile /> : <Frown className='text-red-500' />;

    return (
        <span
            title={`${hasFeedback ? i18n.ts_table_edit_comment() : ""}`}
            // color icon fill based on workAgain value
            className={`timesheets-table__cell timesheets-table__cell--feedback ${
                hasFeedback
                    ? feedback?.workAgain > 0 // eslint-disable-line
                        ? "timesheets-table__cell--feedback--satisfied"
                        : "timesheets-table__cell--feedback--unsatisfied"
                    : ""
            } `}
        >
            <Button onClick={showFeedbackModal} variant='neutral'>
                {!hasFeedback ? <SmilePlus className='text-blue-500' /> : workAgainStatus}
            </Button>
        </span>
    );
});

export default FeedbackCell;
