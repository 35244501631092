import { useRef, useState } from "react";
import {
    addDays,
    addMinutes,
    differenceInMinutes,
    getHours,
    getMinutes,
    isBefore,
    isDate,
    setHours,
    setMinutes,
} from "date-fns";
import { fr } from "date-fns/locale";
import { Pencil, Plus, X } from "lucide-react";
import { Button } from "saphir";
import { ModalSimple, ModalsService, TimePicker } from "side-ui";

import { i18n } from "@lib/i18n";

import "../AttendanceEdit.scss";

export default function AttendanceEditBreak({
    breaks,
    editBreaks,
    breakDuration,
    startTime,
    endTime,
    readOnly,
    breakConflict,
    spanOverOneDay,
}) {
    const initialState = {
        isActive: false,
        breaks:
            breaks && breaks.length
                ? breaks.map((slotBreak, index) => ({
                      id: index,
                      startDate: new Date(slotBreak.startDate),
                      endDate: new Date(slotBreak.endDate),
                      duration: Math.abs(
                          differenceInMinutes(
                              new Date(slotBreak.startDate),
                              new Date(slotBreak.endDate),
                          ),
                      ),
                  }))
                : [
                      {
                          id: 0,
                          startDate: new Date(startTime),
                          endDate: new Date(endTime),
                          duration: 0,
                      },
                  ],
        touched: false,
        breakDuration,
        error: false,
    };

    return (
        <>
            <div
                className={`attendance-edit attendance-edit--break ${readOnly ? "attendance-edit--read-only" : ""} `}
                onClick={
                    readOnly
                        ? () => null
                        : () => {
                              ModalsService.openModal({
                                  id: `EDIT_BREAK`,
                                  content: (
                                      <AttendanceEditBreakModal
                                          spanOverOneDay={spanOverOneDay}
                                          startTime={startTime}
                                          editBreaks={editBreaks}
                                          initialState={initialState}
                                      />
                                  ),
                              });
                          }
                }
                onKeyDown={() => null}
            >
                <div className='attendance-edit__wrapper'>
                    <div className='attendance-edit__label__wrapper'>
                        <p className='timesheets-table__panel__subtitle'>
                            {i18n.ts_panel_sider_breaks({ plural: "" })}
                        </p>

                        <Pencil className='h-4 w-4 text-gray-300' />
                    </div>

                    <div className='attendance-edit__values'>
                        <div className='attendance-edit__values__wrapper'>
                            <p
                                className={`timesheets-table__panel__title ${breakConflict ? "conflicting" : ""} `}
                            >
                                {breakDuration.label}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

const AttendanceEditBreakModal = ({ spanOverOneDay, startTime, editBreaks, initialState }) => {
    const [state, setState] = useState(initialState);

    // When dates or inputted via the keyboard, ReactDatepicker shifts the date to the current day
    // and updates only the time
    // so we need to make sure to extract the initial date first
    // and add hours and minutes to it
    const setTime = (time, id) => {
        if (!isDate(time)) {
            return false;
        }

        const breakToUpdate = state.breaks.find((b) => b.id === id);

        if (!breakToUpdate) {
            return false;
        }

        const breakStartTime = new Date(breakToUpdate.startDate);
        const updateHours = setHours(breakStartTime, getHours(time));
        const updateMinutes = setMinutes(updateHours, getMinutes(time));
        // add one day if shifts spans over one day and breakStart is less than startTime
        const breakResult =
            spanOverOneDay && isBefore(updateMinutes, startTime)
                ? addDays(updateMinutes, 1)
                : updateMinutes;
        const updatedBreaks = state.breaks.map((slotBreak) => {
            if (slotBreak?.id !== id) {
                return slotBreak;
            }

            return {
                ...slotBreak,
                startDate: breakResult,
                endDate: addMinutes(
                    breakResult,
                    parseInt(state.breakDuration.value.toString(), 10),
                ),
                focused: false,
                duration: Math.abs(
                    differenceInMinutes(new Date(slotBreak.startDate), new Date(slotBreak.endDate)),
                ),
            };
        });

        return setState((prevState) => ({
            ...prevState,
            breaks: updatedBreaks,
            touched: true,
        }));
    };

    const handleFocus = (focusedInput, id) => {
        setState((prevState) => ({
            ...prevState,
            breaks: prevState.breaks.map((b) => {
                if (b.id !== id) {
                    return b;
                }

                return {
                    ...b,
                    focused: true,
                };
            }),
        }));
    };

    const handleBlur = (blurredInput, id) => {
        setState((prevState) => ({
            ...prevState,
            breaks: prevState.breaks.map((b) => {
                if (b.id !== id) {
                    return b;
                }

                return {
                    ...b,
                    focused: false,
                };
            }),
        }));
    };

    const handleBreakDurationChange = ({ target }, id) => {
        const breakToUpdate = state.breaks.find((b) => b.id === id);

        if (!breakToUpdate) {
            return undefined;
        }

        const baseDate = new Date(breakToUpdate.startDate);
        const duration = target.value;
        const updatedBreakEnd = addMinutes(baseDate, parseInt(duration, 10));
        const updatedBreaks = state.breaks.map((slotBreak) => {
            if (slotBreak.id !== id) {
                return slotBreak;
            }

            return {
                ...slotBreak,
                duration,
                endDate: updatedBreakEnd,
            };
        });

        return setState((prevState) => ({
            ...prevState,
            breaks: updatedBreaks,
            touched: true,
        }));
    };

    // empty element we use to scroll down to the bottom
    const bottomRef = useRef<HTMLDivElement>(null);

    const addBreak = () => {
        setState((prevState) => ({
            ...prevState,
            breaks: prevState.breaks.concat({
                id: Date.now(),
                startDate: prevState.breaks[0].startDate,
                endDate: prevState.breaks[0].endDate,
                duration: prevState.breaks[0].duration,
            }),
        }));
        // need to add a timeout to make sure the element is rendered
        setTimeout(() => {
            bottomRef.current?.scrollIntoView({ behavior: "smooth" });
        }, 10);
    };

    const removeBreak = (breakId) => {
        // adds a timer to the Event Loop queue.
        // Necessary to avoid element being flushed from DOM
        // before handleClickOutside executes
        setTimeout(() => {
            setState((prevState) => ({
                ...prevState,
                breaks: prevState.breaks.filter((b) => b.id !== breakId),
                touched: true,
            }));
        }, 50);
    };

    const submitBreakUpdate = () => {
        editBreaks(state.breaks);
        ModalsService.closeModal("EDIT_BREAK");
    };

    return (
        <ModalSimple
            title={i18n.ts_edit_form_break_title()}
            validateLabel={i18n.save()}
            hideModal={() => ModalsService.closeModal("EDIT_BREAK")}
            withCloseButton={true}
            cancelLabel={i18n.cancel()}
            action={submitBreakUpdate}
            customClass='attendance-edit-break-modal'
        >
            <section
                className={`attendance-edit__content attendance-edit--break__content ${state?.isActive ? "attendance-edit__content--active" : ""} ${state?.error ? "attendance-edit__content--error" : ""} `}
            >
                <header className='attendance-edit__content__header'>
                    <p className='timesheets-table__panel__title'>{}</p>

                    <p className='timesheets-table__panel__subtitle'>
                        {i18n.ts_edit_form_description()}
                    </p>
                </header>

                <div className='attendance-edit__content__form__container'>
                    <div className='attendance-edit__content__form'>
                        <span className='sui-timepicker__label'>
                            {i18n.ts_panel_sider_breaks({ plural: "" })}
                        </span>

                        <div className='attendance-edit__content__breaks__container'>
                            {state.breaks.map(({ id, startDate, duration, focused }) => (
                                <div className='attendance-edit__content__form__container' key={id}>
                                    <div
                                        className={`attendance-edit__content__form__wrapper ${focused ? "attendance-edit__content__form__wrapper--focused" : ""} `}
                                    >
                                        <TimePicker
                                            selectedTime={startDate}
                                            onChange={(calendarTime) => setTime(calendarTime, id)}
                                            onFocus={() => handleFocus("breakStartFocused", id)}
                                            onBlur={() => handleBlur("breakStartFocused", id)}
                                            hasFocus={focused}
                                            timeIntervals={15}
                                            locale={fr}
                                            className='attendance-edit__content__form__break'
                                            id={`timepicker-break-${id}`}
                                        />

                                        <label
                                            htmlFor={`breakDuration-${id}`}
                                            className='attendance-edit__content__form__breakduration__label'
                                        >
                                            <input
                                                type='number'
                                                name={`breakDuration-${id}`}
                                                id={`breakDuration-${id}`}
                                                value={duration}
                                                className='attendance-edit__content__form__breakduration sui-timepicker__input'
                                                onFocus={() =>
                                                    handleFocus("breakDurationFocused", id)
                                                }
                                                onBlur={() =>
                                                    handleBlur("breakDurationFocused", id)
                                                }
                                                onChange={(e) => handleBreakDurationChange(e, id)}
                                                min={0}
                                            />

                                            <span className='attendance-edit__content__form__breakduration__hint'>
                                                minutes
                                            </span>
                                        </label>
                                    </div>

                                    {state.breaks.length > 1 ? (
                                        <Button
                                            variant='destructive-outlined'
                                            size='sm'
                                            onClick={() => removeBreak(id)}
                                        >
                                            <X />
                                        </Button>
                                    ) : null}
                                </div>
                            ))}
                            <div ref={bottomRef} />
                        </div>
                    </div>
                </div>

                <div className='attendance-edit__action'>
                    <Button onClick={addBreak}>
                        <Plus />
                        {i18n.ts_edit_form_breaks_add()}
                    </Button>
                </div>
            </section>
        </ModalSimple>
    );
};
