import { useRef } from "react";
import { Plus } from "lucide-react";
import { Button } from "saphir";
import { CheckboxButton, Loader, ModalsService, Searchbar } from "side-ui";

import { useFormInput } from "@lib/hooks/form";
import { i18n } from "@lib/i18n";
import escapeSpecialCharacters from "@lib/utils/escapeSpecialCharacters";

import { CustomSubtaskModal } from "./CustomSubtaskModal";

import styles from "./SubtasksForm.module.css";

type SubtasksFormProps = {
    loadedSubtasks?: any[];
    selectedSubtasks: any[];
    setSelectedSubtasks: any;
    isLoading?: boolean;
};
export const SubtasksForm = ({
    loadedSubtasks = [],
    selectedSubtasks,
    setSelectedSubtasks,
    isLoading = false,
}: SubtasksFormProps) => {
    const { formInput: searchSubtaskValue, handleChange: setSearchSubtaskValue } = useFormInput(
        "",
        () => null,
    );
    const addCustomSubtaskRef = useRef<HTMLButtonElement>(null);
    const customSubtasks = selectedSubtasks.filter((s) => s.custom === true);

    const subtasks = [...loadedSubtasks, ...customSubtasks].filter((v) => {
        const reg = new RegExp(escapeSpecialCharacters(searchSubtaskValue.value) || "", "i");
        return reg.test(v.name);
    });

    function handleChange(checkbox) {
        if (selectedSubtasks?.find(({ id }) => id === checkbox?.id)) {
            setSelectedSubtasks((prevState) => prevState.filter(({ id }) => id !== checkbox.id));
            return;
        }
        setSelectedSubtasks((prevState) => prevState.concat(checkbox));
        // delay to wait until modal is closed before scrolling to the bottom of container after a new custom task has been added
        if (checkbox.id.startsWith("custom_subtask")) {
            setTimeout(() => {
                addCustomSubtaskRef.current?.scrollIntoView({ behavior: "smooth" });
            }, 100);
        }
    }

    return (
        <div className={styles.subtasks}>
            <Searchbar
                id='searchSubtask'
                value={searchSubtaskValue.value}
                onChange={(event) => setSearchSubtaskValue(event.target.value)}
                placeholder={i18n.search()}
                iconPosition='right'
                clearOnFocus={false}
            />
            {isLoading ? (
                <Loader />
            ) : (
                subtasks.map(({ id, name }) => (
                    <CheckboxButton
                        key={id}
                        label={name}
                        id={id}
                        value={id}
                        onChange={() => handleChange({ id, name })}
                        checked={Boolean(selectedSubtasks?.find((s) => s.id === id))}
                        name={name}
                        secondaryStyle={true}
                    />
                ))
            )}
            <Button
                variant='primary-outlined'
                className={styles.button}
                ref={addCustomSubtaskRef}
                onClick={() => {
                    setSearchSubtaskValue("");
                    ModalsService.openModal({
                        id: `ADD_CUSTOM_SUBTASK`,
                        content: (
                            <CustomSubtaskModal
                                submitSubtask={handleChange}
                                searchSubtaskValue={searchSubtaskValue?.value}
                            />
                        ),
                    });
                }}
            >
                <Plus />
                {searchSubtaskValue.value
                    ? i18n.job_descriptions_creation_job_subtasks_custom_add_button_input({
                          searchInput: searchSubtaskValue.value,
                      })
                    : i18n.job_descriptions_creation_job_subtasks_custom_add_button()}
            </Button>
        </div>
    );
};
