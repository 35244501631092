import React, { forwardRef, useEffect, useState } from "react";
import { Eye, EyeClosed } from "lucide-react";
import { SubmitHandler, useForm, UseFormRegisterReturn, UseFormSetFocus } from "react-hook-form";
import { toast } from "saphir";
import { Button, Modal, ModalContent, ModalFooter, ModalHeader, TextField, Tip } from "sui";
import { z } from "zod";

import { zodResolver } from "@hookform/resolvers/zod";
import { patchProfile, PatchProfileReq } from "@lib/api";
import { i18n, i18nDK } from "@lib/i18n";
import { User } from "@store/types/user";
import { useMutation } from "@tanstack/react-query";

const getSchema = () =>
    z.object({
        oldPassword: z.string().min(1, { message: i18n.error_field_is_required() }),
        password: z
            .string()
            .regex(/^(?!.*\s)(?=.*[!@#$%^&*(),.?":{}|<>]).+$/, {
                message: i18n.personal_informations_error_invalid_password_format(),
            })
            .min(1, { message: i18n.error_field_is_required() })
            .min(9, {
                message: i18nDK("personal_informations_error_incorrect_password_length"),
            }),
        passwordConfirmation: z
            .string()
            .regex(/^(?!.*\s)(?=.*[!@#$%^&*(),.?":{}|<>]).+$/)
            .min(9),
    });

type Inputs = z.infer<ReturnType<typeof getSchema>>;

export const PasswordModal = forwardRef<HTMLDialogElement, { user: User }>(function (
    { user },
    ref,
) {
    const [passwordVisibility, setPasswordVisibility] = useState({
        oldPassword: false,
        password: false,
        passwordConfirmation: false,
    });
    const [showPaswordConfirmationError, setShowPaswordConfirmationError] = useState(false);
    const [isOldPasswordCorrect, setIsOldPasswordCorrect] = useState(true);

    const schema = getSchema();

    const { mutate: changePassword, isPending } = useMutation({
        mutationFn: (
            params: Pick<PatchProfileReq, "oldPassword" | "passwordConfirmation" | "password">,
        ) =>
            patchProfile({
                ...params,
                email: user?.email,
                phone: user?.phoneNumber,
                firstName: user?.firstName,
                lastName: user?.lastName,
            }),
        onSuccess: () => {
            toast.success(i18n.personal_informations_password_success());
            onClose();
        },
        onError: () => {
            setIsOldPasswordCorrect(false);
        },
    });

    const onSubmit: SubmitHandler<Inputs> = async (data) => {
        changePassword(data);
    };
    const {
        register,
        handleSubmit,
        formState: { errors, isValid },
        setFocus,
        reset,
        getFieldState,
        watch,
    } = useForm<Inputs>({
        mode: "onTouched",
        resolver: zodResolver(schema),
        defaultValues: {
            oldPassword: "",
            password: "",
            passwordConfirmation: "",
        },
    });

    const arePasswordsDirty =
        getFieldState("password").isDirty && getFieldState("passwordConfirmation").isDirty;
    const arePasswordsMatching = watch("password") === watch("passwordConfirmation");

    useEffect(() => {
        setShowPaswordConfirmationError(false);
    }, [watch("passwordConfirmation")]);

    useEffect(() => {
        if (watch("oldPassword").length > 0 && !isOldPasswordCorrect) {
            setIsOldPasswordCorrect(true);
        }
    }, [watch("oldPassword")]);

    function onClose() {
        reset();
        if (ref && "current" in ref) {
            ref.current?.close();
        }
    }
    return (
        <Modal ref={ref} onSubmit={handleSubmit(onSubmit)} onClose={onClose}>
            <ModalHeader title={i18n.personal_informations_password_modal_title()} />
            <ModalContent className='flex flex-col gap-4'>
                <PasswordField
                    rhfField={register("oldPassword")}
                    label={i18n.personal_informations_password_modal_old_field()}
                    state={passwordVisibility}
                    setState={setPasswordVisibility}
                    setFocus={setFocus}
                    error={
                        errors.oldPassword?.message
                            ? errors.oldPassword?.message
                            : !isOldPasswordCorrect
                              ? i18n.personal_informations_error_incorrect_password()
                              : undefined
                    }
                />
                <Tip>{i18n.personal_informations_password_tip()}</Tip>
                <PasswordField
                    rhfField={register("password")}
                    label={i18n.personal_informations_password_modal_new_field()}
                    state={passwordVisibility}
                    setState={setPasswordVisibility}
                    setFocus={setFocus}
                    error={errors.password?.message}
                />
                <PasswordField
                    rhfField={register("passwordConfirmation")}
                    label={i18n.personal_informations_password_modal_confirm_field()}
                    state={passwordVisibility}
                    setState={setPasswordVisibility}
                    setFocus={setFocus}
                    error={
                        arePasswordsDirty && !arePasswordsMatching && showPaswordConfirmationError
                            ? i18n.error_confirmation_not_equal_password()
                            : undefined
                    }
                    onBlur={() => {
                        if (watch("password") !== watch("passwordConfirmation")) {
                            setShowPaswordConfirmationError(true);
                        }
                    }}
                />
            </ModalContent>
            <ModalFooter
                mainButton={
                    <>
                        <Button
                            intention='secondary'
                            shape='outlined'
                            onClick={onClose}
                            type='button'
                        >
                            {i18n.cancel()}
                        </Button>
                        <Button disabled={!isValid || !arePasswordsMatching} loading={isPending}>
                            {i18n.personal_informations_password_modal_save_button()}
                        </Button>
                    </>
                }
            />
        </Modal>
    );
});

type PasswordState = {
    oldPassword: boolean;
    password: boolean;
    passwordConfirmation: boolean;
};

function PasswordField({
    rhfField,
    label,
    state,
    setState,
    setFocus,
    error,
    onBlur,
}: {
    rhfField: UseFormRegisterReturn<keyof Inputs>;
    label: string;
    state: PasswordState;
    setState: React.Dispatch<React.SetStateAction<PasswordState>>;
    setFocus: UseFormSetFocus<Inputs>;
    error?: string;
    onBlur?: () => void;
}) {
    return (
        <TextField
            label={label}
            size='small'
            type={state[rhfField.name] ? "text" : "password"}
            icon={
                state[rhfField.name] ? (
                    <EyeClosed
                        className='h-4 w-4 text-gray-300'
                        onClick={() => {
                            setFocus(rhfField.name);
                            setState((prev) => ({
                                ...prev,
                                [rhfField.name]: false,
                            }));
                        }}
                    />
                ) : (
                    <Eye
                        className='h-4 w-4 text-gray-300'
                        onClick={() => {
                            setFocus(rhfField.name);
                            setState((prev) => ({
                                ...prev,
                                [rhfField.name]: true,
                            }));
                        }}
                    />
                )
            }
            {...rhfField}
            onBlur={(e) => {
                rhfField.onBlur(e);
                if (onBlur) onBlur();
            }}
            error={error}
            placeholder={i18n.personal_informations_new_password_placeholder()}
        />
    );
}
