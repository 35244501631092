import { Pencil } from "lucide-react";
import { Button } from "saphir";

const EditCell = ({ setDisplayPanel }) => (
    <span className='timesheets-table__cell timesheets-table__cell--action'>
        <Button onClick={() => setDisplayPanel(true)} variant='neutral' size='sm'>
            <Pencil />
        </Button>
    </span>
);

export default EditCell;
