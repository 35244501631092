import { useEffect, useRef, useState } from "react";
import clsx from "clsx";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { range } from "lodash";
import { User } from "lucide-react";
import {
    Blank,
    HoursWithSubtitle,
    Loader,
    Sheet,
    Tag as LegacyTag,
    UserWithTag,
    Weekbar,
} from "side-ui";
import { Tag } from "sui";

import { i18n } from "@lib/i18n";
import { Link, useNavigate } from "@tanstack/react-router";
import { useFlag } from "@unleash/proxy-client-react";

import trackEvent from "../../../lib/trackers";
import { getWeekdays, isSameDay } from "../../../lib/utils/dates";
import getWeekStart from "../../../lib/utils/getWeekStart";
import { convertDataToCSV, flattenCSVData, isArray } from "../../../lib/utils/planning";
import SiderProfilePanel from "../../Siders";
import { planningRoute } from "../route";

import "./Planning.scss";

const SiderProfileCardWrapper = (props) => {
    const {
        sider,
        selectedSider,
        getSider,
        resetSelectedSider,
        isSiderProfilePanelVisible,
        setShowSiderProfilePanel,
        taskCategory,
        weeklyVolume,
    } = props;

    return (
        <div
            onMouseEnter={() => {
                if (
                    (selectedSider && selectedSider.id !== sider.id) ||
                    selectedSider === undefined
                ) {
                    getSider({ siderId: sider.id, withOngoing: true });
                }
            }}
            onMouseLeave={() => {
                resetSelectedSider();
            }}
            onClick={() => {
                if (!isSiderProfilePanelVisible) {
                    getSider({
                        siderId: sider.id,
                        withOngoing: true,
                        withTasks: true,
                        withExperiences: false,
                        withEducations: false,
                    });
                    setShowSiderProfilePanel(true, taskCategory);
                }
            }}
        >
            <UserWithTag
                pictureUrl={sider.pictureUrl}
                placeholderIcon='User'
                userName={`${sider.firstName} ${sider.lastName}`}
                tagLabel={
                    weeklyVolume
                        ? `${weeklyVolume}h`
                        : sider.workedHoursInWeek
                          ? `${Math.round(sider.workedHoursInWeek * 100) / 100}h`
                          : ""
                }
                tagColor='grey'
                displayLargerOnHover={false}
                tagTooltipLabel={i18n.planning_sider_worked_hours_week({
                    siderName: sider.firstName,
                    plural: sider.workedHoursInWeek > 1 ? `s` : ``,
                    workedHoursInWeek: Math.round(sider.workedHoursInWeek * 100) / 100,
                })}
            />
        </div>
    );
};

export default function Plannings({
    planning,
    selectedWeek,
    selectWeek,
    getSider,
    selectedSider,
    resetSelectedSider,
    setShowSiderProfilePanel,
    isSiderProfilePanelVisible,
    isSiderLoading,
}) {
    const isFlexiblePlanningEnabled = useFlag("flexible-planning");
    const planningRef = useRef(null);
    const [isMobile, setIsMobile] = useState(null);

    const { week, year } = planningRoute.useSearch();
    const navigate = useNavigate();

    useEffect(() => {
        setIsMobile(window.innerWidth < 768 ? true : false);
    }, []);

    function setWeek() {
        const weekStart = getWeekStart(week, year);
        selectWeek(weekStart.isoWeek(), weekStart.isoWeekYear());
    }

    // Update when url params have changed
    useEffect(() => {
        setWeek();
    }, [week, year]);

    function changeWeek(to) {
        return () => {
            let weekStart;

            if (to === "current") weekStart = getWeekStart();
            else {
                weekStart = getWeekStart(
                    parseInt(selectedWeek?.week, 10) + (to === "next" ? 1 : -1),
                    parseInt(selectedWeek?.year, 10),
                );
            }
            navigate({
                to: `/planning`,
                search: {
                    week: weekStart.isoWeek(),
                    year: weekStart.isoWeekYear(),
                },
            });
        };
    }

    function renderUserPlaceholder(preselection, id, weeklyVolume) {
        return (
            <div className='tasks--planning__user-placeholder justify-between'>
                <div
                    className={clsx(
                        "tasks--planning__user-placeholder--left overflow-hidden whitespace-nowrap",
                        !!weeklyVolume && "ml-1",
                    )}
                >
                    <div className='overflow-hidden overflow-ellipsis font-medium text-gray-900'>
                        {i18n.planning_sheet_empty_user()}
                    </div>
                    {preselection ? (
                        <Link
                            className='table-row__link-button'
                            to='/preselection/$taskId'
                            params={{ taskId: id }}
                        >
                            {i18n.ongoing_section_display_preselection()}
                        </Link>
                    ) : null}
                </div>
                <div className='tasks--planning__user-placeholder--right'>
                    {!!weeklyVolume && <LegacyTag label={`${weeklyVolume}h`} color='grey' />}
                </div>
            </div>
        );
    }

    function renderSheets() {
        const getBreakDuration = (breakDuration) => {
            if (!breakDuration) return null;
            if (breakDuration < 60) return `(-${breakDuration}min)`;
            return `(-${Math.round((breakDuration / 60) * 100) / 100}h)`;
        };

        if (!planning || planning.isLoading) {
            return (
                <div className='loader--centered'>
                    <Loader />
                </div>
            );
        }

        let rowsArray = [];
        // restructure data for mobile view (days > tasks for that day > siders for that task > slots for that day)
        if (isMobile === true) {
            rowsArray = planning?.tasks?.reduce((acc, task) => {
                task.siders.forEach((sider) =>
                    sider.slots.forEach((slot) => {
                        const day = slot.startDate.isoWeekday();
                        const slots = sider.slots;
                        if (!acc[day]) acc[day] = { date: slot.startDate, tasks: [] };
                        if (!acc[day]["tasks"][task.id])
                            acc[day]["tasks"][task.id] = { ...task, siders: [] };
                        if (!acc[day]["tasks"][task.id]["siders"].includes(sider))
                            acc[day]["tasks"][task.id]["siders"].push({
                                ...sider,
                                slots: slots.filter((slot) => slot.startDate.isoWeekday() == day),
                            });
                    }),
                );
                return acc;
            }, {});
            rowsArray = Object.values(rowsArray);
        }

        return (
            <>
                <SiderProfilePanel />
                {!planning.tasks?.length && (
                    <div className='tasks--planning--empty'>
                        <Blank
                            title={i18n.empty_placeholder_planning_title()}
                            subtitle={i18n.empty_placeholder_planning_subtitle()}
                            icon='Calendar'
                            iconColor='blue'
                            buttonLabel={i18n.empty_placeholder_planning_cta()}
                            buttonColor='blue'
                            buttonAction={() => navigate({ to: `/job-descriptions` })}
                            buttonLeftIcon='Plus'
                        />
                    </div>
                )}
                {/* 📱 MOBILE VIEW */}
                {isMobile === true &&
                    rowsArray.map((row) => {
                        let date = row.date.format("dddd D");
                        date = date[0].toUpperCase() + date.slice(1).toLowerCase();
                        const tasks = Object.values(row.tasks);
                        return (
                            // day
                            <div key={date} className='task--planning__mobile-card'>
                                <h3>{date}</h3>
                                <div className='task--planning__mobile-card__task-wrapper'>
                                    {tasks?.map((task) => {
                                        // task
                                        const siders = Object.values(task.siders);
                                        return (
                                            <div
                                                key={task.id}
                                                className='task--planning__mobile-card__task'
                                            >
                                                <h4>{task.name}</h4>
                                                {siders?.map((sider) => {
                                                    const workDuration =
                                                        isFlexiblePlanningEnabled &&
                                                        task.flexiblePlanning
                                                            ? task.flexiblePlanning.weeklyVolume
                                                            : sider.slots.reduce(
                                                                  (acc, slot) =>
                                                                      acc +
                                                                      slot.endDate.diff(
                                                                          slot.startDate,
                                                                          "hours",
                                                                          true,
                                                                      ),
                                                                  0,
                                                              );
                                                    // siders
                                                    return (
                                                        <div
                                                            key={sider.id}
                                                            className='task--planning__mobile-card__sider-row'
                                                        >
                                                            <div className='task--planning__mobile-card__sider__container'>
                                                                {sider.pictureUrl &&
                                                                sider.pictureUrl.startsWith(
                                                                    "https://",
                                                                ) ? (
                                                                    <img
                                                                        className='task--planning__mobile-card__sider__image'
                                                                        src={sider.pictureUrl}
                                                                        alt={`${sider.firstName} ${sider.lastName}`}
                                                                        loading='lazy'
                                                                    />
                                                                ) : (
                                                                    <div className='sui-userwithtag__placeholder'>
                                                                        <User className='h-4 w-4' />
                                                                    </div>
                                                                )}
                                                                <div>
                                                                    <p className='task--planning__mobile-card__sider__name'>
                                                                        {sider.firstName}{" "}
                                                                        {sider.lastName}
                                                                    </p>
                                                                    <p className='task--planning__mobile-card__sider__duration'>
                                                                        {/* check if workDuration is int, else limit to 1 decimal places */}
                                                                        {i18n.locale == "fr"
                                                                            ? "Durée: "
                                                                            : "Duration: "}
                                                                        {workDuration &&
                                                                            `${
                                                                                workDuration -
                                                                                    Math.round(
                                                                                        workDuration,
                                                                                    ) ==
                                                                                0
                                                                                    ? workDuration
                                                                                    : workDuration.toFixed(
                                                                                          1,
                                                                                      )
                                                                            }h`}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            {sider.slots?.map((slot) => {
                                                                // slots
                                                                return (
                                                                    <HoursWithSubtitle
                                                                        key={slot.id}
                                                                        startTime={slot.startDate.format(
                                                                            "HH:mm",
                                                                        )}
                                                                        endTime={slot.endDate.format(
                                                                            "HH:mm",
                                                                        )}
                                                                        subtitle={getBreakDuration(
                                                                            slot.breakDuration,
                                                                        )}
                                                                    />
                                                                );
                                                            })}
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        );
                    })}
                {/* 🖥 DESKTOP VIEW */}
                {isMobile === false &&
                    planning.tasks?.map((task) => {
                        if (!task.siders) return null;
                        const rowsArray = task.siders
                            .sort((a, b) => {
                                // Put empty user to the end
                                if (a.id && b.id) return a.id.localeCompare(b.id);
                                if (!a.id && !b.id) return 0;
                                if (a.id) return -1;
                                return 1;
                            })
                            .map((sider) => {
                                const index =
                                    sider.id && sider.firstName && sider.lastName ? (
                                        <SiderProfileCardWrapper
                                            key={sider.id}
                                            id={sider.id}
                                            sider={sider}
                                            selectedSider={selectedSider}
                                            getSider={getSider}
                                            resetSelectedSider={resetSelectedSider}
                                            setShowSiderProfilePanel={setShowSiderProfilePanel}
                                            isSiderProfilePanelVisible={isSiderProfilePanelVisible}
                                            taskCategory={task.category}
                                            isLoading={isSiderLoading}
                                            weeklyVolume={
                                                isFlexiblePlanningEnabled
                                                    ? task.flexiblePlanning?.weeklyVolume
                                                    : undefined
                                            }
                                        />
                                    ) : (
                                        renderUserPlaceholder(
                                            task.preSelection,
                                            task.id,
                                            isFlexiblePlanningEnabled
                                                ? task.flexiblePlanning?.weeklyVolume
                                                : undefined,
                                        )
                                    );
                                const cells = range(7).map((weekday) =>
                                    sider.slots
                                        .filter(
                                            (slot) => slot.startDate.isoWeekday() === weekday + 1,
                                        )
                                        .sort((a, b) =>
                                            a.startDate.toDate() < b.startDate.toDate() ? -1 : 1,
                                        )
                                        .map((slot) => (
                                            <HoursWithSubtitle
                                                key={slot.id}
                                                startTime={slot.startDate.format("H:mm")}
                                                endTime={slot.endDate.format("H:mm")}
                                                subtitle={getBreakDuration(slot.breakDuration)}
                                            />
                                        )),
                                );
                                return {
                                    indexComponent: index,
                                    cellsArray: cells,
                                    disabled: !sider.id,
                                };
                            });
                        const isInsider = localStorage.getItem(`side_team_logasId`);
                        const SheetInsiderProps = !isInsider
                            ? {}
                            : {
                                  taskId: task?.id,
                                  copyTaskId: true,
                              };

                        return (
                            <div className='tasks--planning__sheet' key={task.id}>
                                <Sheet
                                    title={
                                        <div>
                                            {task.name}{" "}
                                            {isFlexiblePlanningEnabled && task.flexiblePlanning && (
                                                <Tag color='pink' className='ml-4'>
                                                    {i18n.planning_type_flexible_title()}
                                                </Tag>
                                            )}
                                        </div>
                                    }
                                    slotEnd={
                                        <Link
                                            to='/tasks/view/$taskId'
                                            params={{ taskId: task.id }}
                                            search={{ origin: "planning" }}
                                            className='text-blue-500 underline typography-label-m-medium'
                                        >
                                            {i18n.planning_sheet_view_task()}
                                        </Link>
                                    }
                                    rows={rowsArray}
                                    {...SheetInsiderProps} // eslint-disable-line react/jsx-props-no-spreading
                                />
                            </div>
                        );
                    })}
            </>
        );
    }

    const weekStart = getWeekStart(
        parseInt(selectedWeek?.week, 10),
        parseInt(selectedWeek?.year, 10),
    );

    function convertToPDF(planningImage, fileName) {
        return new Promise((resolve, reject) => {
            try {
                // landscape, millimeter unit
                const pdf = new jsPDF("l", "mm"); // eslint-disable-line new-cap
                const pageHeight = pdf.internal.pageSize.getHeight();
                const pageWidth = pdf.internal.pageSize.getWidth();
                const imageHeight = (planningImage.height * 25.4) / 96; // pixels to millimeters
                const pageCount = Math.ceil(imageHeight / pageHeight);

                if (pageCount > 0) {
                    let j = 0;

                    while (j !== pageCount) {
                        pdf.addPage("l", "mm");
                        pdf.addImage(planningImage, "JPEG", 2, -(j * pageHeight), pageWidth - 4, 0);

                        j += 1;
                    }
                }
                // delete first page which is blank
                pdf.deletePage(1);
                pdf.save(fileName);

                resolve();
            } catch (error) {
                reject(error);
            }
        });
    }

    async function printPlanning(selectedFormat) {
        const fileName = `side-planning__week${selectedWeek?.week}-${selectedWeek?.year}`;

        try {
            trackEvent({
                name: `planning - user downloads planning`,
                params: {
                    organisationId: localStorage.getItem(`side_team_activeOrganisationId`),
                    format: selectedFormat,
                },
            });
        } catch (e) {
            console.error(e); // eslint-disable-line
        }

        if (selectedFormat === "pdf") {
            const initialPlanningHeight = planningRef.current?.offsetHeight;
            const initialWidth = "20%";
            const expandedWidth = "30%";
            const weekBar = document.querySelector(".sui-weekbar__index");
            const indexCells = document.querySelectorAll(".sui-sheetrow__index");

            // making sure that all styles update prior to canvas generation
            // are done before creating the PDF file
            await (() =>
                new Promise((resolve) => {
                    // expand height to get the full picture
                    planningRef.current.style.height = "auto";

                    // make index cells sider name readable (not truncated)

                    weekBar.style.width = expandedWidth;
                    if (indexCells) {
                        indexCells.forEach((indexCell) => (indexCell.style.width = expandedWidth)); // eslint-disable-line
                    }

                    resolve();
                }))();

            // convert the styled markup into a canvas image
            const planningImage = await html2canvas(document.getElementById("tasks--planning"));
            // and convert the image to PDF
            await convertToPDF(planningImage, fileName);

            // setting height back to whatever value it was before generating the PDF file
            planningRef.current.style.height = `${initialPlanningHeight}px`;

            // back to initial width style
            indexCells.forEach((indexCell) => (indexCell.style.width = initialWidth)); // eslint-disable-line

            weekBar.style.width = initialWidth;
            // setting height back to whatever value it was before generating the PDF file
            planningRef.current.style.height = `${initialPlanningHeight}px`;

            return;
        }

        if (selectedFormat === "csv") {
            const locale = localStorage.getItem("side_team_locale") || navigator.language;
            const weekDays = getWeekdays(weekStart);

            // an array whose 0 index holds weekdays range array and a
            // matrix containing seven empty arrays representing weekDays columns
            // i.e => [[], [], [], [], [], [], []]
            const planningData = [weekDays];

            const multiDimensionsPlanningData = planning.tasks.reduce(
                (aggregate, task, taskIndex) => {
                    // adding empty rows between tasks
                    aggregate.push([[], [], [], [], [], [], []]);
                    aggregate.push([[], [], [], [], [], [], []]);

                    // looping through siders
                    task.siders.forEach(({ firstName, lastName, slots }) => {
                        // sort slots by date ASC
                        const sortedSlots = slots
                            .sort(
                                (prevSlot, nextSlot) =>
                                    prevSlot.startDate.toDate() - nextSlot.startDate.toDate(),
                            )
                            .map(({ startDate }) => startDate.toDate());
                        const userWithSortedSlots = {
                            siderName: `${firstName} ${lastName}`,
                            sortedSlots,
                        };

                        // looping through week days to find a matching slot
                        aggregate[0].forEach((weekDay, dayIndex) => {
                            const matchingSlot = userWithSortedSlots.sortedSlots.find((startDate) =>
                                isSameDay(startDate, weekDay),
                            );

                            if (matchingSlot) {
                                if (isArray(aggregate[taskIndex + 1])) {
                                    // empty array at the correct index, let's push to it
                                    if (aggregate[aggregate.length - 1][dayIndex].length === 0) {
                                        aggregate[aggregate.length - 1][dayIndex].push(
                                            userWithSortedSlots.siderName,
                                        );
                                        // index is already taken
                                    } else if (aggregate[taskIndex + 1][dayIndex].length > 0) {
                                        // a sider is already at this index, let's add a row
                                        aggregate.push([[], [], [], [], [], [], []]);
                                        // and push to it at the correct day spot
                                        aggregate[aggregate.length - 1][dayIndex].push(
                                            userWithSortedSlots.siderName,
                                        );
                                        // empty index so let's fill it with the current sider
                                    } else {
                                        aggregate[taskIndex + 1][dayIndex].push(
                                            userWithSortedSlots.siderName,
                                        );
                                    }
                                } else {
                                    // if not an array let's create another matrix
                                    aggregate.push([[], [], [], [], [], [], []]);
                                    aggregate[taskIndex + 1][dayIndex].push(
                                        userWithSortedSlots.siderName,
                                    );
                                }
                            }
                        });
                    });

                    return aggregate;
                },
                planningData,
            );

            // flatten the planning data and convert the data to csv, adding a custom filename
            convertDataToCSV(flattenCSVData(multiDimensionsPlanningData, locale), fileName);
        }
    }

    const printOptions = [
        {
            action: () => printPlanning(`pdf`),
            label: i18n.planning_download_as({ downloadFormat: "PDF" }),
        },
        {
            action: () => printPlanning(`csv`),
            label: i18n.planning_download_as({ downloadFormat: "CSV" }),
        },
    ];

    return (
        <div className='tasks--planning' id='tasks--planning'>
            <div className='tasks--planning__weekbar'>
                <Weekbar
                    weekDate={weekStart}
                    backToButtonLabel={i18n.planning_select_current_week()}
                    onChange={changeWeek}
                    headerActions={printOptions}
                />
            </div>
            <div className='tasks--planning__sheets' ref={planningRef}>
                {renderSheets()}
            </div>
        </div>
    );
}
