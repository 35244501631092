import { useEffect } from "react";
import { Info } from "lucide-react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { toast } from "saphir";
import { Loader } from "side-ui";
import { Label, TextField, Tooltip, TooltipContent, TooltipTrigger } from "sui";
import { z } from "zod";

import { queryClient } from "@App";
import { zodResolver } from "@hookform/resolvers/zod";
import { updateCompanyLegalInfo } from "@lib/api";
import { i18n } from "@lib/i18n";
import { queries } from "@lib/queries";
import { normalizeAPE, normalizeSiret } from "@lib/utils/inputsNormalizers";
import { useMutation, useSuspenseQuery } from "@tanstack/react-query";
import { useBlocker } from "@tanstack/react-router";

import SaveBar from "./partials/SaveBar";
import { showUnsavedChangesModal } from "./partials/UnsavedChangesModal";

import "./LegalInformation.scss";

export function LegalInformation() {
    const {
        data: { name, businessName, siret, apeCode },
        isLoading,
    } = useSuspenseQuery(
        queries.company.legalInfo(localStorage.getItem(`side_team_activeOrganisationId`) as string),
    );
    const { mutate, isPending } = useMutation({
        mutationFn: updateCompanyLegalInfo,
        onSuccess: (_, { data }) => {
            queryClient.invalidateQueries(
                queries.company.legalInfo(
                    localStorage.getItem(`side_team_activeOrganisationId`) as string,
                ),
            );
            resetForm(data);
            toast.success(i18n.settings_submit_success());
        },
    });

    const schema = z.object({
        name: z.string({ message: i18n.error_field_is_required() }),
        businessName: z.string({ message: i18n.error_field_is_required() }),
        siret: z
            .string({ message: i18n.error_field_is_required() })
            .regex(/^((\d{3}) ){3}(\d{5})/, {
                message: i18n.settings_legal_error_siret_format(),
            })
            .max(17, { message: i18n.settings_legal_error_siret_format() }),
        apeCode: z
            .string({ message: i18n.error_field_is_required() })
            .regex(/^[0-9]+ [a-zA-Z]/, { message: i18n.settings_legal_error_ape_format() })
            .max(6, { message: i18n.settings_legal_error_siret_format() }),
    });

    type Inputs = z.infer<typeof schema>;

    const {
        watch,
        register,
        handleSubmit,
        formState: { isDirty, errors, isValid },
        reset: resetForm,
        control,
    } = useForm<Inputs>({
        mode: "onBlur",
        resolver: zodResolver(schema),
        defaultValues: {
            name: name || "",
            businessName: businessName || "",
            siret: normalizeSiret(siret) || "",
            apeCode: normalizeAPE(apeCode) || "",
        },
    });

    const onSubmit: SubmitHandler<Inputs> = async (data) => {
        mutate({
            organisationId: localStorage.getItem(`side_team_activeOrganisationId`) as string,
            data,
        });
    };

    const { proceed, status } = useBlocker({
        condition: isDirty,
    });

    useEffect(() => {
        if (status === "blocked") {
            showUnsavedChangesModal(() => isValid && onSubmit(watch()), proceed);
        }
    }, [status]);

    if (isLoading) {
        return <Loader />;
    }

    return (
        <>
            <div className='settings-legal-information'>
                <h2 className='settings__heading'>{i18n.settings_legal_title()}</h2>
                <p className='settings__teasing'>{i18n.settings_legal_subtitle()}</p>
                <form onSubmit={handleSubmit(onSubmit)} className='flex flex-col gap-4'>
                    <TextField
                        {...register("name")}
                        label={i18n.settings_legal_name()}
                        placeholder={i18n.settings_legal_name_placeholder()}
                        size='small'
                        error={errors.name?.message}
                    />
                    <div>
                        <Label htmlFor='businessName' className='mb-2'>
                            <div className='flex gap-1'>
                                {i18n.settings_legal_business_name()}
                                <span className='flex font-normal text-gray-400'>
                                    {i18n.settings_legal_business_name_detail()}
                                    <Tooltip>
                                        <TooltipTrigger>
                                            <Info className='h-4 w-4 text-gray-300' />
                                        </TooltipTrigger>
                                        <TooltipContent>
                                            {i18n.settings_legal_business_name_tip()}
                                        </TooltipContent>
                                    </Tooltip>
                                </span>
                            </div>
                        </Label>
                        <TextField
                            id='businessName'
                            {...register("businessName")}
                            aria-label={i18n.settings_legal_business_name()}
                            placeholder={i18n.settings_legal_business_name_placeholder()}
                            size='small'
                            error={errors.businessName?.message}
                        />
                    </div>

                    <div>
                        <Label htmlFor='siret' className='mb-2'>
                            <div className='flex gap-1'>
                                {i18n.settings_legal_siret()}
                                <span className='text-gray-400'>
                                    <Tooltip>
                                        <TooltipTrigger>
                                            <Info className='h-4 w-4 text-gray-300' />
                                        </TooltipTrigger>
                                        <TooltipContent>
                                            {i18n.settings_legal_siret_tip()}
                                        </TooltipContent>
                                    </Tooltip>
                                </span>
                            </div>
                        </Label>
                        <Controller
                            control={control}
                            name='siret'
                            render={({ field }) => (
                                <TextField
                                    id='siret'
                                    aria-label={i18n.settings_legal_siret()}
                                    placeholder={i18n.settings_legal_siret_placeholder()}
                                    {...field}
                                    onChange={(e) => field.onChange(normalizeSiret(e.target.value))}
                                    size='small'
                                    error={errors.siret?.message}
                                />
                            )}
                        />
                    </div>

                    <div>
                        <Label htmlFor='apeCode' className='mb-2'>
                            <div className='flex gap-1'>
                                {i18n.settings_legal_ape()}
                                <span className='text-gray-400'>
                                    <Tooltip>
                                        <TooltipTrigger>
                                            <Info className='h-4 w-4 text-gray-300' />
                                        </TooltipTrigger>
                                        <TooltipContent>
                                            {i18n.settings_legal_ape_tip()}
                                        </TooltipContent>
                                    </Tooltip>
                                </span>
                            </div>
                        </Label>
                        <Controller
                            control={control}
                            name='apeCode'
                            render={({ field }) => (
                                <TextField
                                    id='apeCode'
                                    aria-label={i18n.settings_legal_ape()}
                                    placeholder={i18n.settings_legal_ape_placeholder()}
                                    {...field}
                                    onChange={(e) => field.onChange(normalizeAPE(e.target.value))}
                                    size='small'
                                    error={errors.apeCode?.message}
                                />
                            )}
                        />
                    </div>
                    {isDirty && (
                        <SaveBar
                            loading={isPending}
                            resetAction={() => {
                                resetForm();
                            }}
                            saveAction={null}
                            canSave={!isPending}
                        />
                    )}
                </form>
            </div>
        </>
    );
}
