import { forwardRef, useEffect, useMemo, useRef, useState } from "react";
import clsx from "clsx";
import {
    addDays,
    addYears,
    differenceInMinutes,
    getDate,
    getDay,
    getDaysInMonth,
    getHours,
    getMinutes,
    isSameDay,
    isSameHour,
    set,
    startOfMonth,
    subDays,
} from "date-fns";
import { useAtom } from "jotai";
import { Trash2 } from "lucide-react";
import {
    Button,
    ChoiceBox,
    fonts,
    Label,
    Modal,
    ModalContent,
    ModalFooter,
    ModalHeader,
    Radio,
    Select,
    TextField,
    Toggle,
    useConfirmDialog,
} from "sui";

import { AddShiftParams, Task, UpdateShiftParams } from "@lib/api";
import { useFormInput } from "@lib/hooks";
import { i18n, i18nDK } from "@lib/i18n";
import { queries } from "@lib/queries";
import { getLocale } from "@lib/utils/getLocale";
import { taskFormRoute } from "@routes/TaskPosting/route";
import { useQuery, useQueryClient } from "@tanstack/react-query";

import { CheckDayBox } from "../CheckDayBox/CheckDayBox";
import { ShiftDatePicker } from "../ShiftDatePicker/ShiftDatePicker";
import { modalShiftsStateAtom } from "../ShiftsStep";
import { TimeField } from "../TimeField";
import { RecurrenceOptions, useShiftAPI } from "../useShiftAPI";

import styles from "./ShiftModal.module.css";

export const ShiftModal = forwardRef<
    HTMLDialogElement,
    { motiveReason?: NonNullable<Task["motive"]>["reason"] }
>(function ShiftModal({ motiveReason }, ref) {
    const { data: user } = useQuery(queries.user.detail());
    const { taskId } = taskFormRoute.useParams();
    const confirm = useConfirmDialog();
    const [{ mode, shift }, setModalShiftsStateAtom] = useAtom(modalShiftsStateAtom);
    const { data: shiftRecurrences, isLoading: isShiftRecurrencesLoading } = useQuery({
        ...queries.shiftRecurrences.detail(shift?.recurrenceId ?? ""),
        enabled: !!shift?.recurrenceId && mode !== "creation",
    });

    const recurrenceRef = useRef<HTMLDivElement>(null);

    // Basic Fields
    const [bulkSelection, setBulkSelection] = useState<RecurrenceOptions>();
    const { formInput: startDate, handleChange: setStartDate } = useFormInput(
        shift?.startDate ?? set(addDays(new Date(), 0), { hours: 8, minutes: 0, seconds: 0 }),
    );
    const { formInput: startDateTime, handleChange: setStartDateTime } = useFormInput(
        shift?.startDate ?? set(addDays(new Date(), 1), { hours: 8, minutes: 0, seconds: 0 }),
    );
    const { formInput: endDateTime, handleChange: setEndDateTime } = useFormInput(
        shift?.endDate ?? set(addDays(new Date(), 1), { hours: 17, minutes: 0, seconds: 0 }),
    );
    const { formInput: breakDuration, handleChange: setBreakDuration } = useFormInput(
        shift?.breakDuration ?? 0,
    );
    const { formInput: slots, handleChange: setSlots } = useFormInput(shift?.slots ?? 1, (value) =>
        value < 1 || value > 50 ? i18n.task_shifts_modal_slots_error() : null,
    );
    // Recurrence Fields
    const intervalRecurrenceOptions = useMemo(getIntervalRecurrenceOptions, []);
    const { formInput: intervalRecurrence, handleChange: setIntervalRecurrence } = useFormInput(
        intervalRecurrenceOptions[0],
    );
    const { formInput: intervalRepeat, handleChange: setIntervalRepeat } = useFormInput(1);
    const [hasRecurrence, setHasRecurrence] = useState(false);
    const [withHolidays, setWithHolidays] = useState(false);
    const [days, setDays] = useState<number[]>([]);
    const [selectedMonthlyOption, setSelectedMonthlyOption] = useState<
        "dateOfMonth" | "weekDayNthOccurrence" | "lastOfMonth"
    >("dateOfMonth");
    const monthlyOptions = useMemo(() => getMonthlyOptions(startDate.value), [startDate.value]);
    const hasMonthlyOptionsSelected = Boolean(
        monthlyOptions.find((option) => option.value === selectedMonthlyOption),
    );
    const dayNameList = useMemo(() => getDayNameList(), []);
    const untilDefault =
        shiftRecurrences?.until ??
        addDays(startDate.value, 6) ??
        set(addDays(endDateTime.value, 6), {
            hours: 23,
            minutes: 59,
            seconds: 59,
        });
    const { formInput: until, handleChange: setUntil } = useFormInput(untilDefault, (value) =>
        startDate.value && value < startDate.value ? i18n.task_shifts_modal_slots_error() : null,
    );
    const [isSubmitted, setIsSubmitted] = useState(false);
    const { createShift, updateShift, removeShift, isPending } = useShiftAPI(mode);

    const disableInputs = mode === "deletion" || isPending;
    const fromDate = user?.isInsider ? addYears(new Date(), -1) : new Date();
    const toDate = addYears(new Date(), 2);
    const daysError =
        !days.length &&
        hasRecurrence &&
        intervalRecurrence.value.value === "weekly" &&
        i18n.task_shifts_modal_days_error();
    const untilError =
        hasRecurrence && startDate.value && until.value < startDate.value
            ? i18n.task_shifts_modal_until_error()
            : "";
    const durationError = (() => {
        // The duration of the shift must be between 2h and 10h of effective work (break excluded)
        if (endDateTime.touched && startDateTime.touched) {
            const duration = differenceInMinutes(endDateTime.value, startDateTime.value, {
                roundingMethod: "floor",
            });
            if (duration - breakDuration.value > 600 || duration - breakDuration.value < 120) {
                return i18n.task_shifts_modal_duration_error();
            }
            return "";
        }
    })();

    // when the shift-recurrences are loaded, we set the initial values
    useEffect(() => {
        if (shiftRecurrences) {
            setIntervalRepeat(shiftRecurrences.interval);
            setIntervalRecurrence(
                intervalRecurrenceOptions.find((option) => option.value === shiftRecurrences.type)!,
            );
            setDays(shiftRecurrences.days);
            setWithHolidays(shiftRecurrences.withHolidays);
            setUntil(shiftRecurrences.until);
            setSelectedMonthlyOption(() => {
                if (shiftRecurrences.type === "monthly") {
                    if (shiftRecurrences.setPos && shiftRecurrences.setPos.includes(-1)) {
                        return "lastOfMonth";
                    }
                    return "weekDayNthOccurrence";
                }
                return "dateOfMonth";
            });
        }
        // we add bulkSelection to the dependencies to reset the recurrence fields when the bulk selection changes
    }, [isShiftRecurrencesLoading, bulkSelection]);

    // handle display recurrence
    // we want to display it if the shift has a recurrence but not if editing a single shift
    useEffect(() => {
        // if we are in edition or deletion mode and we are not in bulk selection, we don't want to display the recurrence
        if ((mode === "edition" || mode === "deletion") && bulkSelection === undefined) {
            setHasRecurrence(false);
        } else if (shiftRecurrences) {
            setHasRecurrence(true);
        } else {
            setHasRecurrence(false);
        }
    }, [bulkSelection, shiftRecurrences, mode]);

    // if we are in bulk recurrence-all, then the startDate is the first shift of the recurrence
    useEffect(() => {
        if (bulkSelection === "recurrence-all" && shiftRecurrences) {
            setStartDate(shiftRecurrences.startDate);
        } else if (shift) {
            setStartDate(shift.startDate);
        }
    }, [bulkSelection, isShiftRecurrencesLoading]);

    useEffect(() => {
        // If the start date changes, the start and end time must be updated
        if (startDate.value) {
            setStartDateTime(
                set(startDate.value, {
                    hours: getHours(startDateTime.value),
                    minutes: getMinutes(startDateTime.value),
                }),
            );
            setEndDateTime(
                set(startDate.value, {
                    hours: getHours(endDateTime.value),
                    minutes: getMinutes(endDateTime.value),
                }),
            );
            // If the start date changes, the until date must be updated
            if (!hasRecurrence) {
                setUntil(untilDefault);
            }
        }
    }, [startDate.value]);

    useEffect(() => {
        if (!isPending && isSubmitted) {
            onClose();
            (ref as React.RefObject<HTMLDialogElement | null>).current?.close();
        }
    }, [isPending]);

    useEffect(() => {
        // Check ovhernight case
        if (isSameDay(startDateTime.value, endDateTime.value)) {
            // Overnight case
            if (getHours(startDateTime.value) > getHours(endDateTime.value)) {
                setEndDateTime(addDays(endDateTime.value, 1));
            } else if (
                isSameHour(startDateTime.value, endDateTime.value) &&
                getMinutes(startDateTime.value) > getMinutes(endDateTime.value)
            ) {
                setEndDateTime(addDays(endDateTime.value, 1));
            }
        }
        // From overnight case to basic case
        else if (getHours(startDateTime.value) < getHours(endDateTime.value)) {
            setEndDateTime(subDays(endDateTime.value, 1));
        } else if (
            isSameHour(startDateTime.value, endDateTime.value) &&
            getMinutes(startDateTime.value) < getMinutes(endDateTime.value)
        ) {
            setEndDateTime(subDays(endDateTime.value, 1));
        }
    }, [startDateTime.value, endDateTime.value]);

    useEffect(() => {
        // early return to prevent overriding the backend values
        if (shiftRecurrences) return;
        // Reset the recurrence fields when the recurrence is disabled
        // or when the recurrence type changes
        if (!hasRecurrence) {
            setDays([]);
            setWithHolidays(false);
            setUntil(untilDefault);
            setSelectedMonthlyOption("dateOfMonth");
        } else if (intervalRecurrence.value.value === "monthly") {
            setSelectedMonthlyOption("dateOfMonth");
            setDays([]);
        } else if (intervalRecurrence.value.value === "weekly") {
            setDays([0, 1, 2, 3, 4]);
        }
    }, [hasRecurrence, intervalRecurrence.value]);

    useEffect(() => {
        if (hasRecurrence) {
            recurrenceRef.current?.scrollIntoView({
                behavior: "smooth",
                block: "start",
            });
        }
    }, [hasRecurrence]);

    const queryClient = useQueryClient();
    function onClose() {
        setModalShiftsStateAtom({ open: false, mode: "creation", shift: null });
        queryClient.removeQueries(queries.shiftRecurrences.detail(shift?.recurrenceId ?? ""));
    }

    function onSubmit() {
        const payload = {
            startDate: startDateTime.value.toISOString(),
            endDate: endDateTime.value.toISOString(),
            breakDuration: breakDuration.value,
            slots: slots.value,
            ...(hasRecurrence &&
                mode !== "deletion" && {
                    recurrence: {
                        interval: intervalRepeat.value,
                        type: intervalRecurrence.value.value,
                        days,
                        withHolidays,
                        until: until.value.toISOString(),
                        ...((intervalRecurrence.value.value === "monthly" &&
                            monthlyOptions.find((option) => option.value === selectedMonthlyOption)
                                ?.data) ??
                            {}),
                    },
                }),
        };
        switch (mode) {
            case "creation":
                createShift({
                    taskId,
                    shift: payload as AddShiftParams["shift"],
                });
                break;
            case "deletion":
                removeShift({
                    taskId,
                    shiftId: shift!.id!,
                    shift: payload as AddShiftParams["shift"],
                });
                break;
            case "edition":
                confirm({
                    title: i18n.task_shifts_dialog_title(),
                    message: i18n.task_shifts_dialog_message(),
                }).then(() => {
                    updateShift({
                        taskId,
                        params: {
                            shift: {
                                ...payload,
                                id: shift!.id!,
                            },
                        } satisfies UpdateShiftParams,
                    });
                });
                break;
        }
        setIsSubmitted(true);
    }

    return (
        <Modal ref={ref} onClose={onClose}>
            <ModalHeader title={i18nDK(`task_shifts_modal_${mode}_title`)} />
            <ModalContent className={styles.content}>
                {/* Edition & Deletion Bulk selection */}
                {shift?.recurrenceId && (mode === "edition" || mode === "deletion") ? (
                    <div className={styles.container}>
                        <Select
                            size='small'
                            label={
                                mode === "edition"
                                    ? i18n.task_shifts_modal_bulk_selection_label_edit()
                                    : i18n.task_shifts_modal_bulk_selection_label_delete()
                            }
                            options={[...getBulkSelectionOptions()]}
                            selection={
                                getBulkSelectionOptions().find(
                                    (option) => option.value === bulkSelection,
                                ) || null
                            }
                            onChange={(option) => setBulkSelection(option?.value || undefined)}
                        />
                    </div>
                ) : null}
                {/* Basic */}
                <ShiftDatePicker
                    label={i18n.task_shifts_modal_date_label()}
                    selected={startDate.value}
                    onSelect={(date) => setStartDate(date)}
                    disabled={disableInputs}
                    fromDate={fromDate}
                    toDate={toDate}
                    error={!startDate.value}
                />
                <div>
                    <div className={styles.flex}>
                        <TimeField
                            label={i18n.task_shifts_modal_schedule_start_label()}
                            placeholder='08:00'
                            value={startDateTime.value}
                            onChange={setStartDateTime}
                            error={!!durationError}
                            disabled={disableInputs}
                            className={styles.timefield}
                        />
                        <TimeField
                            label={i18n.task_shifts_modal_schedule_end_label()}
                            placeholder='17:00'
                            value={endDateTime.value}
                            onChange={setEndDateTime}
                            error={!!durationError}
                            disabled={disableInputs}
                            className={styles.timefield}
                        />
                        <Select
                            size='small'
                            label={
                                <div>
                                    {i18n.task_shifts_modal_break_label()}{" "}
                                    <span className={clsx(fonts.sans16Regular, styles.labelHint)}>
                                        {i18n.task_shifts_modal_break_tip_label()}
                                    </span>
                                </div>
                            }
                            placeholder={i18n.order_workers_filters_modal_isrecommended_placeholder()}
                            onChange={(option) => {
                                setBreakDuration(option?.value || 0);
                            }}
                            options={initBreakOptions()}
                            selection={
                                initBreakOptions().find(
                                    (option) => option.value === breakDuration.value,
                                ) || null
                            }
                            disabled={disableInputs}
                            error={!!durationError}
                        />
                    </div>
                    {durationError && (
                        <p className={clsx(styles.error, fonts.sans18Regular)}>{durationError}</p>
                    )}
                </div>

                <TextField
                    className={styles.siders}
                    label={i18n.task_shifts_modal_siders_label()}
                    size='small'
                    type='number'
                    value={slots.value}
                    onChange={(event) => setSlots(Number(event.target.value))}
                    min={1}
                    max={50}
                    error={slots.error || ""}
                    disabled={
                        disableInputs ||
                        // do not allow to change quantity for recruitment or replacement
                        // because we only have 1 sider on the contract for these cases
                        motiveReason === "replacement" ||
                        motiveReason === "waitingRecruitment"
                    }
                />
                {mode === "creation" ? (
                    <div className={styles.toggle} ref={recurrenceRef}>
                        <Toggle
                            id='recurrence'
                            checked={hasRecurrence}
                            onChange={() => setHasRecurrence((prev) => !prev)}
                            disabled={disableInputs}
                        />
                        <Label htmlFor='recurrence'>{i18n.task_shifts_modal_switch_repeat()}</Label>
                    </div>
                ) : null}
                {/* Recurrence */}
                {(mode === "creation" && hasRecurrence) ||
                (mode !== "creation" && hasRecurrence && bulkSelection !== undefined) ? (
                    <div className={styles.recurrence}>
                        <div className={styles.flexColumn}>
                            <Label htmlFor='recurrence-each'>
                                {i18n.task_shifts_modal_label_each()}
                            </Label>
                            <div className={styles.flex}>
                                <TextField
                                    min={1}
                                    max={50}
                                    className={styles.intervalRepeat}
                                    id='recurrence-each'
                                    type='number'
                                    aria-label={i18n.task_shifts_modal_label_each()}
                                    size='small'
                                    value={intervalRepeat.value}
                                    onChange={(event) =>
                                        setIntervalRepeat(Number(event.target.value))
                                    }
                                    disabled={disableInputs}
                                />
                                <Select
                                    size='small'
                                    aria-label={i18n.task_shifts_modal_label_each()}
                                    className={styles.selectRecurrence}
                                    onChange={setIntervalRecurrence}
                                    options={intervalRecurrenceOptions}
                                    selection={intervalRecurrence.value}
                                    disabled={disableInputs}
                                />
                            </div>
                        </div>

                        <div className={styles.flexColumn}>
                            <Label>{i18n.task_shifts_modal_label_every()}</Label>
                            {intervalRecurrence.value.value === "weekly" ? (
                                <>
                                    <div className={styles.checkDays}>
                                        {dayNameList.map((d, i) => (
                                            <CheckDayBox
                                                key={i}
                                                error={!!daysError}
                                                label={d.slice(0, 1).toUpperCase()}
                                                checked={days.includes(i)}
                                                onChange={() =>
                                                    setDays((prev) =>
                                                        prev.includes(i)
                                                            ? prev.filter((day) => day !== i)
                                                            : [...prev, i],
                                                    )
                                                }
                                                disabled={disableInputs}
                                            />
                                        ))}
                                    </div>
                                    {!days.length && (
                                        <p className={clsx(styles.error, fonts.sans18Regular)}>
                                            {daysError}
                                        </p>
                                    )}
                                </>
                            ) : (
                                monthlyOptions.map((option, i) => (
                                    <ChoiceBox
                                        key={i}
                                        className={styles.monthlyOption}
                                        label={option.label}
                                        selectionControl={
                                            <Radio
                                                disabled={disableInputs}
                                                name='monthly'
                                                value={option.value}
                                                checked={selectedMonthlyOption === option.value}
                                                onChange={() =>
                                                    setSelectedMonthlyOption(option.value)
                                                }
                                            />
                                        }
                                    />
                                ))
                            )}
                        </div>
                        <div className={styles.toggle}>
                            <Toggle
                                id='holidays'
                                checked={withHolidays}
                                onChange={() => setWithHolidays((prev) => !prev)}
                                disabled={disableInputs}
                            />
                            <Label htmlFor='holidays'>
                                {i18n.task_shifts_modal_switch_holidays()}
                            </Label>
                        </div>
                        <ShiftDatePicker
                            label={i18n.task_shifts_modal_label_until()}
                            selected={until.value}
                            onSelect={(date) =>
                                setUntil(set(date, { hours: 23, minutes: 59, seconds: 59 }))
                            }
                            disabled={disableInputs}
                            fromDate={addDays(startDate.value || new Date(), 1)}
                            toDate={toDate}
                            error={untilError || !until.value}
                        />
                    </div>
                ) : null}
            </ModalContent>
            <ModalFooter
                mainButton={
                    <Button
                        loading={isPending}
                        type='button'
                        onClick={onSubmit}
                        iconDisposition='left'
                        icon={mode === "deletion" ? <Trash2 className='h-4 w-4' /> : undefined}
                        intention={mode === "deletion" ? "danger" : undefined}
                        disabled={
                            !!(
                                slots.error ||
                                durationError ||
                                untilError ||
                                daysError ||
                                !startDate.value ||
                                !until.value ||
                                (intervalRecurrence.value.value === "monthly" &&
                                    !hasMonthlyOptionsSelected)
                            )
                        }
                    >
                        {mode === "deletion" ? i18n.label_delete() : i18n.save()}
                    </Button>
                }
                cancelButtonLabel={i18n.cancel()}
            />
        </Modal>
    );
});

function getIntervalRecurrenceOptions() {
    const options = ["weekly", "monthly"] as const;
    return [...options].map((value) => ({
        label: i18nDK(`task_shifts_modal_option_${value}`),
        value,
    }));
}

// RRule examples and documentation https://jkbrzt.github.io/rrule/
// Monthly options are computed based on the start date
function getMonthlyOptions(startDate: Date) {
    const locale = getLocale();
    const dateOfMonth = getDate(startDate);
    const dayOfWeek = getDay(startDate); // 0 for Sunday, 1 for Monday, etc.

    // Occurrence of the week day in the month (e.g. 2nd Monday of the month)
    let weekDayNthOccurrence = 0;
    // Amount of the week day in the month (e.g. 4 or 5 Mondays in the month)
    const weekDayInMonth = (() => {
        const daysInMonth = getDaysInMonth(startDate);
        let weekDayCount = 0;
        let currentDate = startOfMonth(startDate);

        for (let day = 1; day <= daysInMonth; day++) {
            if (getDay(currentDate) === dayOfWeek) {
                weekDayCount++;
                if (isSameDay(currentDate, startDate)) {
                    weekDayNthOccurrence = weekDayCount;
                }
            }
            currentDate = addDays(currentDate, 1);
        }

        return weekDayCount;
    })();

    const rRuleDayOfWeek = dayOfWeek === 0 ? 6 : dayOfWeek - 1; // RRULE uses 0 for Monday
    const dayOfWeekName = locale.localize?.day(dayOfWeek);

    return [
        {
            label: `${dateOfMonth} ${i18n.task_shifts_modal_radio_of_the_month()}`,
            value: "dateOfMonth" as const,
            data: {}, // No data needed. The startDate defines the day of the month
        },
        ...[
            weekDayNthOccurrence === weekDayInMonth
                ? {
                      label: `${i18n.task_shifts_modal_radio_last_of_the_month()} ${dayOfWeekName} ${i18n.task_shifts_modal_radio_of_the_month()}`,
                      value: "lastOfMonth" as const,
                      data: {
                          days: [rRuleDayOfWeek],
                          setPos: [-1],
                      },
                  }
                : {
                      label: `${i18nDK(
                          `task_shifts_modal_radio_${weekDayNthOccurrence}_of_the_month`,
                      )} ${dayOfWeekName} ${i18n.task_shifts_modal_radio_of_the_month()}`,
                      value: "weekDayNthOccurrence" as const,
                      data: {
                          days: [rRuleDayOfWeek],
                          setPos: [weekDayNthOccurrence],
                      },
                  },
        ],
    ];
}

// Break options are the same accross all the platforms (Ops, Team, Inside, etc.)
// Do not edit without checking the other platforms
function initBreakOptions() {
    return [
        {
            id: 1,
            label: i18n.task_shifts_modal_break_placeholder(),
            value: 0,
        },
        ...[
            20, 30, 45, 60, 75, 90, 105, 120, 135, 150, 165, 180, 195, 210, 225, 240, 255, 270, 285,
            300,
        ].map((value, index) => {
            const hours = Math.floor(value / 60);
            const minutes = value % 60;
            return {
                id: index + 2,
                label: `${hours ? `${hours}h` : ""}${minutes ? `${minutes}min` : ""}`,
                value,
            };
        }),
    ];
}

function getDayNameList() {
    const locale = getLocale();
    return [
        ...new Array(6).fill(null).map((_, index) => locale.localize?.day(index + 1)),
        locale.localize?.day(0),
    ];
}

function getBulkSelectionOptions() {
    return [
        {
            label: i18n.task_shifts_modal_bulk_selection_this_shift_only(),
            value: undefined,
        },
        {
            label: i18n.task_shifts_modal_bulk_selection_this_shift_and_following(),
            value: "recurrence-following",
        },
        {
            label: i18n.task_shifts_modal_bulk_selection_all_shifts(),
            value: "recurrence-all",
        },
    ] satisfies Array<{ label: string; value: RecurrenceOptions }>;
}
