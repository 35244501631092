import { Tip } from "sui";

import { i18n } from "@lib/i18n";
import { queries } from "@lib/queries";
import { useQuery } from "@tanstack/react-query";
import { useFlag } from "@unleash/proxy-client-react";

import "./Quotation.scss";

const Quotation = ({ quotation, withTaxes, taskId }) => {
    const isFlexiblePlanningEnabled = useFlag("flexible-planning");
    const { data: task } = useQuery(queries.task.detail(taskId));
    const formattedAssignments = quotation?.assignments?.map((assignment) => {
        const formattedQuantity = parseFloat(assignment.quantity).toFixed(2);

        return {
            tags: assignment?.tags,
            data: [
                { value: assignment.name },
                { value: formattedQuantity },
                { value: assignment.hourlyRate },
                { value: assignment.price },
            ],
        };
    });

    const weightMap = {
        normal: 1,
        night: 2,
        extra: 3,
        sunday: 4,
        solidarity: 5,
        holidays: 6,
    };

    formattedAssignments?.sort((a, b) => {
        // default to highest weight since there is a tagname for each holiday, else set as NaN
        const aWeight = Math.min(...(a.tags?.map((tag) => weightMap[tag] || 6) || []));
        const bWeight = Math.min(...(b.tags?.map((tag) => weightMap[tag] || 6) || []));

        // in case both weights are equal, sort by smallest rateXSalary
        if (aWeight === bWeight) {
            return a.data[2].value - b.data[2].value;
        }

        return aWeight - bWeight;
    });

    const totalWithoutTaxes = quotation?.price;

    const totalWithTaxes = (parseFloat(totalWithoutTaxes) * 1.2).toFixed(2);

    return (
        <div className='order-form__quotation'>
            <div className='order-form__quotation__table'>
                <div className='order-form__quotation__table__head'>
                    <div className='order-form__quotation__table__head__cell'>
                        {i18n.order_quotation_type()}
                    </div>
                    <div className='order-form__quotation__table__head__cell'>
                        {i18n.order_quotation_quantity()}
                    </div>
                    <div className='order-form__quotation__table__head__cell'>
                        {i18n.order_quotation_price()}
                    </div>
                    <div className='order-form__quotation__table__head__cell'>
                        {i18n.order_quotation_total_ht()}
                    </div>
                </div>
                {formattedAssignments?.length && (
                    <div className='order-form__quotation__table__body'>
                        {formattedAssignments.map((assignment) => (
                            <div
                                className='order-form__quotation__table__body__row'
                                key={assignment.data[0].value}
                            >
                                {assignment.data.map((cell, idx) => {
                                    const float = parseFloat(cell.value).toFixed(2);
                                    const capitalized = /^[a-z]/.test(cell.value)
                                        ? cell.value.charAt(0).toUpperCase() + cell.value.slice(1)
                                        : cell.value;

                                    return (
                                        // Using index anyway because it's react's default behavior without key,
                                        // those elements won't move and it gets rid of the console error.
                                        // eslint-disable-next-line react/no-array-index-key
                                        <div
                                            className='order-form__quotation__table__body__row__cell'
                                            key={idx}
                                        >
                                            {float === "NaN" ? capitalized : float}
                                            {idx === 3 ? "€" : ""}
                                        </div>
                                    );
                                })}
                            </div>
                        ))}
                    </div>
                )}
            </div>
            <div className='order-form__quotation__total'>
                <div className='order-form__quotation__total--notax'>
                    <div className='order-form__quotation__total__left'>
                        {i18n.order_summary_estimate_total_without_taxes()}
                    </div>
                    <div className='order-form__quotation__total__right'>
                        {totalWithoutTaxes ? parseFloat(totalWithoutTaxes).toFixed(2) : 0}€
                    </div>
                </div>
                {isFlexiblePlanningEnabled && task?.flexiblePlanning && (
                    <div className='px-6 py-2'>
                        <Tip
                            highlight
                            intention='warning'
                            className='py-2 !typography-body-s-regular'
                        >
                            {i18n.order_summary_estimate_planning_tip()}
                        </Tip>
                    </div>
                )}
                {withTaxes === true && (
                    <div className='order-form__quotation__total--withtaxes'>
                        <div className='order-form__quotation__total__left'>
                            {i18n.order_summary_estimate_total_with_taxes()}
                        </div>
                        <div className='order-form__quotation__total__right'>{totalWithTaxes}€</div>
                    </div>
                )}
                <div className='order-form__quotation__total__disclaimer'>
                    {i18n.order_summary_estimate_disclaimer()}
                </div>
            </div>
            <div className='order-form__quotation__salary'>
                <div className='order-form__quotation__salary__left'>
                    {i18n.order_summary_estimate_base_wage()}
                </div>
                <div className='order-form__quotation__salary__right'>
                    {(+quotation?.salary).toFixed(2)}€
                </div>
            </div>
            <div className='order-form__quotation__salary__disclaimer'>
                {i18n.order_summary_base_wage_disclaimer()}
            </div>
        </div>
    );
};

export default Quotation;
