import { useState } from "react";
import clsx from "clsx";
import { X } from "lucide-react";
import { InputText, ModalSimple, Select } from "side-ui";

import { i18n, i18nDK } from "@lib/i18n";

import styles from "../../WorkConditionsAndSkillsModals.module.css";
export function EditTools({ hideModal, selectedTools, submitTools, toolsOptions }) {
    const [state, setState] = useState({
        // derived state
        // to be able to update custom tool names
        tools: selectedTools,
        touched: false,
    });

    function selectTool(t) {
        const tool = selectedTools.find(({ id, name }) => {
            if (t.id) {
                return id === t.id;
            }

            return t.name === name;
        });
        // remove it if it already exists
        if (tool) {
            return setState((prevState) => ({
                ...prevState,
                tools: prevState.tools.filter((to) => {
                    if (tool.id && to.id) {
                        return to.id !== tool.id;
                    }

                    return tool.name !== to.name;
                }),
            }));
        }

        return setState((prevState) => ({
            ...prevState,
            tools: prevState.tools?.concat(t),
        }));
    }

    function handleCustomToolChange(event, tool) {
        const toolToUpdate = state.tools.find((t) => t.id === tool.id);

        if (!toolToUpdate) {
            return "";
        }
        const { value } = event.target;

        const updatedTools = state.tools.map((t) => {
            if (t.id === tool.id) {
                return {
                    ...t,
                    name: value,
                };
            }

            return t;
        });

        return setState((prevState) => ({
            ...prevState,
            tools: updatedTools,
            touched: !!value.length,
        }));
    }

    function handleToolLevelChange(id, selectedItem) {
        const updatedTools = state.tools.map((tool) => {
            if (tool.id === id) {
                return {
                    ...tool,
                    level: selectedItem,
                    // passing the level id
                    id: toolsOptions[tool.name].levels.find((t) => t.value === selectedItem)?.id,
                };
            }

            return tool;
        });

        setState((prevState) => ({
            ...prevState,
            tools: updatedTools,
            touched: true,
        }));
    }

    return (
        <ModalSimple
            hideModal={hideModal}
            title={i18n.job_descriptions_creation_skills_tools_title()}
            cancelLabel={i18n.cancel()}
            validateLabel={i18n.save()}
            disableButton={!state.touched}
            action={() => submitTools(state.tools)}
            customClass={styles.modal}
        >
            {state.tools.map((selectedTool, index) => (
                <div key={selectedTool.id} className={clsx(styles.edit, styles.tools)}>
                    <div className={styles.title}>
                        {i18nDK(selectedTool.name)}

                        {selectedTool.name?.length ? (
                            <button
                                type='button'
                                title={i18n.label_delete()}
                                onClick={() => {
                                    selectTool(selectedTool);
                                    setState((prevState) => ({
                                        ...prevState,
                                        touched: true,
                                    }));
                                }}
                            >
                                <X className='h-4 w-4' />
                            </button>
                        ) : null}
                    </div>

                    {toolsOptions[selectedTool.name]?.levels ? (
                        <div className={styles.wrapper}>
                            <Select
                                id={`tool-edit-${selectedTool.id}`}
                                name={selectedTool.name}
                                chevronIcon={true}
                                placeholder={i18n.job_descriptions_creation_skills_tools_modal_placeholder()}
                                options={toolsOptions[selectedTool.name].levels.map((level) => ({
                                    id: level.id,
                                    label: i18nDK(level.label),
                                    value: level.value,
                                }))}
                                selectedItem={i18nDK(selectedTool.level)}
                                onChange={(_, __, selectedItem) =>
                                    handleToolLevelChange(selectedTool.id, selectedItem)
                                }
                            />
                        </div>
                    ) : null}

                    {selectedTool.custom ? (
                        <div className={styles.input__wrapper}>
                            <InputText
                                value={selectedTool.name}
                                name={`custom-tool-${index}`}
                                id={`custom-tool-${index}`}
                                smallInput={true}
                                onChange={(event) => handleCustomToolChange(event, selectedTool)}
                            />
                        </div>
                    ) : null}
                </div>
            ))}
        </ModalSimple>
    );
}
