import { ChevronLeft, ChevronRight } from "lucide-react";

import { useLocation } from "@lib/hooks/useLocation";
import { useNavigate } from "@tanstack/react-router";

import trackEvent from "../../lib/trackers";

import "./Pagination.scss";

const Pagination = (props) => {
    const {
        pageCount,
        urlParam,
        urlParamValues,
        path,
        page, // used for tracking only
    } = props;

    const location = useLocation();
    const navigate = useNavigate();

    // in this component we're giving the ability to use another argument than 'page' in the URL
    // so we have a 'param' var, an array with the values it can take
    // and we also have a currentValue var as we can't rely only on indexes/ints
    // for the actives states, we need to check values
    // the default is `page` with page numbers as values though
    const param = urlParam || `page`;
    const paramValues = urlParamValues || [...Array(pageCount + 1).keys()].slice(1);
    // when getting the page number from the URL
    let currentSearch = location && new URLSearchParams(location.search).get(param);
    if (param === `page`) currentSearch = parseInt(currentSearch, 10);
    const currentPageNumber = (currentSearch && paramValues.indexOf(currentSearch)) || 0;
    const currentValue = paramValues[currentPageNumber];

    const getPagesLinks = () => {
        const html = [];
        let endRange;
        let startRange;
        // if there's less than 5 pages, we show them all
        if (pageCount <= 5) {
            endRange = pageCount;
            startRange = 0;
            // otherwise, we show the current one, the 2 before and 2 after
            // if we're on the second to last page, we display the 3 before and the last = 1 after
        } else if (currentPageNumber === pageCount - 2) {
            endRange = currentPageNumber + 2;
            startRange = currentPageNumber - 3;
            // if we're on the last page, we display the 4 before
        } else if (currentPageNumber === pageCount - 1) {
            endRange = currentPageNumber + 1;
            startRange = currentPageNumber - 4;
            // if the page number shown doesnt start at 0
            // (when page number is at least 3 we start hiding the first page)
        } else if (currentPageNumber > 2) {
            endRange = currentPageNumber + 3;
            startRange = currentPageNumber - 2;
        } else {
            // if we're on the first pages, we need to show the 5 first page links from 0
            endRange = 5;
            startRange = 0;
        }
        for (let i = startRange; i < endRange; i += 1) {
            html.push(
                <PageLink
                    key={page + i}
                    label={i}
                    isActive={currentValue === paramValues[i]}
                    path={path}
                    page={page}
                    param={param}
                    paramValues={paramValues}
                />,
            );
        }
        return html;
    };

    if (pageCount === 0) {
        return null;
    }

    return (
        <div className='pagination'>
            {currentValue === paramValues[0] ? null : (
                <button
                    type='button'
                    className={`pagination__arrow ${
                        currentValue === paramValues[0] ? `inactive` : ``
                    }`}
                    onClick={() => {
                        if (currentPageNumber) {
                            trackEvent({
                                name: `clickPager`,
                                params: {
                                    page,
                                    number: currentPageNumber - 1,
                                },
                            });
                            navigate({
                                to: `/${path}`,
                                search: {
                                    [param]: paramValues[currentPageNumber - 1],
                                },
                            });
                        }
                    }}
                >
                    <ChevronLeft className='h-4 w-4 text-gray-500 hover:text-blue-500' />
                </button>
            )}
            <div className='pagination__numbers'>{getPagesLinks()}</div>
            <a
                className={`pagination__arrow ${
                    currentValue === paramValues[pageCount - 1] ? `inactive` : ``
                }`}
                onClick={() => {
                    if (currentPageNumber < pageCount - 1) {
                        trackEvent({
                            name: `clickPager`,
                            params: {
                                page,
                                number: currentPageNumber + 1,
                            },
                        });
                        navigate({
                            to: `/${path}`,
                            search: {
                                [param]: paramValues[currentPageNumber + 1],
                            },
                        });
                    }
                }}
            >
                <ChevronRight className='h-4 w-4 text-gray-500 hover:text-blue-500' />
            </a>
        </div>
    );
};

export default Pagination;

const PageLink = (props) => {
    const { label, isActive, path, page, param, paramValues } = props;
    const navigate = useNavigate();

    return (
        <a
            className={`pagination__item ${isActive ? `active` : ``}`}
            onClick={() => {
                trackEvent({
                    name: `clickPager`,
                    params: {
                        page,
                        number: label,
                    },
                });
                if (paramValues.length) {
                    navigate({
                        to: `/${path}`,
                        search: {
                            [param]: paramValues[label],
                        },
                    });
                }
            }}
        >
            {label + 1}
        </a>
    );
};
