import { getLegalValues } from "@lib//api/getLegalValues";
import {
    getAttendances,
    getCompanyInfo,
    getCompanyLegalInfo,
    getCompanyPaymentInfo,
    getCompanySiders,
    getFullFeedback,
    getHolidays,
    getJobDescriptionJobTitles,
    getJobDescriptionOptions,
    getJobDescriptions,
    getJobDescriptionSubtasks,
    getJobTypes,
    getNumberOfAttendances,
    getOrganisationCompletionStatus,
    getQuotation,
    getSiders,
    getTask,
    getUserInfo,
    searchJobTitles,
} from "@lib/api";
import type { GetAttendancesParams } from "@lib/api/getAttendances";
import { getCompanyMotives } from "@lib/api/getCompanyMotives";
import { getFlexiblePlanning } from "@lib/api/getFlexiblePlanning";
import {
    getFlexiblePlanningQuotation,
    GetFlexiblePlanningQuotationParams,
} from "@lib/api/getFlexiblePlanningQuotation";
import { getShiftRecurrences } from "@lib/api/getShiftRecurrences";
import { getTaskPreSelection } from "@lib/api/getTaskPreselection";
import isAuth from "@lib/utils/isAuth";

import { normalizeJobDescriptions } from "./normalizeJobDescriptions";

const oneDay = 1000 * 60 * 60 * 24;
const fiveMinutes = 1000 * 60 * 5;
export const user = {
    key: ["user"],
    detail: () => ({
        queryKey: [...user.key, "detail"],
        queryFn: () => getUserInfo(),
        enabled: isAuth(),
        staleTime: oneDay,
    }),
};

export const legalValues = {
    key: ["legalValues"],
    list: () => ({
        queryKey: [...legalValues.key, "list"],
        queryFn: getLegalValues,
    }),
};

export const attendance = {
    key: ["attendance"],
    list: (args: GetAttendancesParams) => ({
        queryKey: [...attendance.key, "list", args],
        queryFn: () => getAttendances({ limit: 50, ...args }),
    }),
    toValidateCount: () => ({
        queryKey: [...attendance.key, "toValidateCount"],
        queryFn: getNumberOfAttendances,
    }),
};

export const company = {
    key: ["company"],
    detail: () => ({
        queryKey: [...company.key, "detail"],
        queryFn: () => getCompanyInfo(),
    }),
    companySiders: (query: string) => ({
        queryKey: [...company.key, "companySiders", query],
        queryFn: () => getCompanySiders({ query }),
        enabled: !!query,
    }),
    companyMotives: () => ({
        queryKey: [...company.key, "companyMotives"],
        queryFn: ({ signal }) => getCompanyMotives(signal),
    }),
    completionStatus: () => ({
        queryKey: [...company.key, "completionStatus"],
        queryFn: getOrganisationCompletionStatus,
        staleTime: fiveMinutes,
    }),
    paymentInfo: (organisationId: string) => ({
        queryKey: [...company.key, "paymentInfo"],
        queryFn: () => getCompanyPaymentInfo({ organisationId }),
        enabled: !!organisationId,
    }),
    legalInfo: (organisationId: string) => ({
        queryKey: [...company.key, "legalInfo"],
        queryFn: () => getCompanyLegalInfo(organisationId),
        enabled: !!organisationId,
    }),
};

export const feedback = {
    key: ["feedback"],
    detail: (id: string) => ({
        queryKey: [...feedback.key, "detail", id],
        queryFn: () => getFullFeedback({ feedbackId: id }),
        enabled: !!id,
    }),
};

export const task = {
    key: ["task"],
    detail: (id: string) => ({
        queryKey: [...task.key, "detail", id],
        queryFn: () => getTask(id),
        enabled: !!id,
    }),
    planning: (taskId: string) => ({
        queryKey: [...task.key, "planning", taskId],
        queryFn: () => getFlexiblePlanning(taskId),
    }),
    flexiblePlanningQuotation: (params: GetFlexiblePlanningQuotationParams) => ({
        queryKey: [...task.key, "flexiblePlanningQuotation", params.taskId],
        queryFn: () => getFlexiblePlanningQuotation(params),
    }),
};

export const shiftRecurrences = {
    key: ["shiftRecurrences"],
    detail: (shiftId: string) => ({
        queryKey: [...shiftRecurrences.key, "list"],
        queryFn: ({ signal }) => getShiftRecurrences(shiftId, signal),
        enabled: !!shiftId,
    }),
};
export const taskPreSelection = {
    key: ["preSelection"],
    list: (taskId: string) => ({
        queryKey: [...taskPreSelection.key, "list", taskId],
        queryFn: ({ signal }) => getTaskPreSelection(taskId, signal),
        enabled: !!taskId,
    }),
};

export const holiday = {
    key: ["holiday"],
    list: (year: number) => ({
        queryKey: [...holiday.key, "list", year],
        queryFn: () => (year ? getHolidays(year) : null),
    }),
};

export const sider = {
    key: ["sider"],
    list: (params: any) => ({
        queryKey: [...sider.key, "list", { ...params }],
        queryFn: () => getSiders(params),
        enabled: !!params.taskId,
        select: (data: Awaited<ReturnType<typeof getSiders>>) => data?.siders ?? [],
    }),
};

export const quotation = {
    key: ["quotation"],
    detail: (taskId: string) => ({
        queryKey: [...quotation.key, "detail", taskId],
        queryFn: () => getQuotation({ taskId }),
        enabled: !!taskId,
    }),
};

export const jobDescriptions = {
    key: ["jobDescriptions"],
    list: () => ({
        queryKey: [...jobDescriptions.key, "list"],
        queryFn: getJobDescriptions,
        select: (data) => normalizeJobDescriptions(data),
        staleTime: fiveMinutes,
    }),
};

export const jobTitles = {
    key: ["jobTitles"],
    list: () => ({
        queryKey: [...jobTitles.key, "list"],
        queryFn: getJobDescriptionJobTitles,
        staleTime: oneDay,
    }),
    search: (query: string) => ({
        queryKey: [...jobTitles.key, "search", query],
        queryFn: () => (query ? searchJobTitles(query) : []),
    }),
};

export const jobDescriptionJobTitle = {
    key: ["jobDescriptionJobTitle"],
    list: () => ({
        queryKey: [...jobDescriptionJobTitle.key, "list"],
        queryFn: () => getJobDescriptionJobTitles(),
        select: (data: Awaited<ReturnType<typeof getJobDescriptionJobTitles>>) => {
            return data.map((jobTitle) => ({
                value: jobTitle.id,
                label: jobTitle.name,
            }));
        },
    }),
};

export const jobDescriptionSubtasks = {
    key: ["jobDescriptionSubtasks"],
    list: (jobTitleId: string) => ({
        queryKey: [...jobDescriptionSubtasks.key, "list", jobTitleId],
        queryFn: () => {
            if (!jobTitleId) return Promise.resolve([]);
            return getJobDescriptionSubtasks(jobTitleId);
        },
    }),
};

export const jobDescriptionOptions = {
    key: ["jobDescriptionOptions"],
    list: () => ({
        queryKey: [...jobDescriptionOptions.key, "list"],
        queryFn: getJobDescriptionOptions,
        staleTime: oneDay,
    }),
};

export const jobTypes = {
    key: ["jobTypes"],
    list: () => ({
        queryKey: [...jobTypes.key, "list"],
        queryFn: getJobTypes,
    }),
};

let autocompleteService: google.maps.places.AutocompleteService | null = null;

export const googlePlacesPredictions = {
    key: ["googlePlacesPredictions"],
    list: (searchValue: string, locale?: string) => ({
        queryKey: [...googlePlacesPredictions.key, "list", searchValue],
        queryFn: async (): Promise<google.maps.places.AutocompletePrediction[]> => {
            // Ensure the service is initialized once
            if (!autocompleteService) {
                autocompleteService = new window.google.maps.places.AutocompleteService();
            }

            const query = {
                componentRestrictions: {
                    country: [locale ?? "fr"],
                },
                input: searchValue,
            };

            return new Promise((resolve, reject) => {
                autocompleteService?.getPlacePredictions(query, (predictions, status) => {
                    if (
                        status === window.google.maps.places.PlacesServiceStatus.OK &&
                        predictions
                    ) {
                        resolve(predictions);
                    } else {
                        reject(status);
                    }
                });
            });
        },
    }),
};
