import { Building2, List, School } from "lucide-react";
import { CollapsablePanel, Loader, Overlay, ProfileSection, SlidingWrapper } from "side-ui";

import { i18n, i18nDK } from "@lib/i18n";
import { queries } from "@lib/queries";
import { useQuery } from "@tanstack/react-query";

import SiderDetails from "../../../components/SiderDetails";

import ProfileItem from "./ProfileItem";

import "./SiderProfilePanel.scss";

const SiderProfilePanel = (props) => {
    const { data: companyInfoData } = useQuery(queries.company.detail());
    const companyName = companyInfoData?.organisation.name;
    const groupeName = companyInfoData?.parent;
    const { isVisible, setSiderProfileVisibility, selectedSider, isLoading, taskCategory } = props;

    const sortedEducationsArray =
        selectedSider?.educations?.sort((a, b) => b.endYear - a.endYear) || [];

    const sortedExperiencesArray =
        selectedSider?.experiences?.sort((a, b) => b.duration - a.duration) || [];

    const industryExperiencesArray = sortedExperiencesArray?.filter((experience) =>
        experience?.categories?.includes(taskCategory),
    );

    const otherExperiencesArray = sortedExperiencesArray?.filter((experience) =>
        experience?.categories ? !experience?.categories.includes(taskCategory) : true,
    );

    const sortedCompanyTasksArray =
        selectedSider?.companyTasks?.sort((a, b) => (b.workedHours || 0) - (a.workedHours || 0)) ||
        [];

    const sortedOngoingTasksArray =
        selectedSider?.ongoingTasks?.sort((a, b) => a.startDate.diff(b.endDate)) || [];

    const sortedJobTypesArray =
        selectedSider?.jobTypes?.sort((a, b) => b.workedHours - a.workedHours) || [];

    const numberOfTasksInJobtypes = sortedJobTypesArray
        ?.map((jobType) => jobType.tasksCount)
        .reduce((a, b) => a + b, 0);

    const totalNumberOfTasks =
        (numberOfTasksInJobtypes || 0) + (sortedCompanyTasksArray?.length || 0);

    const totalNumberOfExperiences =
        (otherExperiencesArray?.length || 0) + (industryExperiencesArray?.length || 0);

    return selectedSider ? (
        <div className='sider__profile__panel' id='sider__profile__panel' key={selectedSider.id}>
            <Overlay
                position='fixed'
                isVisible={isVisible && selectedSider}
                toggleVisibility={() => setSiderProfileVisibility(!isVisible)}
                backgroundColor='transparent'
            />
            <SlidingWrapper
                isVisible={isVisible && selectedSider}
                toggleVisibility={() => setSiderProfileVisibility(!isVisible)}
                position='right'
            >
                {!isLoading ? (
                    <>
                        <div className='sider__profile__panel__header'>
                            <SiderDetails
                                firstName={selectedSider.firstName}
                                lastName={selectedSider.lastName}
                                pictureUrl={selectedSider.pictureUrl}
                                phoneNumber={selectedSider.phoneNumber && selectedSider.phoneNumber}
                                emailAddress={
                                    selectedSider.emailAddress && selectedSider.emailAddress
                                }
                                closeFunction={() => setSiderProfileVisibility(!isVisible)}
                            />
                        </div>
                        <div className='sider__profile__panel__sections'>
                            {sortedOngoingTasksArray?.length > 0 && (
                                <ProfileSection
                                    title={i18n.sider_profile_panel_tasks_ongoing({
                                        plural: sortedOngoingTasksArray?.length > 1 ? `s` : ``,
                                    })}
                                    className='sider__profile__panel__section sider__profile__panel__section--ongoing-tasks'
                                >
                                    <div className='sider__profile__panel__section__content'>
                                        {}
                                        {sortedOngoingTasksArray?.map((ongoingTask) => {
                                            const {
                                                id,
                                                name,
                                                startDate,
                                                endDate,
                                                positiveFeedbacks,
                                            } = ongoingTask;

                                            return (
                                                <ProfileItem
                                                    key={id}
                                                    id={id}
                                                    title={name}
                                                    startDate={startDate}
                                                    endDate={endDate}
                                                    feedbacks={positiveFeedbacks}
                                                />
                                            );
                                        })}
                                    </div>
                                </ProfileSection>
                            )}
                            {(sortedCompanyTasksArray?.length > 0 ||
                                sortedJobTypesArray?.length > 0) && (
                                <CollapsablePanel
                                    title={i18n.sider_profile_panel_tasks_side()}
                                    titleChildren={totalNumberOfTasks}
                                    icon={<List className='h-4 w-4 !fill-none' />}
                                    className='sider__profile__panel__collapsable'
                                >
                                    {sortedCompanyTasksArray?.length > 0 && (
                                        <ProfileSection
                                            title={i18n.sider_profile_panel_tasks_company({
                                                companyName: !groupeName ? companyName : groupeName,
                                                plural:
                                                    sortedCompanyTasksArray?.length > 1 ? `s` : ``,
                                                count: sortedCompanyTasksArray?.length,
                                            })}
                                            className='sider__profile__panel__section sider__profile__panel__section--company-tasks'
                                        >
                                            <div className='sider__profile__panel__section__content'>
                                                {}
                                                {sortedCompanyTasksArray?.map((companyTask) => {
                                                    const {
                                                        id,
                                                        name,
                                                        startDate,
                                                        endDate,
                                                        positiveFeedbacks,
                                                        workedHours,
                                                    } = companyTask;

                                                    return (
                                                        <ProfileItem
                                                            key={id}
                                                            id={id}
                                                            title={name}
                                                            startDate={startDate}
                                                            endDate={endDate}
                                                            feedbacks={positiveFeedbacks}
                                                            duration={i18n.sider_profile_panel_hours(
                                                                {
                                                                    workedHours,
                                                                    numberOfHours: workedHours,
                                                                    plural:
                                                                        workedHours > 1 ? `s` : ``,
                                                                },
                                                            )}
                                                        />
                                                    );
                                                })}
                                            </div>
                                        </ProfileSection>
                                    )}
                                    {sortedJobTypesArray?.length > 0 && (
                                        <ProfileSection
                                            title={i18n.sider_profile_panel_tasks_other({
                                                plural: numberOfTasksInJobtypes > 1 ? `s` : ``,
                                                count: numberOfTasksInJobtypes,
                                            })}
                                            className='sider__profile__panel__section sider__profile__panel__section--jobtypes'
                                        >
                                            <div className='sider__profile__panel__section__content'>
                                                {}
                                                {sortedJobTypesArray?.map((jobType) => {
                                                    const {
                                                        id,
                                                        name,
                                                        positiveFeedbacks,
                                                        workedHours,
                                                        tasksCount,
                                                    } = jobType;

                                                    return (
                                                        <ProfileItem
                                                            key={id}
                                                            id={id}
                                                            title={name}
                                                            tasksCount={tasksCount}
                                                            jobTypeFeedbacks={positiveFeedbacks}
                                                            duration={i18n.sider_profile_panel_hours(
                                                                {
                                                                    workedHours,
                                                                    numberOfHours: workedHours,
                                                                    plural:
                                                                        workedHours > 1 ? `s` : ``,
                                                                },
                                                            )}
                                                        />
                                                    );
                                                })}
                                            </div>
                                        </ProfileSection>
                                    )}
                                </CollapsablePanel>
                            )}
                            {(selectedSider?.experiences?.length || 0) > 0 && (
                                <CollapsablePanel
                                    title={i18n.sider_profile_panel_experiences()}
                                    titleChildren={
                                        totalNumberOfExperiences && totalNumberOfExperiences
                                    }
                                    icon={<Building2 className='h-4 w-4 !fill-none' />}
                                    className='sider__profile__panel__collapsable'
                                >
                                    {industryExperiencesArray.length > 0 && (
                                        <ProfileSection
                                            title={i18n.sider_profile_panel_experiences_industry({
                                                industryName: i18nDK(`industry_${taskCategory}`),
                                                plural:
                                                    industryExperiencesArray?.length > 1 ? `s` : ``,
                                                count: industryExperiencesArray?.length,
                                            })}
                                            className='sider__profile__panel__section sider__profile__panel__section--jobtypes'
                                        >
                                            <div className='sider__profile__panel__section__content'>
                                                {}
                                                {industryExperiencesArray?.map((experience) => {
                                                    const {
                                                        id,
                                                        name,
                                                        companyName,
                                                        duration,
                                                        subtasks,
                                                    } = experience;

                                                    let workDuration =
                                                        i18n.sider_profile_panel_months({
                                                            duration,
                                                            numberOfMonths: duration,
                                                            plural: duration > 1 ? `s` : ``,
                                                        });

                                                    if (duration < 1) {
                                                        workDuration =
                                                            i18n.sider_profile_panel_weeks();
                                                    }

                                                    if (duration > 12) {
                                                        const workedYears =
                                                            Math.floor((duration / 12) * 10) / 10;
                                                        workDuration =
                                                            i18n.sider_profile_panel_years({
                                                                workedYears,
                                                                numberOfYears: workedYears,
                                                                plural: workedYears > 1 ? `s` : ``,
                                                            });
                                                    }

                                                    return (
                                                        <ProfileItem
                                                            key={id}
                                                            id={id}
                                                            title={name}
                                                            subtitle={companyName}
                                                            subitems={subtasks}
                                                            duration={workDuration}
                                                        />
                                                    );
                                                })}
                                            </div>
                                        </ProfileSection>
                                    )}
                                    {otherExperiencesArray?.length > 0 && (
                                        <ProfileSection
                                            title={i18n.sider_profile_panel_experiences_other({
                                                plural:
                                                    otherExperiencesArray?.length > 1 ? `s` : ``,
                                                count: otherExperiencesArray?.length,
                                            })}
                                            className='sider__profile__panel__section sider__profile__panel__section--jobtypes'
                                        >
                                            <div className='sider__profile__panel__section__content'>
                                                {}
                                                {otherExperiencesArray?.map((experience) => {
                                                    const {
                                                        id,
                                                        name,
                                                        companyName,
                                                        duration,
                                                        subtasks,
                                                    } = experience;

                                                    let workDuration =
                                                        i18n.sider_profile_panel_months({
                                                            duration,
                                                            numberOfMonths: duration,
                                                            plural: duration > 1 ? `s` : ``,
                                                        });

                                                    if (duration < 1) {
                                                        workDuration =
                                                            i18n.sider_profile_panel_weeks();
                                                    }

                                                    if (duration > 12) {
                                                        const workedYears =
                                                            Math.floor((duration / 12) * 10) / 10;
                                                        workDuration =
                                                            i18n.sider_profile_panel_years({
                                                                workedYears,
                                                                numberOfYears: workedYears,
                                                                plural: workedYears > 1 ? `s` : ``,
                                                            });
                                                    }

                                                    return (
                                                        <ProfileItem
                                                            key={id}
                                                            id={id}
                                                            title={name}
                                                            subtitle={companyName}
                                                            subitems={subtasks}
                                                            duration={workDuration}
                                                        />
                                                    );
                                                })}
                                            </div>
                                        </ProfileSection>
                                    )}
                                </CollapsablePanel>
                            )}
                            {(selectedSider?.educations?.length || 0) > 0 && (
                                <CollapsablePanel
                                    title={i18n.sider_profile_panel_educations()}
                                    titleChildren={sortedEducationsArray?.length}
                                    icon={<School className='h-4 w-4 !fill-none' />}
                                    className='sider__profile__panel__collapsable'
                                >
                                    {sortedEducationsArray?.length > 0 && (
                                        <ProfileSection className='sider__profile__panel__section sider__profile__panel__section--jobtypes'>
                                            {}
                                            {sortedEducationsArray?.map((education) => {
                                                const {
                                                    id,
                                                    schoolName,
                                                    startYear,
                                                    endYear,
                                                    fieldOfStudy,
                                                    degree,
                                                } = education;

                                                const timePeriod = `${startYear}-${endYear}`;

                                                return (
                                                    <ProfileItem
                                                        key={id}
                                                        id={id}
                                                        subtitle={degree}
                                                        title={schoolName}
                                                        duration={timePeriod}
                                                        subitems={[{ name: fieldOfStudy }]}
                                                    />
                                                );
                                            })}
                                        </ProfileSection>
                                    )}
                                </CollapsablePanel>
                            )}
                        </div>
                    </>
                ) : (
                    <Loader />
                )}
            </SlidingWrapper>
        </div>
    ) : (
        <div />
    );
};

export default SiderProfilePanel;
